import { Collection, build, Metadata, str2int } from '@caiu/library';

import { BaseEntity } from '../shared/models';

export class Vote extends BaseEntity {
  id = 0;
  agendaItemId = 0;
  answer = '';
  answerId = 4;
  motion = false;
  second = false;
  voterName = '';
  voterId = 0;
  voterIsAbsent = false;
  voterLastName = '';

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        'answer',
        'fullName',
        'voterLastName'
      ]
    });
  }

  set fullName(value: string) {

  }

  get fullName(): string {
    return `${this.voterName} ${this.voterLastName}`;
  }

  set voteId(value: string) {

  }

  get voteId(): string {
    return `${this.agendaItemId}_${this.voterId}`;
  }
}

export class Votes extends Collection<Vote> {

  constructor() {
    super(Vote);
  }

  get totalVotes(): number {
    return this.count;
  }

  get totalAbstain(): number {
    return this.asArray
      .filter(vote => vote.answerId === 3).length;
  }

  get totalNay(): number {
    return this.asArray
      .filter(vote => vote.answerId === 2).length;
  }

  get totalNotCast(): number {
    return this.asArray
      .filter(vote => vote.answerId === 4).length;
  }

  get totalYea(): number {
    return this.asArray
      .filter(vote => vote.answerId === 1).length;
  }

  getVoters(answerId: number): string {
    return this.asArray
      .filter(vote => vote.answerId === answerId)
      .map(x => `${x.voterName} ${x.voterLastName}`.trim())
      .join(', ');
  }

  get yeaVoters(): string {
    return this.getVoters(1);
  }

  get nayVoters(): string {
    return this.getVoters(2);
  }

  get abstainVoters(): string {
    return this.getVoters(3);
  }

  get notCastVoters(): string {
    return this.getVoters(4);
  }

  update(data: Vote | Vote[]): Votes {
    return build(Votes, super.update(data));
  }
}

export class VoteEdit {
  voteAnswerId = 0;
  metadata: Metadata = {
    ignore: ['_vote', 'vote']
    // readonly: ['voterId', 'voterIsAbsent', 'voterName']
  };

  static Build(vote: Vote, props: any = {}): VoteEdit {
    return Object.assign(new VoteEdit(vote), props);
  }

  static BuildVote(vote: Vote, props: any = {}): Vote {
    return VoteEdit.Build(vote, { voteAnswerId: props['voteAnswerId'] }).vote;
  }

  constructor(private _vote: Vote = new Vote()) {
    this.voteAnswerId = this._vote.answerId;
  }

  get vote(): Vote {
    return build(Vote, this._vote, {
      answerId: str2int(this.voteAnswerId.toString())
    });
  }

  get voteId(): any {
    return this._vote.voteId;
  }

  get voterId(): number {
    return this._vote.voterId;
  }

  get voterIsAbsent(): boolean {
    return this._vote.voterIsAbsent;
  }

  get voterName(): string {
    return this._vote.voterName;
  }
}
