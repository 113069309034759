<div>
    <button
        mat-raised-button
        class="minutes-view"
        (click)="togglePopout( $event )"
    >
        <mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon>
        <span class="icon-text">{{ buttonLabel }}</span>
    </button>
</div>

