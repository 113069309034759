import { Injectable } from '@angular/core';
import { Action, RouterActions, routeParamIdSelector } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GroupActions, GroupsActions } from './groups.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class GroupsEffects {
  /**
   * Redirect to add group form after deleting group.
   */
  @Effect() onDelete: Observable<Action> = this.actions$.pipe(
    ofType(GroupActions.DELETE),
    map((action: Action) => RouterActions.navigate(this.groupsLink))
  );

  /**
   * Redirect to group edit form after adding new group.
   */
  @Effect() onPost: Observable<Action> = this.actions$.pipe(
    ofType(GroupsActions.POST),
    map((action: Action) => RouterActions.navigate(`/${this.groupsLink}/${action.payload.id}/edit`))
  );

  accountUrl = '';

  constructor(private actions$: Actions, private store: Store<any>) {
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
  }

  get groupsLink(): string {
    return `/${this.accountUrl}/groups`;
  }
}
