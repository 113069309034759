import { Collection, Metadata, build } from '@caiu/library';

import { BaseEntity } from '../shared/models';

export type NotesEditor = 'notes' | 'minutes';

export class AgendaItemNotes extends BaseEntity {
  id = 0;
  agendaId = 0;
  agendaItemId = 0;
  notes = '';
  typeId = 1;
  typeName = 'Notes';

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: ['id', 'agendaId', 'agendaItemId', 'createdBy', 'typeId', 'typeName']
    });
  }
}

export class Notes extends Collection<AgendaItemNotes> {

  constructor() {
    super(AgendaItemNotes);
  }

  update(data: AgendaItemNotes | AgendaItemNotes[]): Notes {
    return build(Notes, super.update(data));
  }
}
