import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';
import { DateRange, build, Control } from '@caiu/library';

export const DATERANGE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangeComponent),
  multi: true
};

@Component({
  selector: 'am-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [DATERANGE_ACCESSOR]
})
export class DateRangeComponent implements ControlValueAccessor, OnInit {
  form = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl()
  });
  @Input() debug = false;
  @Input() inline = false;
  @Input() min: Date;
  @Input() max: Date;
  @Input() startAt: Date;
  @Input() startLabel = 'Start Date';
  @Input() endLabel = 'End Date';
  @Input() startView;
  @Input() touchUi;
  @Output() startDateChanged = new EventEmitter<Date>();
  @Output() endDateChanged = new EventEmitter<Date>();
  @ViewChild('startDate') startDatepicker;
  @ViewChild('endDate') endDatepicker;
  private onModelChange: Function;
  private onTouch: Function;
  _value: DateRange = new DateRange();
  focused: DateRange = new DateRange();
  now = new Date();

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.form.valueChanges.subscribe(x => {
      this.onChange(x);
    });
  }

  get value(): DateRange {
    return this.form.value;
  }

  @Input()
  set value(value: DateRange) {
    this.form.setValue(value);
  }

  get startDateId() {
    return this.startDatepicker.id;
  }

  get startDateOpened() {
    return this.startDatepicker.opened;
  }

  get endDateId() {
    return this.endDatepicker.id;
  }

  get endDateOpened() {
    return this.endDatepicker.opened;
  }

  get startDateValue(): Date {
    return this.value.startDate ? new Date(this.value.startDate) : null;
  }

  set startDateValue(value: Date) {
    this.changeStartDate(value);
  }

  get endDateValue(): Date {
    return this.value.endDate ? new Date(this.value.endDate) : null;
  }

  set endDateValue(value: Date) {
    this.changeEndDate(value);
  }

  get startDateLastDay() {
    return new Date(
      this.startDateValue.getFullYear(),
      this.startDateValue.getMonth() + 1,
      0
    );
  }

  filter = (d: Date): boolean => {
    return this.ifMonthSame(d) || this.ifMonthDifferent(d);
  }

  changeStartDate(date: Date) {
    this.onChange(
      build(DateRange, {
        endDate: this.value.endDate,
        startDate: date
      })
    );
    this.startDateChanged.emit(date);
  }

  ifMonthSame(d: Date) {
    return (
      d.getMonth() === this.startDateValue.getMonth() &&
      d.getDate() >= this.startDateValue.getDate() &&
      d.getFullYear() === this.startDateValue.getFullYear()
    );
  }

  ifMonthDifferent(d: Date) {
    return (
      (d.getMonth() > this.startDateValue.getMonth() &&
        d.getFullYear() >= this.startDateValue.getFullYear() &&
        (1 < d.getDate() ||
          this.startDateValue.getDate() <= this.startDateLastDay.getDate())) ||
      d.getFullYear() > this.startDateValue.getFullYear()
    );
  }

  changeEndDate(date: Date) {
    this.onChange(
      build(DateRange, {
        endDate: date,
        startDate: this.value.startDate
      })
    );
    this.endDateChanged.emit(date);
  }

  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  writeValue(value: DateRange) {
    this.value = value;
  }

  onChange(value: DateRange) {
    // this.value = value;
    if (this.onModelChange) {
      this.onModelChange(value);
    }
  }

  onBlur(value: DateRange) { }

  onFocus(value: DateRange) {
    this.focused = value;
    if (this.onTouch) {
      this.onTouch();
    }
  }
}
