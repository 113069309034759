import { Router } from '@angular/router'
import {
  Component,
  OnInit,
  Input,
  ViewChild
} from '@angular/core'
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations'
import { FormGroup } from '@angular/forms'
import {
  SmartComponent,
  AccordionComponent,
  FULL_PLUGINS,
  FULL_TOOLBAR,
  HttpActions,
  Control,
  build,
  routeParamIdSelector,
  MessageSubscription,
  RouterActions
} from '@caiu/library'
import { createAction, props, Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import {
  AgendaItemNotes,
  Notes,
  NotesEditor
} from './notes.model'
import {
  NotesActions,
  agendaItemNotesSelector,
  notesSelector
} from './notes.reducer'
import {
  AgendaItemMinutes,
  Minutes
} from '../minutes/minutes.model'
import {
  MinutesActions,
  agendaItemMinutesSelector,
  minutesSelector
} from '../minutes/minutes.reducer'
import { currentUserIdSelector } from '../shared/selectors'
import { isMinuteTakerSelector } from '../members/members.reducer'
import {
  nextAgendaItemLinkSelector,
  previousAgendaItemLinkSelector
} from '../agenda-items/agenda-items.reducer'
import { PostMessagePayload } from '../popout/popout.model'
import { Logger } from '../shared/utils'
import { PopoutComponent } from '../popout/popout.component'
import { accountUrlSelector } from '../accounts/accounts.reducer'
import { ImageUpload } from '../shared/models'
import { environment } from 'src/environments/environment'

const DeleteMinutesQuite = createAction(
  MinutesActions.DELETE_QUITE,
  props<{ payload: number }>()
)

const DeleteNotesQuiet = createAction(
  NotesActions.DELETE_QUIET,
  props<{ payload: number }>()
)

@Component( {
  selector: 'am-notes',
  templateUrl: './notes.component.html',
  styleUrls: [ './notes.component.scss' ],
  animations: [
    trigger( 'toggle', [
      state('show', style( {} ) ),
      state('hide', style( { transform: 'rotate(180deg)' } ) ),
      transition( 'show <=> hide', [
        animate( '500ms ease-out' )
      ] )
    ] )
  ]
} )
export class NotesComponent extends SmartComponent implements OnInit {
  @Control( AgendaItemNotes ) form: FormGroup
  @Input() opened = false
  @ViewChild( AccordionComponent ) accordion: AccordionComponent
  @ViewChild( PopoutComponent ) popout: PopoutComponent
  
  readonly logger = new Logger( NotesComponent.name )
  
  accordionPreviousState: boolean | null = null
  account = ''
  account$: Observable<string>
  agendaId = 0
  agendaId$: Observable<number>
  _agendaItemId = 0
  agendaItemId$: Observable<number>
  baseMinutes: Minutes = new Minutes()
  baseMinutes$: Observable<Minutes>
  baseNotes: Notes = new Notes()
  baseNotes$: Observable<Notes>
  isMinuteTaker = false
  isMinuteTaker$: Observable<boolean>
  meetingId = 0
  meetingId$: Observable<number>
  _minutes: AgendaItemMinutes = new AgendaItemMinutes()
  minutes$: Observable<AgendaItemMinutes>
  next = ''
  next$: Observable<string>
  _notes: AgendaItemNotes = new AgendaItemNotes()
  notes$: Observable<AgendaItemNotes>
  previous = ''
  previous$: Observable<string>
  _type: NotesEditor = 'notes'
  userId = 0
  userId$: Observable<number>
  messages = [
    build(MessageSubscription, {
      action: NotesActions.DELETE,
      channel: 'TOASTS',
      mapper: e => `Notes deleted successfully!`
    }),
    build(MessageSubscription, {
      action: NotesActions.DELETE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error deleting notes.`
    }),
    build(MessageSubscription, {
      action: NotesActions.POST,
      channel: 'TOASTS',
      mapper: e => `Notes saved successfully!`
    }),
    build(MessageSubscription, {
      action: NotesActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving notes.`
    }),
    build(MessageSubscription, {
      action: NotesActions.PUT,
      channel: 'TOASTS',
      mapper: e => `Notes saved successfully!`
    }),
    build(MessageSubscription, {
      action: NotesActions.PUT_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving notes.`
    }),
    build(MessageSubscription, {
      action: MinutesActions.DELETE,
      channel: 'TOASTS',
      mapper: e => `Minutes deleted successfully!`
    }),
    build(MessageSubscription, {
      action: MinutesActions.DELETE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error deleting minutes.`
    }),
    build(MessageSubscription, {
      action: MinutesActions.POST,
      channel: 'TOASTS',
      mapper: e => `Minutes saved successfully!`
    }),
    build(MessageSubscription, {
      action: MinutesActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving minutes.`
    }),
    build(MessageSubscription, {
      action: MinutesActions.PUT,
      channel: 'TOASTS',
      mapper: e => `Minutes saved successfully!`
    }),
    build(MessageSubscription, {
      action: MinutesActions.PUT_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving minutes.`
    })
  ]

  editorArgs = {
    // images_upload_url: `${environment.apiBaseUrl}/images`,
    // spellchecker_rpc_url: '../../../assets/spellchecker/spellchecker.php',
    // spellchecker_rpc_url: 'spellchecker.php',
    images_upload_base_path: environment.apiBaseUrl,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    images_reuse_filename: true,
    images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
    images_upload_handler: function ( blobInfo, success ) {
      //* Create a JSON payload containing the file name and file bytes and serialize it to a string
      const img = build( ImageUpload, {
        fileContent: blobInfo.base64(),
        fileName: blobInfo.filename(),
        fileSize: blobInfo.blob().size,
        mimeType: blobInfo.blob().type
      } )
      const body = JSON.stringify( img )
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
      
      ( async () => {
        const response = fetch( `${ environment.apiBaseUrl }/images`, {
          method: 'POST',
          headers,
          body
        } )
        await ( await response ).json()
          .then( ( data: ImageUpload ) => success( `${ environment.apiBaseUrl }/${ data.location }` ) )
          .catch( ( error ) => {
            console.error( error )
          } )
      } )()
    }
  }
  editorPlugins = [ ...FULL_PLUGINS, 'imagetools' ]
  editorToolbar = [ ...FULL_TOOLBAR ]
  editorExpanded = false

  constructor(
    public store: Store<any>,
    public router: Router
  ) {
    super( store )

    this.account$ = accountUrlSelector( store )
    this.agendaId$ = routeParamIdSelector( store, 'agendaId' )
    this.agendaItemId$ = routeParamIdSelector( store, 'agendaItemId' )
    this.isMinuteTaker$ = isMinuteTakerSelector( store )
    this.meetingId$ = routeParamIdSelector( store, 'meetingId' )
    this.minutes$ = agendaItemMinutesSelector( store )
    this.notes$ = agendaItemNotesSelector( store )
    this.userId$ = currentUserIdSelector( store )
    this.next$ = nextAgendaItemLinkSelector( store )
    this.previous$ = previousAgendaItemLinkSelector( store )
    this.baseMinutes$ = minutesSelector( store )
    this.baseNotes$ = notesSelector( store )
  }

  set agendaItemId( value: number ) {
    this._agendaItemId = value

    if ( value ) {
      this.getNotes( value )
      this.getMinutes( value )
    }
  }

  get agendaItemId(): number {
    return this._agendaItemId
  }

  get editorDisplay(): string {
    return this.opened && !this.PopoutIsOpen
      ? 'block'
      : 'none'
  }

  set minutes( value: AgendaItemMinutes ) {
    this.logger.log( 'minutes setter', { value } )
    this._minutes = value

    if ( this.type === 'minutes' )
      this.setValue( value )
  }

  get minutes(): AgendaItemMinutes {
    return this._minutes
  }

  get minutesViewLink(): any {
    return [ `/`, { outlets: { popup: 'minutes' } } ]
  }

  set notes( value: AgendaItemNotes ) {
    this._notes = value

    if ( this.type === 'notes' )
      this.setValue( value )
  }

  get notes(): AgendaItemNotes {
    return this._notes
  }

  set type( value: NotesEditor ) {
    this._type = value

    if ( value === 'minutes' )
      this.setValue( this.minutes )

    if ( value === 'notes' )
      this.setValue( this.notes )
  }

  get type(): NotesEditor {
    return this._type
  }

  get valueOut(): AgendaItemMinutes | AgendaItemNotes {
    return this.type === 'notes'
      ? build( AgendaItemNotes, this.notes, this.form.value )
      : build( AgendaItemMinutes, this.minutes, this.form.value )
  }

  get PopoutURL() {
    const queryParams = {
      account: this.account,
      agendaId: this.agendaId,
      agendaItemId: this.agendaItemId,
      meetingId: this.meetingId,
      isMinuteTaker: this.isMinuteTaker,
      type: this.type.toLowerCase()
    }

    return this.router.serializeUrl( this.router.createUrlTree( [ 'popouts', 'minutes' ], { queryParams } ) )
  }

  get PopoutTarget() {
    return 'Minutes'
  }

  get PopoutLabel() {
    return this.PopoutIsOpen
      ? 'Close Editor View'
      : 'Open Editor View'
  }

  get PopoutIsOpen() {
    return this.popout?.isOpen ?? false
  }

  ngOnInit() {
    this.sync( [
      'account',
      'agendaId',
      'agendaItemId',
      'isMinuteTaker',
      'meetingId',
      'minutes',
      'notes',
      'next',
      'previous',
      'baseMinutes',
      'baseNotes',
    ] )

    this.onInit()
  }

  changeEditor(event) {
    if (
      event
      && event.event
      && event.event.command === 'mceFullScreen'
    ) {
      this.editorExpanded = !this.editorExpanded
    }
  }

  messageHandler( data: PostMessagePayload<any> ) {
    this.logger.log( 'messageHandler', { data } )

    switch( data.action ) {
      case 'previous':
        this.logger.log( 'messageHandler previous', { data } )
        this.previousItem()

        break
      case 'next':
        this.logger.log( 'messageHandler next', { data } )
        this.nextItem()

        break
      case 'toggle':
        this.logger.log( 'messageHandler toggle', { data } )
        this.toggleEditor( data.data.data.value as NotesEditor )

        break
      case 'addMinutes':
        this.logger.log( 'messageHandler addMinutes', { data } )
        this.getMinutes( this.agendaItemId )

        break
      case 'addNotes':
        this.logger.log( 'messageHandler addNotes', { data } )
        this.getNotes( this.agendaItemId )

        break
      case 'deleteMinutes':
        this.logger.log( 'messageHandler deleteMinutes', { data } )
        this.store.dispatch( DeleteMinutesQuite( { payload: this.minutes.id } ) )
        this.form.markAsPristine()

        break
      case 'deleteNotes':
        this.logger.log( 'messageHandler deleteNotes', { data } )
        this.store.dispatch( DeleteNotesQuiet( { payload: this.notes.id } ) )
        this.form.markAsPristine()

        break
      case 'updateMinutes':
        this.logger.log( 'messageHandler updateMinutes', { data } )
        this.getMinutes( this.agendaItemId )

        break
      case 'updateNotes':
        this.logger.log( 'messageHandler updateNotes' )
        this.getNotes( this.agendaItemId )

        break
      case 'close':
        this.logger.log( 'messageHandler close' )
        this.checkPreviousState()

        break
      default:
        this.logger.log( `messageHandler no handler for action "${ data.action }" found.` )
    }
  }

  close() {
    this.accordion.close()
    this.opened = false
  }

  previousItem( event?: Event ) {
    event?.stopPropagation()

    if ( !this.previous ) return

    this.popout && this.popout.sendMessage( { action: 'update', data: { value: this.previous } } )
    this.dispatch( RouterActions.navigate( this.previous ) )
  }

  nextItem( event?: Event ) {
    event?.stopPropagation()

    if ( !this.next ) return
    
    this.popout && this.popout.sendMessage( { action: 'update', data: { value: this.next } } )
    this.dispatch( RouterActions.navigate( this.next ) )
  }

  onDelete( event: Event ) {
    event.stopPropagation()

    if ( this.type === 'notes' ) {
      this.deleteNotes( this.notes.id )
    } else {
      this.deleteMinutes( this.minutes.id )
    }
  }

  onSave( event: Event ) {
    event.stopPropagation()

    if ( this.type === 'notes' ) {
      if (this.notes.id === 0) {
        this.addNotes( <AgendaItemNotes>this.valueOut )
      } else {
        this.updateNotes( <AgendaItemNotes>this.valueOut )
      }
    }
    
    if ( this.type === 'minutes' ) {
      if (this.minutes.id === 0) {
        this.addMinutes( <AgendaItemMinutes>this.valueOut )
      } else {
        this.updateMinutes( <AgendaItemMinutes>this.valueOut )
      }
    }

    this.form.markAsPristine()
  }

  open() {
    this.accordion.open()
    this.opened = true
  }

  toggle() {
    this.opened = !this.opened

    if ( this.opened )
      return this.accordion.open()

    this.accordion.close()
  }

  private checkPreviousState() {
    //* If we lose the previous state bail early
    if (this.accordionPreviousState === null) return

    //* If the current & previous state match, no need to continue
    if (this.accordionPreviousState === this.opened) return

    this.accordionPreviousState = null
    this.toggle()
  }

  popoutButtonClick( event: MouseEvent, isOpen: boolean ) {
    this.logger.log( 'popoutButtonClick', { event, isOpen } )

    if ( isOpen ) {
      this.accordionPreviousState = this.opened
      this.opened = false
      this.accordion.close()

      return
    }

    this.checkPreviousState()
  }

  onStart( event: Event ) {}

  onDone( event: Event ) {
    if ( this.opened )
      return this.accordion.open()

    this.accordion.close()
  }

  private toggleEditor( type: NotesEditor ) {
    this.type = type

    !this.opened && this.open()
  }

  onClickNotes( event: Event ) {
    event?.stopPropagation()

    this.toggleEditor( 'notes' )

    this.popout?.isOpen && this.popout.sendMessage(
      { action: 'toggle', data: { value: 'notes' } }
    )
  }

  onClickMinutes( event: Event ) {
    event?.stopPropagation()

    this.toggleEditor( 'minutes' )

    this.popout?.isOpen && this.popout.sendMessage(
      { action: 'toggle', data: { value: 'minutes' } }
    )
  }

  addMinutes( minutes: AgendaItemMinutes ) {
    this.dispatch( HttpActions.post(
      `minutes`,
      minutes,
      MinutesActions.POST,
      MinutesActions.POST_ERROR
    ) )
  }

  addNotes( notes: AgendaItemNotes ) {
    this.dispatch( HttpActions.post(
      `notes`,
      notes,
      NotesActions.POST,
      NotesActions.POST_ERROR
    ) )
  }

  deleteMinutes( id: number ) {
    this.logger.log( 'deleteMinutes', { id } )
    this.dispatch( HttpActions.delete(
      `minutes/${ id }`,
      id,
      MinutesActions.DELETE,
      MinutesActions.DELETE_ERROR
    ) )
  }

  deleteNotes( id: number ) {
    this.dispatch( HttpActions.delete(
      `notes/${ id }`,
      id,
      NotesActions.DELETE,
      NotesActions.DELETE_ERROR
    ) )
  }

  getMinutes( id: number ) {
    this.dispatch( HttpActions.get(
      `agendaItems/${ id }/minutes`,
      MinutesActions.GET
    ) )
  }

  getNotes( id: number ) {
    this.dispatch( HttpActions.get(
      `agendaItems/${ id }/notes`,
      NotesActions.GET
    ) )
  }

  updateMinutes( minutes: AgendaItemMinutes ) {
    this.dispatch( HttpActions.put(
      `minutes/${ minutes.id }`,
      minutes, MinutesActions.PUT,
      MinutesActions.PUT_ERROR
    ) )
  }

  updateNotes( notes: AgendaItemNotes ) {
    this.dispatch( HttpActions.put(
      `notes/${ notes.id }`,
      notes, NotesActions.PUT,
      NotesActions.PUT_ERROR
    ) )
  }
}
