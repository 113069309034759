import { Validators } from '@angular/forms';
import { Collection, build, Metadata, atLeastOneAlphaNumericValidator } from '@caiu/library';

import { AgendaItem } from '../agenda-items/agenda-items.model';
import { Attachment } from '../attachments/attachments.model';
import { Meeting } from '../meetings/meetings.model';
import { BaseEntity } from '../shared/models';

export class Agenda extends BaseEntity {
  accountId = 0;
  accountName = '';
  accountUrl = '';
  agendaDate: Date = null;
  agendaTypeId = 1;
  attendanceTakerId = 0;
  createdByName = '';
  deleted = false;
  description = '';
  displayMinutes = false;
  displayOrder = 0;
  groupId = null;
  groupName = '';
  hasChildren = 0;
  markedForDelete: Date = null;
  meetingDeleted: Date = null;
  meetingId = 0;
  meetingName = '';
  meetingTimeLocation = '';
  minuteTakerId = 0;
  name = '';
  outlineId = 2;
  securityStatusId = 0;
  securityStatusName = '';
  timeframeId = 0;
  videoConferenceUrl = '';
  videoConferenceUrlPrivate = '';
  voteTakerId = 0;
  withholdDescription = false;

  agendaItems: AgendaItem[] = [];
  attachments: Attachment[] = [];
  meeting: Meeting = new Meeting();

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        ...this.ignore,
        'id',
        'accountId',
        'accountName',
        'accountUrl',
        'agendaDate',
        'agendaItems',
        'agendaTypeId',
        'agendaTypeName',
        'attachments',
        'createdByName',
        'deleted',
        'group',
        'groupName',
        'hasChildren',
        'isPrivate',
        'lastModifiedByName',
        'markedForDelete',
        'meeting',
        'meetingDate',
        'meetingDeleted',
        'meetingId',
        'meetingName',
        'meetingTimeLocation',
        'securityStatusName',
        'timeframeDescription',
        'visibility'
      ],
      groupId: {
        validators: [Validators.required]
      },
      name: {
        validators: [Validators.required, Validators.maxLength(150), atLeastOneAlphaNumericValidator]
      },
      videoConferenceUrl: {
        // validators: [urlValidator]
      },
      videoConferenceUrlPrivate: {
        // validators: [urlValidator]
      }
    });
  }

  set isPrivate(value: boolean) {
    if (value) {
      this.securityStatusId = 1;
    }
  }

  get isPrivate(): boolean {
    return this.securityStatusId === 1;
  }

  set isPublic(value: boolean) {
    if (value) {
      this.securityStatusId = 4;
    }
  }

  get isPublic(): boolean {
    return this.securityStatusId === 4;
  }

  get visibility(): string {
    switch (this.securityStatusId) {
      case 1:
        return 'only those with edit permissions.';
      case 2:
        return 'all group members.';
      case 3:
        return 'members of this account.';
      case 4:
        return 'the public.';
      default:
        return 'only those with edit permissions.';
    }
  }

  buildTemplate(templateName: string): Agenda {
    return build(Agenda, this, { name: templateName });
  }
}

export class AgendaAttendance {
  agendaId = 0;
  attendee = '';
  createdOn = '';
  isPresent = false;
  writeIn = '';
}

export class Agendas extends Collection<Agenda> {
  showAgenda = true;

  constructor() {
    super(Agenda);
  }
}
