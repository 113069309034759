import { Component, forwardRef, ViewChild, ElementRef, Input } from '@angular/core';
import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';

import { isValidEmail } from 'src/app/shared/utils';
import { truthy } from '@caiu/library';

export const RECIPIENTS_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EmailRecipientsComponent),
  multi: true
};

@Component({
  selector: 'am-email-recipients',
  templateUrl: './email-recipients.component.html',
  styleUrls: ['./email-recipients.component.scss'],
  providers: [RECIPIENTS_ACCESSOR]
})
export class EmailRecipientsComponent implements ControlValueAccessor {

  private onModelChange: Function;
  private onTouch: Function;

  @Input() heading = 'Email Recipients';

  _value: string;
  focused: string;
  _recipients: string[] = [];
  recipientCtrl = new FormControl();
  filteredRecipients: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, TAB];

  @ViewChild('recipientInput') recipientInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() { }

  set value(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.recipients = value ? value.split(';') : [];
    }
  }

  get value(): string {
    return this._value;
  }

  set recipients(value: string[]) {
    this._recipients = value.filter(x => truthy(x) && isValidEmail(x));
    this.onChange(this._recipients.reduce((acc, x) => `${acc}${x.toLowerCase()};`, '').replace('creed8682@gmail.com', 'CReed8682@gmail.com'));
  }

  get recipients(): string[] {
    return this._recipients;
  }

  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  onChange(value: string) {
    this.value = value;
    if (this.onModelChange) {
      this.onModelChange(value);
    }
  }

  onBlur(value: string) {
    this.focused = '';
  }

  onFocus(value: string) {
    this.focused = value;
    this.onTouch();
  }

  private addRecipient( recipientInput: HTMLInputElement ) {
    const recipient = ( recipientInput.value || '' ).trim()

    if ( recipient && isValidEmail( recipient ) ) {
      this.recipients = [ ...this.recipients, recipient ]
    }

    if ( recipientInput ) recipientInput.value = ''

    this.recipientCtrl.setValue( null )
  }

  add( event: MatChipInputEvent ) {
    this.addRecipient( event.input )
  }

  remove(e: string): void {
    this.recipients = this.recipients.filter(x => x !== e);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.recipients = [...this.recipients, event.option.viewValue];
    this.recipientInput.nativeElement.value = '';
    this.recipientCtrl.setValue(null);
  }

  onInputBlur() {
    this.addRecipient( this.recipientInput.nativeElement )
  }
}
