<div class="notes-preview" *ngIf="notes">
  <span class="notes-margin">&nbsp;</span>
<table class="notes-container" *ngIf="notes">
  <tr>
    <td class="notes-left-margin">&nbsp;</td>
    <td class="notes-cell">
      <div class="notes-heading" *ngIf="showHeader">Notes</div>
      <p class="notes" [innerHtml]="notes | safeHtml"></p>
    </td>
  </tr>
</table>
</div>