import { Injectable } from '@angular/core';
import { RouterActions, Action } from '@caiu/library';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnnouncementsActions } from './announcements.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';


@Injectable()
export class AnnouncementsEffects {
  /**
   * Navigate to announcement ID edit form after adding announcement
   */
  @Effect() onAdd: Observable<Action> = this.actions$.pipe(
    ofType(AnnouncementsActions.POST),
    map((action: Action) => RouterActions.navigate(this.accountUrl ?
      `/${this.accountUrl}/announcements/${action.payload.id}/edit` : `/admin/announcements/${action.payload.id}/edit`))
  );

  accountUrl = '';

  constructor(private actions$: Actions, private store: Store<any>) {
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
  }
}
