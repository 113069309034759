import { Component, OnInit } from '@angular/core';
import { SmartComponent, HttpActions, routeParamIdSelector, MessageSubscription, build, toArray } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Vote } from './votes.model';
import { VotesActions, VoteActions, voteSelector, agendaItemVotesSelector, agendaItemVotersSelector, isPresentVoterSelector } from './votes.reducer';
import { isVoteTakerSelector, GroupMembersActions } from '../members/members.reducer';
import { GroupMember } from '../groups/groups.model';
import { agendaGroupIdSelector } from '../agendas/agendas.reducer';
import { currentUserIdSelector } from '../shared/selectors';
import { Logger } from '../shared/utils';

@Component({
  selector: 'am-votes',
  templateUrl: './votes.component.html',
  styleUrls: ['./votes.component.scss']
})
export class VotesComponent extends SmartComponent implements OnInit {
  private readonly logger: Logger = new Logger( VotesComponent.name )

  _agendaItemId = 0;
  agendaItemId$: Observable<number>;
  editing = false;
  _groupId = 0;
  groupId$: Observable<number>;
  isVoteTaker = false;
  isVoteTaker$: Observable<boolean>;
  isVoter = false;
  isVoter$: Observable<boolean>;
  showVote$: Observable<boolean>;
  showVoteResults$: Observable<boolean>;
  _vote: Vote = new Vote();
  vote$: Observable<Vote>;
  voters$: Observable<GroupMember[]>;
  _votes: Vote[] = [];
  votes$: Observable<Vote[]>;
  voterId$: Observable<number>;
  messages = [
    build(MessageSubscription, {
      action: VotesActions.POST,
      channel: 'TOASTS',
      mapper: e => `Vote added successfully!`
    }),
    build(MessageSubscription, {
      action: VotesActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving vote.`
    }),
    build(MessageSubscription, {
      action: VotesActions.SAVE,
      channel: 'TOASTS',
      mapper: e => `Votes saved successfully!`
    }),
    build(MessageSubscription, {
      action: VotesActions.SAVE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving votes.`
    }),
    build(MessageSubscription, {
      action: VoteActions.PUT,
      channel: 'TOASTS',
      mapper: e => `Vote saved successfully!`
    }),
    build(MessageSubscription, {
      action: VoteActions.PUT_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving vote.`
    })
  ];

  constructor(public store: Store<any>) {
    super(store);
    this.agendaItemId$ = routeParamIdSelector(store, 'agendaItemId');
    this.groupId$ = agendaGroupIdSelector(store);
    this.isVoteTaker$ = isVoteTakerSelector(store);
    this.isVoter$ = isPresentVoterSelector(store);
    this.votes$ = agendaItemVotesSelector(store);
    this.vote$ = voteSelector(store);
    this.voterId$ = currentUserIdSelector(store);
    this.voters$ = agendaItemVotersSelector(store);
  }

  set agendaItemId(value: number) {
    if (value) {
      this.getVotes(value);
    }
    this._agendaItemId = value;
  }

  get agendaItemId(): number {
    return this._agendaItemId;
  }

  set groupId(value: number) {
    if (value) {
      this.getGroupMembers(value);
    }
    this._groupId = value;
  }

  get groupId(): number {
    return this._groupId;
  }

  set votes( votes: Vote[] ) {
    this.logger.log( 'votes SET', { votes } )

    this._votes = votes
    
    // const voteArrays: [ Vote[], Vote[] ] = this._votes.length > votes.length
    //   ? [ this._votes, votes ]
    //   : [ votes, this._votes ]
    // const updatedVotes = ( voteArrays[ 0 ].length === 0
    //   ? voteArrays[ 1 ]
    //   : voteArrays[ 0 ].map( ( ogVote ) => {
    //     const match = voteArrays[ 1 ].find( ( vote ) => vote.voterId === ogVote.voterId )
        
    //     if ( !match ) {
    //       return ogVote
    //     }

    //     const newVote = new Date( ogVote.createdDate ) > new Date( match.createdDate )
    //       ? {
    //         ...match,
    //         ...ogVote
    //       } as Vote
    //       : {
    //         ...ogVote,
    //         ...match
    //       } as Vote

    //     return newVote
    //   } )
    // )
    // .filter( ( vote ) => vote !== null )

    // this.logger.log( 'votes SET', { votes, updatedVotes } )

    // this._votes = updatedVotes
  }

  get votes() {
    return this._votes
  }

  get hasVotes(): boolean {
    //* [16721] filter votes array to remove any votes that haven't been cast
    const filteredVotes = toArray( this.votes ).filter( ( vote ) => vote.id !== 0 )

    return filteredVotes.length > 0 || this.vote.id !== 0;
  }

  get mode(): 'REPORT' | 'EDIT' | 'VOTE' {
    return this.isVoteTaker && this.editing ? 'EDIT'
      : this.vote.id === 0 && this.isVoter && !this.isVoteTaker && this.editing ? 'VOTE'
        : 'REPORT';
  }

  get showVote(): boolean {
    return this.isVoter && !this.isVoteTaker && (this.vote.id === 0 || this.editing);
  }

  set vote(value: Vote) {
    this._vote = value;
    // if (value.id) {
    //   this.editing = false;
    // }
  }

  get vote(): Vote {
    return this._vote;
  }

  get showVoteResults() {
    const isVoter = this.isVoter
    const hasVotes = this.hasVotes
    const hasVoted = this.vote.id !== 0
    const editing = this.editing
    const isVoterAndHasVoted = isVoter && hasVoted
    const result = !editing && ( !isVoter || isVoterAndHasVoted ) && hasVotes

    return result
  }

  ngOnInit() {
    this.onInit();
    this.sync(['agendaItemId', 'groupId', 'isVoter', 'isVoteTaker', 'vote', 'votes']);
  }

  addVote(vote: Vote) {
    this.logger.log( 'addVote', { vote } )
    
    this.httpPost(`votes`, vote, VotesActions.POST).subscribe(x => {
      this.editing = false;
    });
  }

  getGroupMembers(groupId: number) {
    this.dispatch(HttpActions.get(`groups/${groupId}/members/all`, GroupMembersActions.GET_GROUP));
  }

  getVotes(agendaItemId: number) {
    this.logger.log( 'getVotes', { agendaItemId } )

    this.dispatch(HttpActions.get(`agendaitems/${agendaItemId}/votes`, VotesActions.GET));
  }

  saveVotes(e: Vote[]) {
    this.logger.log( 'saveVotes', { votes: e } )
    
    this.httpPost(`agendaitems/${this.agendaItemId}/votes`, e, VotesActions.SAVE).subscribe( ( x ) => {
      this.editing = false;
    });
  }

  updateVote(vote: Vote) {
    this.logger.log( 'updateVote', { vote } )
    
    this.httpPut(`votes/${vote.id}`, vote, VoteActions.PUT).subscribe(x => {
      this.editing = false;
    });
  }

}
