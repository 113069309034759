import { Collection, build, DateRange, Metadata, Validators } from '@caiu/library';

import { BaseEntity, FileUpload, File } from '../shared/models';
import { mapFileUpload } from '../shared/utils';

export class Announcements extends Collection<Announcement> {
  dashboard: number[] = [];

  constructor() {
    super(Announcement);
  }

  replace(data: Announcement[]): Announcements {
    return build(Announcements, super.replace(data));
  }

  update(data: Announcement | Announcement[]): Announcements {
    return build(Announcements, super.update(data));
  }

}

export class Announcement extends BaseEntity {
  accountId = 0;
  announcementTypeId = null;
  announcementTypeName = '';
  description = '';
  groupId = 0;
  subject = '';
  signature = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  attachments: File[] = [];

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        ...this.ignore,
        'id',
        'accountId',
        'announcementTypeName',
        'endDate',
        'groupName',
        'hasAttachments',
        'startDate'
      ],
      controls: ['dateRange'],
      subject: {
        validators: [Validators.required]
      }
    });
  }

  set dateRange(value: DateRange) {
    this.startDate = value.startDate;
    this.endDate = value.endDate;
  }

  get dateRange(): DateRange {
    return build(DateRange, {
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate)
    });
  }

  get hasAttachments(): boolean {
    return this.attachments.length > 0;
  }
}

export class SysAdminAnnouncement extends BaseEntity {
  subject = '';
  message = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
}

export enum AnnouncementType {
  DEFAULT,
  SYSTEM_WIDE,
  ACCOUNT_ONLY,
  GROUP
}

export class AnnouncementRow {

  constructor(private _announcement: Announcement) {
  }

  get announcement(): Announcement {
    return build(Announcement, this._announcement);
  }

  get announcementId(): number {
    return this.announcement.id;
  }

  get endDate(): Date {
    return new Date(this.announcement.endDate);
  }

  get startDate(): Date {
    return new Date(this.announcement.startDate);
  }

  get subject(): string {
    return this.announcement.subject;
  }

  get type(): string {
    return this.announcement.announcementTypeName;
  }
}
