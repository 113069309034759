import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, ActivatedRoute } from '@angular/router';
import {
  build,
  HttpActions,
  LookupService,
  Lookup,
  RouterService,
  StorageService,
  SmartComponent,
  ConfigActions,
  WindowActions,
  WindowResize,
  DialogModel,
  LookupActions,
  messageSelector,
  AutoLogoutComponent,
  Token,
  routeNameSelector
} from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { GroupsActions } from './groups/groups.reducer';
import { AccountMembersActions } from './members/members.reducer';
import { AccountsActions, UserActions, CurrentUserActions, AccountActions, ViewModeActions } from './shared/actions';
import { HOURS, MINUTES_BY_5, MINUTES_BY_15, WIDGETS } from './shared/lookup';
import { CurrentUser } from './shared/models';
import { currentUserIdSelector, authenticatedSelector } from './shared/selectors';
import { WidgetsActions } from './shared/widgets/widgets.reducer';
import { environment } from '../environments/environment';
import { accountUrlSelector } from './accounts/accounts.reducer';

@Component({
  selector: 'am-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SmartComponent implements OnInit {
  authenticated = false;
  authenticated$: Observable<boolean>;
  currentUrl = '/';
  errorMessage$: Observable<string>;
  loggingOut = false;
  routeData: Subscription | null;
  routeName: string;
  routeName$: Observable<string>;
  time;
  toast$: Observable<string>;
  _userId = 0;
  userId$: Observable<number>;

  constructor(
    public store: Store<any>,
    public lookup: LookupService,
    private routerService: RouterService,
    public storage: StorageService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super(store);
    this.authenticated$ = authenticatedSelector(store);
    this.toast$ = messageSelector(store, 'TOASTS');
    this.errorMessage$ = messageSelector(store, 'ERRORS');
    this.userId$ = currentUserIdSelector(store);
    this.addSubscription(accountUrlSelector(store).subscribe(url => {
      if (url) {
        this.httpGet(`accounts/url/${url}`, AccountActions.GET, AccountActions.GET_ERROR);
      }
    }));
    this.routeName$ = routeNameSelector(store);
  }

  get errorMessageChanges(): Subscription {
    return this.errorMessage$.subscribe(x => {
      this.showErrorMessage(x);
    });
  }

  get localStorageActions(): string[] {
    return [
      UserActions.LOGIN_SUCCESS,
      AccountsActions.ACTIVATE,
      AccountsActions.ACTIVATE_URL,
      AccountsActions.ACTIVATE_AND_REDIRECT,
      LookupActions.ADD_KEYS,
      UserActions.IMPERSONATE,
      CurrentUserActions.PUT,
      UserActions.LOGOUT,
      UserActions.REVERT_TO_ADMIN,
      ViewModeActions.SET
    ];
  }

  get localStorageMapper(): (x: any) => any {
    return state => {
      const currentUser = build(CurrentUser, state['currentUser'], {
        accounts: state['currentUser']['accounts'],
        impersonating: state['currentUser']['impersonating'] ?
          build(CurrentUser, {
            id: state['currentUser']['impersonating']['id'],
            firstName: state['currentUser']['impersonating']['firstName'],
            lastName: state['currentUser']['impersonating']['lastName'],
            token: build(Token, state['currentUser']['impersonating']['token']),
            accounts: state['currentUser']['impersonating']['accounts'],
            userAccounts: state['currentUser']['impersonating']['userAccounts'],
            userGroups: state['currentUser']['impersonating']['userGroups']
          })
          : null
      });
      const viewMode = state['viewMode'];
      return { currentUser, viewMode };
    };
  }

  get lookupKeys(): string[] {
    return ['AccountStatuses', 'DateRanges', 'GroupRoles', 'Outlines', 'UserRoles', 'VoteAnswers'];
  }

  get lookupValues(): Lookup[] {
    return [
      build(Lookup, { key: 'Hours', values: HOURS }),
      build(Lookup, { key: 'MinutesBy5', values: MINUTES_BY_5 }),
      build(Lookup, { key: 'MinutesBy15', values: MINUTES_BY_15 }),
      build(Lookup, { key: 'lkpWidgets', values: WIDGETS })
    ];
  }

  get popup(): ActivatedRoute | null {
    return this.popups && this.popups.length > 0 ? this.popups[0] : null;
  }

  get popups(): ActivatedRoute[] {
    return this.routerService.activatedRoute.children.filter(x => x.outlet === 'popup');
  }

  get sessionStorageActions(): string[] {
    return [];
  }

  get sessionStorageMapper(): (x: any) => any {
    return (s: any) => ({});
  }

  get showDialog(): boolean {
    return this.popups.length > 0;
  }

  get toastChanges(): Subscription {
    return this.toast$.subscribe(x => {
      this.openSnackBar(x);
    });
  }

  set userId(value: number) {
    this._userId = value;
    if (value) {
      if (this.authenticated) {
        this.getUserAccounts(value);
        this.getUserGroups(value);
        this.getWidgets();
      }
    }
    if (value === 38) { //TODO: check SYS_ADMIN role
      this.getAllAccounts();
    }
  }

  get userId(): number {
    return this._userId;
  }

  // @HostListener('window:mousemove')
  // resetTimer() {
  //   // this.dispatch({ type: UserActions.RESET_LAST_ACTIVE });
  //   clearTimeout(this.time);
  //   this.time = setTimeout(() => {
  //     // location.reload();
  //   }, 1800000); // logout after 30 minutes
  // }

  @HostListener('window:mousemove')
  resetTimer() {
    // this.dispatch({ type: CurrentUserActions.RESET_LAST_ACTIVE });
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      this.autoLogout();
    }, 3600000); // logout after 30 minutes
    // }, 60000);
  }

  ngOnInit() {
    this.sync(['authenticated', 'userId', 'routeName']);
    this.loadConfiguration();
    this.initStorage();
    this.initLookup();
    this.initRouting();
    this.addSubscription(this.toastChanges);
    this.addSubscription(this.errorMessageChanges);
    setTimeout(() => {
      if (!this.time) {
        this.autoLogout();
      }
    }, 1800000);
  }

  initLookup() {
    this.lookup.load(this.lookupKeys, this.lookupValues);
  }

  initRouting() {
    this.routerService.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.urlAfterRedirects;
        this.onNavigationEnd(e);
      }
    });
  }

  initStorage() {
    this.storage.init(this.localStorageMapper, this.sessionStorageMapper, this.localStorageActions, this.sessionStorageActions);
  }

  autoLogout() {
    if (this.authenticated && !this.loggingOut && !(this.routeName === 'agenda-item-edit')) {
      this.loggingOut = true;
      this.openDialog(AutoLogoutComponent, {
        width: '600px'
      });
    }
  }

  getAllAccounts() {
    this.dispatch(HttpActions.get('accounts/all', AccountsActions.GET));
  }

  getWidgets() {
    this.dispatch(HttpActions.get(`widgets`, WidgetsActions.GET));
  }

  loadConfiguration() {
    this.dispatch(ConfigActions.initialize(environment));
  }

  logout() {
    this.dispatch(UserActions.logout());
  }

  onNavigationEnd(e: NavigationEnd): void {
    if (this.routeData && this.routeData.unsubscribe) {
      this.routeData.unsubscribe();
    }
    if (this.popup && this.popup.data) {
      this.routeData = this.popup.data.subscribe(x => {
        const dialog = build(DialogModel, x['dialog']);
        if (this.showDialog) {
          dialog.config.disableClose = true;
          this.openDialog(this.popup?.component, dialog.config);
        }
      });
    } else {
      this.routeData = null;
    }
    window.scrollTo(0, 0);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000
    });
  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'snackbar-error'
    });
  }

  @HostListener('window:load', ['$event'])
  onLoad(e: any) {
    const windowHeight = e && e.currentTarget && e.currentTarget.innerHeight ? e.currentTarget.innerHeight : 0;
    const windowWidth = e && e.currentTarget && e.currentTarget.innerWidth ? e.currentTarget.innerWidth : 0;
    this.dispatch(WindowActions.resize(build(WindowResize, { windowHeight, windowWidth })));
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    const windowHeight = e && e.currentTarget && e.currentTarget.innerHeight ? e.currentTarget.innerHeight : 0;
    const windowWidth = e && e.currentTarget && e.currentTarget.innerWidth ? e.currentTarget.innerWidth : 0;
    this.dispatch(WindowActions.resize(build(WindowResize, { windowHeight, windowWidth })));
  }

  closeDialog(e: any) {
    super.closeDialog(e);
    if (e && this.loggingOut) {
      this.logout();
    } else {
      const queryParams = this.routerService.router.url
        .split('?')[1]
        ?.split('&')
        .map((keyValueString) => keyValueString.split('='))
        .reduce((accumulator, keyValueArray) => {
          accumulator[keyValueArray[0]] = keyValueArray[1]

          return accumulator
        },
          {}
        )

      //* bcampbell: updated navigate to include query params from previous window for ticket 13265
      //*   this change allows the user to preview minutes in the print popup on the meeings screen
      //*   without loosing their previous query to look up meetings
      this.routerService.navigate(
        [{ outlets: { popup: null } }],
        {
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
    }
    this.loggingOut = false;
  }

  getUserAccounts(userId: number) {
    this.dispatch(HttpActions.get(`users/${userId}/accounts`, AccountMembersActions.GET_USER_ACCOUNTS));
  }

  getUserGroups(userId: number) {
    this.dispatch(HttpActions.get(`users/${userId}/groups`, GroupsActions.GET_USER_GROUPS));
  }
}
