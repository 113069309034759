import { Component, Input, OnInit } from '@angular/core';
import { HttpService, truthy, build, DumbComponent, compareStrings } from '@caiu/library';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { sortFullNames } from 'src/app/shared/utils';

import { Vote } from '../votes.model';

@Component({
  selector: 'am-votes-preview',
  templateUrl: './votes-preview.component.html',
  styleUrls: ['./votes-preview.component.scss']
})
export class VotesPreviewComponent extends DumbComponent implements OnInit {

  agendaItemIdSubject = new BehaviorSubject(0);
  _votes: Vote[] = [];
  votes$: Observable<Vote[]>;

  constructor(public http: HttpService) {
    super();
    this.votes$ = this.agendaItemIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendaitems/${id}/votes`)),
      map(x => x.map(y => build(Vote, y)))
    );
  }

  @Input()
  set agendaItemId(value: number) {
    this.agendaItemIdSubject.next(value);
  }

  get agendaItemId(): number {
    return this.agendaItemIdSubject.value;
  }

  @Input()
  set votes(value: Vote[]) {
    console.log(value);
    this._votes = value;
  }

  get votes(): Vote[] {
    return this._votes;
  }

  get totalVotes(): number {
    return this.votes.length;
  }

  get abstainCount(): number {
    return this.votes.filter(x => x.answerId === 3).length;
  }

  get nayCount(): number {
    return this.votes.filter(x => x.answerId === 2).length;
  }

  get notCastCount(): number {
    return this.votes.filter(x => x.answerId === 4).length;
  }

  get yeaCount(): number {
    return this.votes.filter(x => x.answerId === 1).length;
  }

  get motion(): Vote {
    return this.votes.find(x => x.motion);
  }

  get second(): Vote {
    return this.votes.find(x => x.second);
  }

  ngOnInit() {
    this.sync(['votes']);
  }

  getVoters(answerId: number): string {
    return sortFullNames(this.votes
      .filter(vote => vote.answerId === answerId)
      .map(x => `${x.voterName}`.trim()))
      .join(', ');
  }

}
