import { Injectable } from '@angular/core';
import { RouterActions, Action, toArray, arrayDistinct } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { currentUserAccountsSelector } from '../members/members.reducer';
import { UserActions, CurrentUserActions } from './actions';
import { AccountMember } from './models';
import { redirectToSelector } from './selectors';
import { userIsSysAdminSelector } from '../accounts/accounts.reducer';

@Injectable()
export class CurrentUserEffects {

  /**
   * Navigate to root url upon successful login.
   */
  @Effect() onLoginSuccess: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.LOGIN_SUCCESS),
    map((action: Action) => this.redirectAfterLogin(action.payload))
  );

  /**
   * Navigate to login page after logging out.
   */
  @Effect() onLogout: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.LOGOUT),
    map(action => RouterActions.navigate('/login'))
  );

  /**
   * Redirect to reset password page after reset password code is generated in the API.
   */
  @Effect() onRecoverPassword: Observable<Action> = this.actions$.pipe(
    ofType(CurrentUserActions.RECOVER_PASSWORD),
    map((action: Action) => RouterActions.navigate(`/reset-password?code=${action.payload.passwordResetCode}`))
  );

  /**
   * Navigate to root url upon successful password reset.
   */
  @Effect() onResetPassword: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.RESET_PASSWORD),
    map(action => RouterActions.navigate('/'))
  );

  /**
   * Navigate to root url after impersonating user.
   */
  @Effect() onImpersonate: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.IMPERSONATE),
    map((action: Action) => this.redirectAfterLogin(action.payload))
  );

  /**
   * Navigate to root url after reverting to admin user.
   */
  @Effect() onRevertToAdmin: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.REVERT_TO_ADMIN),
    map((action: Action) => RouterActions.navigate('admin/accounts'))
  );

  isSysAdmin = false;
  userAccounts: AccountMember[] = [];
  userAccounts$: Observable<AccountMember[]>;
  redirectTo = '';
  redirectTo$: Observable<string>;

  constructor(private actions$: Actions, public store: Store<any>) {
    this.redirectTo$ = redirectToSelector(store);
    this.redirectTo$.subscribe(x => {
      this.redirectTo = x;
    });
    this.userAccounts$ = currentUserAccountsSelector(store);
    this.userAccounts$.subscribe(x => {
      this.userAccounts = toArray(x);
    });
    userIsSysAdminSelector(store).subscribe(q => {
      this.isSysAdmin = q;
    });
  }

  redirectAfterLogin(e): Action {
    const urls = arrayDistinct(this.userAccounts.map(x => x.accountUrl));
    // const url = this.isSysAdmin || (e.user && e.user.userName === 'agendaman@caiu.org') ?
    //   'admin/accounts' : this.redirectTo || urls.length === 1 ? `${urls[0]}/dashboard` : 'accounts';
    const url = this.redirectTo ? this.redirectTo
      : (this.isSysAdmin || (e.user && e.user.userName === 'agendaman@caiu.org') ?
        'admin/accounts' : 'accounts');
    return RouterActions.navigate(url);
  }
}
