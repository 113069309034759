import { NgModule } from '@angular/core';

import { AnnouncementComponent } from './announcement.component';
import { AttachmentsModule } from '../attachments/attachments.module';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    AttachmentsModule
  ],
  declarations: [
    AnnouncementComponent,
  ],
  exports: [
    AnnouncementComponent
  ]
})
export class AnnouncementModule { }
