import { Injectable } from '@angular/core';
import { Action, RouterActions } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MeetingActions, MeetingsActions } from './meetings.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class MeetingsEffects {
  /**
   * Redirect to meetings after deleting meeting.
   */
  @Effect() onDelete: Observable<Action> = this.actions$.pipe(
    ofType(MeetingActions.DELETE),
    map((action: Action) => RouterActions.navigate(`/${this.accountUrl}/meetings`))
  );

  /**
   * Redirect to meeting after adding new meeting.
   */
  @Effect() onPost: Observable<Action> = this.actions$.pipe(
    ofType(MeetingsActions.POST),
    map((action: Action) => RouterActions.navigate(`/${this.accountUrl}/meetings/${action.payload.id}/agendas/0/edit`))
  );

  accountUrl = '';
  accountUrl$: Observable<string>;

  constructor(private actions$: Actions, private store: Store<any>) {
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
  }
}
