<div class="votes">
  <button *ngIf="!showVote && isVoter && !isVoteTaker" mat-raised-button class="btn btn-save font-effect-3d-float"
    (click)="editing = true">
    <mat-icon>check</mat-icon>
    <span class="btn-label">Edit Vote</span>
  </button>
  <button *ngIf="!editing && isVoteTaker" mat-raised-button class="btn btn-save font-effect-3d-float"
    (click)="editing = true">
    <mat-icon>check</mat-icon>
    <span class="btn-label">Edit Votes</span>
  </button>
  <am-votes-form *ngIf="isVoteTaker && editing" [votes]="votes" (save)="saveVotes($event)" (cancel)="editing = false">
  </am-votes-form>
  <am-vote *ngIf="showVote" [agendaItemId]="agendaItemId" [vote]="vote" [voterId]="voterId$ | async"
    (add)="addVote($event)" (update)="updateVote($event)">
  </am-vote>
  <am-vote-results *ngIf="showVoteResults" [votes]="votes"></am-vote-results>
</div>
