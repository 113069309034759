<div class="wrapper">
  <am-print-options
    (changes)="details = $event"
    (doPrint)="onPrint()"
    (pdf)="getPdf()"
    (word)="getWordDoc()"
    [hasDescription]="agenda.description"
    [isPublicUser]="isPublicUser$ | async"
    [showMinutes]="displayMinutes"
    [isForAgendaItem]="isForAgendaItem"
    [isMinutesView]="isMinutesView"
  >
  </am-print-options>
  <a id="docLink" [href]="fileUrl" target="_blank" #docLink [download]="fileName"></a>
  <div class="heading" #headingEl>
    <p class="meeting-name">{{ agenda.meetingName }}</p>
    <p class="meeting-info">{{ agenda.meetingTimeLocation }}</p>
    <p class="agenda-name">{{ agenda.name }}</p>
  </div>
  <div class="details-wrapper">
    <div *ngIf="details.showAgendaDescription" class="description" [innerHTML]="agenda.description | safeHtml"
      #agendaDescription></div>
  </div>
  <div class="screen-margin-only scrollable">
    <div class="print-preview" #printPreview>
      <am-attendance-preview *ngIf="details.attendance" [agendaId]="agenda.id"></am-attendance-preview>
      <am-agenda-item-preview
        *ngFor="let treeItem of treeItems"
        [displayOrder]="getOutlineType(treeItem)"
        [depth]="treeItem.depth"
        [agendaItem]="treeItem.item"
        [details]="details"
        [displayMinutes]="displayMinutes"
        [displayType]="outlineId"
      >
      </am-agenda-item-preview>
    </div>
  </div>
</div>
<mat-spinner *ngIf="printing" class="spinner-bottom" [diameter]="100"></mat-spinner>
