import { Component, Output, EventEmitter, forwardRef, Input, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { build, equals, HttpActions, HttpService, inArray, SmartComponent, toArray, truthy } from '@caiu/library';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';

import { Tag } from '../tags.model';
import { TagsActions, userTagsSelector } from '../tags.reducer';
import { accountIdSelector } from 'src/app/accounts/accounts.reducer';
import { Group } from 'src/app/groups/groups.model';
import { currentUserAccountsSelector, currentUserGroupsSelector } from 'src/app/members/members.reducer';
import { AccountMember } from '../../models';
import { currentUserIdSelector } from '../../selectors';
import { GroupsActions } from 'src/app/groups/groups.reducer';
import { map, switchMap } from 'rxjs/operators';
import { agendaGroupIdSelector } from 'src/app/agendas/agendas.reducer';

export const TAGS_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsControlComponent),
  multi: true
};

@Component({
  selector: 'am-tags-control',
  templateUrl: './tags-control.component.html',
  styleUrls: ['./tags-control.component.scss'],
  providers: [TAGS_ACCESSOR]
})
export class TagsControlComponent extends SmartComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  private onModelChange: Function;
  private onTouch: Function;
  _value: Tag[];
  focused: Tag[];
  accountId = 0;
  accountId$: Observable<number>;
  accounts$: Observable<AccountMember[]>;
  groups$: Observable<Group[]>;
  _tags: Tag[] = [];
  tags$: Observable<Tag[]>;
  userId = 0;
  userId$: Observable<number>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public store: Store<any>, public http: HttpService) {
    super(store);
    this.accountId$ = accountIdSelector(store);
    this.tags$ = combineLatest([
      this.accountId$.pipe(switchMap(id => http.get(`accounts/${id}/tags`))),
      agendaGroupIdSelector(store).pipe(switchMap(id => truthy(id) ? http.get(`groups/${id}/tags`) : of([])))
    ]).pipe(
      map(x => [...x[1], ...x[0].filter(y => y.groupId === null)].map(y => build(Tag, y)))
    );
    this.accounts$ = currentUserAccountsSelector(store);
    this.groups$ = currentUserGroupsSelector(store);
    this.userId$ = currentUserIdSelector(store);
  }

  get selectedIds(): number[] {
    return toArray(this.value).map(x => x.id);
  }

  set tags(value: Tag[]) {
    const ids = this.selectedIds;
    this._tags = toArray(value).map(x => build(Tag, x, {
      checked: inArray(ids, x.id)
    }));
  }

  get tags(): Tag[] {
    return this._tags;
  }

  set value(value: Tag[]) {
    this._value = value;
    const ids = this.selectedIds;
    this._tags = toArray(this.tags).map(x => build(Tag, x, {
      checked: inArray(ids, x.id)
    }));
  }

  get value(): Tag[] {
    return this._value;
  }

  ngOnInit() {
    this.sync(['accountId', 'tags', 'userId']);
  }

  ngOnDestroy() {
    this.writeValue([]);
  }

  ngOnChanges(e) {
  }

  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  writeValue(value: Tag[]) {
    this.value = value;
  }

  onChange(value: Tag[]) {
    if (!equals(this.value, value)) {
      this.value = value;
      if (this.onModelChange) {
        this.onModelChange(value);
      }
    }
  }

  onBlur(value: Tag[]) {
    this.focused = [];
  }

  onFocus(value: Tag[]) {
    this.focused = value;
    this.onTouch();
  }

  onAdd(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // this.onChange(value);

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemove(id: number) {
    this.onChange(this.value.filter(x => x.id !== id));
  }

  toggle(e: Tag) {
    if (e.checked) {
      this.onRemove(e.id);
    } else {
      this.addTag(e);
    }
  }

  addTag(e: Tag) {
    this.onChange([...toArray(this.value), e]);
  }

  getTags(accountId: number) {
    this.dispatch(HttpActions.get(`accounts/${accountId}/tags`, TagsActions.GET));
  }

  getUserGroups(userId: number) {
    this.dispatch(HttpActions.get(`users/${userId}/groups`, GroupsActions.GET_USER_GROUPS));
  }
}

