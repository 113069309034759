<div class="account-profile">
  <div>
    <div class="header-right">
      <a id="home" title="AgendaManager | See general account information" class="logo" routerLink="/">
        <span class="service-mark">AgendaManager<sup title="Service Mark">SM</sup></span>
      </a>
      <div id="AccountInfo">
        <mat-icon (click)="toggleDialog()" (mouseenter)="onHover()" *ngIf="authenticated">person</mat-icon>
      </div>
      <div id="AccountName" *ngIf="account.id">{{ account.name }}</div>
      <am-current-user-dialog [show]="showDialog"></am-current-user-dialog>
    </div>
    <a class="menu-login" routerLink="/login" title="Log In" *ngIf="!authenticated">Sign In</a>
  </div>
</div>
