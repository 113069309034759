import { Injectable } from '@angular/core';
import { Action, RouterActions, routeParamIdSelector } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AgendaActions, AgendasActions } from './agendas.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class AgendasEffects {
  /**
   * Redirect to add agenda form after deleting agenda.
   */
  @Effect() onDelete: Observable<Action> = this.actions$.pipe(
    ofType(AgendaActions.DELETE),
    map((action: Action) => RouterActions.navigate(`/${this.accountUrl}/meetings/${this.meetingId}/agendas/0/edit`))
  );

  /**
   * Redirect to agenda after adding new agenda.
   */
  @Effect() onPost: Observable<Action> = this.actions$.pipe(
    ofType(AgendasActions.POST),
    map((action: Action) => RouterActions.navigate(`/${this.accountUrl}/meetings/${this.meetingId}/agendas/${action.payload.id}`))
  );

  /**
   * Redirect to agenda after adding new agenda template.
   */
  @Effect() onPostAgendaTemplate: Observable<Action> = this.actions$.pipe(
    ofType(AgendasActions.POST_AGENDA_TEMPLATE),
    map((action: Action) => RouterActions.navigate(`/${this.accountUrl}/meetings/0/agendas/${action.payload.templateId}`))
  );

  /**
   * Redirect to agenda edit form after adding new agenda from template.
   */
  @Effect() onPostTemplate: Observable<Action> = this.actions$.pipe(
    ofType(AgendasActions.POST_TEMPLATE),
    map((action: Action) => RouterActions.navigate(`/${this.accountUrl}/meetings/${this.meetingId}/agendas/${action.payload.id}/edit`))
  );

  accountUrl = '';
  meetingId = 0;

  constructor(private actions$: Actions, private store: Store<any>) {
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
    routeParamIdSelector(store, 'meetingId').subscribe(id => {
      this.meetingId = id;
    });
  }
}
