import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { build, ConfirmDeleteComponent, HttpActions, SmartComponent, Control, HttpService, truthy, falsy } from '@caiu/library';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { TagComponent } from '../tag/tag.component';
import { Tag, Tags } from '../tags.model';
import { TagsActions, userTagsSelector } from '../tags.reducer';
import { accountIdSelector } from 'src/app/accounts/accounts.reducer';
import { currentUserAccountsSelector, currentUserGroupsSelector } from 'src/app/members/members.reducer';
import { AccountMember } from '../../models';
import { Group } from 'src/app/groups/groups.model';
import { GroupsActions } from 'src/app/groups/groups.reducer';
import { currentUserIdSelector } from '../../selectors';

@Component({
  selector: 'am-tags-widget',
  templateUrl: './tags-widget.component.html',
  styleUrls: ['./tags-widget.component.scss']
})
export class TagsWidgetComponent extends SmartComponent implements OnInit {

  @Control(Tag) form: FormGroup;
  adminAccountIds$: Observable<number[]>;
  _accountId = 0;
  accountId$: Observable<number>;
  groupId = 0;
  groups: Group[] = [];
  groups$: Observable<Group[]>;
  filteredTags: Tag[] = [];
  filteredTags$: Observable<Tag[]>;
  managerGroupIds$: Observable<number[]>;
  tags$: Observable<Tag[]>;
  accounts: AccountMember[] = [];
  accounts$: Observable<AccountMember[]>;
  deleting = null;
  saving = false;
  userId = 0;
  userId$: Observable<number>;
  userTags$: Observable<Tag[]>;

  constructor(public store: Store<any>, public dialog: MatDialog, public http: HttpService) {
    super(store);
    this.accountId$ = accountIdSelector(store);
    this.accounts$ = currentUserAccountsSelector(store);
    this.groups$ = this.form.get('accountId').valueChanges.pipe(
      switchMap(id => http.get(`accounts/${id}/groups/user`))
    );
    this.userId$ = currentUserIdSelector(store);
    this.adminAccountIds$ = http.get(`accountMembers/admin`);
    this.managerGroupIds$ = http.get(`groupMembers/manager`);
    this.tags$ = combineLatest([
      this.form.get('accountId').valueChanges,
      this.form.get('groupId').valueChanges,
      userTagsSelector(store)
    ]).pipe(
      switchMap(x => truthy(x[1]) ? http.get(`groups/${x[1]}/tags`) : http.get(`accounts/${x[0]}/tags`))
    );
    this.filteredTags$ = combineLatest([this.tags$, this.form.get('name').valueChanges.pipe(startWith('')), this.adminAccountIds$, this.managerGroupIds$]).pipe(
      map(x => {
        const tags = x[0].map(y => build(Tag, y, { userCanEdit: Tags.CanEditTag(y, x[2], x[3]) }));
        if (falsy(x[1])) {
          return tags;
        }
        const matches = tags.filter(y => y.name.toLowerCase().includes(x[1].toLowerCase()));
        return matches.length > 0 ? matches : tags;
      })
    );
  }

  set accountId(value: number) {
    this._accountId = value;
    if (value) {
      // this.getTags(value);
      setTimeout(() => {
        this.form.get('accountId').setValue(value);
        this.form.get('groupId').setValue(0);
      }, 0);
    }
  }

  get accountId(): number {
    return this._accountId;
  }

  ngOnInit(): void {
    this.sync(['accountId', 'accounts', 'groups', 'filteredTags', 'userId']);
  }

  onAdd(e) {
    e.stopPropagation();
    this.openTag(build(Tag, this.form.value, {
      userId: this.userId,
      userCanEdit: true
    }));
  }

  onRemove(e: any, tag: Tag) {
    e.stopPropagation();
    this.deleting = tag.id;
    this.openDialog(ConfirmDeleteComponent, {
      data: {
        question: `Are you sure you want to delete this tag?`
      }
    });
  }

  closeDialog(e: Tag) {
    if (e) {
      if (this.deleting) {
        this.deleteTag(this.deleting);
      } else {
        this.saveTag(e);
      }
    }
    this.deleting = null;
    this.saving = false;
    super.closeDialog(e);
  }

  saveTag(e: Tag) {
    if (e.id) {
      this.updateTag(e);
    } else {
      this.addTag(e);
    }
    this.form.get('name').setValue('');
  }

  addTag(e: Tag) {
    this.dispatch(HttpActions.post(`tags`, e, TagsActions.POST, TagsActions.POST_ERROR));
  }

  deleteTag(id: number) {
    this.dispatch(HttpActions.delete(`tags/${id}`, id, TagsActions.DELETE, TagsActions.DELETE_ERROR));
  }

  getTags(accountId: number) {
    this.dispatch(HttpActions.get(`accounts/${accountId}/tags`, TagsActions.GET));
  }

  getUserGroups(userId: number) {
    this.dispatch(HttpActions.get(`users/${userId}/groups`, GroupsActions.GET_USER_GROUPS));
  }

  updateTag(e: Tag) {
    this.dispatch(HttpActions.put(`tags/${e.id}`, e, TagsActions.PUT, TagsActions.PUT_ERROR));
  }

  onDrop(e) {
  }

  openTag(tag: Tag) {
    if (tag.userCanEdit) {
      this.openDialog(TagComponent, {
        data: {
          tag,
          groups: this.groups,
          accounts: this.accounts.map(x => x.account)
        },
        width: '800px'
      });
    }
  }

}
