<form [formGroup]="form" iuForm>
  <h2 class="item-header">{{ heading }}</h2>
  <mat-radio-group aria-label="Select an option" formControlName="answerId">
    <mat-radio-button [value]="1">Yea</mat-radio-button>
    <mat-radio-button [value]="2">Nay</mat-radio-button>
    <mat-radio-button [value]="3">Abstain</mat-radio-button>
  </mat-radio-group>
  <button mat-raised-button class="btn btn-save font-effect-3d-float" (click)="onSubmit($event)">
    <mat-icon>check</mat-icon>
    <span class="icon-text">Place Vote</span>
  </button>
</form>
