import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'am-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  @Input() accountUrl = '';
  @Input() routeName: string;
  @Output() logout = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
