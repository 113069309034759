import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SmartComponent } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CurrentUser, Account, AccountMember } from '../models';
import { authenticatedSelector } from '../selectors';
import { Logger } from '../utils';

@Component({
  selector: 'am-current-user',
  templateUrl: './current-user.component.html',
  styleUrls: ['./current-user.component.scss']
})
export class CurrentUserComponent extends SmartComponent implements OnInit {
  @Input() account: Account = new Account();
  authenticated = false;
  authenticated$: Observable<boolean>;
  private readonly logger = new Logger( CurrentUserComponent.name )
  showDialog = false;
  @Input() user: CurrentUser = new CurrentUser();
  @Input() userAccounts: AccountMember[] = [];

  constructor(public store: Store<any>) {
    super(store);
    this.authenticated$ = authenticatedSelector(store);
  }

  get activeUser(): CurrentUser {
    return this.user && this.user.impersonating
      ? this.user.impersonating
      : this.user;
  }

  ngOnInit() {
    this.sync(['authenticated']);
  }

  onHover() {
    this.logger.log( 'onHover' )
    this.showDialog = true

    setTimeout( () => {
      this.logger.log( 'onHover setTimeout' )
      this.showDialog = false
    }, 2000 )
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }
}
