<div id="print-buttons">
  <div *ngIf="!isMinutesView" class="left">
    <mat-button-toggle appearance="legacy" [checked]="form.get('descriptions').value"
      (change)="form.get('descriptions').setValue(!form.get('descriptions').value)">
      <mat-icon *ngIf="!form.get('descriptions').value">add</mat-icon>
      <mat-icon *ngIf="form.get('descriptions').value">remove</mat-icon>
      <span class="icon-text">Descriptions</span>
    </mat-button-toggle>
    <mat-button-toggle appearance="legacy" [checked]="form.get('attachments').value"
      (change)="form.get('attachments').setValue(!form.get('attachments').value)">
      <mat-icon *ngIf="!form.get('attachments').value">add</mat-icon>
      <mat-icon *ngIf="form.get('attachments').value">remove</mat-icon>
      <span class="icon-text">Attachments</span>
    </mat-button-toggle>
    <mat-button-toggle *ngIf="!isPublicUser" appearance="legacy" [checked]="form.get('notes').value"
      (change)="form.get('notes').setValue(!form.get('notes').value)">
      <mat-icon *ngIf="!form.get('notes').value">add</mat-icon>
      <mat-icon *ngIf="form.get('notes').value">remove</mat-icon>
      <span class="icon-text">Notes</span>
    </mat-button-toggle>
    <mat-button-toggle *ngIf="(!isPublicUser && showMinutes) || showMinutes" appearance="legacy" [checked]="form.get('minutes').value"
      (change)="form.get('minutes').setValue(!form.get('minutes').value)">
      <mat-icon *ngIf="!form.get('minutes').value">add</mat-icon>
      <mat-icon *ngIf="form.get('minutes').value">remove</mat-icon>
      <span class="icon-text">Minutes</span>
    </mat-button-toggle>
    <mat-button-toggle
      appearance="legacy"
      [checked]="form.get('attendance').value"
      (change)="form.get('attendance').setValue(!form.get('attendance').value)"
      *ngIf="!isForAgendaItem"
    >
      <mat-icon *ngIf="!form.get('attendance').value">add</mat-icon>
      <mat-icon *ngIf="form.get('attendance').value">remove</mat-icon>
      <span class="icon-text">Attendance</span>
    </mat-button-toggle>
    <mat-button-toggle appearance="legacy" [checked]="form.get('voting').value"
      (change)="form.get('voting').setValue(!form.get('voting').value)">
      <mat-icon *ngIf="!form.get('voting').value">add</mat-icon>
      <mat-icon *ngIf="form.get('voting').value">remove</mat-icon>
      <span class="icon-text">Voting</span>
    </mat-button-toggle>
    <mat-button-toggle appearance="legacy" [checked]="form.get('tags').value"
      (change)="form.get('tags').setValue(!form.get('tags').value)">
      <mat-icon *ngIf="!form.get('tags').value">add</mat-icon>
      <mat-icon *ngIf="form.get('tags').value">remove</mat-icon>
      <span class="icon-text">Tags</span>
    </mat-button-toggle>
    <mat-button-toggle
      appearance="legacy"
      [checked]="form.get('subItems').value"
      (change)="form.get('subItems').setValue(!form.get('subItems').value)"
      *ngIf="isForAgendaItem"
    >
      <mat-icon *ngIf="!form.get('subItems').value">add</mat-icon>
      <mat-icon *ngIf="form.get('subItems').value">remove</mat-icon>
      <span class="icon-text">Sub-Items</span>
    </mat-button-toggle>
  </div>
  <div class="export" [ngClass]="isMinutesView ? 'full' : ''" *ngIf="export">
    <button class="btn-right print" mat-raised-button color="primary" (click)="doPrint.emit()"
      [disabled]="form.get('attachments').value">
      <mat-icon class="print-icon">print</mat-icon>
      <span class="icon-text">Print</span>
    </button>
    <button class="btn-right word" mat-raised-button color="primary" (click)="word.emit()"
      [disabled]="form.get('attachments').value">
      <img src="assets/word.ico" class="wordbutton" />
      <span class="icon-text">Word</span>
    </button>
    <button class="btn-right pdf" mat-raised-button color="primary" (click)="pdf.emit()">
      <img src="assets/pdf.png" class="pdfbutton" />
      <span class="icon-text pdf-text">PDF</span>
    </button>
  </div>
</div>
<div class="embed">
  <mat-checkbox *ngIf="hasDescription"
    (change)="form.get('showAgendaDescription').setValue(!form.get('showAgendaDescription').value)">
    Show agenda description
  </mat-checkbox>
  <mat-checkbox *ngIf="form.get('attachments').value"
    (change)="form.get('appendAttachments').setValue(!form.get('appendAttachments').value)">
    Append attachments within pdf when possible (Otherwise attachments are embedded in original format)
  </mat-checkbox>
</div>
