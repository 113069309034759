import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { PrintRoutingModule } from './print-routing.module';
import { PrintComponent } from './print.component';
import { PrintOptionsComponent } from './print-options/print-options.component';
import { AgendaItemsModule } from '../agenda-items/agenda-items.module';
import { AttachmentsModule } from '../attachments/attachments.module';
import { AttendanceModule } from '../attendance/attendance.module';
import { MinutesModule } from '../minutes/minutes.module';
import { NotesModule } from '../notes/notes.module';
import { VotesModule } from '../votes/votes.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    PrintRoutingModule,
    MatButtonToggleModule,
    AttachmentsModule,
    AttendanceModule,
    MinutesModule,
    NotesModule,
    VotesModule,
    AgendaItemsModule
  ],
  declarations: [PrintComponent, PrintOptionsComponent],
  exports: [PrintOptionsComponent]
})
export class PrintModule { }
