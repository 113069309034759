import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core'
import { SmartComponent } from '@caiu/library'
import { Store } from '@ngrx/store'
import { Logger } from 'src/app/shared/utils'
import { DialogBaseComponent } from '../dialog-base/dialog-base.component'
import { ConfirmResult } from './dialog-confirm.model'

@Component( {
  selector: 'am-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: [ './dialog-confirm.component.scss' ]
} )
export class DialogConfirmComponent extends SmartComponent implements OnInit {
  @ViewChild( DialogBaseComponent ) dialogBase: DialogBaseComponent
  @Input() closeCallback: ( value: ConfirmResult ) => void
  private readonly logger = new Logger( DialogConfirmComponent.name )

  constructor( store: Store<any> ) {
    super( store )
  }

  get isOpen() {
    return this.dialogBase.isOpen
  }

  ngOnInit(): void {
  }

  confirmClick( event: MouseEvent ) {
    event.preventDefault()
    this.close( 'confirm' )
  }

  cancelClick( event: MouseEvent ) {
    event.preventDefault()
    this.close( 'cancel' )
  }

  public open() {
    this.logger.log( 'open' )
    this.dialogBase.open()
  }

  public close( value: ConfirmResult ) {
    this.dialogBase.close( value )
  }
}
