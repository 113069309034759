import { Action, build, routeParamIdSelector, toArray, falsy } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { Agendas, Agenda } from './agendas.model';
import { MeetingActions, MeetingsActions, meetingSecurityStatusIdSelector, meetingNameSelector, meetingUrlSelector } from '../meetings/meetings.reducer';
import { accountIdSelector } from '../accounts/accounts.reducer';

export class AgendasActions {
  static ACTIVATE = '[Agendas] Activate';
  static GET = '[Agendas] Get';
  static POST = '[Agendas] POST';
  static POST_ERROR = '[Agendas] POST ERROR';
  static POST_AGENDA_TEMPLATE = '[Agendas] POST AGENDA TEMPLATE';
  static POST_AGENDA_TEMPLATE_ERROR = '[Agendas] POST AGENDA TEMPLATE ERROR';
  static POST_TEMPLATE = '[Agendas] POST TEMPLATE';
  static POST_TEMPLATE_ERROR = '[Agendas] POST TEMPLATE ERROR';
  static TOGGLE = '[Agenda] Toggle';
  static UPDATE = '[Agendas] Update';
  static UPDATE_ERROR = '[Agendas] Update Error';

  static activate(id: number): Action {
    return {
      type: AgendasActions.ACTIVATE,
      payload: id
    };
  }

  static toggle(show: boolean) {
    return {
      type: AgendasActions.TOGGLE,
      payload: show
    };
  }
}

export class AgendaActions {
  static DELETE = '[Agenda] DELETE';
  static DELETE_ERROR = '[Agenda] DELETE ERROR';
  static GET = '[Agenda] Get';
  static PUT = '[Agenda] PUT';
  static PUT_ERROR = '[Agenda] PUT ERROR';
  static PUT_STREAM = '[Agenda] PUT STREAM';
}

export class AgendaDetailsActions {
  static SET_VALUE = '[Agendas] Activate';

  static setValue(payload: boolean) {
    return {
      type: AgendaDetailsActions.SET_VALUE,
      payload
    };
  }
}

export function agendasReducer(state: Agendas = new Agendas(), action: Action): Agendas {
  switch (action.type) {
    case AgendasActions.ACTIVATE:
      return build(Agendas, state.activate(<number>action.payload));

    case AgendasActions.GET:
      return build(Agendas, state.update(<Agenda[]>action.payload));

    case AgendasActions.POST:
    case AgendasActions.POST_TEMPLATE:
    case AgendasActions.UPDATE:
      return build(Agendas, state.update(<Agenda>action.payload));

    case AgendasActions.POST_AGENDA_TEMPLATE:
      return build(Agendas, state.update(<Agenda>action.payload.template));

    case AgendaActions.GET:
      return build(Agendas, state.update(<Agenda>action.payload));

    case AgendaActions.DELETE:
    case AgendaActions.PUT:
    case AgendaActions.PUT_STREAM:
      return build(Agendas, state.update(<Agenda>action.payload));

    case AgendasActions.TOGGLE:
      return build(Agendas, state, { showAgenda: <boolean>action.payload });

    case MeetingsActions.GET:
      return build(Agendas, state.update(toArray(action.payload).reduce((acc, x) => {
        return [...acc, ...toArray(x.agendas)];
      }, [])));

    case MeetingActions.GET:
      return build(Agendas, state.update(toArray(action.payload.agendas)));

    default:
      return state;
  }
}

export function agendaDetailsReducer(state: boolean = false, action: Action): boolean {
  switch (action.type) {

    case AgendaDetailsActions.SET_VALUE:
      return action.payload;

    default:
      return state;
  }
}

export function agendaDetailsSelector(store: Store<any>): Observable<boolean> {
  return store.select('agendaDetails');
}

export function agendasSelector(store: Store<any>): Observable<Agendas> {
  return store.select('agendas');
}

export function deletedAgendasSelector(store: Store<any>): Observable<Agenda[]> {
  return combineLatest(accountIdSelector(store), agendasSelector(store), (accountId, agendas) =>
    agendas.asArray.filter(agenda => (agenda.markedForDelete || agenda.meetingDeleted) && agenda.accountId === accountId)
  );
}

export function deletedAgendasSysAdminSelector(store: Store<any>): Observable<Agenda[]> {
  return combineLatest(agendasSelector(store), (agendas) =>
    agendas.asArray.filter(agenda => (agenda.markedForDelete || agenda.meetingDeleted))
  );
}

export function agendaToggleSelector(store: Store<any>): Observable<boolean> {
  return agendasSelector(store).pipe(
    map(agendas => agendas.showAgenda),
    distinctUntilChanged()
  );
}

export function agendaSelector(store: Store<any>): Observable<Agenda> {
  return combineLatest(
    agendasSelector(store),
    routeParamIdSelector(store, 'agendaId'),
    meetingNameSelector(store),
    meetingUrlSelector(store),
    meetingSecurityStatusIdSelector(store),
    (agendas, id, meetingName, url, meetingSecurityStatusId) => {
      const agenda = agendas.get(id);
      // agenda.name = agenda.name || meetingName;
      agenda.securityStatusId = agenda.securityStatusId || meetingSecurityStatusId;
      agenda.videoConferenceUrl = agenda.id === 0 && falsy(agenda.videoConferenceUrl) ? url : agenda.videoConferenceUrl;

      return agenda;
    });
}

export function meetingAgendasSelector(store: Store<any>): Observable<Agenda[]> {
  return combineLatest(agendasSelector(store), routeParamIdSelector(store, 'meetingId'), (agendas, meetingId) => {
    return agendas.asArray.filter(x => x.meetingId === meetingId && !x.markedForDelete);
  });
}

export function agendaDateSelector(store: Store<any>): Observable<Date> {
  return agendaSelector(store).pipe(
    map(x => x.agendaDate),
    distinctUntilChanged()
  );
}

export function agendaNameSelector(store: Store<any>): Observable<string> {
  return agendaSelector(store).pipe(
    map(x => x.name),
    distinctUntilChanged()
  );
}

export function agendaGroupIdSelector(store: Store<any>): Observable<number> {
  return agendaSelector(store).pipe(
    map(x => x.groupId),
    distinctUntilChanged()
  );
}

export function agendaIdGroupIdSelector(store: Store<any>, agendaId$: Observable<number>): Observable<number> {
  return combineLatest([agendasSelector(store), agendaId$]).pipe(
    map(x => x[0].get(x[1]).groupId),
    distinctUntilChanged()
  );
}

export function agendaAttendanceTakerIdSelector(store: Store<any>): Observable<number> {
  return agendaSelector(store).pipe(
    map(x => x.attendanceTakerId),
    distinctUntilChanged()
  );
}

export function agendaMinuteTakerIdSelector(store: Store<any>): Observable<number> {
  return agendaSelector(store).pipe(
    map(x => x.minuteTakerId),
    distinctUntilChanged()
  );
}

export function agendaVoteTakerIdSelector(store: Store<any>): Observable<number> {
  return agendaSelector(store).pipe(
    map(x => x.voteTakerId),
    distinctUntilChanged()
  );
}

export function agendaSecurityStatusIdSelector(store: Store<any>): Observable<number> {
  return agendaSelector(store).pipe(
    map(x => x.securityStatusId),
    distinctUntilChanged()
  );
}

export function isAgendaPrivateSelector(store: Store<any>): Observable<boolean> {
  return agendaSelector(store).pipe(
    map(x => x.isPrivate),
    distinctUntilChanged()
  );
}

export function isAgendaPublicSelector(store: Store<any>): Observable<boolean> {
  return agendaSelector(store).pipe(
    map(x => x.isPublic),
    distinctUntilChanged()
  );
}
