<div class="minutes-preview">
  <span class="minutes-margin">&nbsp;</span>
<table class="minutes-container" *ngIf="minutes">
  <tr>
    <td class="minutes-left-margin">&nbsp;</td>
    <td class="minutes-cell">
      <div class="minutes-heading" *ngIf="showHeader">Minutes</div>
      <p class="minutes" [innerHtml]="minutes | safeHtml"></p>
    </td>
  </tr>
</table>
</div>

