import { Component, OnInit, Input } from '@angular/core';
import {
  SmartComponent,
  CurrentUser,
  currentUserSelector,
  truthy,
  windowHeightSelector
} from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AccountMember, Account } from '../models';
import { fileToBinary } from '../utils';
import {
  accountSelector
} from '../../accounts/accounts.reducer';
import { currentUserAccountsSelector } from 'src/app/members/members.reducer';
import { map } from 'rxjs/operators';

@Component({
  selector: 'am-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends SmartComponent implements OnInit {
  @Input() headerType: 'DEFAULT' | 'LOGIN' = 'DEFAULT';
  account: Account = new Account();
  account$: Observable<Account>;
  marginTop$: Observable<number>;
  user$: Observable<CurrentUser>;
  userAccounts$: Observable<AccountMember[]>;
  windowHeight$: Observable<number>;

  constructor(public store: Store<any>) {
    super(store);
    this.account$ = accountSelector(store);
    this.windowHeight$ = windowHeightSelector(store);
    this.marginTop$ = this.windowHeight$.pipe(
      map(x => x > 800 ? 100 : 0)
    );
    this.user$ = currentUserSelector(store);
    this.userAccounts$ = currentUserAccountsSelector(store);
  }

  get hasAccount(): boolean {
    return truthy(this.account.id);
  }

  get logoSrc(): string {
    return this.account.logo ? fileToBinary(this.account.logo) : '';
  }

  ngOnInit() {
    this.sync(['account']);
  }
}
