import { NgModule } from '@angular/core'

import { PopoutRoutingModule } from './popout-routing.module'
import { PopoutComponent } from './popout.component'
import { SharedModule } from '../shared/shared.module'

@NgModule( {
    imports: [
        PopoutRoutingModule,
        SharedModule
    ],
    declarations: [
        PopoutComponent
    ],
    exports: [
        PopoutComponent
    ]
} )
export class PopoutModule { }

