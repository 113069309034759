<div id="announcement">
  <div class="header">
    <span class="subject">{{ announcement?.subject | uppercase }}</span>
    <span class="subtitle"></span>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div class="wrapper scrollable">
    <div class="body" [innerHTML]="announcement?.description | safeHtml"></div>
    <div class="signature" [innerHTML]="announcement?.signature | safeHtml"></div>
    <am-attachments *ngIf="announcement?.attachments?.length > 0" [attachments]="announcement?.attachments"
      attachmentsBasePath="announcementattachments">
    </am-attachments>
  </div>
</div>
