import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LibraryModule, FormsModule, DialogModule, EditorModule } from '@caiu/library';

import { ArrowsComponent } from './arrows/arrows.component';
import { AuditInfoComponent } from './audit-info/audit-info.component';
import { ContainerComponent } from './container/container.component';
import { CurrentUserModule } from './current-user/current-user.module';
import { DemoModule } from './demo/demo.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PanelModule } from './panel/panel.module';
import { SearchWidgetModule } from './search-widget/search-widget.module';
import { TabsModule } from './tabs/tabs.module';
import { DateRangeComponent } from './date-range/date-range.component';
import { WidgetsModule } from './widgets/widgets.module';
import { FilterComponent } from './filter/filter.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { DropdownModule } from './dropdown/dropdown.module'

@NgModule({
  imports: [
    LibraryModule,
    DialogModule,
    EditorModule,
    FormsModule,
    CurrentUserModule,
    DemoModule,
    DragDropModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    PanelModule,
    SearchWidgetModule,
    TabsModule,
    WidgetsModule,
    DropdownModule
  ],
  declarations: [
    ArrowsComponent,
    AuditInfoComponent,
    ContainerComponent,
    FooterComponent,
    HeaderComponent,
    DateRangeComponent,
    FilterComponent,
    SafeHtmlPipe,
    MobileMenuComponent
  ],
  exports: [
    LibraryModule,
    DialogModule,
    EditorModule,
    FormsModule,
    CurrentUserModule,
    DemoModule,
    DragDropModule,
    SearchWidgetModule,
    TabsModule,
    ArrowsComponent,
    AuditInfoComponent,
    ContainerComponent,
    FooterComponent,
    HeaderComponent,
    MatBadgeModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    PanelModule,
    DateRangeComponent,
    WidgetsModule,
    FilterComponent,
    SafeHtmlPipe,
    DropdownModule
  ]
})
export class SharedModule { }
