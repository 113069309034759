import {
    Component,
    Input
} from '@angular/core'
import { Router } from '@angular/router'
import { DumbComponent } from '@caiu/library'

import {
    DropdownItem,
    DropdownOptions
} from './dropdown.model'
import { Logger } from '../utils'

type DropdownStyle = 'standard' | 'tab'

@Component( {
    selector: 'am-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    animations: []
} )
export class DropdownComponent extends DumbComponent {
    @Input() options: DropdownOptions
    @Input() dropdownItems: DropdownItem[]
    @Input() actionStyle: DropdownStyle = 'standard'
    @Input() debug: boolean = false
    private readonly logger = new Logger( DropdownComponent.name )
    eventLogs: string[] = []

    constructor(
        private router: Router
    ) {
        super()
    }

    private log( message: string, event?: PointerEvent )
    private log( message: string, ...data: [ ...any, PointerEvent? ] )
    private log( message: string, ...data: any[] ) {
        let event: PointerEvent | null = null

        if ( data?.length >= 1 ) {
            const lastItem = data[ data.length - 1 ]
            const isEvent = lastItem.constructor.name === 'PointerEvent'

            if ( isEvent ) event = lastItem as PointerEvent
        }

        if ( event ) {
            const {
                pointerType,
                target,
                type,
                x,
                y
            } = event
            const eventData = {
                pointerType,
                type,
                x,
                y,
                target: target ? ( target as Node ).nodeName : ''
            }
            const eventLog = JSON.stringify( {
                message,
                data,
                event: eventData
            } )

            this.eventLogs.unshift( eventLog )
        }
        else {
            this.eventLogs.unshift( JSON.stringify( { message, data } ) )
        }

        if ( this.eventLogs.length > 10 ) {
            this.eventLogs.pop()
        }

        const element = document.getElementById( 'debugList' )

        if ( !element ) return

        element.style.display = 'none'

        return
    }

    get items(): DropdownItem[] {
        return this.dropdownItems.sort( ( itemA, itemB ) => {
            const orderA = itemA.order ?? 0
            const orderB = itemB.order ?? 0

            return orderA - orderB
        } )
    }

    get actionClassName() {
        const basicClasses = [
            'dropdown-action'
        ].join( ' ' )

        switch( this.actionStyle ) {
            case 'tab':
                return `${ basicClasses } dropdown-tab`
            case 'standard':
            default:
                return basicClasses
        }
    }

    itemClick( event: MouseEvent, item: DropdownItem ) {
        this.log( `Dropdown item "${ item.label }" clicked.`, event )

        if ( item.route ) {
            this.router.navigateByUrl( item.route )
            
            return
        }

        item.callback && item.callback( event )
    }
}
