import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormComponent, Control, build } from '@caiu/library';

import { Vote, VoteEdit } from '../votes.model';

@Component({
  selector: 'am-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoteComponent extends FormComponent {

  @Input() agendaItemId = 0;
  @Input() showResults = false;
  @Input() voterId = 0;
  _vote: Vote = new Vote();
  @Output() add: EventEmitter<Vote> = new EventEmitter<Vote>();
  @Output() update: EventEmitter<Vote> = new EventEmitter<Vote>();
  @Control(Vote) form: FormGroup;
  modelKey = 'vote';
  modelChanges = [];

  constructor() {
    super();
  }

  @Input()
  set vote(value: Vote) {
    this._vote = value;
    this.setValue(value);
  }

  get vote(): Vote {
    return this._vote;
  }

  get heading() {
    return this.vote.id === 0 ? `You haven't voted yet!` : `Change vote`;
  }

  get valueOut(): Vote {
    return build(Vote, this.form.value, {
      agendaItemId: this.agendaItemId,
      voterId: this.voterId
    });
  }

  get voteId(): number {
    return this.vote.id;
  }

  onSubmit(e: any) {
    if (this.vote.id === 0) {
      this.add.emit(this.valueOut);
    } else {
      this.update.emit(this.valueOut);
    }
  }

}
