<div class="attachments" *ngIf="!preview">
  <h2 class="item-header">Attachments</h2>
  <button
    *ngFor="let attachment of attachments"
    mat-button
    class="attachment-link"
    (click)="openAttachment(attachment)"
  >
    {{ attachment.fileName }}
    <mat-icon>
      {{ getAttachmentIcon( attachment ) }}
    </mat-icon>
    <mat-icon
      *ngIf="attachment.isPrivate"
    >
      lock
    </mat-icon>
  </button>
  <a id="open-att" [href]="fileUrl" target="_blank" #attLink [download]="fileName"></a>
</div>
<div class="attachments preview" *ngIf="preview">
  <h4 class="attachments-header">Attachments</h4>
  <h6 *ngFor="let attachment of attachments" mat-button class="attachment-link mat-button"
    (click)="openAttachment(attachment)">
    {{ attachment.fileName }}</h6>
  <a id="open-att" [href]="fileUrl" target="_blank" #attLink [download]="fileName"></a>
</div>
<div class="loading" *ngIf="loading">
  <mat-progress-bar mode="query"></mat-progress-bar>
  <mat-hint>Please wait while the file is loading.</mat-hint>
</div>
<div class="loading error" *ngIf="errorMessage">
  <mat-hint>{{ errorMessage }}</mat-hint>
</div>
