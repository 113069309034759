import { Collection } from '@caiu/library';

import { AgendaItem } from '../agenda-items/agenda-items.model';

export class BinItem {
  id = 0;
  accountId = 0;
  agendaItemId = 0;
  order = 0;
  userId = 0;

  agendaItem: AgendaItem = new AgendaItem();
}

export class BinItems extends Collection<BinItem> {
  constructor() {
    super(BinItem);
  }

}

export class BinItemOptions {
  accountId = 0;
  binItemId = 0;
  copyAttachments = false;
  copyDescription = false;
  copyMinutes = false;
  copyNotes = false;
  copySubItems = false;
  copyTags = false;
  isPrivate = false;
  isVotable = false;
  order = 0;
  parentId = null;
  removeAfterCopy = false;
}
