const BYTE_SIZES = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB'
]

export const convertBytesToString = ( bytes?: number, decimals: number = 2 ) => {
    const EMPTY_BYTES = 0

    if ( !bytes || bytes === EMPTY_BYTES ) return `0 ${ BYTE_SIZES[ 0 ] }`

    const k = 1024
    const dm = decimals && decimals > EMPTY_BYTES
        ? decimals
        : EMPTY_BYTES
    const i = Math.floor( Math.log( bytes ) / Math.log( k ) )

    return `${ parseFloat( ( bytes / ( k ** i ) ).toFixed( dm ) ) } ${ BYTE_SIZES[ i ] }`
}

export const padNumber = ( number: string | number, character = '0', size = 2 ) => `${ String( number ).length >= size
    ? ''
    : new Array( size - String( number ).length ).fill( character ).join( '' )
 }${ number }`

 export const tryParse = ( number: string, radix?: number ): number | undefined => {
    try {
        const newNumber = parseInt( number, 10 )

        return newNumber
    } catch( error ) {
        return undefined
    }
 }

 