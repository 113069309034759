import { Injectable } from '@angular/core';
import { Action, HttpActions, inArray, routeParamIdSelector, RouterActions, toArray } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AgendaItem } from './agenda-items.model';
import { AgendaItemActions, AgendaItemsActions } from './agenda-items.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';

@Injectable()
export class AgendaItemsEffects {
  /**
   * Redirect to agenda after deleting agenda item.
   */
  @Effect() onDelete: Observable<Action> = this.actions$.pipe(
    ofType(AgendaItemActions.DELETE),
    map((action: Action) => inArray(toArray(action.payload).map(x => x.id), this.agendaItemId) ? this.redirectToAgenda(this.meetingId, this.agendaId) : { type: 'NONE' })
  );

  /**
   * Save edited agenda item to database.
   */
  @Effect() onSave: Observable<Action> = this.actions$.pipe(
    ofType(AgendaItemActions.SAVE),
    map((action: Action) => this.save(<AgendaItem>action.payload))
  );

  /**
   * Redirect to agenda item after adding new agenda item.
   */
  @Effect() onPost: Observable<Action> = this.actions$.pipe(
    ofType(AgendaItemsActions.POST),
    map((action: Action) => this.redirectToAgendaItem(<AgendaItem>action.payload))
  );

  accountUrl = '';
  agendaItemId = 0;
  agendaId = 0;
  meetingId = 0;
  constructor(private actions$: Actions, public store: Store<any>) {
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
    routeParamIdSelector(store, 'meetingId').subscribe(id => {
      this.meetingId = id;
    });
    routeParamIdSelector(store, 'agendaId').subscribe(id => {
      this.agendaId = id;
    });
    routeParamIdSelector(store, 'agendaItemId').subscribe(id => {
      this.agendaItemId = id;
    });
  }

  redirectToAgenda(meetingId: number, agendaId: number): Action {
    return RouterActions.navigate(`/${this.accountUrl}/meetings/${meetingId}/agendas/${agendaId}`);
  }

  redirectToAgendaItem(agendaItem: AgendaItem): Action {
    return RouterActions.navigate(`/${this.accountUrl}/meetings/${agendaItem.agenda.meetingId}/agendas/${agendaItem.agendaId}/agendaitems/${agendaItem.id}/edit`);
  }

  save(agendaItem: AgendaItem): Action {
    return agendaItem.id === 0
      ? HttpActions.post(`agendaitems`, agendaItem, AgendaItemsActions.POST)
      : HttpActions.put(`agendaitems/${agendaItem.id}`, agendaItem, AgendaItemActions.PUT);
  }
}
