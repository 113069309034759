import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-attachments-upload',
  templateUrl: './attachments-upload.component.html',
  styleUrls: ['./attachments-upload.component.scss']
})
export class AttachmentsUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
