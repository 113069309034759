import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'

import { DropdownComponent } from './dropdown.component'

@NgModule( {
    declarations: [
        DropdownComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule
    ],
    exports: [
        DropdownComponent
    ]
} )
export class DropdownModule { }
