import { Action } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Notifications, Notification } from './notifications.model';

export class NotificationsActions {
  static ADD_ACCOUNT_REQUEST = '[Notifications] Add Account Request';
  static ADD_ACCOUNT_REQUEST_ERROR = '[Notifications] Add Account Request Error';
  static APPROVE_ACCOUNT_REQUEST = '[Notifications] Approve Account Request';
  static APPROVE_ACCOUNT_REQUEST_ERROR = '[Notifications] Approve Account Request Error';
  static DENY_ACCOUNT_REQUEST = '[Notifications] Deny Account Request';
  static DENY_ACCOUNT_REQUEST_ERROR = '[Notifications] Deny Account Request Error';
  static DELETE = '[Notifications] Delete';
  static GET = '[Notifications] Get';
  static SAVE = '[Notifications] Save';

  static delete(payload: string): Action {
    return {
      type: NotificationsActions.DELETE,
      payload
    }
  }

  static save(payload: Notification<any>[]): Action {
    return {
      type: NotificationsActions.SAVE,
      payload
    }
  }
}

export class NotificationActions {
  static DELETE = '[Notification] Delete';
  static PUT = '[Notification] Update';
}

export function notificationsReducer(state: Notifications = new Notifications(), action: Action): Notifications {
  switch (action.type) {

    case NotificationsActions.GET:
    case NotificationsActions.SAVE:
      return state.update(action.payload);

    case NotificationsActions.DELETE:
      return state.delete(action.payload);

    default:
      return state;
  }
}

export function notificationsSelector(store: Store<any>): Observable<Notifications> {
  return store.select('notifications');
}

export function userNotificationsSelector(store: Store<any>): Observable<Notification<any>[]> {
  return notificationsSelector(store).pipe(
    map(x => x.asArray)
  );
}
