<form [formGroup]="form">
  <h1>Edit Attendance Log Event</h1>
  <div class="row">
    <h3 class="label">Attendee</h3>
    <span class="value">{{ data.userId === 0 ? data.writeIns : data.userName }}</span>
  </div>
  <div class="row">
    <h3>Attendance Status</h3>
    <mat-radio-group aria-label="Select an attendance status" formControlName="isPresent">
      <mat-radio-button [value]="true">Present</mat-radio-button>
      <mat-radio-button [value]="false">Absent</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="row">
    <iu-datetime-control [hideDate]="true" formControlName="timestamp"></iu-datetime-control>
  </div>
  <div class="actions">
    <button mat-button class="btn-cancel" mat-dialog-close>Cancel</button>
    <button mat-button class="btn-submit" [mat-dialog-close]="valueOut">Save</button>
  </div>
</form>
