import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { SmartComponent } from '@caiu/library'
import { Store } from '@ngrx/store'
import { Logger } from 'src/app/shared/utils'

@Component( {
  selector: 'am-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: [ './dialog-base.component.scss' ]
} )
export class DialogBaseComponent extends SmartComponent implements OnInit {
  @ViewChild( 'dialogBase' ) dialogElementRef: ElementRef<HTMLDialogElement>
  @Input() closeCallback: ( value: string ) => void
  public isOpen = false
  protected readonly logger = new Logger( DialogBaseComponent.name )

  private get dialogElement() {
    return this.dialogElementRef && this.dialogElementRef.nativeElement
      ? this.dialogElementRef.nativeElement
      : null
  }

  constructor( store: Store<any> ) {
    super( store )
  }

  ngOnInit(): void {}

  public handleClose( event: Event ) {
    this.logger.log( 'handleClose', { event, callback: this.closeCallback, value: this.dialogElement?.returnValue } )
    this.closeCallback && this.closeCallback( this.dialogElement?.returnValue )
  }

  public open() {
    this.logger.log( 'open', { element: this.dialogElement } )
    this.dialogElement?.showModal()
    this.isOpen = true
  }

  public close( value: string ) {
    this.dialogElement?.close( value )
    this.isOpen = false
  }
}
