import { Component, Input, OnInit } from '@angular/core';
import { HttpService, truthy, build, DumbComponent } from '@caiu/library';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { AgendaItemMinutes } from '../minutes.model';

@Component({
  selector: 'am-minutes-preview',
  templateUrl: './minutes-preview.component.html',
  styleUrls: ['./minutes-preview.component.scss']
})
export class MinutesPreviewComponent extends DumbComponent implements OnInit {

  @Input() showHeader = true;
  agendaItemIdSubject = new BehaviorSubject(0);
  minutes = '';
  minutes$: Observable<string>;

  constructor(public http: HttpService) {
    super();
    this.minutes$ = this.agendaItemIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendaitems/${id}/minutes`)),
      map(x => build(AgendaItemMinutes, x).minutes),
      filter(y => truthy(y))
    );
  }

  @Input()
  set agendaItemId(value: number) {
    this.agendaItemIdSubject.next(value);
  }

  get agendaItemId(): number {
    return this.agendaItemIdSubject.value;
  }

  // @Input()
  // set minutes(value: string) {
  //   this._minutes = value;
  // }

  // get minutes(): string {
  //   return this._minutes;
  // }

  ngOnInit() {
    this.sync(['minutes']);
  }

}
