import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Control, DumbComponent, build } from '@caiu/library';

import { Attendee } from '../attendance.model';

@Component({
  selector: 'am-attendance-event',
  templateUrl: './attendance-event.component.html',
  styleUrls: ['./attendance-event.component.scss']
})
export class AttendanceEventComponent extends DumbComponent implements OnInit {

  @Control(Attendee) form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Attendee) {
    super();
    this.setValue(data);
  }

  get valueOut(): Attendee {
    // const d = new Date(this.form.get('timestamp').value);
    // const timestamp = new Date(d.setHours(d.getHours() - 4));
    // return build(Attendee, this.data, this.form.value, {
    //   timestamp
    // });
    return build(Attendee, this.data, this.form.value);
  }

  ngOnInit(): void {
  }

}
