import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-demo-routes',
  templateUrl: './demo-routes.component.html',
  styleUrls: ['./demo-routes.component.scss']
})
export class DemoRoutesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
