import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ArrayControl, build, Control, DumbComponent, equals } from '@caiu/library';
import { FormArray, FormGroup } from '@angular/forms';

import { Vote } from '../votes.model';
import { Logger } from 'src/app/shared/utils';

class MotionSecond {
  motion = '';
  second = '';
}

class Voter {
  voterId = 0;
  voterName = '';
}

@Component({
  selector: 'am-votes-form',
  templateUrl: './votes-form.component.html',
  styleUrls: ['./votes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VotesFormComponent extends DumbComponent implements OnInit {

  @Control(MotionSecond) form: FormGroup;
  @ArrayControl(Vote) formArray: FormArray;
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter<Vote[]>();
  _motionId = 0;
  _secondId = 0;
  _termMotion = '';
  _termSecond = '';
  _votes: Vote[] = [];
  votersMotion: Voter[] = [];
  votersSecond: Voter[] = [];
  private readonly logger = new Logger( VotesFormComponent.name )

  constructor() {
    super();
  }

  @Input()
  set votes(value: Vote[]) {
    if (!equals(this._votes, value)) {
      this.formArray.setValue(value);
      this._votes = value;
      this.refresh();
    }
  }

  get votes(): Vote[] {
    return this._votes;
  }

  get totalVotes(): number {
    return this.votes.length;
  }

  get abstainCount(): number {
    return this.votes.filter(x => x.answerId === 3).length;
  }

  get nayCount(): number {
    return this.votes.filter(x => x.answerId === 2).length;
  }

  get notCastCount(): number {
    return this.votes.filter(x => x.answerId === 4).length;
  }

  get yeaCount(): number {
    return this.votes.filter(x => x.answerId === 1).length;
  }

  set motionId(value: number) {
    this._motionId = value;
    const voter = this.voters.find(x => x.voterId === value);
    if (voter) {
      this.form.get('motion').setValue(voter.voterName);
      // this.formArray.setValue(this.formArray.value.map(x => {
      //   const motion = x.voterId === value;
      //   const answerId = motion ? 1 : x.answerId;
      //   return build(Vote, x, {
      //     motion,
      //     answerId
      //   });
      // }));
    } else {
      this.form.get('motion').setValue('');
      this.formArray.setValue(this.formArray.value.map(x => {
        return build(Vote, x, {
          motion: false
        });
      }));
    }
  }

  get motionId(): number {
    return this._motionId;
  }

  set secondId(value: number) {
    this._secondId = value;
    const voter = this.voters.find(x => x.voterId === value);
    if (voter) {
      this.form.get('second').setValue(voter.voterName);
      // this.formArray.setValue(this.formArray.value.map(x => {
      //   const second = x.voterId === value;
      //   const answerId = second ? 1 : x.answerId;
      //   return build(Vote, x, {
      //     second,
      //     answerId
      //   });
      // }));
    } else {
      this.form.get('second').setValue('');
      this.formArray.setValue(this.formArray.value.map(x => {
        return build(Vote, x, {
          second: false
        });
      }));
    }
  }

  get secondId(): number {
    return this._secondId;
  }

  set termMotion(value: string) {
    this._termMotion = value;
    this.resetVotersMotion();
  }

  get termMotion(): string {
    return this._termMotion;
  }

  set termSecond(value: string) {
    this._termSecond = value;
    this.resetVotersSecond();
  }

  get termSecond(): string {
    return this._termSecond;
  }

  get valueOut(): Vote[] {
    return this.formArray.value.map(x => {
      const motion = x.voterId === this.motionId;
      const second = x.voterId === this.secondId;
      const vote = this.votes.find(y => y.id === x.id);
      return build(Vote, vote, x, {
        // answerId: motion || second ? 1 : x.answerId,
        answerId: x.answerId,
        motion,
        second
      });
    });
  }

  get voters(): Voter[] {
    return this.votes.map(x => build(Voter, {
      voterId: x.voterId,
      voterName: x.fullName
        ? x.fullName
        : x.voterName || ''
    }));
  }

  ngOnInit() {
    this.addSubscription(this.form.get('motion').valueChanges.subscribe(term => {
      this.termMotion = term;
    }));
    this.addSubscription(this.form.get('second').valueChanges.subscribe(term => {
      this.termSecond = term;
    }));
    this.form.get('motion').setValue('');
    this.form.get('second').setValue('');
  }

  applyToAll(answerId: number) {
    this.formArray.setValue(this.formArray.value.map(x => Object.assign({}, x, { answerId })));
  }

  clearMotion() {
    this.motionId = 0;
  }

  clearSecond() {
    this.secondId = 0;
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    this.save.emit(this.valueOut);
  }

  refresh() {
    const voters = this.voters

    this.votersMotion = voters
    this.votersSecond = voters

    
    const motion = this.votes.find( ( voter ) => voter.motion )
    const second = this.votes.find( ( voter ) => voter.second )

    this.logger.log( 'refresh', { votes: this.votes, voters, motion, second } )

    if (motion) {
      this.motionId = motion.voterId;
      setTimeout(() => {
        this.form.get('motion').setValue(motion.voterName);
      }, 0);
    } else {
      this.form.get('motion').setValue('');
    }
    
    if (second) {
      this.secondId = second.voterId;
      setTimeout(() => {
        this.form.get('second').setValue(second.voterName);
      }, 0);
    } else {
      this.form.get('second').setValue('');
    }
  }

  resetVotersMotion() {
    this.votersMotion = this.voters.filter(x => x.voterName.toLowerCase().indexOf(this.termMotion.toLowerCase()) !== -1);
  }

  resetVotersSecond() {
    this.votersSecond = this.voters.filter(x => x.voterId !== this.motionId && x.voterName.toLowerCase().indexOf(this.termSecond.toLowerCase()) !== -1);
  }

  selectMotion(e) {
    this.motionId = build(Voter, this.voters.find(x => x.voterName === e.option.value)).voterId;
    this.resetVotersSecond();
  }

  selectSecond(e) {
    this.secondId = build(Voter, this.voters.find(x => x.voterName === e.option.value)).voterId;
    // setTimeout(() => {
    //   this.applyToAll(1);
    // }, 0);
  }

}
