import {
    Component,
    HostListener,
    OnInit
} from '@angular/core'
import { Store } from '@ngrx/store'
import { SmartComponent } from '@caiu/library'

import { PopoutMessageEvent, PostMessagePayload } from '../../popout.model'
import { Logger } from 'src/app/shared/utils'

@Component( {
    selector: 'am-popout-base',
    templateUrl: './popout-base.component.html'
} )
export abstract class PopoutBase<T> extends SmartComponent implements OnInit {
    protected __main__: Window
    readonly logger = new Logger( PopoutBase.name )

    constructor( store: Store<any> ) {
        super( store )
    }

    ngOnInit() {
        this.logger.log( 'ngOnInit', window, PopoutBase.name )
        this.__main__ = window.opener
    }

    @HostListener( 'window:message', [ '$event' ] )
    protected handleMessages( event: PopoutMessageEvent<T> ) {
        this.logger.log( 'handleMessages', event.data, PopoutBase.name )

        switch( event.data.action ) {
            case 'close':
                window.close()
                break
        }
    }

    @HostListener( 'window:beforeunload', [ '$event ' ] )
    protected handleUnload( event: BeforeUnloadEvent ) {
        this.logger.log( 'handleUnload', event )

        this.sendMessage( { action: 'close', data: null } )
    }

    protected sendMessage( payload: PostMessagePayload<T> ) {
        this.logger.log( 'sendMessage', payload )

        this.__main__.postMessage( payload, this.__main__.location.origin )
    }
}

