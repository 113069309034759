<div id="test-links">
  <a [routerLink]="['/', { outlets: { popup: 'bin' } }]">Bin (#3012)</a>
  <a [routerLink]="['/', { outlets: { popup: 'email' } }]">Email (#1831)</a>
  <a [routerLink]="['/', { outlets: { popup: 'minutes' } }]">Minutes (#3020)</a>
  <a [routerLink]="['/', { outlets: { popup: 'print' } }]">Print (#3023)</a>
  <a [routerLink]="['/', { outlets: { popup: 'templates' } }]">Template Manager (#3026)</a>
  <a routerLink="/caiu/account">Account (#2829)</a>
  <a routerLink="/caiu/edit">Account Edit (#1824)</a>
  <a routerLink="/admin/accounts/new">Account Edit (Create Account)</a>
  <a routerLink="/caiu/info">Account Info (#3469)</a>
  <a routerLink="/admin/accounts">Accounts (#1825)</a>
  <a routerLink="/accounts">Accounts Portal (#2830)</a>
  <a routerLink="/caiu/meetings/1/agendas/1">Agenda (#1848)</a>
  <a routerLink="/caiu/meetings/1/agendas/1/edit">Agenda Edit (#1847)</a>
  <a routerLink="/caiu/meetings/1/agendas/1/agendaitems/4000">Agenda Item (#1846)</a>
  <a routerLink="/caiu/meetings/1/agendas/1/agendaitems/4000/edit">Agenda Item Edit (#1843)</a>
  <a routerLink="/caiu/announcements/5/edit">Announcement Edit (#1826)</a>
  <a routerLink="/admin/announcements">Announcements (#1827)</a>
  <a routerLink="/caiu/dashboard">Dashboard (#1830)</a>
  <!-- <a class="tab" routerLink="/caiu/files">Files (#1842)</a> -->
  <a routerLink="/caiu/groups/5/edit">Group Edit (#1845)</a>
  <a routerLink="/caiu/groups">Groups (#1832)</a>
  <!-- <a class="tab" routerLink="/help">Help (#1828)</a> -->
  <a routerLink="/login">Login (#1841)</a>
  <a routerLink="/caiu/meetings/1">Meeting (#1839)</a>
  <a routerLink="/caiu/meetings/1/edit">Meeting Edit (#1834)</a>
  <a routerLink="/caiu/meetings">Meetings (#1836)</a>
  <a routerLink="/caiu/members/0/edit">Member Edit (#1837)</a>
  <a routerLink="/caiu/members">Members (#1838)</a>
  <!-- <a class="tab" routerLink="/caiu/members/import">Members Import (#1833)</a> -->
  <a routerLink="/caiu/profile">Profile (#1829)</a>
  <a routerLink="/reset-password">Reset Password (#1840)</a>
  <a routerLink="/admin/restore">Restore (#1844)</a>
  <a routerLink="/caiu/search">Search (#1823)</a>
</div>
