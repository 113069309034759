import { Action, build, compareDates } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Template, Templates } from './templates.model';
import { AgendasActions } from '../agendas/agendas.reducer';

export class TemplatesActions {
  static DELETE = '[Templates] Delete';
  static DELETE_ERROR = '[Templates] Delete Error';
  static GET = '[Templates] Get';
  static POST = '[Templates] Post';
  static POST_ERROR = '[Templates] Post Error';
}

export function templatesReducer(state: Templates = new Templates(), action: Action): Templates {
  switch (action.type) {

    case TemplatesActions.DELETE:
      return state.delete(action.payload);

    case TemplatesActions.GET:
      return build(Templates, state.replace(<Template[]>action.payload));

    case TemplatesActions.POST:
    case AgendasActions.POST_AGENDA_TEMPLATE:
      return build(Templates, state.update(<Template>action.payload));

    default:
      return state;
  }
}

export function templatesSelector(store: Store<any>): Observable<Templates> {
  return store.select('templates');
}

export function userTemplatesSelector(store: Store<any>): Observable<Template[]> {
  return templatesSelector(store).pipe(
    map(x => x.asArray.sort((a, b) => compareDates(a.lastModifiedDate, b.lastModifiedDate)).reverse())
  );
}

export function templateSelector(store: Store<any>): Observable<Template> {
  return templatesSelector(store).pipe(
    map(templates => templates.active || new Template())
  );
}
