import {
    Component,
    HostListener,
    ViewChild
} from '@angular/core'
import {
    ActivatedRoute,
    Router
} from '@angular/router'
import { FormGroup } from '@angular/forms'
import {
    build,
    Control,
    FULL_PLUGINS,
    FULL_TOOLBAR,
    HttpActions,
    MessageSubscription,
    routeParamBoolSelector,
    routeParamIdSelector,
    routeParamSelector
} from '@caiu/library'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { PopoutBase } from '../popout-base/popout-base.component'
import {
    AgendaItemActions,
    nextAgendaItemLinkSelector,
    previousAgendaItemLinkSelector
} from '../../../agenda-items/agenda-items.reducer'
import { currentUserIdSelector } from '../../../shared/selectors'
import {
    Logger,
    tryParse
} from '../../../shared/utils'
import { PopoutMessageEvent } from '../../popout.model'
import { PopoutMinutesMessage } from './popout-minutes.model'
import { AgendaItemMinutes } from 'src/app/minutes/minutes.model'
import {
    AgendaItemNotes,
    NotesEditor
} from 'src/app/notes/notes.model'
import {
    agendaItemMinutesSelector,
    MinutesActions
} from 'src/app/minutes/minutes.reducer'
import {
    agendaItemNotesSelector,
    NotesActions
} from 'src/app/notes/notes.reducer'
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component'
import { ConfirmResult } from 'src/app/dialogs/dialog-confirm/dialog-confirm.model'
import { ImageUpload } from '../../../shared/models'
import { environment } from 'src/environments/environment';

@Component( {
    selector: 'am-popout-minutes',
    templateUrl: './popout-minutes.component.html',
    styleUrls: [ './popout-minutes.component.scss' ]
} )
export class PopoutMinutesComponent extends PopoutBase<PopoutMinutesMessage> {
    @ViewChild( DialogConfirmComponent ) confirmDialog: DialogConfirmComponent
    @Control( AgendaItemNotes ) form: FormGroup
    readonly logger = new Logger( PopoutMinutesComponent.name )

    _agendaItemId = 0
    agendaItemId$: Observable<number>
    _agendaItemName = ''
    agendaItemName$: Observable<string>
    private dialogNavigateCallbacks: [ confirmCallback: () => void, cancelCallback?: () => void ]
    isMinuteTaker = false
    isMinuteTaker$: Observable<boolean>
    messages = [
        build( MessageSubscription, {
            action: NotesActions.DELETE,
            channel: 'TOASTS',
            mapper: () => `Notes deleted successfully!`
        } ),
        build( MessageSubscription, {
            action: NotesActions.DELETE_ERROR,
            channel: 'ERRORS',
            mapper: () => `Error deleting notes.`
        } ),
        build( MessageSubscription, {
            action: NotesActions.POST,
            channel: 'TOASTS',
            mapper: () => `Notes saved successfully!`
        } ),
        build( MessageSubscription, {
            action: NotesActions.POST_ERROR,
            channel: 'ERRORS',
            mapper: () => `Error saving notes.`
        } ),
        build( MessageSubscription, {
            action: NotesActions.PUT,
            channel: 'TOASTS',
            mapper: () => `Notes saved successfully!`
        } ),
        build( MessageSubscription, {
            action: NotesActions.PUT_ERROR,
            channel: 'ERRORS',
            mapper: () => `Error saving notes.`
        } ),
        build( MessageSubscription, {
            action: MinutesActions.DELETE,
            channel: 'TOASTS',
            mapper: () => `Minutes deleted successfully!`
        } ),
        build( MessageSubscription, {
            action: MinutesActions.DELETE_ERROR,
            channel: 'ERRORS',
            mapper: () => `Error deleting minutes.`
        } ),
        build( MessageSubscription, {
            action: MinutesActions.POST,
            channel: 'TOASTS',
            mapper: () => `Minutes saved successfully!`
        } ),
        build( MessageSubscription, {
            action: MinutesActions.POST_ERROR,
            channel: 'ERRORS',
            mapper: () => `Error saving minutes.`
        } ),
        build( MessageSubscription, {
            action: MinutesActions.PUT,
            channel: 'TOASTS',
            mapper: () => `Minutes saved successfully!`
        } ),
        build( MessageSubscription, {
            action: MinutesActions.PUT_ERROR,
            channel: 'ERRORS',
            mapper: () => `Error saving minutes.`
        } )
    ]
    _minutes: AgendaItemMinutes = new AgendaItemMinutes()
    minutes$: Observable<AgendaItemMinutes>
    next = ''
    next$: Observable<string>
    _notes: AgendaItemNotes = new AgendaItemNotes()
    notes$: Observable<AgendaItemNotes>
    previous = ''
    previous$: Observable<string>
    _type: NotesEditor = 'minutes'
    type$: Observable<NotesEditor>
    userId = 0
    userId$: Observable<number>

    editorArgs = {
        // images_upload_url: `${environment.apiBaseUrl}/images`,
        // spellchecker_rpc_url: '../../../assets/spellchecker/spellchecker.php',
        // spellchecker_rpc_url: 'spellchecker.php',
        images_upload_base_path: environment.apiBaseUrl,
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        images_reuse_filename: true,
        images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
        images_upload_handler: function ( blobInfo, success ) {
          //* Create a JSON payload containing the file name and file bytes and serialize it to a string
          const img = build( ImageUpload, {
            fileContent: blobInfo.base64(),
            fileName: blobInfo.filename(),
            fileSize: blobInfo.blob().size,
            mimeType: blobInfo.blob().type
          } )
          const body = JSON.stringify( img )
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          };
          
          ( async () => {
            const response = fetch( `${ environment.apiBaseUrl }/images`, {
              method: 'POST',
              headers,
              body
            } )
            await ( await response ).json()
              .then( ( data: ImageUpload ) => success( `${ environment.apiBaseUrl }/${ data.location }` ) )
              .catch( ( error ) => {
                console.error( error )
              } )
          } )()
        }
      }
      editorPlugins = [ ...FULL_PLUGINS, 'imagetools' ]
      editorToolbar = [ ...FULL_TOOLBAR ]
      editorExpanded = false

    constructor(
        public store: Store<any>,
        private router: Router,
        private activedRoute: ActivatedRoute
    ) {
        super( store )

        this.agendaItemId$ = routeParamIdSelector( store, 'agendaItemId' )
        this.isMinuteTaker$ = routeParamBoolSelector( store, 'isMinuteTaker' )
        this.minutes$ = agendaItemMinutesSelector( store )
        this.next$ = nextAgendaItemLinkSelector( store )
        this.notes$ = agendaItemNotesSelector( store )
        this.previous$ = previousAgendaItemLinkSelector( store )
        this.type$ = routeParamSelector( store, 'type' )
        this.userId$ = currentUserIdSelector( store )
    }

    private static extractAgendaIdFromLink( link: string | number ) {
        if ( typeof link === 'number' ) return link

        return tryParse(
            link.replace( 'edit', '' )
                .split( '/' )
                .filter( ( item ) => !!item )
                .pop()
        )  
    }

    get title() {
        return 'Notes/Minutes Popout'
    }

    get notesActive() {
        return this.type === 'notes'
    }

    get minutesActive() {
        return this.type === 'minutes'
    }

    get saveButtonActive() {
        return this.form.dirty
    }

    get deleteButtonActive() {
        return (
            this.type === 'notes'
            && this.notes.id !== 0
        )
        || (
            this.type === 'minutes'
            && this.minutes.id !== 0
        )
    }

    set agendaItemId( value: number ) {
        this.logger.log( 'set agendaItemId', { value } )
        this._agendaItemId = value

        if ( !value ) return

        this.getNotes( value )
        this.getMinutes( value )
        this.getAgendaItem( value )
    }

    get agendaItemId(): number {
        return this._agendaItemId
    }

    set minutes( value: AgendaItemMinutes ) {
        this._minutes = value

        if ( this.type === 'minutes' ) {
            this.setValue( value )
            this.form.markAsPristine()
        }
    }

    get minutes(): AgendaItemMinutes {
        return this._minutes
    }

    set notes( value: AgendaItemNotes ) {
        this._notes = value
        
        if ( this.type === 'notes' ) {
            this.setValue( value )
            this.form.markAsPristine()
        }
    }

    get notes(): AgendaItemNotes {
        return this._notes
    }

    set type( value: NotesEditor ) {
        this._type = value

        if ( value === 'minutes' ) {
            this.setValue( this.minutes )
            this.form.markAsPristine()
        }
        
        if ( value === 'notes' ) {
            this.setValue( this.notes )
            this.form.markAsPristine()
        }
    }

    get type(): NotesEditor {
        return this._type
    }

    get valueOut(): AgendaItemMinutes | AgendaItemNotes {
        return this.type === 'notes'
            ? build(
                AgendaItemNotes,
                this.notes,
                this.form.value
            )
            : build(
                AgendaItemMinutes,
                this.minutes,
                this.form.value
            )
    }

    set agendaItemName( value: string ) {
        this.logger.log( 'set agendaItemName', { value } )
        this._agendaItemName = value
    }

    get agendaItemName() {
        return this._agendaItemName ?? ''
    }

    ngOnInit() {
        super.ngOnInit()
        
        const syncKeys = [
            'agendaItemId',
            'isMinuteTaker',
            'minutes',
            'next',
            'notes',
            'previous',
            'type'
        ]

        this.sync( syncKeys )
        this.onInit()
    }

    @HostListener( 'window:beforeunload', [ '$event' ] )
    protected onBeforeClose( event: BeforeUnloadEvent ) {
        this.logger.log( 'onBeforeClose - before prevent default', { event } )
        
        if ( this.form.dirty ) event.preventDefault()
    }

    @HostListener( 'window:message', [ '$event' ] )
    protected handleMessages( event: PopoutMessageEvent<PopoutMinutesMessage> ) {
        this.logger.log( 'handleMessages', { data: event.data } )
        const action = event.data.action ?? ''

        switch( action ) {
            case 'close':
                this.logger.log( 'handleMessages close', { data: event.data } )
                this.safeNavigate( this.handleClose.bind( this ) )

                break
            case 'toggle':
                this.logger.log( 'handeMessages toggle', { data: event.data } )
                this.toggleEditor( event.data.data.value as NotesEditor )

                break
            case 'update':
                const agendaItemId = PopoutMinutesComponent.extractAgendaIdFromLink( event.data.data.value as string )
                this.logger.log( 'handleMessages update', { data: event.data, agendaItemId } )
                this.router.navigate(
                    [],
                    {
                        relativeTo: this.activedRoute,
                        queryParams: { agendaItemId },
                        queryParamsHandling: 'merge'
                    }
                )

                break
            default:
                this.logger.log( 'handleMessages - no matchings messages found', { action } )
                super.handleMessages( event )
        }

        this.logger.log( 'handleMessages - end of switch statement', { action } )
    }

    dialogCloseCallback( result: ConfirmResult ) {
        this.logger.log( 'dialogCloseCallback', { result } )

        const [
            confirmCallback,
            cancelCallback
        ] = this.dialogNavigateCallbacks

        if ( result === 'cancel' ) {
            cancelCallback && cancelCallback()
            return
        }

        confirmCallback && confirmCallback()
    }

    private async safeNavigate( ...args: [ confirmCallback: () => void, cancelCallback?: () => void ] ) {
        this.logger.log( 'safeNavigate', { args } )

        //* Wrapping the confirm callback to mark the form as pristine in case user clicks browser close button
        const wrappedConfirm = () => {
            this.logger.log( 'wrappedConfirm' )
            this.form.markAsPristine()
            args[ 0 ]()
        }

        this.dialogNavigateCallbacks = [ wrappedConfirm, args[ 1 ] ]

        //* If no changes present in form then immediately call confirm
        if ( !this.form.dirty ) {
            this.logger.log( 'safeNavigate - form ok, calling confirm' )
            args[ 0 ]()
            return
        }

        this.confirmDialog.open()
    }

    handleClose() {
        this.logger.log( 'handleClose' )

        this.sendMessage( {
            action: 'close',
            data: {}
        } )
        window.close()
    }

    onSave( event: Event ) {
        event.stopPropagation()

        if (this.type === 'notes') {
            const value = this.valueOut as AgendaItemNotes

            this.notes.id === 0
                ? this.addNotes( value )
                : this.updateNotes( value )
        }
        
        if ( this.type === 'minutes' ) {
            const value = this.valueOut as AgendaItemMinutes

            this.minutes.id === 0
                ? this.addMinutes( value )
                : this.updateMinutes( value )
        }

        this.form.markAsPristine()
    }

    onDelete( event: Event ) {
        event.stopPropagation()

        if (this.type === 'notes')
            this.deleteNotes( this.notes.id )
        
        if ( this.type === 'minutes' )
            this.deleteMinutes( this.minutes.id )

        this.form.markAsPristine()
    }

    private toggleEditor( type: NotesEditor ) {
        this.type = type
        this.form.markAsPristine()
    }

    onClickNotes( event: Event ) {
        event.stopPropagation()

        const confirmCallback = () => {
            this.toggleEditor( 'notes' )
            this.sendMessage( { action: 'toggle', data: { value: 'notes' } } )
        }

        this.safeNavigate( confirmCallback )
    }

    onClickMinutes( event: Event ) {
        event.stopPropagation()

        const confirmCallback = () => {
            this.toggleEditor( 'minutes' )
            this.sendMessage( { action: 'toggle', data: { value: 'minutes' } } )
        }

        this.safeNavigate( confirmCallback )
    }

    onClickCLose( event: Event ) {
        event.stopPropagation()

        this.safeNavigate( this.handleClose.bind( this ) )
    }

    changeEditor( event ) {
        if (
        event
        && event.event
        && event.event.command === 'mceFullScreen'
        ) {
        this.editorExpanded = !this.editorExpanded
        }
    }

    previousItem( event: Event ) {
        event.stopPropagation()

        const confirmCallback = () => {
            this.logger.log( 'previousItem', { previous: this.previous } )
            this.sendMessage( { action: 'previous', data: null } )
        }

        this.safeNavigate( confirmCallback )
    }

    nextItem( event: Event ) {
        event.stopPropagation()

        const confirmCallback = () => {
            this.logger.log( 'nextItem', { next: this.next } )
            this.sendMessage( { action: 'next', data: null } )
        }

        this.safeNavigate( confirmCallback )
    }

    addMinutes( minutes: AgendaItemMinutes ) {
        this.httpPost(
            `minutes`,
            minutes,
            MinutesActions.POST,
            MinutesActions.POST_ERROR
        ).subscribe( () => this.sendMessage(
            { action: 'addMinutes', data: null }
        ) )
    }

    addNotes( notes: AgendaItemNotes ) {
        this.httpPost(
            `notes`,
            notes,
            NotesActions.POST,
            NotesActions.POST_ERROR
        ).subscribe( () => this.sendMessage(
            { action: 'addNotes', data: null }
        ) )
    }

    deleteMinutes( id: number ) {
        this.httpDelete(
            `minutes/${ id }`,
            id,
            MinutesActions.DELETE,
            MinutesActions.DELETE_ERROR
        ).subscribe( () => this.sendMessage(
            { action: 'deleteMinutes', data: null }
        ) )
    }

    deleteNotes( id: number ) {
        this.httpDelete(
            `notes/${ id }`,
            id,
            NotesActions.DELETE,
            NotesActions.DELETE_ERROR
        ).subscribe( () => this.sendMessage(
            { action: 'deleteNotes', data: null }
        ) )
    }

    getMinutes( id: number ) {
        this.dispatch(
            HttpActions.get(
                `agendaItems/${ id }/minutes`,
                MinutesActions.GET
            )
        )
    }

    getNotes( id: number ) {
        this.dispatch(
            HttpActions.get(
                `agendaItems/${ id }/notes`,
                NotesActions.GET
            )
        )
    }

    getAgendaItem( id: number ) {
        this.httpGet( `agendaItems/${ id }`, AgendaItemActions.GET ).subscribe( ( x ) => {
            this.logger.log( 'getAgendaItem', { x } )

            if ( x && x.payload && x.payload.name ) {
                this._agendaItemName = x.payload.name
            }
        } )
    }

    updateMinutes( minutes: AgendaItemMinutes ) {
        this.httpPut(
            `minutes/${minutes.id}`,
            minutes,
            MinutesActions.PUT,
            MinutesActions.PUT_ERROR
        ).subscribe( ( action ) => {
            const t = true

            this.logger.log( 'updateMinutes subscription', { action } )

            t && this.sendMessage(
                { action: 'updateMinutes', data: null }
            ) 
        } )
    }

    updateNotes( notes: AgendaItemNotes ) {
        this.httpPut(
            `notes/${ notes.id }`,
            notes,
            NotesActions.PUT,
            NotesActions.PUT_ERROR
        ).subscribe( () => this.sendMessage(
            { action: 'updateNotes', data: null }
        ) )
    }
}
