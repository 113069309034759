import { Component, OnInit, Input } from '@angular/core';
import { build, toArray } from '@caiu/library';

import { Tag } from '../tags.model';

@Component({
  selector: 'am-vertical-tags',
  templateUrl: './vertical-tags.component.html',
  styleUrls: ['./vertical-tags.component.scss']
})
export class VerticalTagsComponent implements OnInit {

  @Input() tags: Tag[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  get first(): Tag {
    return toArray(this.tags).length > 0 ? build(Tag, toArray(this.tags)[0]) : null;
  }

}
