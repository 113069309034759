<div *ngIf="totalVotes > 0" class="votes-preview">
  <h3 class="table-heading">Vote Results</h3>
  <table style="width: 100%; border-spacing: 0;">
    <tr>
      <td class="vote-answer">Yea:</td>
      <td class="vote-total">{{ yeaCount }}</td>
      <td class="voters">{{ getVoters(1) }}</td>
    </tr>
    <tr>
      <td class="vote-answer">Nay:</td>
      <td class="vote-total">{{ nayCount }}</td>
      <td class="voters">{{ getVoters(2) }}</td>
    </tr>
    <tr>
      <td class="vote-answer">Abstain:</td>
      <td class="vote-total">{{ abstainCount }}</td>
      <td class="voters">{{ getVoters(3) }}</td>
    </tr>
    <tr>
      <td class="vote-answer">Not Cast:</td>
      <td class="vote-total">{{ notCastCount }}</td>
      <td class="voters">{{ getVoters(4) }}</td>
    </tr>
  </table>
  <div class="motion-second">
    <span *ngIf="motion" class="motion-second-row first">
      <span class="lbl">Motion:&nbsp;</span>
      <span class="val">{{ motion?.voterName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </span>
    <span *ngIf="second" class="motion-second-row second">
      <span class="lbl">Second:&nbsp;</span>
      <span class="val">{{ second?.voterName }}</span>
    </span>
  </div>
</div>
