import { build, Action, compareStrings, routeParamIdSelector, inArray, arrayDistinct, toArray } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Groups, Group, GroupMember } from './groups.model';
import { accountIdSelector, userAccountRoleSelector } from '../accounts/accounts.reducer';
import { groupMembersSelector, isAccountAdminSelector } from '../members/members.reducer';
import { currentUserIdSelector } from '../shared/selectors';

export class GroupsActions {
  static GET = '[Groups] Get';
  static GET_ACCOUNT_GROUPS = '[Groups] Get Account Groups';
  static GET_ACCOUNT_GROUPS_ERROR = '[Groups] Get Account Groups Error';
  static GET_ACCOUNT_GROUPS_DROPDOWN = '[Groups] Get Account Groups for Dropdown';
  static GET_USER_GROUPS = '[Groups] Get User Groups';
  static GET_USER_GROUPS_ERROR = '[Groups] Get User Groups Error';
  static POST = '[Groups] POST';
  static POST_ERROR = '[Groups] POST ERROR';
}

export class GroupActions {
  static DELETE = '[Group] DELETE';
  static DELETE_ERROR = '[Group] DELETE ERROR';
  static GET = '[Group] GET';
  static GET_MEMBERS = '[Group] Get Members';
  static PUT = '[Group] PUT';
  static PUT_ERROR = '[Group] PUT ERROR';
}

export function groupsReducer(state: Groups = new Groups(), action: Action): Groups {
  switch (action.type) {

    case GroupsActions.GET:
    case GroupsActions.GET_ACCOUNT_GROUPS:
    case GroupsActions.GET_ACCOUNT_GROUPS_DROPDOWN:
    case GroupActions.GET:
    case GroupActions.PUT:
      return state.update(action.payload);


    case GroupActions.DELETE:
      return build(Groups, state.delete(action.payload));

    case GroupsActions.GET_USER_GROUPS:
      return state.updateFromGroupMembers(action.payload);

    default:
      return state;
  }
}

export function groupsSelector(store: Store<any>): Observable<Groups> {
  return store.select('groups');
}

export function groupSelector(store: Store<any>): Observable<Group> {
  return combineLatest(groupsSelector(store), routeParamIdSelector(store, 'groupId'), groupMembersSelector(store), currentUserIdSelector(store), (groups, id, groupMembers, userId) => {
    const existing = build(Group, groups.get(id));
    return build(Group, existing, {
      members: groupMembers.asArray.filter(x => x.groupId === id).map(x => build(GroupMember, x)),
      administratorId: existing.administratorId || userId
    });
  });
}

export function groupsSearchSelector(store: Store<any>, filter?: (group: Group) => boolean): Observable<Group[]> {
  return groupsSelector(store).pipe(
    map(groups => {
      return filter ? groups.asArray.filter(filter) : groups.asArray;
    })
  );
}

export function accountGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest([groupsSearchSelector(store), accountIdSelector(store), groupMembersSelector(store)]).pipe(
    map(x => {
      return x[0].filter(y => y.accountId === x[1] && y.isActive)
        .sort((a, b) => compareStrings(a.name, b.name))
        .map(y => build(Group, y, {
          members: x[2].asArray.filter(z => z.groupId === y.id).map(z => build(GroupMember, z))
        }));
    })
  );
}

export function userAccountGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest([accountGroupsSelector(store), userGroupIdsSelector(store), isAccountAdminSelector(store)]).pipe(
    map(x => {
      return x[2] ? toArray(x[0]) : toArray(x[0]).filter(y => inArray(toArray(x[1]), y.id));
    })
  );
}

export function adminGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest(currentUserIdSelector(store), groupsSelector(store),
    (userId, groups) => groups.asArray
      .filter(x => x.administratorId === userId && x.isActive)
      .sort((a, b) => compareStrings(a.name, b.name))
  );
}

export function userGroupIdsSelector(store: Store<any>): Observable<number[]> {
  return userGroupMembershipsSelector(store).pipe(
    map(x => {
      return arrayDistinct(x.map(y => y.groupId));
    })
  );
}

export function userGroupMembershipsSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest(groupMembersSelector(store), currentUserIdSelector(store), (groupMembers, userId) => groupMembers.asArray.filter(x => x.userId === userId));
}

export function administratorGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest(accountGroupsSelector(store), currentUserIdSelector(store),
    (groups, userId) => {
      return groups.filter(x => x.administratorId === userId)
    });
}

export function managerGroupIdsSelector(store: Store<any>): Observable<number[]> {
  return userGroupMembershipsSelector(store).pipe(
    map(members => {
      return members
        .filter(member => member.groupRoleName === 'Manager')
        .map(x => x.groupId);
    })
  );
}

export function managerGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest([accountGroupsSelector(store), managerGroupIdsSelector(store)]).pipe(
    map(x => {
      return x[0].filter(y => inArray(x[1], y.id));
    })
  );
}
