import { Action, build, routeParamIdSelector, toInt } from '@caiu/library';

import { BinItem, BinItems } from './bin-items.model';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { accountIdSelector } from '../accounts/accounts.reducer';
import { nextAgendaItemOrderSelector } from '../agenda-items/agenda-items.reducer';

export class BinActions {
  static ADD_AGENDA_ITEM = '[Bin] Add Agenda Item';
  static ADD_AGENDA_ITEM_AND_REMOVE = '[Bin] Add Agenda Item And Remove';
  static ADD_AGENDA_ITEM_ERROR = '[Bin] Add Agenda Item Error';
  static DELETE = '[Bin] Delete';
  static DELETE_ERROR = '[Bin] Delete Error';
  static GET = '[Bin] Get';
  static POST = '[Bin] Post';
  static POST_ERROR = '[Bin] Post Error';
}

// export function activeIdInAccount(bin: BinItems): boolean {
//   const binItem = bin.active || new BinItem();
//   return binItem.accountId === bin.accountId;
// }

// export function accountHasItems(bin: BinItems): boolean {
//   return bin.asArray.findIndex(item => item.accountId === bin.accountId) !== -1;
// }

// export function getAccountItems(bin: BinItems): BinItem[] {
//   return bin.asArray.filter(item => item.accountId === bin.accountId);
// }

// export function getActiveIdForAccount(bin: BinItems): number {
//   return activeIdInAccount(bin) ? toInt(bin.activeId) : accountHasItems(bin) ? getAccountItems(bin)[0].id : 0;
// }

// export function activateAccount(state: BinItems, accountId: number): BinItems {
//   const bin = build(BinItems, state, { accountId });
//   return build(BinItems, bin, { activeId: getActiveIdForAccount(bin) });
// }

export function binReducer(state: BinItems = new BinItems(), action: Action): BinItems {
  switch (action.type) {
    case BinActions.GET:
      return build(BinItems, state.update(<BinItem[]>action.payload));

    case BinActions.POST:
      return build(BinItems, state.update([<BinItem>action.payload]));

    default:
      return state;
  }
}

export function binSelector(store: Store<any>): Observable<BinItems> {
  return store.select('bin');
}

export function binItemSelector(store: Store<any>): Observable<BinItem> {
  return binSelector(store).pipe(map(bin => bin.active || new BinItem()));
}

export function binItemsSelector(store: Store<any>): Observable<BinItem[]> {
  return combineLatest(binSelector(store), accountIdSelector(store), (bin, accountId) =>
    accountId === 0 ? bin.asArray : bin.asArray.filter(item => item.accountId === accountId)
  );
}

export function binItemIdSelector(store: Store<any>): Observable<number> {
  return binSelector(store).pipe(
    map(bin => toInt(bin.activeId)),
    distinctUntilChanged()
  );
}

// export function binAgendaIdSelector(store: Store<any>): Observable<number> {
//   return binSelector(store).pipe(
//     map(bin => bin.agendaId),
//     distinctUntilChanged()
//   );
// }

// export function binDisplayOrderSelector(store: Store<any>): Observable<number> {
//   return binSelector(store).pipe(
//     map(bin => bin.displayOrder),
//     distinctUntilChanged()
//   );
// }

export function binitemsCountSelector(store: Store<any>): Observable<number> {
  return binSelector(store).pipe(
    map(bin => bin.count),
    distinctUntilChanged()
  );
}

export function hasBinItemsSelector(store: Store<any>): Observable<boolean> {
  return binitemsCountSelector(store).pipe(
    map(x => x > 0),
    distinctUntilChanged()
  );
}

export function firstBinItemIdSelector(store: Store<any>): Observable<number> {
  return hasBinItemsSelector(store).pipe(
    withLatestFrom(binItemsSelector(store), (hasItems, items) => (hasItems ? build(BinItem, items[0]).id : 0)),
    distinctUntilChanged()
  );
}

export function hasBinItemIdSelector(store: Store<any>): Observable<boolean> {
  return binItemIdSelector(store).pipe(
    withLatestFrom(binItemsSelector(store), (id, items) => items.findIndex(item => item.id === id) !== -1),
    distinctUntilChanged()
  );
}

export function binItemOrderSelector(store: Store<any>): Observable<number> {
  return combineLatest([routeParamIdSelector(store, 'parentId'), routeParamIdSelector(store, 'order'), nextAgendaItemOrderSelector(store)]).pipe(
    map(x => {
      if (x[1]) {
        return x[1];
      }
      if (x[0]) {
        return 0;
      }
      return x[2];
    })
  );
}
