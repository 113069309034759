import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, PreloadAllModules, Router } from '@angular/router';
import { NotFoundComponent, authenticatedSelector, CanDeactivateGuard, HttpService, build, RouterActions } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { currentUserAccountUrlSelector } from './accounts/accounts.reducer';
import { userLastActiveSelector } from './shared/selectors';
import { AnnouncementComponent } from './announcement/announcement.component';
import { map, tap } from 'rxjs/operators';
import { AgendaItemInfo } from './agenda-items/agenda-items.model';

@Injectable()
export class RootGuard implements CanActivate {
  accountUrl = '';
  accountUrlChanges: Subscription;

  constructor(public store: Store<any>) {
    currentUserAccountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return of(true);
  }

  private getUrl(route: ActivatedRouteSnapshot): string {
    const url = route.url.map(segment => segment.path).reduce((acc, val) => acc + val, '');
    if (url === 'dashboard') {
      return `${this.accountUrl}/dashboard`;
    } else if (url === 'account') {
      return `${this.accountUrl}/account`;
    }
    return url;
  }
}

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  authenticated = false;
  authenticated$: Observable<boolean>;
  lastActive: Date = new Date();
  lastActive$: Observable<Date>;
  maxInactivityTime = 1800000;

  constructor(public store: Store<any>, public router: Router) {
    this.authenticated$ = authenticatedSelector(store);
    this.authenticated$.subscribe(x => {
      this.authenticated = x;
    });
    this.lastActive$ = userLastActiveSelector(store);
    this.lastActive$.subscribe(x => {
      this.lastActive = x;
    });
  }

  get inactivityTime(): number {
    return Math.floor((new Date().getTime() - new Date(this.lastActive).getTime()) / 1000);
  }

  get timedOut(): boolean {
    return this.inactivityTime > this.maxInactivityTime;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const redirectUrl = `/login?redirectTo=${state.url}`;
    if (!this.authenticated || this.timedOut) {
      // this.router.navigate(['/login']);
      this.store.dispatch(RouterActions.navigate(redirectUrl));
      return of(false);
    }
    return of(true);
  }
}

@Injectable()
export class AgendaItemGuard implements CanActivate {

  constructor(public store: Store<any>, public router: Router, public http: HttpService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.http.get(`agendaitems/${route.params['agendaItemId']}/info`).pipe(
      map(x => build(AgendaItemInfo, x).url),
      tap(url => {
        if (url) {
          this.router.navigate([url]);
        }
      }),
      map(x => false)
    );
  }
}

const routes: Routes = [
  {
    path: 'announcements/:announcementId',
    outlet: 'popup',
    component: AnnouncementComponent
  },
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: { routeName: 'not-found', routeLabel: 'Not Found' }
      },
      {
        path: 'home',
        redirectTo: 'login'
      },
      {
        path: 'contact',
        redirectTo: 'help'
      },
      {
        path: 'login',
        canActivate: [RootGuard],
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'accounts',
        canActivate: [AuthenticatedGuard, RootGuard],
        loadChildren: () => import('./user-accounts/user-accounts.module').then(m => m.UserAccountsModule)
      },
      {
        path: 'agendaitems/:agendaItemId',
        canActivate: [AgendaItemGuard],
        loadChildren: () => import('./agenda-items/agenda-items.module').then(m => m.AgendaItemsModule)
      },
      {
        path: 'popouts',
        loadChildren: () => import( './popout/popout.module' ).then( ( module ) => module.PopoutModule )
      },
      // {
      //   path: 'testpage',
      //   loadChildren: () => import( './test-page/test-page.module' ).then( ( module ) => module.TestPageModule )
      // },
      {
        path: 'admin',
        canActivate: [AuthenticatedGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'accounts'
          },
          {
            path: 'account/edit',
            pathMatch: 'full',
            redirectTo: 'edit'
          },
          {
            path: 'basicinfo',
            pathMatch: 'full',
            redirectTo: 'info'
          },
          {
            path: 'users',
            pathMatch: 'full',
            redirectTo: 'members'
          },
          {
            path: 'accounts',
            loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
          },
          {
            path: 'accounts/new',
            loadChildren: () => import('./account-edit/account-edit.module').then(m => m.AccountEditModule)
          },
          {
            path: 'announcements',
            loadChildren: () => import('./announcements/announcements.module').then(m => m.AnnouncementsModule)
          },
          {
            path: 'edit',
            loadChildren: () => import('./account-edit/account-edit.module').then(m => m.AccountEditModule)
          },
          {
            path: 'groups',
            loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule)
          },
          {
            path: 'info',
            loadChildren: () => import('./account-info/account-info.module').then(m => m.AccountInfoModule)
          },
          {
            path: 'members',
            loadChildren: () => import('./members/members.module').then(m => m.MembersModule)
          },
          {
            path: 'restore',
            loadChildren: () => import('./restore/restore.module').then(m => m.RestoreModule)
          },
          {
            path: 'users/edit',
            loadChildren: () => import( './user-edit/user-edit.module' ).then( ( m ) => m.UserEditModule )
          }
        ]
      },
      {
        path: ':account',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'dashboard'
          },
          {
            path: 'account-edit',
            pathMatch: 'full',
            redirectTo: 'edit'
          },
          {
            path: 'attachments',
            redirectTo: 'files'
          },
          {
            path: 'basicinfo',
            redirectTo: 'info'
          },
          {
            path: 'import/members',
            redirectTo: 'members/import'
          },
          {
            path: 'meeting/edit/:meetingId',
            redirectTo: 'meetings/:meetingId/edit'
          },
          {
            path: 'meeting/view/:meetingId/:agendaId/:agendaItemId',
            redirectTo: 'meetings/:meetingId/agendas/:agendaId/agendaitems/:agendaItemId'
          },
          {
            path: 'meeting/view/:meetingId/:agendaId',
            redirectTo: 'meetings/:meetingId/agendas/:agendaId'
          },
          {
            path: 'meeting/view/:meetingId',
            redirectTo: 'meetings/:meetingId'
          },
          {
            path: 'edit',
            loadChildren: () => import('./account-edit/account-edit.module').then(m => m.AccountEditModule)
          },
          {
            path: 'account',
            loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
          },
          {
            path: 'announcements',
            loadChildren: () => import('./announcements/announcements.module').then(m => m.AnnouncementsModule)
          },
          {
            path: 'dashboard',
            canActivate: [RootGuard],
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'groups',
            loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule)
          },
          {
            path: 'groups/:groupId',
            pathMatch: 'full',
            redirectTo: 'groups/:groupId/edit'
          },
          {
            path: 'groups/:groupId/edit',
            loadChildren: () => import('./group-edit/group-edit.module').then(m => m.GroupEditModule)
          },
          {
            path: 'info',
            loadChildren: () => import('./account-info/account-info.module').then(m => m.AccountInfoModule)
          },
          {
            path: 'members',
            loadChildren: () => import('./members/members.module').then(m => m.MembersModule)
          },
          {
            path: 'profile/:userId',
            loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
          },
          {
            path: 'profile',
            loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
          },
          {
            path: 'restore',
            loadChildren: () => import('./restore/restore.module').then(m => m.RestoreModule)
          },
          {
            path: 'search',
            loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
          },
          {
            path: 'files',
            loadChildren: () => import('./files/files.module').then(m => m.FilesModule)
          },
          {
            path: 'meetings',
            loadChildren: () => import('./meetings/meetings.module').then(m => m.MeetingsModule)
          },
          {
            path: 'templates',
            loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule)
          },
          {
            path: 'tags',
            loadChildren: () => import( './tags/tags.module' ).then( ( module ) => module.TagsModule )
          }
        ]
      },
      {
        path: '**',
        redirectTo: 'login'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthenticatedGuard, RootGuard, CanDeactivateGuard, AgendaItemGuard]
})
export class AppRoutingModule { }

