import { AbstractControl } from '@angular/forms';
import { Collection, Metadata, build, Validators, truthy } from '@caiu/library';

import { AgendaItem } from '../agenda-items/agenda-items.model';
import { Attachment } from '../attachments/attachments.model';
import { BaseEntity } from '../shared/models';
import { isValidEmail } from '../shared/utils';

export function multipleEmailValidator(ctrl: AbstractControl) {
  if (!ctrl.value) {
    return null;
  } else if (typeof (ctrl.value) === 'string') {
    const emails = ctrl.value.split(';').filter(x => truthy(x));
    return emails.reduce((acc, email) => acc && isValidEmail(email), true) ? null : { emails: true };
  }

  return { emails: true };
}

export class EmailItem extends BaseEntity {
  to = '';
  from = '';
  cc = '';
  bcc = '';
  subject = '';
  body = '';
  addAttachments = false;
  convertAttachments = false;
  content = '';

  agendaItem: AgendaItem = new AgendaItem();
  attachments: Attachment[] = [];

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [...this.ignore, 'id', 'agendaItem', 'agendaItemName', 'attachments', 'content'],
      bcc: {
        validators: [multipleEmailValidator]
      },
      cc: {
        validators: [multipleEmailValidator]
      },
      subject: {
        validators: [Validators.required]
      },
      to: {
        validators: [Validators.required, multipleEmailValidator]
      },
      body: {
        validators: [Validators.required]
      }
    });
  }

  get agendaItemName(): string {
    return this.agendaItem.name;
  }

}

export class Email extends Collection<EmailItem> {

  constructor() {
    super(EmailItem);
  }
}
