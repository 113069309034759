import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, InjectionToken, ErrorHandler } from '@angular/core';
import {
  EffectsModule,
  ErrorsModule,
  EventEffects,
  EventsModule,
  LookupModule,
  HttpEffects,
  HttpModule,
  RouterEffects,
  RouterModule,
  StorageEffects,
  StorageModule,
  MessagesEffects,
  apiBaseUrlSelector,
  HubModule,
  ActionsEffects
} from '@caiu/library';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CurrentUserEffects } from './shared/effects';
import { SharedModule } from './shared/shared.module';
import { AccountsEffects } from './accounts/accounts.effects';
import { AgendasEffects } from './agendas/agendas.effects';
import { AnnouncementModule } from './announcement/announcement.module';
import { AnnouncementsEffects } from './announcements/announcements.effects';
import { BinEffects } from './bin-items/bin.effects';
import { BinItemsModule } from './bin-items/bin-items.module';
import { DialogRouteModule } from './shared/dialog-route/dialog-route.module';
import { EmailItemModule } from './email-item/email-item.module';
import { GroupsEffects } from './groups/groups.effects';
import { MeetingsEffects } from './meetings/meetings.effects';
import { MembersEffects } from './members/members.effects';
import { MinutesModule } from './minutes/minutes.module';
import { PrintModule } from './print/print.module';
import { ReducersService } from './reducers.service';
import { environment } from 'src/environments/environment';
import { AttendanceModule } from './attendance/attendance.module';
import { authTokenSelector } from './shared/selectors';
import { GlobalErrorHandler } from './shared/error-handler';
import { PopoutModule } from './popout/popout.module';
import { PopoutMinutesModule } from './popout/popouts/popout-minutes/popout-minutes.module';
import { DialogBaseModule } from './dialogs/dialog-base/dialog-base.module';
import { DialogConfirmModule } from './dialogs/dialog-confirm/dialog-confirm.module';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('Registered Reducers');

export function getReducers(reducersService: ReducersService) {
  return reducersService.getReducers();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DialogRouteModule,
    EffectsModule.forRoot([
      ActionsEffects,
      CurrentUserEffects,
      EventEffects,
      HttpEffects,
      MessagesEffects,
      RouterEffects,
      StorageEffects,
      AccountsEffects,
      AgendasEffects,
      AnnouncementsEffects,
      BinEffects,
      GroupsEffects,
      MeetingsEffects,
      MembersEffects
    ]),
    ErrorsModule.forRoot(),
    EventsModule,
    HttpModule.forRoot(apiBaseUrlSelector, authTokenSelector),
    LookupModule.forRoot(),
    RouterModule.forRoot(),
    HubModule.forRoot(`${environment.apiBaseUrl}/hub`),
    StorageModule.forRoot('AMSuite'),
    StoreModule.forRoot(REDUCER_TOKEN, {
      initialState: {},
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    SharedModule,
    BinItemsModule,
    EmailItemModule,
    MinutesModule,
    PrintModule,
    AnnouncementModule,
    AttendanceModule,
    PopoutModule,
    PopoutMinutesModule,
    DialogBaseModule,
    DialogConfirmModule
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [ReducersService],
      useFactory: getReducers
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

