import { NgModule } from '@angular/core'

import { SharedModule } from '../../shared/shared.module'
import { DialogBaseComponent } from './dialog-base.component'

@NgModule( {
	imports: [
		SharedModule
	],
	declarations: [ DialogBaseComponent ],
	exports: [ DialogBaseComponent ]
} )
export class DialogBaseModule { }
