import { Component, Input, OnInit } from '@angular/core';
import { HttpService, truthy, build } from '@caiu/library';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { Attachment } from '../attachments.model';

@Component({
  selector: 'am-attachments-preview',
  templateUrl: './attachments-preview.component.html',
  styleUrls: ['./attachments-preview.component.scss']
})
export class AttachmentsPreviewComponent implements OnInit {

  agendaItemIdSubject = new BehaviorSubject(0);
  attachments$: Observable<Attachment[]>;

  constructor(public http: HttpService) {
    this.attachments$ = this.agendaItemIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendaitems/${id}/attachments/preview`)),
      map(x => x.map(y => build(Attachment, y)))
    );
  }

  @Input()
  set agendaItemId(value: number) {
    this.agendaItemIdSubject.next(value);
  }

  get agendaItemId(): number {
    return this.agendaItemIdSubject.value;
  }

  ngOnInit(): void {
  }

}
