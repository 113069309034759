<header *ngIf="headerType == 'DEFAULT'">
  <div id="status-bar">
    <div id="logo-container" *ngIf="hasAccount">
      <img id="account-logo" [src]="logoSrc" />
    </div>
    <am-search-widget></am-search-widget>
    <am-current-user [user]="user$ | async" [account]="account$ | async" [userAccounts]="userAccounts$ | async">
    </am-current-user>
  </div>
  <am-tabs></am-tabs>
</header>

<header *ngIf="headerType === 'LOGIN'">
  <div id="inner-header" [style.margin-top.px]="marginTop$ | async">
    <h1 title="AgendaManager">
      <a href="http://agendamanager.com" title="To AgendaManager home page…">
        <img title="Agenda Manager" alt="Get AgendaManager" src="/assets/agendaman-home-logo.png" />
      </a>
    </h1>
  </div>
</header>
