import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SmartComponent, routeParamIdSelector, routeNameSelector, HttpActions, ConfirmDeleteComponent, build, MessageSubscription, HttpService, contentHeightSelector, isMobileSelector } from '@caiu/library';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';

import { AgendaItem, AgendaViewMode } from './agenda-items.model';
import { AgendaItemActions, activeAgendaItemsSelector, agendaItemSelector, unsavedAgendaItemSelector, AgendaItemsActions, isVotableSelector } from './agenda-items.reducer';
import { accountIdSelector } from '../accounts/accounts.reducer';
import { AttachmentsActions } from '../attachments/attachments.reducer';
import { BinItem } from '../bin-items/bin-items.model';
import { BinActions } from '../bin-items/bin-items.reducer';
import { CurrentUser } from '../shared/models';
import { currentUserIdSelector, currentUserSelector, isPublicUserSelector, viewModeSelector } from '../shared/selectors';
import { AgendaItemNotes } from '../notes/notes.model';
import { agendaItemNotesSelector } from '../notes/notes.reducer';
import { agendaDetailsSelector } from '../agendas/agendas.reducer';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { Logger } from '../shared/utils';

@Component({
  selector: 'am-agenda-items',
  templateUrl: './agenda-items.component.html',
  styleUrls: ['./agenda-items.component.scss']
})
export class AgendaItemsComponent extends SmartComponent implements OnInit {
  private readonly logger = new Logger(AgendaItemsComponent.name)

  accountId = 0;
  accountId$: Observable<number>;
  activeId = 0;
  activeId$: Observable<number>;
  agendaId = 0;
  agendaId$: Observable<number>;
  agendaItem: AgendaItem = new AgendaItem();
  agendaItem$: Observable<AgendaItem>;
  binLink$: Observable<string>;
  contentHeight = 0;
  contentHeight$: Observable<number>;
  hasDetails = false;
  hasDetails$: Observable<boolean>;
  editing$: Observable<boolean>;
  editLink$: Observable<string>;
  emailLink$: Observable<string>;
  isMobile = true;
  isMobile$: Observable<boolean>;
  isPublicUser = true;
  isPublicUser$: Observable<boolean>;
  isVotable$: Observable<boolean>;
  meetingId = 0;
  meetingId$: Observable<number>;
  mode$: Observable<'view' | 'edit'>;
  notes$: Observable<AgendaItemNotes>;
  notes: AgendaItemNotes;
  queryParams = {};
  routeName = 'agenda-items';
  routeName$: Observable<string>;
  showMinutes = true;
  showMinutes$: Observable<boolean>;
  unsavedAgendaItem: AgendaItem;
  unsavedAgendaItem$: Observable<AgendaItem>;
  user$: Observable<CurrentUser>;
  userId = 0;
  userId$: Observable<number>;
  viewMode: AgendaViewMode;
  viewMode$: Observable<AgendaViewMode>;
  canEditAgenda = false;
  canEditAgenda$: Observable<boolean>;
  canSuggest = false;
  canSuggest$: Observable<boolean>;
  userHasBin$: Observable<boolean>;
  messages = [
    build(MessageSubscription, {
      action: AgendaItemActions.DELETE,
      channel: 'TOASTS',
      mapper: e => `Agenda item deleted successfully!`
    }),
    build(MessageSubscription, {
      action: AgendaItemActions.DELETE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error deleting agenda item.`
    }),
    build(MessageSubscription, {
      action: AgendaItemsActions.POST,
      channel: 'TOASTS',
      mapper: e => `Agenda item saved successfully!`
    }),
    build(MessageSubscription, {
      action: AgendaItemsActions.POST_QUICK,
      channel: 'TOASTS',
      mapper: e => `Agenda item saved successfully!`
    }),
    build(MessageSubscription, {
      action: AgendaItemsActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving agenda item.`
    }),
    build(MessageSubscription, {
      action: AgendaItemActions.PUT,
      channel: 'TOASTS',
      mapper: e => `Agenda item saved successfully!`
    }),
    build(MessageSubscription, {
      action: AgendaItemActions.PUT_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving agenda item.`
    }),
    build(MessageSubscription, {
      action: AttachmentsActions.REMOVE,
      channel: 'TOASTS',
      mapper: e => `Attachment deleted successfully!`
    }),
    build(MessageSubscription, {
      action: AttachmentsActions.REMOVE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error deleting attachment.`
    }),
    build(MessageSubscription, {
      action: BinActions.POST,
      channel: 'TOASTS',
      mapper: e => `Bin item saved successfully!`
    }),
    build(MessageSubscription, {
      action: BinActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving bin item.`
    }),
    build(MessageSubscription, {
      action: BinActions.DELETE,
      channel: 'TOASTS',
      mapper: e => `Bin item deleted successfully!`
    }),
    build(MessageSubscription, {
      action: BinActions.DELETE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error deleting bin item.`
    })
  ];

  constructor(public store: Store<any>, public dialog: MatDialog, public http: HttpService) {
    super(store);
    this.accountId$ = accountIdSelector(store);
    this.activeId$ = routeParamIdSelector(store, 'agendaItemId');
    this.agendaId$ = routeParamIdSelector(store, 'agendaId');
    this.contentHeight$ = contentHeightSelector(store);
    this.meetingId$ = routeParamIdSelector(store, 'meetingId');
    this.notes$ = agendaItemNotesSelector(store);
    this.routeName$ = routeNameSelector(store);
    this.user$ = currentUserSelector(store);
    this.agendaItem$ = agendaItemSelector(store);
    this.unsavedAgendaItem$ = unsavedAgendaItemSelector(store);
    this.isMobile$ = isMobileSelector(store);
    this.isPublicUser$ = isPublicUserSelector(store);
    this.isVotable$ = isVotableSelector(store);
    this.hasDetails$ = agendaDetailsSelector(store);
    this.userId$ = currentUserIdSelector(store);
    this.viewMode$ = viewModeSelector(store);
    this.canEditAgenda$ = this.isPublicUser$.pipe(
      switchMap(isPublicUser => {
        if (isPublicUser) {
          return of(false);
        } else {
          return routeParamIdSelector(store, 'agendaId').pipe(
            filter(id => id !== 0),
            distinctUntilChanged(),
            switchMap(id => http.get(`agendas/${id}/canedit`)),
            distinctUntilChanged()
          );
        }
      })
    );
    this.canSuggest$ = this.isPublicUser$.pipe(
      switchMap(isPublicUser => {
        if (isPublicUser) {
          return of(false);
        } else {
          return routeParamIdSelector(store, 'agendaId').pipe(
            filter(id => id !== 0),
            distinctUntilChanged(),
            switchMap(id => http.get(`agendas/${id}/cansuggest`)),
            distinctUntilChanged()
          );
        }
      })
    );
    this.addSubscription(combineLatest([routeParamIdSelector(store, 'parentId'), routeParamIdSelector(store, 'order')]).subscribe(x => {
      this.queryParams = {
        parentId: x[0],
        order: x[1]
      }
    }));
  }

  get editing(): boolean {
    return this.mode === 'edit';
  }

  get mode(): string {
    return this.routeName === 'agenda-item-edit' ? 'edit' : 'view';
  }

  get showNotes() {
    const isNotPublic = !this.isPublicUser
    const isNotSlideshow = this.viewMode !== 'SLIDESHOW'
    const isNotMobile = !this.isMobile
    const isNotNewItem = this.agendaItem.id !== 0
    const result = isNotPublic && isNotSlideshow && isNotMobile && isNotNewItem

    return result
  }

  ngOnInit() {
    this.onInit();
    this.sync([
      'accountId',
      'activeId',
      'agendaId',
      'agendaItem',
      'isPublicUser',
      'canEditAgenda',
      'canSuggest',
      'contentHeight',
      'hasDetails',
      'isMobile',
      'meetingId',
      'notes',
      'routeName',
      'unsavedAgendaItem',
      'userId',
      'viewMode'
    ]);
  }

  onDelete() {
    this.openDialog(ConfirmDeleteComponent, {
      width: '600px',
      closeOnNavigation: true,
      restoreFocus: true,
      data: {
        question: 'Are you sure you want to delete this agenda item?'
      }
    });
  }

  addBinItem(agendaItem: AgendaItem) {
    const item = build(BinItem, {
      agendaItem,
      accountId: this.accountId,
      agendaItemId: agendaItem.id
    });
    this.dispatch(HttpActions.post(`binitems/${agendaItem.id}`, {}, BinActions.POST, BinActions.POST_ERROR));
  }

  closeDialog(result: any) {
    if (result) {
      this.deleteAgendaItem(this.activeId, this.agendaId, this.meetingId);
    }
    super.closeDialog(result);
  }

  onAcceptSuggestion() {
    this.updateAgendaItem(build(AgendaItem, this.agendaItem, {
      approved: true,
      isSuggestion: false
    }));
  }

  onRejectSuggestion() {
    this.openDialog(ConfirmDeleteComponent, {
      width: '600px',
      closeOnNavigation: true,
      restoreFocus: true,
      data: {
        question: `You are about to delete the selected Agenda Item,
        any sub-items beneath this item will be deleted with it and cannot be recovered.
        Any attachments, minutes, or notes will also be deleted.
        Are you sure you want to delete this agenda item?`
      }
    });
  }

  deleteAgendaItem(agendaItemId: number, agendaId: number, meetingId: number) {
    this.dispatch(
      HttpActions.delete(
        `agendaitems/${agendaItemId}`,
        null,
        AgendaItemActions.DELETE,
        AgendaItemActions.DELETE_ERROR
      )
    );
  }

  saveAgendaItem() {
    this.dispatch({ type: AgendaItemActions.SAVE_CHANGES });
  }

  updateAgendaItem(agendaItem: AgendaItem) {
    this.dispatch(HttpActions.put(`agendaitems/${agendaItem.id}`, agendaItem, AgendaItemActions.PUT));
  }
}
