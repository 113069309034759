<div
  class="agenda-item-preview"
  [class.isRomanDisplay]="displayType === 4"
  [class.subitem]="depth > 0"
  [class.level-1]="depth === 1"
  [class.level-2]="depth === 2"
  [class.level-3]="depth === 3"
  [class.level-4]="depth === 4"
  [style.margin-left.px]="marginLeftPx"
>
  <div class="item-heading">
    <span class="order">{{ displayOrder }}</span>&nbsp;
    <span class="name">{{ agendaItem.name }}</span>
    <am-tags-preview
      *ngIf="details.tags"
      [agendaItemId]="agendaItem.id"
    >
    </am-tags-preview>
  </div>
  <p
    *ngIf="details.descriptions"
    [innerHTML]="agendaItem.description | safeHtml"
  >
  </p>
  <am-attachments-preview
    [agendaItemId]="agendaItem.id"
    *ngIf="details.attachments && agendaItem.hasAttachments"
  >
  </am-attachments-preview>
  <am-notes-preview
    [agendaItemId]="agendaItem.id"
    *ngIf="details.notes"
  >
  </am-notes-preview>
  <am-minutes-preview
    [agendaItemId]="agendaItem.id"
    *ngIf="displayMinutes && details.minutes"
    [style.width.%]="100"
  >
  </am-minutes-preview>
  <am-votes-preview
    [agendaItemId]="agendaItem.id"
    *ngIf="details.voting && agendaItem.isVotable"
  >
  </am-votes-preview>
</div>
