<footer>
  <div class="contact-support">
    <span>Agenda Manager Support</span><br />
    <a href="mailto:agendaman@caiu.org">support@agendamanager.com</a>&nbsp;|&nbsp;(717) 732-8403
  </div>
  <div class="developed-by">
    <span class="pre">Developed by</span>
    <a href="http://www.caiu.org">
      <img src="/assets/caiu_logo.png" />
    </a>
  </div>
</footer>
