import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SmartComponent, build, HttpActions, compareNumbers, toArray } from '@caiu/library';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Attachment } from './attachments.model';
import { AttachmentsActions } from './attachments.reducer';
import { FileType, Logger, matchFileType } from '../shared/utils';

@Component({
  selector: 'am-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent extends SmartComponent implements OnInit {
  @Input() attachmentsBasePath = 'attachments';
  @Input() preview = false;
  @Input() removeAction = AttachmentsActions.REMOVE;
  @Input() uploadAction = AttachmentsActions.ADD;
  @Input() uploadUrl = '';
  _attachments: Attachment[] = [];
  errorMessage = '';
  fileUrl: SafeUrl = "";
  fileName = "";
  loading = false;
  @ViewChild("attLink", { static: false }) attLink;
  private readonly logger = new Logger( AttachmentsComponent.name )

  constructor(public store: Store<any>,
    private _http: HttpClient,
    private sanitizer: DomSanitizer) {
    super(store);
  }

  @Input()
  set attachments(value: Attachment[]) {
    this._attachments = value;
  }

  get attachments(): Attachment[] {
    return toArray(this._attachments).sort((a, b) => compareNumbers(a.order, b.order));
  }

  get label(): string {
    return `${this.attachments.length} ${this.attachments.length === 1 ? 'Attachment' : 'Attachments'}`;
  }

  ngOnInit() {
  }

  //* [12282] matches attachemnt file extention to an appropriate material icon
  getAttachmentIcon( attachment: Attachment ) {
    const iconMap: [ FileType, string ][] = [
      [ 'document', 'description' ],
      [ 'spreadsheet', 'table_chart' ],
      [ 'presentation', 'slideshow' ],
      [ 'image', 'image' ],
      [ 'audio', 'audiotrack' ],
      [ 'video', 'videocam' ]
    ]

    for ( const [ fileType, icon ] of iconMap ) {
      if ( matchFileType( fileType, attachment.fileExtension ) )
        return icon
    }

    return 'insert_drive_file'
  }

  openAttachment(e: Attachment) {
    // const w = window.open('');
    // const src = `data:${e.mimeType};base64,${e.fileBinary}`;
    // w.document.write(`<iframe width='100%' height='100%' src='${src}'></iframe>`);
    this.loading = true;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    this.fileName = e.fileName;
    const exportUrl =
      environment.apiBaseUrl +
      `/${this.attachmentsBasePath}/file/${e.fileId}`;
    this._http
      .get(exportUrl, { responseType: "blob" })
      .pipe(
        map((res) => {
          return new Blob([res], { type: e.mimeType });
        }),
        catchError(err => {
          this.onError();
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(res)
          );
          setTimeout((x) => {
            this.attLink.nativeElement.click();
            this.loading = false;
          }, 0);
        }
      });
  }

  onError() {
    this.loading = false;
    this.errorMessage = 'The file could not be opened.';
    setTimeout(() => {
      this.errorMessage = '';
    }, 8000);
  }

  upload(files: any[]) {
    const payload = new FormData();
    files.forEach(f => {
      payload.append('file', f);
    });
    this.addAttachment(build(Attachment, payload));
  }

  addAttachment(attachment: Attachment) {
    this.dispatch(HttpActions.post(`${this.uploadUrl}/attachments`, attachment, this.uploadAction));
  }

  deleteAttachment(attachmentId: number) {
    this.dispatch(HttpActions.delete(`${this.uploadUrl}/attachments/${attachmentId}`, attachmentId, this.removeAction));
  }

  updateAttachments(attachments: Attachment[]) {
    this.dispatch(HttpActions.post(`${this.uploadUrl}/attachments`, attachments, this.uploadAction));
  }

}
