<am-dialog-base [closeCallback]="closeCallback">
	<form class="confirm-form">
		<p>
			You have unsaved changes. Are you sure you want to leave?
		</p>
		<div class="btn-group">
			<button
				(click)="cancelClick( $event )"
				value="cancel"
			>
				Cancel
			</button>
			<button
				(click)="confirmClick( $event )"
				value="confirm"
			>
				Confirm
			</button>
		</div>
	</form>
</am-dialog-base>
