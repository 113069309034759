import { build, DateRange, DateHelper, positiveIntegerArray, QueryModel, toInt, Action, compareStrings, integerArray, toArray } from '@caiu/library';

import { File, FileUpload } from '../models';

export function buildDateRange(dateRangeId: number): DateRange {
    let startDate: Date;
    let endDate: Date;
    switch (dateRangeId) {
        case 0:
            break;
        case 1:
            return new DateRange();
        case 2: // Today
            startDate = DateHelper.Today;
            endDate = startDate;
            break;
        case 3: // Next 15
            startDate = DateHelper.Today;
            endDate = DateHelper.AddDays(startDate, 15);
            break;
        case 4: // Next 30
            startDate = DateHelper.Today;
            endDate = DateHelper.AddDays(startDate, 30);
            break;
        case 5: // Next 60
            startDate = DateHelper.Today;
            endDate = DateHelper.AddDays(startDate, 60);
            break;
        case 6: // Previous 15
            endDate = DateHelper.Today;
            startDate = DateHelper.SubtractDays(endDate, 15);
            break;
        case 7: // Previous 30
            endDate = DateHelper.Today;
            startDate = DateHelper.SubtractDays(endDate, 30);
            break;
        case 8: // Previous 60
            endDate = DateHelper.Today;
            startDate = DateHelper.SubtractDays(endDate, 60);
            break;
        case 9: // School Year - Runs from July 1st Thru June 30th
            startDate = DateHelper.YearStartDate;
            endDate = DateHelper.YearEndDate;
            break;
        case 10: // Number of meetings = 10
            startDate = DateHelper.Today;
            endDate = DateHelper.FutureDate;
            break;
        case 11: // Previous School Year
            startDate = DateHelper.PreviousYearStartDate;
            endDate = DateHelper.PreviousYearEndDate;
            break;
    }
    return build(DateRange, { startDate, endDate });
}

export function findDateRangeId(startDate: Date, endDate: Date): number {
    return positiveIntegerArray(11).filter(x => x > 1).find(x => {
        const dr = buildDateRange(x);
        return DateHelper.IsSameDay(dr.startDate, startDate) && DateHelper.IsSameDay(dr.endDate, endDate);
    }) || 1;
}

export function fromLocalStore(key: string, action: Action, defaultValue = {}): any {
    return action.payload.localStore ? action.payload.localStore[key] : defaultValue;
}

export function buildQueryString(query: QueryModel<any>): string {
    return buildQueryStringFromObject({
        skip: query.skip,
        take: query.take,
        term: query.term,
        sortBy: query.sortBy,
        sortDirection: query.sortDirection
    });
}

export function buildQueryStringFromObject(obj: any): string {
    return Object.keys(obj).reduce((acc, key) => obj[key] ? acc ? `${acc}&${key}=${obj[key]}` : `?${key}=${obj[key]}` : acc, '');
}

export function fileToBinary(f: File) {
    return f.mimeType && f.fileBinary ? `data:${f.mimeType};base64,${f.fileBinary}` : '';
}

export function countDigits(n: number): number {
    return (n + '').replace('.', '').length;
}

export function isValidEmail(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

export function mapFileUpload(upload: FileUpload, file?: File): File {
    return build(File, {
        fileBinary: upload.src.replace(File.getSrcPrefix(upload.type), ''),
        fileExtension: upload.extension,
        fileName: upload.name,
        fileSize: upload.size,
        mimeType: upload.type,
        id: file ? file.id : 0
    });
}

export function match(str1: string, str2: string): boolean {
    return str1.toLowerCase().includes(str2.toLowerCase());
}

export function roundToDecimalPlace(num: number, decimalPlace: number): number {
    return Math.round(num * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);
}

export function startsWith(str1: string, str2: string): boolean {
    return str1.toLowerCase().startsWith(str2.toLowerCase());
}

export function findCharCodeAtIndex(index: number): string {
    const i1 = Math.floor(index / 26);
    return i1 > 0 ? `${findLetterAtIndex(i1 - 1)}${findLetterAtIndex(index % 26)}` : findLetterAtIndex(index);
}

export function findLetterAtIndex(index: number): string {
    return String.fromCharCode(97 + index);
}

export function toLetters(str: string): string {
    const digits = str.split('.').filter(x => x !== '');
    return digits.map((x, i) => i === 0 ? `${x}.` : findCharCodeAtIndex(toInt(x) - 1))
        .reduce((acc, x, i) => i === 0 ? x : `${acc}${x}`, '');
}

const RomanNumeralLookup = Object.freeze( {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1
} )

export function toRomanNumeral( input: number ): string {
  let number = input
  let romanNumeral = ''

  for ( let key in RomanNumeralLookup ) {
    const matchNumber = RomanNumeralLookup[ key ]
    while ( number >= matchNumber ) {
      romanNumeral += key
      number -= matchNumber
    }
  }

  return romanNumeral
}

export function sortFullNames(names: string[]) {
    return names
        .map(x => {
            const names = toNames(x);
            return `${names.lastName} ${names.firstName}`;
        })
        .sort((a, b) => compareStrings(a, b))
        .map(x => {
            const names = toNames(x);
            return `${names.lastName} ${names.firstName}`;
        });
}

export function toNames(fullName): { firstName: string, lastName: string } {
    const names = fullName.split(' ');
    if (names.length > 2) {
        return { firstName: fullName, lastName: '' };
    }
    const firstName = names[0];
    const lastName = names[names.length - 1];
    return { firstName, lastName };
}

export function escapeCharacters(str: string): string {
    return `${str}`.replaceAll(String.fromCharCode(8239), String.fromCharCode(32));
}

export function previewMinutes(minutes: string): string {
    return `
    <div>
      <span style="width: 100%;display: block;font-size: 1px;line-height: 5px;">&nbsp;</span>
      <table style="background-color: #EFF4FF;width: 100%;border-spacing: 0;font-size: 10.5pt;border-style: solid;border-width: 1px 0;border-color: #C8D2EA;">
        <tr>
          <td style="width: 10px;">&nbsp;</td>
          <td>
            <div style="background-color: #EFF4FF;font-weight: bold;margin-top: 5px;">Minutes</div>
            <p>${minutes}</p>
          </td>
        </tr>
      </table>
    </div>
  `;
}

export function previewNotes(notes: string): string {
    return `
    <div>
      <span style="width: 100%;display: block;font-size: 1px;line-height: 5px;">&nbsp;</span>
      <table style="background-color: #FFFEEF;width: 100%;border-spacing: 0;font-size: 10.5pt;border-style: solid;border-width: 1px 0;border-color: #FFE65F;">
        <tr>
          <td style="width: 10px;">&nbsp;</td>
          <td>
            <div style="background-color: #FFFEEF;font-weight: bold;margin-top: 5px;">Minutes</div>
            <p>${notes}</p>
          </td>
        </tr>
      </table>
    </div>
  `;
}

export function previewVotes(data: any[]): string {
    const votes = toArray(data);
    const yeaCount = votes.filter(x => x.answerId === 1).length;
    const nayCount = votes.filter(x => x.answerId === 2).length;
    const abstainCount = votes.filter(x => x.answerId === 3).length;
    const notCastCount = votes.filter(x => x.answerId === 4).length;
    const motion = votes.find(x => x.motion);
    const second = votes.find(x => x.second);
    const motionSecond = motion && second ? `
    <div style="width: 100%;font-size: 12px;margin-top: 3px;">
      <span>
        <span style="margin-right: 5px;font-weight: bold;">Motion:&nbsp;</span>
        <span>${motion ? motion.voterName : ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span>
        <span style="margin-right: 5px;font-weight: bold;">Second:&nbsp;</span>
        <span>${second ? second.voterName : ''}</span>
      </span>
    </div>
  ` : '';
    const classVoteAnswer = `style="width: 56px;text-align: right;font-size: 12px;font-weight: bold;"`;
    const classVoteTotal = `style="width: 20px;text-align: center;font-size: 12px;"`;
    const classVoters = `style="font-size: 12px;"`;
    return `
    <div style="margin-top: 0;margin-right: 0;margin-right: 0;margin-left: 20px;font-size: 12px;width: 100%;">
      <h3 style="font-style: italic;border-bottom: solid 1px #4e5466;margin: 6px 0 2px 0;">Vote Results</h3>
      <table style="width: 100%; border-spacing: 0;">
        <tr>
          <td ${classVoteAnswer}>Yea:</td>
          <td ${classVoteTotal}>${yeaCount}</td>
          <td ${classVoters}>${previewVoters(votes, 1)}</td>
        </tr>
        <tr>
          <td ${classVoteAnswer}>Nay:</td>
          <td ${classVoteTotal}>${nayCount}</td>
          <td ${classVoters}>${previewVoters(votes, 2)}</td>
        </tr>
        <tr>
          <td ${classVoteAnswer}>Abstain:</td>
          <td ${classVoteTotal}>${abstainCount}</td>
          <td ${classVoters}>${previewVoters(votes, 3)}</td>
        </tr>
        <tr>
          <td ${classVoteAnswer}>Not Cast:</td>
          <td ${classVoteTotal}>${notCastCount}</td>
          <td ${classVoters}>${previewVoters(votes, 4)}</td>
        </tr>
      </table>
      ${motionSecond}
    </div>
  `;
}

export function previewVoters(votes: any[], answerId: number): string {
    return sortFullNames(toArray(votes)
        .filter(vote => vote.answerId === answerId)
        .map(x => `${x.voterName}`.trim()))
        .join(', ');
}

export const isString = ( value: any ): value is string => typeof value === 'string'

export const isFunction = ( value: any ): value is Function => typeof value === 'function'

export const isUndefined = ( value: any ): value is undefined => typeof value === 'undefined'

export const isNullOrUndefined = ( value: any ): value is null | undefined => isUndefined( value ) || value === null

export const isObject = ( value: any ): value is object => !isNullOrUndefined( value ) && typeof value === 'object'

export const isPlainObject = ( value: any ): value is object => {
  if ( !isObject( value ) ) return false

  const prototype = Object.getPrototypeOf( value )

  if ( prototype === null ) return true

  const constructor = Object.prototype.hasOwnProperty.call( prototype, 'constructor' ) && prototype.constructor

  return typeof constructor === 'function'
    && constructor instanceof constructor
    && Function.prototype.toString.call( constructor ) === Function.prototype.toString.call( Object )
}
