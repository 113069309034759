import {
  build,
  Collection,
  Metadata
} from '@caiu/library';

export class Widget {
  id = 0;
  active = false;
  name = '';
  userId = 0;
  height = 0;
  heightPx = 0;
  offsetX = 0;
  label = '';
  left = 0;
  offsetY = 0;
  showIcons = false;
  top = 0;
  width = 0;
  widthPx = 0;
  zIndex = 0;

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: ['active', 'heightPx', 'justifyX', 'justifyY', 'label', 'left', 'showIcons', 'top', 'widthPx']
    });
  }
}

export class Widgets extends Collection<Widget> {

  borderWidth = 10;
  wrapperHeight = 0;
  wrapperWidth = 0;

  constructor() {
    super(Widget);
  }

  delete(id: number): Widgets {
    return build(Widgets, super.delete(id), {
      wrapperHeight: this.wrapperHeight,
      wrapperWidth: this.wrapperWidth
    });
  }

  hideIcons(id: number): Widgets {
    const existing = this.get(id);
    return build(Widgets, super.update(build(Widget, existing, {
      showIcons: false,
      wrapperHeight: this.wrapperHeight,
      wrapperWidth: this.wrapperWidth
    })));
  }

  replace(data: Widget[]): Widgets {
    return build(Widgets, super.replace(data.map(x => build(Widget, x, {
      showIcons: build(Widget, this.get(x.id)).showIcons,
      wrapperHeight: this.wrapperHeight,
      wrapperWidth: this.wrapperWidth
    }))));
  }

  showIcons(id: number): Widgets {
    const existing = this.get(id);
    return build(Widgets, super.update(build(Widget, existing, {
      showIcons: true,
      wrapperHeight: this.wrapperHeight,
      wrapperWidth: this.wrapperWidth
    })));
  }

  update(data: Widget | Widget[]): Widgets {
    const items = Array.isArray(data) ? data.map(x => build(Widget, x, {
      showIcons: build(Widget, this.get(x.id)).showIcons
    })) : build(Widget, data, {
      showIcons: build(Widget, this.get(data.id)).showIcons
    });
    return build(Widgets, super.update(items), {
      wrapperHeight: this.wrapperHeight,
      wrapperWidth: this.wrapperWidth
    });
  }

  updateDimensions(wrapperHeight: number, wrapperWidth: number): Widgets {
    return build(Widgets, this, { wrapperHeight, wrapperWidth });
  }

  getDefaultItems(h: number, w: number, userId = 0): Widget[] {
    return userId ? [
      build(Widget, {
        name: 'announcements',
        label: 'Announcements',
        left: .05 * w,
        top: 40,
        offsetY: 40 / h,
        offsetX: .05,
        height: 150 / h,
        width: .4,
        heightPx: 150,
        widthPx: .4 * w
      }),
      build(Widget, {
        name: 'notifications',
        label: 'Notifications',
        left: .5 * w,
        top: 40,
        offsetY: 40 / h,
        offsetX: .5,
        height: 150 / h,
        width: .4,
        heightPx: 150,
        widthPx: .4 * w
      }),
      build(Widget, {
        name: 'meetings',
        label: 'Meetings',
        left: .05 * w,
        top: 245,
        offsetY: 245 / h,
        offsetX: .05,
        height: 520 / h,
        width: .85,
        heightPx: 520,
        widthPx: .85 * w
      })
    ] : [
        build(Widget, {
          name: 'meetings',
          label: 'Meetings',
          left: .05 * w,
          top: 80,
          offsetY: 80 / h,
          offsetX: .05,
          height: 520 / h,
          width: .85,
          heightPx: 520,
          widthPx: .85 * w
        })
      ];
  }

}
