import { Action, build, compareStrings, routeParamIdSelector, toArray } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Votes, Vote } from './votes.model';
import { currentUserIdSelector } from '../shared/selectors';
import { groupMembersSelector } from '../members/members.reducer';
import { agendaGroupIdSelector } from '../agendas/agendas.reducer';
import { GroupMember } from '../groups/groups.model';
import { attendeesSelector } from '../attendance/attendance.reducer';
import { Logger } from '../shared/utils';

const logger = new Logger( 'votes.reducer' )

export class VotesActions {
  static GET = '[Votes] Get Votes';
  static POST = '[Votes] Post Vote';
  static POST_ERROR = '[Votes] Post Vote Error';
  static SAVE = '[Votes] Save Votes';
  static SAVE_ERROR = '[Votes] Save Votes Error';
}

export class VoteActions {
  static GET = '[Vote] Get Vote';
  static PUT = '[Vote] Put Vote';
  static PUT_ERROR = '[Vote] Put Vote Error';
}

export function votesReducer(state: Votes = new Votes(), action: Action): Votes {
  switch (action.type) {

    case VotesActions.GET:
    case VotesActions.POST:
    case VotesActions.SAVE:
    case VoteActions.PUT:
      //* [16612] Was seeing an issue where UI was not being updated properly
      //* There were two scenarios:
      //*    1. DB was returning NO results & the app continued using the stale data instead of clearing it
      //*    2. Multiple agenda items in one agenda had votes & every new item that was loaded just pushed all of the results onto the same array
      //* This line below fixes that issue by removing any items currently in state every update
      //* but it speaks to a larger underlying issue with how state is managed for the app.
      logger.log( 'votesReducer', { action } )
      state.removeItems( ( item ) => true, 'items' )

      return state.update( action.payload )
    default:
      return state;
  }
}

export function votesSelector(store: Store<any>): Observable<Votes> {
  return store.select('votes');
}

export function allVotesSelector(store: Store<any>): Observable<Vote[]> {
  return votesSelector(store).pipe(
    map(x => x.asArray)
  );
}

export function agendaItemVotesSelector(store: Store<any>): Observable<Vote[]> {
  return combineLatest( [
    allVotesSelector( store ),
    agendaItemVotersSelector( store ),
    routeParamIdSelector( store, 'agendaItemId' ),
    attendeesSelector( store )
  ] ).pipe(
    map( ( data ) => {
      const [
        allVotes,
        voters,
        agendaItemId,
        attendees
      ] = data
      let builtVotes = []

      const activeVoters = voters.filter( ( voter ) => voter.userIsActive )
      const parsedVoters = attendees.filter( ( attendee ) => attendee.id !== 0 ).length === 0
        ? activeVoters
        : activeVoters.filter( ( voter ) => toArray( attendees ).length === 0
          ? true
          : attendees.findIndex( ( attendee ) => attendee.userId === voter.userId && attendee.isPresent ) !== -1)
      const noVotes = allVotes.length === 0
      const voteCountDoesntMatchVotersCount = allVotes.length !== parsedVoters.length

      if ( noVotes || voteCountDoesntMatchVotersCount ) {
        builtVotes = parsedVoters
          .sort( ( voterA, voterB ) => compareStrings(
            `${ voterA.userLastName} ${ voterA.userFirstName }`,
            `${ voterB.userLastName } ${ voterB.userFirstName }`
          ) )
          .map( ( voter ) => build(
              Vote,
              build(
                Vote,
                allVotes.find( ( vote ) => vote.agendaItemId === agendaItemId && vote.voterId === voter.userId )
              ) ?? {},
              {
                voterId: voter.userId,
                voterName: voter.userName,
                agendaItemId
              }
            )
          )
      } else {
        builtVotes = allVotes.map( ( vote ) => build(
          Vote,
          vote,
          { agendaItemId }
        ) )
      }

      return builtVotes
    } )
  )
}

export function isPresentVoterSelector(store: Store<any>): Observable<boolean> {
  return combineLatest([agendaItemVotesSelector(store), currentUserIdSelector(store)])
    .pipe(
      map(x => x[0].findIndex(y => y.voterId === x[1]) !== -1)
    );
}

export function voteSelector(store: Store<any>): Observable<Vote> {
  return combineLatest(agendaItemVotesSelector(store), currentUserIdSelector(store), routeParamIdSelector(store, 'agendaItemId'),
    (votes, userId, agendaItemId) => build(Vote, votes.find(x => x.voterId === userId && x.agendaItemId === agendaItemId))
  );
}

export function agendaItemVotersSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest(groupMembersSelector(store), agendaGroupIdSelector(store), (members, groupId) => {
    return members.asArray.filter(x => x.groupId === groupId && x.groupRoleId === 7);
  });
}
