import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';

import { EmailRoutingModule } from './email-routing.module';
import { EmailComponent } from './email.component';
import { EmailRecipientsComponent } from './email-recipients/email-recipients.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    EmailRoutingModule,
    MatAutocompleteModule,
    MatChipsModule,
  ],
  declarations: [
    EmailComponent,
    EmailRecipientsComponent,
  ]
})
export class EmailModule { }
