import {
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  SmartComponent,
  build,
  DialogAction,
  HttpActions,
  Control,
  HttpService,
  toArray,
  routeParamIdSelector,
  routeNameSelector,
  truthy,
  compareStrings,
  falsy
} from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, map, distinctUntilChanged, startWith, filter, tap } from 'rxjs/operators';

import { BinItemOptions, BinItem } from './bin-items.model';
import { AgendaItem } from '../agenda-items/agenda-items.model';
import {
  BinActions, binItemOrderSelector
} from './bin-items.reducer';
import { nextAgendaItemOrderSelector } from '../agenda-items/agenda-items.reducer';
import { accountIdSelector } from '../accounts/accounts.reducer';
import { currentUserAccountsSelector } from '../members/members.reducer';
import { AccountMember, Account } from '../shared/models';
import { Attachment } from '../attachments/attachments.model';
import { Logger } from '../shared/utils';

@Component({
  selector: 'am-bin',
  templateUrl: './bin.component.html',
  styleUrls: ['./bin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BinComponent extends SmartComponent implements OnInit, OnDestroy {
  @Control(BinItemOptions) form: FormGroup;
  _accountId = 0;
  accountId$: Observable<number>;
  accounts$: Observable<Account[]>;
  agendaId = 0;
  agendaId$: Observable<number>;
  binItem$: Observable<BinItem>;
  _binItem: BinItem = new BinItem();
  _binItemId = 0;
  binItemId$: Observable<number>;
  _binItems: BinItem[] = null;
  binItems$: Observable<BinItem[]>;
  displayOrder = 0;
  displayOrder$: Observable<number>;
  empty = true;
  loading = false;
  parentId = null;
  parentId$: Observable<number>;
  userAccounts$: Observable<AccountMember[]>;
  order = 0
  order$: Observable<number>
  private readonly logger = new Logger( BinComponent.name )

  constructor(public store: Store<any>, public templateRef: ViewContainerRef, public http: HttpService, public dialogRef: MatDialogRef<BinComponent>) {
    super(store);
    this.accountId$ = accountIdSelector(store);
    this.agendaId$ = routeParamIdSelector(store, 'agendaId');
    this.userAccounts$ = currentUserAccountsSelector(store);
    this.displayOrder$ = binItemOrderSelector(store);
    this.parentId$ = routeParamIdSelector(this.store, 'parentId');
    this.binItemId$ = routeParamIdSelector(this.store, 'binItemId');
    this.order$ = routeParamIdSelector( this.store, 'order' )
    this.binItem$ = this.form.get('binItemId').valueChanges.pipe(
      distinctUntilChanged(),
      filter(id => truthy(id)),
      switchMap(id => {
        return this.http.get(`binitems/${id}`);
      }),
      map(response => {
        const item = build(BinItem, response, {
          agendaItem: build(AgendaItem, response.agendaItem, {
            attachments: toArray(response.agendaItem.attachments).map(x => build(Attachment, x))
          })
        });
        return item;
      })
    );
    this.binItems$ =
      this.form.get('accountId').valueChanges.pipe(
        startWith(0),
        distinctUntilChanged(),
        tap(x => { this.loading = true; }),
        switchMap(id => {
          return id === 0 ? this.http.get(`binitems`) : this.http.get(`accounts/${id}/binitems`);
        }),
        map(response => {
          return toArray(response).sort((a, b) => compareStrings(a.agendaItem.name, b.agendaItem.name));
        }),
        tap(x => { this.loading = false; })
      );
  }

  set accountId(value: number) {
    this._accountId = value;
    this.form.get('accountId').setValue(value);
  }

  get accountId(): number {
    return this._accountId;
  }

  get actions(): DialogAction[] {
    return [
      build(DialogAction, { value: 'add', label: 'Add', color: 'accent' }),
      build(DialogAction, { value: 'delete', label: 'Delete', color: 'warn' }),
      build(DialogAction, {
        value: 'cancel',
        label: 'Cancel',
        color: 'primary'
      })
    ];
  }

  set binItem(value: BinItem) {
    this._binItem = value;
    // setTimeout(() => {
    //   this.accountId = value.accountId;
    // }, 0);
  }

  get binItem(): BinItem {
    return this._binItem;
  }

  set binItemId(value: number) {
    if (value && this._binItemId !== value) {
      // this.binItem = build(BinItem, toArray(this.binItems).find(x => x.id === this.form.value.binItemId), {
      //   agendaId: this.agendaId
      // });
      this.form.get('binItemId').setValue(value);
      this.getBinItem(value);
    }
  }

  get binItemId(): number {
    return this.form.get('binItemId').value;
  }

  set binItems(value: BinItem[]) {
    this._binItems = value;
    this.empty = Array.isArray(value) && value.length === 0;
  }

  get binItems(): BinItem[] {
    return toArray(this._binItems).filter(x => x.agendaItem.parentId === null);
  }

  get description(): string {
    const description = this.binItem.agendaItem.description;
    return falsy(description) || description.toLowerCase() === 'null' ? '' : description;
  }

  get options(): BinItemOptions {
    return build(BinItemOptions, this.form.value, {
      order: this.order || 0,
      parentId: this.parentId || null
    });
  }

  ngOnInit() {
    this.sync([
      'accountId',
      'agendaId',
      'binItem',
      'binItemId',
      'binItems',
      'displayOrder',
      'parentId',
      'order'
    ]);
    // this.addSubscription(this.form.get('binItemId').valueChanges.subscribe(id => {
    //   if (id) {
    //     this.dispatch(RouterActions.navigate([`/`, { outlets: { popup: `agendas/${this.agendaId}/bin/${id}` } }]));
    //   }
    // }));
    this.addSubscription(routeNameSelector(this.store).subscribe(x => {
      if (x !== 'bin') {
        this.dialogRef.close();
      }
    }));
    this.setValue(new BinItemOptions());
  }

  ngOnDestroy() {
    this.form.reset();
    super.ngOnDestroy();
  }

  addAgendaItem(agendaItemId: number) {
    const onSuccess = this.form.value.removeAfterCopy ? BinActions.ADD_AGENDA_ITEM_AND_REMOVE : BinActions.ADD_AGENDA_ITEM;
    this.loading = true;
    this.logger.log( 'addAgendaItem', {
      options: this.options,
      agendaId: this.agendaId,
      order: this.order,
      agendaItemId
    } )
    this.httpPost(`agendas/${this.agendaId}/agendaitems/${agendaItemId}`, this.options, onSuccess, BinActions.ADD_AGENDA_ITEM_ERROR).subscribe(x => {
      this.loading = false;
    });
  }

  deleteBinItem(e: number) {
    this.dispatch(
      HttpActions.delete(`binItems/${e}`, e, BinActions.DELETE, BinActions.DELETE_ERROR)
    );
  }

  getBinItem(e: number) {
    this.dispatch(HttpActions.get(`binitems/${e}`, BinActions.GET));
  }
}
