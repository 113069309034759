import {
    Component,
    Input,
    OnInit
} from '@angular/core'
import {
    HttpService,
    truthy,
    build,
    DumbComponent
} from '@caiu/library'
import {
    BehaviorSubject,
    Observable
} from 'rxjs'
import {
    filter,
    switchMap,
    map
} from 'rxjs/operators'

import { Tag, Tags } from '../tags.model'
import { Logger } from '../../utils/logger'

@Component( {
    selector: 'am-tags-preview',
    templateUrl: './tags-preview.component.html',
    styleUrls: [ './tags-preview.component.scss' ]
} )
export class TagsPreviewComponent extends DumbComponent implements OnInit {
    agendaItemIdSubject = new BehaviorSubject( 0 )
    _tags: Tag[] = []
    tags$: Observable<Tag[]>
    private readonly logger = new Logger( TagsPreviewComponent.name )

    constructor( public http: HttpService ) {
        super()

        this.tags$ = this.agendaItemIdSubject.asObservable().pipe(
            filter( ( id ) => truthy( id ) ),
            switchMap( ( id ) => http.get( `agendaitems/${ id }/tags` ) ),
            filter( ( tags ) => truthy( tags ) )
        )
    }

    @Input()
    set agendaItemId( value: number ) {
        this.agendaItemIdSubject.next( value )
    }

    get agendaItemId(): number {
        return this.agendaItemIdSubject.value
    }

    set tags( tags: Tag[] ) {
        this.logger.log( 'tags SET', { tags } )

        this._tags = tags
    }

    get tags() {
        return this._tags
    }

    ngOnInit() {
        this.logger.log( 'ngOnInit' )

        this.sync( [
            'tags'
        ] )
    }
}
