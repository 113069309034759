import { Action, routeParamIdSelector, build } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { Attachments, Attachment } from './attachments.model';
import { AgendaItemActions } from '../agenda-items/agenda-items.reducer';
import { AnnouncementActions } from '../announcements/announcements.reducer';

export class AttachmentsActions {
  static ADD = '[Attachments] Add Attachment';
  static REMOVE = '[Attachments] Remove Attachment';
  static REMOVE_ERROR = '[Attachments] Remove Attachment Error';
}

export function attachmentsReducer(state: Attachments = new Attachments(), action: Action): Attachments {
  switch (action.type) {
    case AgendaItemActions.GET:
    case AgendaItemActions.PUT:
      return state.updateForAgendaItem(action.payload.attachments);

    case AnnouncementActions.GET:
    case AnnouncementActions.PUT:
      return state.updateForAnnouncement(action.payload.attachments);

    case AttachmentsActions.REMOVE:
      return build(Attachments, state.delete(action.payload.id));

    default:
      return state;
  }
}
export function attachmentsSelector(store: Store<any>): Observable<Attachments> {
  return store.select('attachments');
}

export function agendaItemAttachmentsSelector(store: Store<any>): Observable<Attachment[]> {
  return combineLatest(attachmentsSelector(store), routeParamIdSelector(store, 'agendaItemId'), (attachments, agendaItemId) =>
    attachments.asArray.filter(x => x.agendaItemId === agendaItemId)
  );
}
