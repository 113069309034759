import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DialogModel, build, DialogAction } from '@caiu/library';
import { Observable, of } from 'rxjs';

import { PrintComponent } from './print.component';

@Injectable()
export class PrintResolver implements Resolve<DialogModel> {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DialogModel> {
    const actions = [
      build(DialogAction, { value: 'close', label: 'Close', primary: true })
    ];
    return of(build(DialogModel, { title: 'Print', width: '1000px', actions }));
  }

}

const routes: Routes = [{
  path: 'print',
  outlet: 'popup',
  component: PrintComponent,
  data: { routeName: 'print', routeLabel: 'Print' },
  resolve: {
    dialog: PrintResolver
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PrintResolver]
})
export class PrintRoutingModule { }
