import { Component, OnInit, Optional, Inject } from '@angular/core';
import { SmartComponent, routeParamIdSelector, HttpActions } from '@caiu/library';
import { Observable } from 'rxjs';

import { Announcement } from '../announcements/announcements.model';
import { Store } from '@ngrx/store';
import { AnnouncementActions, announcementSelector } from '../announcements/announcements.reducer';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'am-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent extends SmartComponent implements OnInit {

  _announcementId = 0;
  announcementId$: Observable<number>;
  _announcement: Announcement = new Announcement();
  announcement$: Observable<Announcement>;

  constructor(public store: Store<any>, @Optional() @Inject(MAT_DIALOG_DATA) public data: Announcement) {
    super(store);
    this.announcementId$ = routeParamIdSelector(store, 'announcementId');
    this.announcement$ = announcementSelector(store);
  }

  set announcement(value: Announcement) {
    this._announcement = value;
  }

  get announcement(): Announcement {
    return this.data || this._announcement;
  }

  set announcementId(value: number) {
    this._announcementId = value;
    if (value) {
      this.getAnnouncement(value);
    }
  }

  get announcementId(): number {
    return this._announcementId;
  }

  ngOnInit() {
    this.sync(['announcementId', 'announcement']);
  }

  getAnnouncement(id: number) {
    this.dispatch(HttpActions.get(`announcements/${id}`, AnnouncementActions.GET));
  }

}
