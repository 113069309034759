import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SmartComponent, build, DialogAction, routeParamIdSelector, DateHelper, HttpActions, CurrentUser, currentUserSelector } from '@caiu/library';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Agenda } from '../agendas/agendas.model';
import { AgendaActions, agendaSelector } from '../agendas/agendas.reducer';
import { AgendaItem } from '../agenda-items/agenda-items.model';
import { AgendaItemActions, AgendaItemsActions, agendaItemSelector, agendaTreeItemsStreamSelector, agendaTreeItemsStreamSelector2 } from '../agenda-items/agenda-items.reducer';
import { Account, AccountRole, ExportSettings, HasDetails, User } from '../shared/models';
import { Tree, TreeItem } from '../shared/tree';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { authTokenSelector, isPublicUserSelector } from '../shared/selectors';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '../shared/utils';
import { accountSelector } from '../accounts/accounts.reducer';

window.getComputedStyle = window.getComputedStyle || function (element) {
  return element['currentStyle'];
}

export function htmlToString(element): string {
  const str = `${htmlElementTag(element)}${htmlElementContents(element)}${htmlElementCloseTag(element)}`;
  return str;
}

export function htmlElementTag(element): string {
  const html = element.outerHTML;
  const style = htmlStyleElementToString(element);
  const insertAtIndex = html.indexOf('>');
  return `${html.substring(0, insertAtIndex)} style="${style}">`;
}

export function htmlElementContents(element): string {
  const children = Array.from(element.children);
  return `${stripTags(element.innerHTML)}${children.reduce((acc, el) => acc + htmlToString(el), '')}`;
}

export function htmlElementCloseTag(element): string {
  const html = element.outerHTML;
  return html.substring(html.lastIndexOf('</'));
}

export function stripCommentsFromHtml(htmlString): string {
  return htmlString.replace(/(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g, "");
}
// /(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g

function getStylesWithoutDefaults(element) {

  // creating an empty dummy object to compare with
  var dummy = document.createElement('element-' + (new Date().getTime()));
  document.body.appendChild(dummy);

  // getting computed styles for both elements
  var defaultStyles = getComputedStyle(dummy);
  var elementStyles = getComputedStyle(element);

  // calculating the difference
  var diff = {};
  for (var key in elementStyles) {
    if (elementStyles.hasOwnProperty(key)
      && defaultStyles[key] !== elementStyles[key]) {
      diff[key] = elementStyles[key];
    }
  }

  // clear dom
  dummy.remove();

  return diff;
}

export function htmlStyleElementToString(element): string {
  const computedStyle = window.getComputedStyle(element);
  const styles = getStylesWithoutDefaults(element);
  return computedStyle.cssText;
}

export function htmlWrap(html: string, style: string): string {
  return `<head>${style ? '<style>' + style + '</style>' : ''}</head><body>${html}</body>`;
}

export function stripTags(str: string): string {
  const tagIndex = str.indexOf('<');
  const txt = str.substring(0, tagIndex === -1 ? str.length : tagIndex);
  return txt;
}

@Component({
  selector: 'am-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent extends SmartComponent implements OnInit {

  @ViewChild('headingEl', { static: true }) headingEl: ElementRef;
  @ViewChild('printPreview', { static: true }) printPreviewEl: ElementRef;
  @ViewChild('agendaDescription', { static: true }) agendaDescriptionEl: ElementRef;
  @ViewChild("docLink", { static: false }) docLink;
  agenda: Agenda = new Agenda();
  agenda$: Observable<Agenda>;
  agendaItem: AgendaItem = new AgendaItem();
  agendaItem$: Observable<AgendaItem>;
  authToken = '';
  authToken$: Observable<string>;
  currentUser = new CurrentUser();
  currentUser$: Observable<CurrentUser>;
  details: HasDetails = new HasDetails();
  tree: Tree<AgendaItem> = new Tree<AgendaItem>([], AgendaItem);
  tree$: Observable<Tree<AgendaItem>>;
  fileUrl: SafeUrl = "";
  fileName = "";
  isPublicUser: boolean = false;
  isPublicUser$: Observable<boolean>;
  outlineId = 2;
  outlineId$: Observable<number>;
  printing = false;
  styles = "";
  isForAgendaItem = false;
  isMinutesView = false;
  _agendaId = 0;
  agendaId$: Observable<number>;
  _agendaItemId = 0;
  agendaItemId$: Observable<number>;
  account = new Account()
  account$: Observable<Account>
  readonly logger = new Logger( PrintComponent.name )

  constructor(public store: Store<any>, private http: HttpClient, actions: Actions, private sanitizer: DomSanitizer, private route: ActivatedRoute) {
    super(store);

    this.route.queryParams.subscribe( ( params ) => {
      this.isForAgendaItem = Boolean( params[ 'agendaItem' ] ) ?? false
      this.isMinutesView = Boolean( params[ 'minutesView' ] ) ?? false
    } )

    if ( this.isMinutesView ) {
      this.agendaId$ = routeParamIdSelector(store, 'agendaId')
      this.agendaItemId$ = routeParamIdSelector(store, 'agendaItemId')
    }

    this.account$ = accountSelector(store)
    
    this.agenda$ = agendaSelector(store);
    this.agendaItem$ = agendaItemSelector(store);
    this.outlineId$ = this.agenda$.pipe(
      map(x => x.outlineId),
      distinctUntilChanged()
    );
    this.tree$ = this.isForAgendaItem
      ? agendaTreeItemsStreamSelector2(store, actions, agendaItemSelector(store))
      : agendaTreeItemsStreamSelector(store, actions, routeParamIdSelector(store, 'agendaId'));
    this.isPublicUser$ = isPublicUserSelector(store);
    this.authToken$ = authTokenSelector(store);
    this.currentUser$ = currentUserSelector(store);
  }

  set agendaId(value: number) {
    this._agendaId = value

    if ( value ) {
      this.getAgenda( value )
    }
  }

  get agendaId() {
    return this._agendaId
  }

  getAgenda( agendaId: number ) {
    this.dispatch(
      HttpActions.get( `agendas/${ agendaId }`, AgendaActions.GET )
    )
    this.dispatch(
      HttpActions.get( `agendas/${ agendaId }/agendaItems`, AgendaItemsActions.GET )
    )
  }

  set agendaItemId( value: number ) {
    this._agendaItemId = value

    if ( value ) {
      this.getAgendaItem( value )
    }
  }

  get agendaItemId() {
    return this._agendaItemId
  }

  getAgendaItem( agendaItemId: number ) {
    this.dispatch(
      HttpActions.get( `agendaItems/${ agendaItemId }`, AgendaItemActions.GET )
    )
  }

  get actions(): DialogAction[] {
    return [
      build(DialogAction, { value: null, label: 'Close' }),
    ];
  }

  get exportSettings(): ExportSettings {
    return build( ExportSettings, {
      agendaId: this.agenda.id,
      html: htmlWrap( `${
        stripCommentsFromHtml( this.headingEl.nativeElement.outerHTML )
      }${
        this.details.showAgendaDescription
          ? this.agenda.description
          : ''
      }${
        stripCommentsFromHtml( this.printPreviewEl.nativeElement.outerHTML )
      }`, this.styles ),
      convertAttachment: this.details.appendAttachments,
      addAttachment: this.details.attachments,
      name: this.agenda.name
    } )
  }

  get title(): string {
    return 'Print';
  }

  get treeItems() {
    if ( this.isForAgendaItem && !this.details.subItems ) {
      return [ this.tree.findByIndex( 0 ) ]
    }

    return this.tree.treeItems
  }

  get displayMinutes() {
    const isImpersonating = !!( ( this.currentUser as any ).impersonating )
    const user = ( isImpersonating
      ? ( this.currentUser as any ).impersonating
      : this.currentUser ) as User
    const agenda = this.agenda
    const account = user.userAccounts.find( ( account ) => account.accountId === agenda.accountId )
    const isAdmin = ( account as any )?.roleId === AccountRole.Administrator
    const isMinuteTaker = this.agenda.minuteTakerId === user.id
    const canDisplayMinutes = this.agenda.displayMinutes

    return isAdmin || isMinuteTaker || canDisplayMinutes
  }

  ngOnInit() {
    this.http.get("assets/print-meeting.css", { responseType: 'text' }).subscribe(data => {
      this.styles = data;
    },
      error => {
        //can I get the response body here?
        console.log("Error getting meeting-print.css file from assets directory: ", error);
      });

    const syncKeys = [
      'account',
      'agenda',
      'agendaItem',
      'outlineId',
      'tree',
      'authToken',
      'currentUser',
      'isPublicUser'
    ]

    if ( this.isMinutesView ) {
      syncKeys.unshift( ...[ 'agendaId', 'agendaItemId' ] )
    }

    this.sync( syncKeys )

    if ( this.isForAgendaItem ) {
      this.details.subItems = false
    }

    if ( this.isMinutesView ) {
      this.details.minutes = true
    }
  }

  getOutlineType( treeItem: TreeItem<AgendaItem> ) {
    switch( this.outlineId ) {
      case 4:
        return treeItem.displayOrderRomanNumerals
      case 3:
        return treeItem.displayOrderLettersOnly
      case 2:
        return treeItem.displayOrderNumbers
      case 1:
      default:
        return treeItem.displayOrderLetters
    }
  }

  onPrint() {
    //this.print(this.printPreviewEl.nativeElement.innerHTML);
    this.print(this.exportSettings.html);
  }

  getPdf() {
    this.fileName = `${this.agenda.name}_${DateHelper.ToDayString(this.agenda.agendaDate)}.pdf`;
    // this.agenda.name +
    // DateHelper.ToDayString(new Date()) +
    // "_" +
    // DateHelper.Today.toLocaleTimeString() +
    // ".pdf";
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    if (this.authToken) {
      headers['Authorization'] = `Bearer ${this.authToken}`;
    }

    this.printing = true;
    this.http.post(environment.apiBaseUrl + `/print/printpdf?currentAccountId=${ this.account.id }`, this.exportSettings, { responseType: "blob", headers }).pipe(
      // this.http.post(`print/printpdf`, this.exportSettings).pipe(
      map((res) => {
        return new Blob([res], { type: "application/pdf" });
      })
    )
      .subscribe((res) => {
        this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(res)
        );
        this.printing = false;
        setTimeout((x) => {
          this.docLink.nativeElement.click();
        }, 0);
      });
  }

  getWordDoc() {
    this.fileName = `${this.agenda.name}_${DateHelper.ToDayString(this.agenda.agendaDate)}.docx`;
    // this.agenda.name +
    // DateHelper.ToDayString(new Date()) +
    // "_" +
    // DateHelper.Today.toLocaleTimeString() +
    // ".docx";

    this.printing = true;
    this.http.post(environment.apiBaseUrl + `/print/printword?currentAccountId=${ this.account.id }`, this.exportSettings, { responseType: "blob" }).pipe(
      // this.http.post(`print/printword`, this.exportSettings, { responseType: "blob" }).pipe(
      map((res) => {
        return new Blob([res], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
      })
    )
      .subscribe((res) => {
        this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(res)
        );
        this.printing = false;
        setTimeout((x) => {
          this.docLink.nativeElement.click();
        }, 0);
      });
  }
}
