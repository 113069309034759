import { TypeConstructor, compareNumbers, toArray, build, falsy, compareDates, arrayDistinct } from '@caiu/library';
import { countDigits, findCharCodeAtIndex, roundToDecimalPlace, toRomanNumeral } from './utils';

// group items according to stored order
function groupItems<T extends Orderable>(items: TreeItem<T>[]) {
  return items.reduce((acc, x) => {
    const obj = Object.assign({}, acc);
    const parentId = x.parentId || 0;
    if (!obj[parentId]) {
      obj[parentId] = {};
    }
    if (obj[parentId] && Array.isArray(obj[parentId][x.order])) {
      obj[parentId][x.order] = [...obj[parentId][x.order], x];
    } else {
      obj[parentId][x.order] = [x];
    }
    return obj;
  }, {});
}

// ensures no items within the same subtree have identical display order
export function orderSubtree<T extends Orderable>(subtree: { [key: number]: TreeItem<T>[] }): TreeItem<T>[] {
  const ordered = Object.keys(subtree).reduce((acc, key) => {
    return Object.assign({}, acc, { [key]: subtree[key].sort((a, b) => compareDates(a.item.createdDate, b.item.createdDate)) });
  }, {});
  const flattened = Object.keys(ordered).reduce((acc, key) => {
    return [...acc, ...ordered[key]];
  }, []);
  return flattened.map((x, i) => x.reduce({ order: i + 1, subtreeOrder: i + 1 }));
}

export interface Orderable {
  id: number;
  order: number;
  parentId: number;
}

export class TreeItem<T extends Orderable> {
  depth = 0;
  path: number[] = [];
  subtreeOrder = 0;
  sortOrder = 0;
  storedOrder = '';
  treeOrder = 0;
  index = 0; // overall order within the flattened tree

  static Build<T extends Orderable>(item: T): TreeItem<T> {
    return <TreeItem<T>>{
      item: item,
      id: item.id,
      order: item.order,
      parentId: item.parentId
    };
  }

  constructor(public item: T) { }

  //* Tagged template literal function to handle the basic formatting of order displays
  static orderFormat( strings, ...values ) {
    return `${ strings.map( ( string, index ) => index === 0
      ? string
      : `${ values[ index - 1 ] ?? ''}${ string }`
    ).join( '' ) }.`
  }

  get displayOrderLetters(): string {
    return this.fullPath.reduce(
      ( acc, item, index ) => index === 0
        ? TreeItem.orderFormat`${ item }`
        : TreeItem.orderFormat`${ acc }${ findCharCodeAtIndex( item - 1 ) }`,
      ''
    )
  }

  get displayOrderNumbers(): string {
    return this.fullPath.reduce(
      ( acc, item ) => TreeItem.orderFormat`${ acc }${ item }`,
      ''
    )
  }

  //* Display only the last element in the path for a given entry
  get displayOrderLettersOnly(): string {
    const length = this.fullPath.length
    const input = this.fullPath[ length - 1 ]
    //* Check if the input (last index) is even, if so convert to letter
    const convertToLetter = length % 2 === 0

    if ( convertToLetter )
      return TreeItem.orderFormat`${ findCharCodeAtIndex( input - 1 ) }`

    return TreeItem.orderFormat`${ input }`
  }

  //* Definition for Roman Numeral ordering
  //*  I A 1 a (Roman numeral, uppercase letter, number, lowercase letter)
  get displayOrderRomanNumerals(): string {
    const length = this.fullPath.length
    const input = this.fullPath[ length - 1 ]
    const remainder = length % 4
    const symbolType = remainder === 0
      ? 4
      : remainder

    switch( symbolType ) {
      case 4:
        return TreeItem.orderFormat`${ findCharCodeAtIndex( input - 1 ) }`
      case 3:
        return TreeItem.orderFormat`${ input }`
      case 2:
        return TreeItem.orderFormat`${ findCharCodeAtIndex( input - 1 ).toUpperCase() }`
      case 1:
        return TreeItem.orderFormat`${ toRomanNumeral( input ) }`
      default:
        return ''
    }
  }

  get hasParent(): boolean {
    return this.parentId ? true : false;
  }

  set id(value: number) {
    this.item.id = value;
  }

  get id(): number {
    return this.item.id;
  }

  get fullPath(): number[] {
    return [...this.path, this.subtreeOrder];
  }

  set parentId(value: number) {
    this.item.parentId = value;
  }

  get parentId(): number {
    return this.item.parentId;
  }

  set order(value: number) {
    this.item.order = value;
  }

  get order(): number {
    return this.item.order;
  }

  reduce(props: any = {}): TreeItem<T> {
    return Object.assign(
      new TreeItem<T>(this.item),
      {
        id: this.id,
        index: this.index,
        path: this.path,
        order: this.order,
        parentId: this.parentId,
        item: this.item,
        depth: this.depth,
        subtreeOrder: this.subtreeOrder,
        sortOrder: this.sortOrder,
        storedOrder: this.storedOrder,
        treeOrder: this.treeOrder
      },
      props
    );
  }
}

export class Tree<T extends Orderable> {
  _activeId = 0;
  _activeIndex = -1;
  treeItems: TreeItem<T>[] = [];

  static FindParent<T extends Orderable>(items: TreeItem<T>[], item: TreeItem<T>): TreeItem<T> {
    return items.find(x => x.id === item.parentId) || new TreeItem<T>(<T>{});
  }

  static Build<T extends Orderable>(items: T[], ctor: TypeConstructor<T>): Tree<T> {
    const treeItems = items.map(x => {
      const siblings = x.parentId ? items.filter(y => y.parentId === x.parentId) : items.filter(y => falsy(y.parentId));
      const order = siblings.filter(y => y.order < x.order).length + 1;
      return Object.assign(new TreeItem<T>(x), { order });
    });
    const tree = new Tree<T>(Tree.DeriveIndexFromOrder(treeItems), ctor);

    return tree;
  }

  static BuildWithIndexes<T extends Orderable>(treeItems: TreeItem<T>[], ctor: TypeConstructor<T>): Tree<T> {
    const tree = new Tree<T>(Tree.DeriveOrderFromIndex(treeItems), ctor);
    return tree;
  }

  static DeriveOrderFromIndex<T extends Orderable>(treeItems: TreeItem<T>[]): TreeItem<T>[] {
    const items = treeItems.map(x => {
      const siblings = x.parentId ? treeItems.filter(y => y.parentId === x.parentId) : treeItems.filter(y => falsy(y.parentId));
      const order = siblings.filter(y => y.index < x.index).length + 1;
      return x.reduce({ order });
    });
    
    return items;
  }

  static DeriveIndexFromOrder<T extends Orderable>(treeItems: TreeItem<T>[]): TreeItem<T>[] {
    const o = Tree.GetOrderings(treeItems);
    const data = o.reduce((acc, arr, i) => {
      const k = acc.length;
      const mapped = toArray(arr).map((item, j) => item.reduce({ treeOrder: i + 1 }));
      return [...acc, ...mapped];
    }, []);
    
    return data;
  }

  static GetOrderings<T extends Orderable>(treeItems: TreeItem<T>[]): TreeItem<T>[][] {
    const trees = treeItems.reduce((acc, item) => {
      const treeId = item.parentId || 0;
      const subitems = acc[treeId] || [];
      acc[treeId] = [...subitems, item];
      return acc;
    }, {});
    return Tree.GetSubtreeOrderings(treeItems).map(parentId => toArray(trees[parentId]).sort((a, b) => compareNumbers(a.order, b.order)));
  }

  static GetSubtreeOrderings<T extends Orderable>(treeItems: TreeItem<T>[], parentId = 0): number[] {
    const children = treeItems.filter(x => (parentId ? x.parentId === parentId : falsy(x.parentId)));
    return children.length > 0
      ? children
        .sort((a, b) => compareNumbers(a.order, b.order))
        .reduce(
          (acc, y) => {
            return [...acc, ...Tree.GetSubtreeOrderings(treeItems, y.id)];
          },
          [parentId]
        )
      : [];
  }

  static GetPowerByLevel<T extends Orderable>(treeItems: TreeItem<T>[]): number[] {
    const levels = arrayDistinct(treeItems.map(x => x.depth)).sort((a, b) => compareNumbers(a, b));
    return levels.filter(level => level !== 0).reduce((acc, level) => {
      const maxSubtreeOrderAtDepth = Math.max(...treeItems.filter(x => x.depth === level).map(x => x.subtreeOrder));
      const power = countDigits(maxSubtreeOrderAtDepth) + acc[acc.length - 1];
      return [...acc, power];
    }, [0]);
  }

  constructor(private _treeItems: TreeItem<T>[], public ctor: TypeConstructor<T>) {
    const items = this._treeItems.map(x => x.reduce({ depth: this.getDepth(x) }));
    const grouped = groupItems(items);
    const ordered = Object.keys(grouped).reduce((acc, key) => Object.assign({}, acc, { [key]: orderSubtree(grouped[key]) }), {});
    const flattened = Object.keys(ordered).reduce((acc, key) => [...acc, ...ordered[key]], []);
    const treeItems = flattened.map(x => x.reduce({ sortOrder: this.getSortOrderWithinTree(flattened, x, Tree.GetPowerByLevel(flattened), x.depth) }))
    .sort((a, b) => compareNumbers(a.sortOrder, b.sortOrder));

    this.treeItems = treeItems.map((x, index) => x.reduce({ index, path: this.assignPath(treeItems, x) }));
  }

  get activeId(): number {
    return this._activeId;
  }

  set activeId(id: number) {
    this._activeId = id;
    this._activeIndex = this.getIndexById(id);
  }

  get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(index: number) {
    this._activeIndex = index;
    this._activeId = this.getIdByIndex(index);
  }

  get copy(): Tree<T> {
    return Object.assign(new Tree<T>(this._treeItems, this.ctor), this);
  }

  get instance(): T {
    return new this.ctor() || <T>{};
  }

  get lastParentIndex(): number {
    const parents = this.parents.sort((a, b) => compareNumbers(a.index, b.index));
    return parents.length > 0 ? parents[parents.length - 1].index : 0;
  }

  get length(): number {
    return toArray(this._treeItems).length;
  }

  get next(): TreeItem<T> {
    return this.getNext(this.activeIndex);
  }

  get nextId(): number {
    return this.next.id;
  }

  get nextIndex(): number {
    return this.activeIndex + 1;
  }

  get parents(): TreeItem<T>[] {
    return this.treeItems.filter(x => !x.parentId);
  }

  get previous(): TreeItem<T> {
    return this.getPrevious(this.activeIndex);
  }

  get previousId(): number {
    return this.previous.id;
  }

  get previousIndex(): number {
    return this.activeIndex - 1;
  }

  get items(): T[] {
    return this._treeItems.map(x => x.item);
  }

  get orderedItems(): TreeItem<T>[] {
    return this.treeItems.sort((a, b) => compareNumbers(a.index, b.index));
  }

  assignPath(items: TreeItem<T>[], item: TreeItem<T>, path = []): number[] {
    if (!item.parentId) {
      return path;
    }
    const parent = items.find(x => x.id === item.parentId);
    return this.assignPath(items, parent, [parent.subtreeOrder, ...path]);
  }

  countDescendants(parentId): number {
    return this.getDescendants(parentId).length + 1;
  }

  findByIndex(index: number): TreeItem<T> {
    return this.treeItems.find(x => x.index === index);
  }

  getChildren(parentId?: number): TreeItem<T>[] {
    return this.treeItems.filter(x => (falsy(parentId) ? falsy(x.parentId) : x.parentId === parentId));
  }

  getDescendants(parentId?: number): TreeItem<T>[] {
    const children = this.getChildren(parentId);
    return children.reduce((acc, x) => {
      return [...acc, ...this.getChildren(x.id)];
    }, children);
  }

  getDepth(item: TreeItem<T>): number {
    return item.parentId ? this.getDepth(this.getItemById(item.parentId)) + 1 : 0;
  }

  getNext(index: number): TreeItem<T> {
    return this.getItemByIndex(index + 1);
  }

  getNextId(index: number): number {
    return this.getNext(index).id;
  }

  getPrevious(index: number): TreeItem<T> {
    return this.getItemByIndex(index - 1);
  }

  getPreviousId(index: number): number {
    return this.getPrevious(index).id;
  }

  getSortOrderWithinTree(items: TreeItem<T>[], item: TreeItem<T>, powers: number[], depth = 0): number {
    return item.parentId && depth > 0
      ? roundToDecimalPlace(Math.pow(10, -1 * powers[depth]) * item.subtreeOrder
        + this.getSortOrderWithinTree(items, items.find(x => x.id === item.parentId), powers, depth - 1), powers[depth])
      : item.subtreeOrder;
  }

  getSortOrder(item: TreeItem<T>, depth = 0): number {
    return item.parentId && depth > 0
      ? roundToDecimalPlace(Math.pow(10, -1 * depth) * item.order + this.getSortOrder(this.getItemById(item.parentId), depth - 1), depth)
      : item.order;
  }

  getSubtree( parentId?: number ): Tree<T> {
    const items = this.getDescendants( parentId ).map(( treeItem ) => treeItem.item )
    const parentItem = this.getItemById( parentId ).item

    parentItem.parentId = null

    const subtree = Tree.Build( [ parentItem, ...items ], this.ctor )

    return subtree
  }

  isDescendant(id: number, parentId?: number): boolean {
    return this.getDescendants(parentId).findIndex(x => x.id === id) !== -1;
  }

  isInSubtree(id: number, parentId?: number): boolean {
    return id === parentId || this.isDescendant(id, parentId);
  }

  reorderOn(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    if (source.index === target.index) {
      return this;
    }
    return source.index < target.index ? this.reorderOnDown(source, target) : this.reorderOnUp(source, target);
  }

  reorderOnUp(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    const items = this.treeItems.map(x =>
      x.reduce({
        parentId: x.id === source.id ? target.id : x.parentId,
        index: this.isInSubtree(x.id, source.id)
          ? target.index + 1 + x.index - source.index
          : x.index < source.index && x.index > target.index
            ? x.index + this.countDescendants(source.id)
            : x.index
      })
    );
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  reorderOnDown(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    const items = this.treeItems.map(x => {
      return x.reduce({
        parentId: x.id === source.id ? target.id : target.parentId === source.id && x.parentId === source.id ? null : x.parentId,
        index: this.isInSubtree(x.id, source.id)
          ? target.index + x.index - source.index
          : x.index > source.index && x.index <= target.index
            ? x.index - this.countDescendants(source.id)
            : x.index
      });
    });
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  reorderBefore(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    if (source.index === target.index) {
      return this;
    }
    return source.index < target.index ? this.reorderBeforeDown(source, target) : this.reorderBeforeUp(source, target);
  }

  reorderBeforeUp(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    const items = this.treeItems.map(x => {
      return x.reduce({
        parentId: x.id === source.id ? target.parentId : x.parentId,
        index: this.isInSubtree(x.id, source.id)
          ? target.index + x.index - source.index
          : x.index < source.index && x.index >= target.index
            ? x.index + this.countDescendants(source.id)
            : x.index
      });
    });
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  reorderBeforeDown(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    const items = this.treeItems.map(x =>
      x.reduce({
        parentId: x.id === source.id ? target.parentId : x.parentId,
        index: this.isInSubtree(x.id, source.id)
          ? target.index - 1 + x.index - source.index
          : x.index < source.index && x.index >= target.index
            ? x.index + this.countDescendants(source.id)
            : x.index
      })
    );
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  reorderAfter(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    if (source.index === target.index) {
      return this;
    }
    return source.index < target.index ? this.reorderAfterDown(source, target) : this.reorderAfterUp(source, target);
  }

  reorderAfterUp(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    const items = this.treeItems.map(x =>
      x.reduce({
        parentId: x.id === source.id ? target.parentId : x.parentId,
        index: this.isInSubtree(x.id, source.id)
          ? target.index + 1 + x.index - source.index
          : x.index < source.index && x.index > target.index
            ? x.index + this.countDescendants(source.id)
            : x.index
      })
    );
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  reorderAfterDown(source: TreeItem<T>, target: TreeItem<T>): Tree<T> {
    const items = this.treeItems.map(x => {
      const parentId =
        x.id === source.id ? (x.id === target.parentId ? null : target.parentId) : this.isDescendant(target.id, source.id) && x.parentId === source.id ? null : x.parentId;
      return x.reduce({
        parentId,
        index: this.isInSubtree(x.id, source.id)
          ? target.index + x.index - source.index
          : x.index > source.index && x.index <= target.index
            ? x.index - this.countDescendants(source.id)
            : x.index
      });
    });
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  addToEnd(source: TreeItem<T>): Tree<T> {
    const tree = this.reorderAfterDown(source, this.findByIndex(this.treeItems.length - 1));
    const items = tree.treeItems.map(x => {
      return x.reduce({
        parentId: x.id === source.id ? null : x.parentId
      });
    });
    return Tree.BuildWithIndexes(items, this.ctor);
  }

  getIdByIndex(index: number): number {
    const item = this.getItemByIndex(index);
    return item.id;
  }

  private getIndexById(id: number): number {
    return this.getItemById(id).index;
  }

  getOrderedIndexById(id: number): number {
    const item = this.orderedItems.find(x => x.id === id);
    return item ? item.index : null;
  }

  private getItemById(id: number): TreeItem<T> {
    return this._treeItems.find(item => item.id === id) || new TreeItem<T>(this.instance);
  }

  getItemByIndex(index: number): TreeItem<T> {
    return this.treeItems.find(item => item.index === index) || new TreeItem<T>(this.instance);
  }
}
