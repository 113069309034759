const FILETYPE_EXPRESSIONS = {
    document: /\.?(doc|docm|docx|odt|pdf|rtf|txt)$/i,
    spreadsheet: /\.?(csv|xls|xlsx)$/i,
    presentation: /\.?(ppt|pptx)$/i,
    image: /\.?(bmp|gif|jpeg|jpg|png|svg|webp)$/i,
    audio: /\.?(aac|flac|m4a|mp3|ogg|wav)$/i,
    video: /\.?(3gp|avi|mkv|mov|mp4|ogv|swf|webm|wmv)$/i
}

export type FileType = keyof typeof FILETYPE_EXPRESSIONS

export const matchFileType = ( type: FileType, extention: string ) => {
    const parsedExtention = extention
        .toLowerCase()
        .trim()
    const expression = FILETYPE_EXPRESSIONS[ type ]

    return expression.test( parsedExtention )
}
