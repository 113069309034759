import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

import { PanelComponent } from './panel.component';
import { PanelMiniComponent } from './panel-mini.component';
import { PanelAltComponent } from './panel-alt.component';

@NgModule({
  declarations: [PanelComponent, PanelMiniComponent, PanelAltComponent],
  imports: [CommonModule, MatCardModule],
  exports: [PanelComponent, PanelMiniComponent, PanelAltComponent]
})
export class PanelModule {}
