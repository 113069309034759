<div class="wrapper scrollable">
  <am-print-options
    [export]="false"
    [isForAgendaItem]="true"
    [hasDescription]="false"
    (changes)="details = $event"
  ></am-print-options>
  <button class="btn-toggle" color="primary" mat-button (click)="showPreview = !showPreview">{{ showPreview ? 'Hide
    Preview' : 'Show Preview' }}</button>
  <div class="scrollable">
    <div class="preview" [@toggle]="showPreview ? 'show' : 'hide'">
      <am-agenda-item-preview [agendaItem]="agendaItem$ | async" [details]="details"></am-agenda-item-preview>
    </div>
    <form [formGroup]="form" iuForm>
      <mat-form-field>
        <input matInput placeholder="TO" id="txtTO" class="field" type="text" formControlName="to" required />
        <mat-hint>Separate multiple addresses with a semicolon ";"</mat-hint>
        <mat-error *ngIf="form.get('to').hasError('emails')">Please enter valid email addresses.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="BCC" id="txtBCC" class="field" type="text" formControlName="bcc" />
        <mat-error *ngIf="form.get('to').hasError('emails')">Please enter valid email addresses.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="CC" id="txtCC" class="field" type="text" formControlName="cc" />
        <mat-error *ngIf="form.get('to').hasError('emails')">Please enter valid email addresses.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Subject" id="txtSubject" class="field" type="text" formControlName="subject" />
      </mat-form-field>
      <div class="control editor-container">
        <label for="txtBody" class="control-label bold">Body:</label>
        <iu-editor id="editor2" formControlName="body" [expanded]="true" [height]="300" type="FULL" [init]="editorArgs">
        </iu-editor>
      </div>
    </form>
  </div>
  <div class="actions">
    <button mat-raised-button class="btn-cancel" mat-dialog-close>Cancel</button>
    <button *ngIf="form.valid" mat-raised-button class="btn-save" color="accent" (click)="onSend()">Send</button>
  </div>
</div>
