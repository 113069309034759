<div *ngIf="first" class="inline-tags" [class.tree]="!showAll">
  <mat-chip-list *ngIf="!showAll">
    <mat-chip class="inline-tag" [style.background]="first.colorHex">
      <span class="font-effect-outline">{{ first.name }}</span>
    </mat-chip>
  </mat-chip-list>
  <mat-chip-list *ngIf="showAll">
    <mat-chip *ngFor="let tag of allTags" class="inline-tag" [style.background]="tag.colorHex">
      <span class="font-effect-outline">{{ tag.name }}</span>
    </mat-chip>
  </mat-chip-list>
  <span *ngIf="!showAll && hasMore" class="more" mat-mini-fab [matTooltip]="moreText" matTooltipPosition="right"
    aria-label="Button that displays a tooltip when focused or hovered over">+{{ more }}</span>
</div>
