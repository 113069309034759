<form [formGroup]="form">
  <mat-form-field>
    <input matInput [matDatepicker]="picker1" [placeholder]="startLabel" formControlName="startDate">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput [matDatepicker]="picker2" [placeholder]="endLabel" formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
</form>
