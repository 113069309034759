<div class="tags-preview">
    <span
        class="tags-break"
        [style.display]="tags.length ? 'inline-block' : 'none'"
    >
        &nbsp;-
    </span>
    <span
        *ngFor="let tag of tags"
        class="tag"
        [style.background]="tag.colorHex"
    >
        {{ tag.name }}
    </span>
</div>
