import { Collection, QueryModel, Metadata, DateHelper, DateRange, build, compareNumbers, toInt, toArray, arrayDistinct, atLeastOneAlphaNumericValidator, falsy } from '@caiu/library';
import { Validators } from '@angular/forms';

import { Agenda } from '../agendas/agendas.model';
import { EmailItem } from '../email/email.model';
import { GroupMember, Group } from '../groups/groups.model';
import { BaseEntity, Account } from '../shared/models';
import { buildDateRange } from '../shared/utils';

const RecurrenceTypeBase = {
  Day: 1,
  Week: 2,
  Month: 3,
  Year: 4
} as const

const RecurrenceType = {
  ...RecurrenceTypeBase,
  Custom: 5
} as const

const CustomRecurrenceWeekdays = {
  M: 1 << 0,
  T: 1 << 1,
  W: 1 << 2,
  Th: 1 << 3,
  Fr: 1 << 4,
  Sa: 1 << 5,
  Su: 1 << 6
} as const

type RecurrenceTypeKeys = keyof typeof RecurrenceType
type RecurrenceType = typeof RecurrenceType[RecurrenceTypeKeys]
type CustomRecurrenceTypeKeys = keyof typeof RecurrenceTypeBase
type CustomRecurrenceType = typeof RecurrenceTypeBase[CustomRecurrenceTypeKeys]
type CustomRecurrenceWeekdaysKeys = keyof typeof CustomRecurrenceWeekdays
type CustomRecurrenceWeekdays = number

export class RecurringMeeting extends BaseEntity {
  id = 0
  startDate: Date = new Date()
  endDate: Date = new Date()
  recurrenceType: RecurrenceType | 0 = 0
  customRecurrenceType: CustomRecurrenceType | 0 = 0
  customRecurrenceCound = 0
  customRecurrenceWeekdays: CustomRecurrenceWeekdays = 0
  agendaTemplate = 0
  Meetings: Meeting[] = []
}

export class Meeting extends BaseEntity {
  id = 0;
  account: Account = new Account();
  accountId = 0;
  accountName = '';
  agendaIds: number[] = [];
  attendanceTakerId = 0;
  comments = '';
  conferenceId = 0;
  _durationHours: number;
  _durationMinutes: number;
  email: EmailItem[] = [];
  endHour: number;
  endMinutes = 0;
  endMeridian: 'AM' | 'PM' = 'PM';
  generalInfo = '';
  groupId = 0;
  groupMembers: GroupMember[] = [];
  groupName = '';
  groups: Group[] = [];
  location = '';
  markedForDelete: Date = null;
  minuteTakerId = 0;
  name = '';
  securityStatusId = 0;
  securityStatusName = '';
  startHour = 12;
  startMinutes = 0;
  startMeridian: 'AM' | 'PM' = 'PM';
  typeId = 0;
  videoConferenceUrl = '';
  voteTakerId = 0;
  recurringMeetingId: number | null = null
  recurringMeetingDetails: RecurringMeeting | null = null
  _agendas: Agenda[] = [];
  _date: Date = new Date();

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        ...this.ignore,
        'id',
        '_agendas',
        '_date',
        '_durationHours',
        '_durationMinutes',
        'account',
        'accountName',
        'agendaIds',
        'agendas',
        'createdByName',
        'email',
        'endDateTime',
        'endHour',
        'endMeridian',
        'endMinutes',
        'endTime',
        'generalInfo',
        'groups',
        'groupMembers',
        'lastModifiedByName',
        'Location',
        'markedForDelete',
        'outlineId',
        'securityStatusName',
        'startDateTime',
        'startTime'
      ],
      date: {
        validators: [Validators.required]
      },
      name: {
        validators: [Validators.required, Validators.maxLength(75), atLeastOneAlphaNumericValidator]
      },
      securityStatusId: {
        validators: [Validators.required]
      },
      startTimeHour: {
        validators: [Validators.required]
      },
      startTimeMinutes: {
        validators: [Validators.required]
      }
    });
  }

  set agendas(value: Agenda[]) {
    this._agendas = value;
    this.agendaIds = toArray(value).sort((a, b) => compareNumbers(a.displayOrder, b.displayOrder)).map(x => x.id);
  }

  get agendas(): Agenda[] {
    return toArray(this._agendas).sort((a, b) => compareNumbers(a.displayOrder, b.displayOrder));
  }

  set date(value: Date) {
    this._date = value;
  }

  get date(): Date {
    return new Date(this._date);
  }

  set durationHours(value: number) {
    this._durationHours = value;
    const endHour = this.startHour + value + (this.startMeridian === 'PM' ? 12 : 0);
    this.endHour = endHour % 12 || 12;
    this.endMeridian = endHour >= 12 ? 'PM' : 'AM';
  }

  get durationHours(): number {
    if (this._durationHours) {
      return this._durationHours;
    } else if (this.endHour && this.endMeridian) {
      if (this.endMeridian === this.startMeridian) {
        return Math.max(this.startMinutes <= this.endMinutes ? (this.endHour % 12) - (this.startHour % 12) : (this.endHour % 12) - (this.startHour % 12) - 1, 0);
      } else if (this.startMeridian === 'AM') { // Start Meridian = AM, End Meridian = PM
        return Math.max(
          this.startMinutes <= this.endMinutes ?
            (this.endHour === 12 ? this.endHour - this.startHour : 12 - this.startHour + this.endHour)
            : (this.endHour === 12 ? this.endHour - this.startHour - 1 : 12 - this.startHour + this.endHour - 1)
          , 0); // 12 - this.startHour + (this.endHour % 12) - 1
      } else { // Start Meridian = PM, End Meridian = AM
        return Math.max(
          this.startMinutes <= this.endMinutes ? (this.endHour === 12 ? this.endHour - this.startHour : this.endHour + 12 - this.startHour)
            : (this.endHour === 12 ? this.endHour - this.startHour - 1 : (this.endHour + 12 - this.startHour) - 1)
          , 0);
      }
    }
    return this._durationHours === 0 ? 0 : 1;
  }

  set durationMinutes(value: number) {
    this._durationMinutes = value;
    const endMinutes = this.startMinutes + value;
    const endHour = this.startHour + (typeof (this._durationHours) === 'number' ? this._durationHours : this.durationHours);
    this.endMinutes = endMinutes % 60;
    this.endHour = endMinutes > 60 ? (endHour % 12 || 12) + 1 : (endHour % 12 || 12);
  }

  get durationMinutes(): number {
    if (this._durationMinutes) {
      return this._durationMinutes;
    } else if (this.endHour && this.endMeridian) {
      return this.startMinutes <= this.endMinutes ? this.endMinutes - this.startMinutes : 60 - this.startMinutes + this.endMinutes;
    }
    return 0;
  }

  get startDateTime(): Date {
    const hours = this.startMeridian === 'AM' ? this.startHour % 12
      : this.startHour === 12 ? 12 : this.startHour + 12;
    const minutes = this.startMinutes;
    return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), hours, minutes);
  }

  set startTime(value: string) {
    if (value && value.substring) {
      const hour = toInt(value.substring(0, 2));
      this.startHour = hour % 12 || 12;
      this.startMinutes = toInt(value.substring(3, 5));
      this.startMeridian = hour >= 12 ? 'PM' : 'AM';
    }
  }

  get startTime(): string {
    const minutes = this.startMinutes < 10 ? `0${this.startMinutes || '0'}` : this.startMinutes.toString();
    const militaryHours = this.startMeridian === 'AM' ? (this.startHour % 12)
      : this.startHour === 12 ? 12 : this.startHour + 12;
    const hours = militaryHours < 10 ? `0${militaryHours}` : militaryHours.toString();
    return `${hours}:${minutes}`;
  }

  get endDateTime(): Date {
    return DateHelper.BuildEndTime(this.startDateTime, this.durationHours, this.durationMinutes);
  }

  set endTime(value: string) {
    if (value && value.substring) {
      const hour = toInt(value.substring(0, 2));
      this.endHour = hour % 12 || 12;
      this.endMinutes = toInt(value.substring(3, 5));
      this.endMeridian = hour >= 12 ? 'PM' : 'AM';
    }
  }

  get endTime(): string {
    const d = this.endDateTime;
    const minutes = d.getMinutes() < 10 ? `0${d.getMinutes() || '0'}` : d.getMinutes().toString();
    const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours().toString();
    return `${hours}:${minutes}`;
  }
}

export class Meetings extends Collection<Meeting> {
  dashboard: number[] = [];

  constructor() {
    super(Meeting);
  }

  update(data: Meeting | Meeting[]): Meetings {
    return build(Meetings, super.update(data));
  }
}

export class MeetingsQuery extends QueryModel<Meeting> {
  accountId = 0;
  _dateRange: DateRange = new DateRange();
  _dateRangeId = 3;
  groupId = 0;
  params = {};
  take = 10;
  term = '';

  get metadata(): Metadata {
    return build(Metadata, {
      controls: ['dateRange'],
      ignore: ['_dateRangeId', '_dateRange', 'accountId', 'endDate', 'fields', 'filters', 'groups', 'params', 'month', 'skip', 'sort', 'startDate', 'take', 'today', 'userId', 'year']
    });
  }

  get dateRangeId(): number {
    return this._dateRangeId;
  }

  set dateRangeId(value: number) {
    this._dateRangeId = value;
    if (value !== 1) {
      this.dateRange = buildDateRange(value);
    }
  }

  get dateRange(): DateRange {
    return this._dateRange;
  }

  set dateRange(value: DateRange) {
    this._dateRange = value;
    this.dateRangeId = 1;
  }

  set startDate(value: Date) {
    this.dateRange.startDate = value;
  }

  get startDate(): Date {
    return new Date(this.dateRange.startDate);
  }

  set endDate(value: Date) {
    this.dateRange.endDate = value;
  }

  get endDate(): Date {
    return new Date(this.dateRange.endDate);
  }
}

export class MeetingRow {
  constructor(public meeting: Meeting, public accountUrl) {
  }

  get agendas(): Agenda[] {
    return this.meeting.agendas.filter(x => falsy(x.markedForDelete));
  }

  get groups(): string[] {
    return arrayDistinct(toArray(this.agendas).reduce((acc, x) => {
      return [...acc, x.groupName];
    }, []));
  }

  get locationName(): string {
    return this.meeting.location;
  }

  get meetingDate(): Date {
    return this.meeting.date;
  }

  get meetingId(): number {
    return this.meeting.id;
  }

  get meetingLink(): string {
    return `/${this.accountUrl}/meetings/${this.meetingId}`;
  }

  get meetingName(): string {
    return this.meeting.name;
  }

  get startTime(): Date {
    const date = new Date(this.meeting.date);
    const hours = this.meeting.startMeridian === 'AM' ? this.meeting.startHour : this.meeting.startHour === 12 ? 12 : this.meeting.startHour + 12;
    const minutes = this.meeting.startMinutes;
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  get minutes(): number {
    return this.agendas.filter( ( agenda ) => agenda.displayMinutes ).length
  }
}
