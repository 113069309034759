import { build, Action, toInt, Collection, inArray, routeParamIdSelector, toArray, querySelector, QueryModel, routeParamSelector, compareStrings, arrayDistinct } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';

import { accountIdSelector, accountUrlSelector, accountsSelector, userIsSysAdminSelector } from '../accounts/accounts.reducer';
import { GroupMember, GroupMembers, Group, GroupMemberEdit } from '../groups/groups.model';
import { GroupActions, GroupsActions, groupsSelector } from '../groups/groups.reducer';
import { AccountMember, AccountMembers, MembersQuery, Account } from '../shared/models';
import { usersSelector, currentUserIdSelector, sortDirectionSelector } from '../shared/selectors';
import { UserActions } from '../shared/actions';
import { agendaGroupIdSelector, agendaIdGroupIdSelector, agendaMinuteTakerIdSelector, agendaVoteTakerIdSelector, agendaAttendanceTakerIdSelector } from '../agendas/agendas.reducer';

export class AccountMembersActions {
  static ACTIVATE = '[Account Members] Activate';
  static CHANGE_ACCOUNT = '[Account Members] Change Account';
  static GET = '[Account Members] Get';
  static GET_GROUP_ADMINS = '[Account Members] Get Group Admins';
  static GET_USER_ACCOUNTS = '[Account Member] Get User Accounts';
  static POST = '[Account Members] Post';
  static POST_ERROR = '[Account Members] Post Error';
  static RECOVER_PASSWORD = '[Account Members] Recover Password';
  static RECOVER_PASSWORD_ERROR = '[Account Members] Recover Password Error';

  static activate(id: number): Action {
    return {
      type: AccountMembersActions.ACTIVATE,
      payload: id
    };
  }

  static changeAccount(accountId: number): Action {
    return {
      type: AccountMembersActions.CHANGE_ACCOUNT,
      payload: accountId
    };
  }
}

export class AccountMemberActions {
  static GET = '[Account Member] Get';
  static POST = '[Account Member] Post';
  static POST_ERROR = '[Account Member] Post Error';
  static PUT = '[Account Member] Put';
  static PUT_ERROR = '[Account Member] Put Error';
}

export class GroupMembersActions {
  static GET = '[Group Members] Get';
  static GET_GROUP = '[Group Members] Get Group';
  static DELETE = '[Group Members] Delete';
  static DELETE_ERROR = '[Group Members] Delete Error';
  static POST = '[Group Members] Post';
  static POST_ERROR = '[Group Members] Post Error';
  static GET_ACCOUNT_GROUPS = '[Group Members] Get Account Groups';
  static UPDATE_USER_GROUPS = '[Group Members] Update User Groups';
}

export function accountMembersReducer(state = new AccountMembers(), action: Action): AccountMembers {
  switch (action.type) {

    case UserActions.LOGIN_SUCCESS:
      return state.login(action.payload.user.id, action.payload.user.userAccounts);

    case AccountMembersActions.GET:
      return state.updateSearch(action.payload.results, action.payload.total);

    case AccountMembersActions.GET_USER_ACCOUNTS:
      return state.update(action.payload);

    case AccountMembersActions.GET_GROUP_ADMINS:
      return state.update(action.payload);

    case AccountMembersActions.POST:
    case AccountMemberActions.PUT:
      return state.updateMember(action.payload);

    case AccountMemberActions.GET:
      return state.update([action.payload]);

    default:
      return state;
  }
}

export function groupMembersReducer(state = new GroupMembers(), action: Action): GroupMembers {
  switch (action.type) {

    case GroupMembersActions.GET:
    case GroupMembersActions.UPDATE_USER_GROUPS:
      return state.update(action.payload.results);

    case GroupMembersActions.GET_GROUP:
    case GroupMembersActions.POST:
    case GroupsActions.GET_USER_GROUPS:
      return state.update(action.payload);

    case GroupsActions.GET:
      return state.updateForGroups(toArray(action.payload));

    case GroupActions.GET:
    case GroupActions.PUT:
      return state.updateForGroup(action.payload.id, action.payload.members);

    case GroupsActions.GET_ACCOUNT_GROUPS:
      return state.update(toArray(action.payload).reduce((acc, x) => x ? [...acc, ...toArray(x.members)] : acc, []));

    case AccountMemberActions.GET:
      return state.update(action.payload.groups);

    default:
      return state;
  }
}

export function accountMembersSelector(store: Store<any>): Observable<AccountMembers> {
  return store.select('accountMembers');
}

export function accountMemberSelector(store: Store<any>): Observable<AccountMember> {
  return combineLatest(accountMembersSelector(store), accountIdSelector(store), routeParamIdSelector(store, 'userId'),
    (accountMembers, accountId, userId) => build(AccountMember, accountMembers.asArray.find(x => x.accountId === accountId && x.userId === userId)));
}

export function accountMembersIdSelector(store: Store<any>): Observable<number> {
  return accountMembersSelector(store).pipe(
    map(accountMembers => toInt(accountMembers.activeId)),
    distinctUntilChanged()
  );
}

export function activeAccountMembersSelector(store: Store<any>): Observable<AccountMember[]> {
  return combineLatest(accountMembersSelector(store), accountIdSelector(store), (accountMembers, accountId) => {
    return accountMembers.asArray.filter(x => x.accountId === accountId);
  });
}

export function accountAdminsSelector(store: Store<any>): Observable<AccountMember[]> {
  return activeAccountMembersSelector(store).pipe(
    map(x => x.filter(y => inArray([2], y.accountRoleId)).sort((a, b) => compareStrings(a.displayName, b.displayName)))
  );
}

export function accountGroupAdminsSelector(store: Store<any>): Observable<AccountMember[]> {
  return activeAccountMembersSelector(store).pipe(
    map(x => x.filter(y => inArray([2, 3], y.accountRoleId)).sort((a, b) => compareStrings(a.displayName, b.displayName)))
  );
}

export function groupMembersSelector(store: Store<any>, groupId = 0, accountId = 0): Observable<GroupMembers> {
  return store.select('groupMembers');
}

export function activeGroupMembersSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest(
    routeParamIdSelector(store, 'accountId'),
    routeParamIdSelector(store, 'groupId'),
    groupMembersSelector(store),
    usersSelector(store),
    (accountId, groupId, groupMembers, users) => {
      return groupMembers.asArray.filter(x => x.accountId === accountId && x.groupId === groupId)
        .map(x => build(GroupMember, x, { user: users.get(x.userId) }));
    }
  );
}

export function agendaGroupMemberRolesSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest([
    groupMembersSelector(store),
    agendaGroupIdSelector(store),
    usersSelector(store)]).pipe(
      map(x => {
        return x[0].asArray.filter(y => y.groupId === x[1] && y.userIsActive)
          .map(y => build(GroupMember, y, { user: x[2].get(y.userId) }));
      })
    );
}

export function agendaGroupMembersSelector(store: Store<any>): Observable<GroupMemberEdit[]> {
  return agendaGroupMemberRolesSelector(store).pipe(
    map(x => GroupMemberEdit.ToRows(x))
  );
}

export function activeAgendaGroupMembersSelector(store: Store<any>): Observable<GroupMemberEdit[]> {
  return agendaGroupMemberRolesSelector(store).pipe(
    map(x => {
      return GroupMemberEdit.ToRows(x.filter(y => y.userIsActive));
    })
  );
}

export function groupIdMembersSelector(store: Store<any>, groupId$: Observable<number>): Observable<GroupMember[]> {
  return combineLatest(groupMembersSelector(store), groupId$, (groupMembers, groupId) => {
    return groupMembers.asArray.filter(x => x.groupId === groupId);
  });
}

export function accountMemberGroupsSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest(accountMemberSelector(store), groupMembersSelector(store),
    (accountMember, groupMembers) => {
      return groupMembers.asArray.filter(x => x.accountId === accountMember.accountId && x.userId === accountMember.userId);
    });
}

export function currentUserAccountsSelector(store: Store<any>): Observable<AccountMember[]> {
  return combineLatest([accountMembersSelector(store), currentUserIdSelector(store), accountsSelector(store)]).pipe(
    map(x => {
      return x[0].asArray.filter(y => y.userId === x[1] && y.isUserActive)
        .map(y => build(AccountMember, y, { account: build(Account, x[2].get(y.accountId)) }))
        .filter(y => y.account.statusId !== 2) // filter inactive accounts
        ;
    })
  );
}

export function currentUserAccountSelector(store: Store<any>): Observable<AccountMember> {
  return combineLatest(currentUserAccountsSelector(store), accountIdSelector(store), (accounts, id) => build(AccountMember, accounts.find(x => x.accountId === id)));
}

export function currentUserPrimaryAccountIdSelector(store: Store<any>): Observable<number> {
  return currentUserAccountsSelector(store).pipe(
    map(x => x.findIndex(y => y.isPrimaryAccount) === -1 ?
      toArray(x).length > 0 ?
        build(AccountMember, toArray(x)[0]).accountId : null
      : build(AccountMember, x.find(y => y.isPrimaryAccount)).accountId
    ),
    distinctUntilChanged()
  );
}

export function currentUserGroupMembershipsSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest([groupMembersSelector(store), currentUserIdSelector(store), accountIdSelector(store)]).pipe(
    map(x => {
      return x[0].asArray.filter(y => y.userId === x[1] && y.accountId === x[2] && y.groupIsActive).sort((a, b) => compareStrings(a.groupName, b.groupName));
    })
  );
}

export function currentUserGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest([currentUserGroupMembershipsSelector(store), groupsSelector(store)]).pipe(
    map(x => arrayDistinct(x[0].map(y => y.groupId)).map(id => build(Group, x[1].get(id), { id })))
  );
}

export function activeUserGroupMembershipsSelector(store: Store<any>): Observable<GroupMember[]> {
  return combineLatest([groupMembersSelector(store), routeParamIdSelector(store, 'userId'), accountIdSelector(store)]).pipe(
    map(x => x[0].asArray.filter(y => y.userId === x[1] && y.accountId === x[2] && y.groupIsActive).sort((a, b) => compareStrings(a.groupName, b.groupName)))
  );
}

export function activeUserGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest([activeUserGroupMembershipsSelector(store), groupsSelector(store)]).pipe(
    map(x => arrayDistinct(x[0].map(y => y.groupId)).map(id => build(Group, x[1].get(id), { id })))
  );
}

export function currentUserManagerGroupsSelector(store: Store<any>): Observable<Group[]> {
  return combineLatest(currentUserGroupMembershipsSelector(store), groupsSelector(store), currentUserIdSelector(store), userIsSysAdminSelector(store),
    (memberships, groups, userId, userIsSysAdmin) => {
      return userIsSysAdmin ? groups.asArray
        : arrayDistinct(
          [...memberships.filter(x => x.groupRoleId === 1).map(x => x.groupId),
          ...groups.asArray.filter(x => x.administratorId === userId).map(x => x.id)
          ])
          .map(id => build(Group, groups.get(id), { id }))
          .filter(x => x.isActive)
          .sort((a, b) => compareStrings(a.name, b.name));
    }
  );
}

export function currentUserAccountRoleIdSelector(store: Store<any>): Observable<number> {
  return currentUserAccountSelector(store).pipe(
    map(x => x.accountRoleId),
    distinctUntilChanged()
  );
}

export function isGroupAdminSelector(store: Store<any>): Observable<boolean> {
  return currentUserAccountRoleIdSelector(store).pipe(
    map(x => x < 4),
    distinctUntilChanged()
  );
}

export function isAccountAdminSelector(store: Store<any>): Observable<boolean> {
  return currentUserAccountRoleIdSelector(store).pipe(
    map(x => x < 3),
    distinctUntilChanged()
  );
}

export function membersQuerySelector(store: Store<any>): Observable<MembersQuery> {
  return combineLatest(
    querySelector(store).pipe(startWith(build(MembersQuery, {}))),
    accountIdSelector(store),
    accountUrlSelector(store),
    routeParamIdSelector(store, 'groupId').pipe(startWith(0)),
    routeParamSelector(store, 'showInactive').pipe(startWith(false)),
    routeParamSelector(store, 'sortBy', 'lastName').pipe(startWith('lastName')),
    sortDirectionSelector(store),
    routeParamSelector(store, 'term'),
    (query, accountId, accountUrl, groupId, showInactive, sortBy, sortDirection, term) => {
      return build(MembersQuery, query, {
        accountId,
        accountUrl,
        groupId,
        showInactive: showInactive === 'true' ? true : false,
        sortBy,
        sortDirection,
        term
      });
    });
}

export function activeUserAccountRoleIdSelector(store: Store<any>): Observable<number> {
  return accountMemberSelector(store).pipe(
    map(x => x.accountRoleId),
    distinctUntilChanged()
  );
}

export function activeUserIsSysAdminSelector(store: Store<any>): Observable<boolean> {
  return activeUserAccountRoleIdSelector(store).pipe(
    map(x => x === 1),
    distinctUntilChanged()
  );
}

export function accountMembersSearchTotalSelector(store: Store<any>): Observable<number> {
  return accountMembersSelector(store).pipe(
    map(x => x.total),
    distinctUntilChanged()
  );
}

export function isGroupMemberSelector(store: Store<any>): Observable<boolean> {
  return combineLatest([currentUserGroupMembershipsSelector(store), agendaGroupIdSelector(store), currentUserIdSelector(store)]).pipe(
    map(x => {
      return x[0].findIndex(y => y.groupId === x[1]) !== -1;
    })
  );
}

export function isContributorSelector(store: Store<any>): Observable<boolean> {
  return isAgendaContributorSelector(store, agendaGroupIdSelector(store));
}

export function isAgendaContributorSelector(store: Store<any>, agendaId$: Observable<number>): Observable<boolean> {
  return combineLatest([currentUserGroupMembershipsSelector(store), agendaIdGroupIdSelector(store, agendaId$)]).pipe(
    map(x => x[0].findIndex(y => y.groupId === x[1] && (y.groupRoleId === 2 || y.groupRoleId === 1)) !== -1)
  );
}

export function isAttendanceTakerSelector(store: Store<any>): Observable<boolean> {
  return combineLatest([currentUserGroupMembershipsSelector(store), agendaGroupIdSelector(store), currentUserIdSelector(store), agendaAttendanceTakerIdSelector(store)]).pipe(
    map(x => x[3] ? x[3] === x[2] : x[0].findIndex(y => y.groupId === x[1] && y.groupRoleId === 4) !== -1)
  );
}

export function isMinuteTakerSelector(store: Store<any>): Observable<boolean> {
  return combineLatest(currentUserGroupMembershipsSelector(store), agendaGroupIdSelector(store), currentUserIdSelector(store), agendaMinuteTakerIdSelector(store),
    (groupMembers, groupId, userId, minuteTakerId) => {
      return minuteTakerId ? minuteTakerId === userId : groupMembers.findIndex(x => x.groupId === groupId && x.groupRoleId === 5) !== -1;
    });
}

export function isVoterSelector(store: Store<any>): Observable<boolean> {
  return combineLatest([currentUserGroupMembershipsSelector(store), agendaGroupIdSelector(store)])
    .pipe(
      map(x => x[0].findIndex(y => y.groupId === x[1] && y.groupRoleId === 7) !== -1)
    );
}

export function isVoteTakerSelector(store: Store<any>): Observable<boolean> {
  return combineLatest(currentUserGroupMembershipsSelector(store), agendaGroupIdSelector(store), currentUserIdSelector(store), agendaVoteTakerIdSelector(store),
    (groupMembers, groupId, userId, voteTakerId) => {
      return voteTakerId ? voteTakerId === userId : groupMembers.findIndex(x => x.groupId === groupId && x.groupRoleId === 6) !== -1;
    });
}
