import { Injectable } from '@angular/core';
import {
  actionsStreamReducer,
  configReducer,
  errorsReducer,
  eventsReducer,
  formReducer,
  lookupReducer,
  messagesReducer,
  redirectsReducer,
  routerReducer,
  sidenavReducer,
  viewSettingsReducer,
  windowReducer
} from '@caiu/library';
import { ActionReducerMap } from '@ngrx/store';

import { currentUserReducer, dateRangeReducer, groupIdReducer, searchReducer, usersReducer, viewModeReducer } from './shared/reducers';
import { accountsReducer } from './accounts/accounts.reducer';
import { agendaItemsReducer } from './agenda-items/agenda-items.reducer';
import { agendaDetailsReducer, agendasReducer } from './agendas/agendas.reducer';
import { announcementsReducer } from './announcements/announcements.reducer';
import { attachmentsReducer } from './attachments/attachments.reducer';
import { binReducer } from './bin-items/bin-items.reducer';
import { emailReducer } from './email/email.reducer';
import { groupsReducer } from './groups/groups.reducer';
import { meetingsReducer } from './meetings/meetings.reducer';
import { accountMembersReducer, groupMembersReducer } from './members/members.reducer';
import { minutesReducer } from './minutes/minutes.reducer';
import { notesReducer } from './notes/notes.reducer';
import { notificationsReducer } from './notifications/notifications.reducer';
import { tagsReducer } from './shared/tags/tags.reducer';
import { templatesReducer } from './templates/templates.reducer';
import { votesReducer } from './votes/votes.reducer';
import { attendanceReducer } from './attendance/attendance.reducer';
import { widgetsReducer } from './shared/widgets/widgets.reducer';

@Injectable({
  providedIn: 'root'
})
export class ReducersService {
  constructor() { }

  getReducers(): ActionReducerMap<any> {
    return {
      actions: actionsStreamReducer,
      currentUser: currentUserReducer,
      config: configReducer,
      errors: errorsReducer,
      events: eventsReducer,
      lookup: lookupReducer,
      messages: messagesReducer,
      redirects: redirectsReducer,
      route: routerReducer,
      widgets: widgetsReducer,
      window: windowReducer,
      accounts: accountsReducer,
      agendaDetails: agendaDetailsReducer,
      agendaItems: agendaItemsReducer,
      agendas: agendasReducer,
      announcements: announcementsReducer,
      attachments: attachmentsReducer,
      attendance: attendanceReducer,
      bin: binReducer,
      email: emailReducer,
      groups: groupsReducer,
      meetings: meetingsReducer,
      accountMembers: accountMembersReducer,
      dateRange: dateRangeReducer,
      groupId: groupIdReducer,
      groupMembers: groupMembersReducer,
      minutes: minutesReducer,
      notes: notesReducer,
      notifications: notificationsReducer,
      search: searchReducer,
      tags: tagsReducer,
      templates: templatesReducer,
      users: usersReducer,
      viewMode: viewModeReducer,
      votes: votesReducer
    };
  }
}
