<form [formGroup]="form" iuForm>
  <!-- <label [for]="id" class="control-label">{{ id }}</label> -->
  <iu-editor
    [id]="elementId"
    formControlName="minutes"
    [expanded]="true"
    type="FULL"
    [init]="editorArgs"
    [plugins]="editorPlugins"
    [toolbar]="editorToolbar"
    (onBeforeExecCommand)="changeEditor($event)"
  ></iu-editor>
</form>
