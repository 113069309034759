<div class="attendance-preview">
  <div class="attendance-margin">
    <div class="roll-call">Roll Call</div>
    <div class="row">
      <strong class="attendance-heading">Present:&nbsp;</strong>
      <span class="attendees">{{ attendeesPresent }}</span>
    </div>
    <div class="row write-ins">
      <strong class="attendance-heading">Write-ins:&nbsp;</strong>
      <span class="attendees">{{ writeIns }}</span>
    </div>
    <div class="row">
      <strong class="attendance-heading">Absent:&nbsp;</strong>
      <span class="attendees">{{ attendeesAbsent }}</span>
    </div>
    <div class="row log" *ngIf="logUpdates.length > 0">
      <strong class="attendance-heading">Attendance Updates:</strong>
      <ul>
        <li *ngFor="let update of logUpdates">{{ update }}</li>
      </ul>
    </div>
  </div>
  <div class="attendance-border"></div>
</div>
