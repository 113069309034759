<div id="bin-empty">
  <ul style="list-style:none">
    <li>&nbsp;</li>
    <li>&nbsp;</li>
    <li>
      <label>Your bin is empty!</label>
    </li>
    <li>&nbsp;</li>
    <li>&nbsp;</li>
    <li>
      <label class="label-row"> To add bin items, click on an existing agenda item to open the item in the left
        pane.</label>
    </li>
    <li>&nbsp;</li>
    <li>
      <label class="label-row">Click the "Copy To Bin" button at the top right to add the selected item to your
        bin.</label>
    </li>
    <li>&nbsp;</li>
    <li>&nbsp;</li>
    <li>&nbsp;</li>
    <li class="li-button">
      <button mat-button color="warn" mat-dialog-close>Close</button>
    </li>
    <li>&nbsp;</li>
  </ul>
</div>
