import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Vote } from '../votes.model';

@Component({
  selector: 'am-vote-results',
  templateUrl: './vote-results.component.html',
  styleUrls: ['./vote-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoteResultsComponent {

  @Input() votes: Vote[] = [];

  constructor() { }

  get totalVotes(): number {
    return this.votes.length;
  }

  get abstainCount(): number {
    return this.votes.filter(x => x.answerId === 3).length;
  }

  get nayCount(): number {
    return this.votes.filter(x => x.answerId === 2).length;
  }

  get notCastCount(): number {
    return this.votes.filter(x => x.answerId === 4).length;
  }

  get yeaCount(): number {
    return this.votes.filter(x => x.answerId === 1).length;
  }

  get motion(): Vote {
    return this.votes.find(x => x.motion);
  }

  get second(): Vote {
    return this.votes.find(x => x.second);
  }

}
