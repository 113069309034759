<div class="tags-control">
  <form>
    <!-- <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Choose Account</mat-label>
        <mat-select formControlName="accountId">
          <mat-option *ngFor="let member of accounts$ | async" [value]="member.accountId">{{ member.accountName }}
          </mat-option>
        </mat-select>
        <mat-hint>Tags for agenda items within this account.</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Choose Group</mat-label>
        <mat-select formControlName="groupId">
          <mat-option [value]="0">All Groups</mat-option>
          <mat-option *ngFor="let group of groups$ | async" [value]="group.id">{{ group.name }}</mat-option>
        </mat-select>
        <mat-hint>Tags for agenda items within this group.</mat-hint>
      </mat-form-field>
    </div> -->
    <mat-form-field class="tags-input" appearance="outline">
      <mat-chip-list #chipList aria-label="Tag Selection">
        <mat-chip *ngFor="let tag of value" [style.background]="tag.colorHex" (removed)="onRemove(tag.id)">
          <span class="font-effect-outline">{{tag.name}}</span>
          <mat-icon class="font-effect-outline" matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="Tags..." [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="onAdd($event)">
      </mat-chip-list>
    </mat-form-field>
  </form>
  <div class="tags-list scrollable" [class.empty]="tags?.length === 0">
    <mat-chip-list>
      <mat-chip class="inline-tag" cdkDrag *ngFor="let tag of tags" (click)="toggle(tag)"
        [style.background]="tag.checked ? tag.colorHex : '#fff'" [style.border-width.px]="tag.checked ? '0px' : '2px'"
        [style.border-color]="tag.colorHex" [class.checked]="tag.checked">
        <span [class.font-effect-outline]="tag.checked"
          [style.color]="tag.checked ? '#fff' : tag.colorHex">{{ tag.name }}</span>
      </mat-chip>
    </mat-chip-list>
  </div>
  <p class="no-results" *ngIf="tags?.length === 0">You have no tags.</p>
</div>
