import { Injectable } from '@angular/core';
import { RouterActions, Action } from '@caiu/library';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountsActions, AccountActions } from '../shared/actions';
import { Account } from '../shared/models';

@Injectable()
export class AccountsEffects {

  @Effect() onAdd: Observable<Action> = this.actions$.pipe(
    ofType(AccountsActions.POST, AccountActions.PUT),
    map((action: Action) => this.redirectAfterAdd(action.payload))
  );


  constructor(private actions$: Actions) { }

  redirectAfterAdd(e: Account): Action {
    return RouterActions.navigate(`/${e.url}/info`);
  }
}
