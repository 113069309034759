import { Collection, BaseEntity, build, Metadata, Time, DateHelper } from '@caiu/library';

export class Attendee extends BaseEntity {
  id = 0;
  agendaId = 0;
  _isPresent = null;
  timestamp: Date = new Date();
  userId = 0;
  userFirstName = '';
  userLastName = '';
  _userName = '';
  writeIns = '';

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        ...this.ignore,
        'agendaId',
        'userFirstName',
        'userLastName',
        'labelAbsent',
        'labelPresent',
        'labelRemoved',
        'status',
        'time',
        'userId',
        'userName',
        '_userName',
        'writeIns',
        '_isPresent'
      ]
    });
  }

  set isPresent(value: boolean) {
    // this._isPresent = value ? true : false;
    this._isPresent = value;
  }

  get isPresent(): boolean {
    // return this.id ? this._isPresent : (this._isPresent ? true : null);
    return this._isPresent;
  }

  get labelAbsent(): string {
    return this.id === 0 ? 'Absent' : this.isPresent ? 'Leave' : 'Absent';
  }

  get labelPresent(): string {
    return this.id === 0 ? 'Present' : this.isPresent ? 'Joined' : 'Join';
  }

  get labelRemoved(): string {
    return 'N/A';
  }

  get status(): string {
    return this.isPresent === null ? 'N/A' : this.isPresent === true ? 'Joined' : 'Left';
  }

  set time(value: Time) {
    const date = new Date(this.timestamp);
    date.setHours(value.meridian === 'AM' ? value.hour : value.hour + 12);
    date.setMinutes(value.minutes);
    this.timestamp = date;
  }

  get time(): Time {
    const date = new Date(this.timestamp);
    return build(Time, {
      hour: date.getHours(),
      minutes: date.getMinutes(),
      meridian: DateHelper.GetMeridian(this.timestamp)
    });
  }

  set userName(value: string) {
    this._userName = value;
    if (value) {
      const names = value.split(' ');
      this.userFirstName = names[0];
      this.userLastName = names[names.length - 1];
    }
  }

  get userName(): string {
    return this._userName;
  }
}

export class Attendance extends Collection<Attendee> {
  constructor() {
    super(Attendee);
  }

  delete(id: number): Attendance {
    return build(Attendance, super.delete(id));
  }

  replace(data: Attendee[]): Attendance {
    return build(Attendance, super.replace(data));
  }

  update(data: Attendee | Attendee[]): Attendance {
    return build(Attendance, super.update(data));
  }

  updateAttendee(data: Attendee): Attendance {
    return data.isPresent === null ? // setting attendance to N/A clears attendance log for user
      this.replace([...this.asArray.filter(x => !(x.agendaId === data.agendaId && x.userId === data.userId)), data])
      : this.update(data);
  }
}
