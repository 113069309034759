import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SmartComponent, Control, HttpActions, truthy, build, DateHelper, MessageSubscription, RouterActions, routeParamIdSelector } from '@caiu/library';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { EmailItem } from './email.model';
import { EmailActions } from './email.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';
import { Agenda } from '../agendas/agendas.model';
import { agendaDateSelector, agendaGroupIdSelector, agendaNameSelector, agendaSecurityStatusIdSelector, agendaSelector } from '../agendas/agendas.reducer';
import { GroupMemberEdit } from '../groups/groups.model';
import { GroupMembersActions, activeAgendaGroupMembersSelector } from '../members/members.reducer';
import { userEmailSelector, userNameSelector } from '../shared/selectors';
import { isValidEmail } from '../shared/utils';
import { environment } from 'src/environments/environment';
import { EDITOR_ARGS } from '../shared/models';

@Component({
  selector: 'am-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent extends SmartComponent implements OnInit {

  @Control(EmailItem) form: FormGroup;
  accountUrl = '';
  accountUrl$: Observable<string>;
  _agenda: Agenda = new Agenda();
  agenda$: Observable<Agenda>;
  agendaDate$: Observable<Date>;
  agendaName$: Observable<string>;
  agendaSecurityStatusId = null;
  agendaSecurityStatusId$: Observable<number>;
  defaultMessage$: Observable<string>;
  editorArgs = EDITOR_ARGS;
  _groupId = 0;
  groupId$: Observable<number>;
  _groupMemberEmails = '';
  groupMemberEmails$: Observable<string>;
  groupMembers: GroupMemberEdit[] = [];
  groupMembers$: Observable<GroupMemberEdit[]>;
  userEmail = '';
  userEmail$: Observable<string>;
  userName$: Observable<string>;
  messages = [
    build(MessageSubscription, {
      action: EmailActions.POST,
      channel: 'TOASTS',
      mapper: e => `Email sent successfully!`
    }),
    build(MessageSubscription, {
      action: EmailActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error sending email.`
    })
  ];

  constructor(public store: Store<any>) {
    super(store);
    this.accountUrl$ = accountUrlSelector(store);
    this.agendaSecurityStatusId$ = agendaSecurityStatusIdSelector(store);
    this.groupId$ = agendaGroupIdSelector(store);
    this.groupMembers$ = activeAgendaGroupMembersSelector(store);
    this.groupMemberEmails$ = this.groupMembers$.pipe(
      map(x => x.map(y => y.emailAddress)
        .filter(y => truthy(y) && isValidEmail(y))
        .reduce((acc, y) => `${acc}${y};`, '')),
      distinctUntilChanged()
    );
    this.agenda$ = agendaSelector(store);
    this.userEmail$ = userEmailSelector(store);
    this.defaultMessage$ = combineLatest([
      userNameSelector(store),
      agendaSelector(store),
      accountUrlSelector(store),
      routeParamIdSelector(store, 'meetingId')
    ]).pipe(
      filter(x => truthy(x[1].agendaDate)),
      map(x => {
        const txt = `${x[0]} has shared with you agenda details from the meeting
        <a href="https://app.agendamanager.com/${x[2]}/meetings/${x[3]}">${x[1].meetingName}</a>
        held on ${DateHelper.FormatDateSlashes(x[1].agendaDate)}.`;
        return truthy(x[1].videoConferenceUrl) ? `${txt}<br><br><a href="${x[1].videoConferenceUrl}">Click here for the Video Conference</a>` : txt;
      }),
      distinctUntilChanged()
    );
  }

  set agenda(value: Agenda) {
    this._agenda = value;
    this.form.get('subject').setValue(`Upcoming Meeting: ${value.meetingName}`);
  }

  get agenda(): Agenda {
    return this._agenda;
  }

  set groupId(value: number) {
    this._groupId = value;
    if (value) {
      this.getGroupMembers(value);
    }
  }

  get groupId(): number {
    return this._groupId;
  }

  set groupMemberEmails(value: string) {
    this._groupMemberEmails = value;
    this.form.get('to').setValue(value.toLowerCase().replace('creed8682@gmail.com', 'CReed8682@gmail.com'));
  }

  get groupMemberEmails(): string {
    return this._groupMemberEmails.toLowerCase().replace('creed8682@gmail.com', 'CReed8682@gmail.com');
  }

  get valueOut(): EmailItem {
    return build(EmailItem, this.form.value, {
      from: this.userEmail
    });
  }

  ngOnInit() {
    this.onInit();
    this.sync(['accountUrl', 'agendaSecurityStatusId', 'groupId', 'groupMemberEmails', 'groupMembers', 'agenda', 'userEmail']);
    this.addSubscription(this.defaultMessage$.subscribe(x => {
      this.form.get('body').setValue(x);
    }));
  }

  send() {
    this.addEmail(this.valueOut);
  }

  addEmail(emailItem: EmailItem) {
    this.httpPost(`email`, emailItem, EmailActions.POST, EmailActions.POST_ERROR).subscribe(x => {
      if (x.type === EmailActions.POST) {
        this.dispatch(RouterActions.navigate(`/${this.accountUrl}/meetings/${this.agenda.meetingId}/agendas/${this.agenda.id}`));
      }
    });
  }

  getGroupMembers(groupId: number) {
    this.dispatch(HttpActions.get(`groups/${groupId}/members`, GroupMembersActions.GET));
  }

  clear() {
    this.form.get('to').setValue('');
  }

  selectAll() {
    this.form.get('to').setValue(this.groupMemberEmails.replace('creed8682@gmail.com', 'CReed8682@gmail.com'));
  }

}
