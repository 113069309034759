export class GridFilter {
    id: number;
    column: FilterColumn;
    condition: FilterCondition;
    value: string;
}

export class FilterColumn {
    name: string;
    displayName: string;
    type = '';
}

export class FilterCondition {
    name: string;
    displayName: string;

    constructor(name: string, displayName: string) {
        this.name = name;
        this.displayName = displayName;
    }
}