import { NgModule } from '@angular/core';

import { NotesComponent } from './notes.component';
import { NotesPreviewComponent } from './notes-preview/notes-preview.component';
import { MinutesModule } from '../minutes/minutes.module';
import { SharedModule } from '../shared/shared.module';
import { PopoutModule } from '../popout/popout.module'

@NgModule( {
  imports: [
    MinutesModule,
    PopoutModule,
    SharedModule
  ],
  declarations: [
    NotesComponent,
    NotesPreviewComponent,
  ],
  exports: [
    NotesComponent,
    NotesPreviewComponent,
  ]
} )
export class NotesModule { }
