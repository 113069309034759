import { Component, Input, OnInit } from '@angular/core';
import { RouterActions, SmartComponent } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { accountSelector, accountUrlSelector, accountIdSelector } from 'src/app/accounts/accounts.reducer';
import { currentUserAccountsSelector } from 'src/app/members/members.reducer';
import { UserActions } from '../actions';
import { CurrentUser, Account } from '../models';
import { isImpersonatingSelector, currentUserSelector } from '../selectors';
import { Logger } from '../utils';

@Component({
  selector: 'am-current-user-dialog',
  templateUrl: './current-user-dialog.component.html',
  styleUrls: ['./current-user-dialog.component.scss']
})
export class CurrentUserDialogComponent extends SmartComponent implements OnInit {
  account: Account = new Account();
  account$: Observable<Account>;
  accountId$: Observable<number>;
  accounts: Account[] = [];
  accounts$: Observable<Account[]>;
  accountUrl = '';
  accountUrl$: Observable<string>;
  isImpersonating$: Observable<boolean>;
  private readonly logger = new Logger( CurrentUserDialogComponent.name )
  mouseIn = false;
  @Input() show = false;
  user: CurrentUser = new CurrentUser();
  user$: Observable<CurrentUser>;

  constructor(public store: Store<any>) {
    super(store);
    this.account$ = accountSelector(store);
    this.accountId$ = accountIdSelector(store);
    this.accountUrl$ = accountUrlSelector(store);
    this.isImpersonating$ = isImpersonatingSelector(store);
    this.user$ = currentUserSelector(store);
    this.accounts$ = currentUserAccountsSelector(store).pipe(
      map(x => x.map(y => y.account))
    );
  }

  get accountInfoDisplay(): string {
    return this.show || this.mouseIn ? 'block' : 'none';
  }

  ngOnInit() {
    this.sync(['accounts', 'accountUrl', 'user']);
  }

  onMouseEnter() {
    this.mouseIn = true
  }

  onMouseLeave( event: MouseEvent ) {
    //* [12510] checking related target field on event object to prevent misfiring
    //*   when opening <select> elements in Mozilla Firefox
    if ( event.relatedTarget === null ) return

    this.mouseIn = false
  }

  changeAccount(account: Account) {
    this.dispatch(RouterActions.navigate(`/${account.url}/dashboard`));
  }

  logout() {
    this.dispatch(UserActions.logout());
  }

  revertToAdmin() {
    this.dispatch(UserActions.revertToAdmin());
  }
}
