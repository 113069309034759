import { build, Collection, HasId, TypeConstructor } from '@caiu/library';

export class Notification<T extends HasId> {

  static Build<T extends HasId>(data: T, typeId: number, ctor?: TypeConstructor<T>, resolved = false) {
    const notification = new Notification(ctor && typeof ctor === 'function' ? build(ctor, data) : data);
    notification.typeId = typeId;
    notification.resolved = resolved;
    return notification;
  }

  linkText = '';
  linkUrl = '';
  message = '';
  resolved = false;
  resolvedBy = '';
  status = '';
  statusId: NotificationStatus = NotificationStatus.DEFAULT;
  typeId: NotificationType = NotificationType.DEFAULT;

  constructor(public data: T) {
  }

  set id(value: string) {
  }

  get id(): string {
    return `${this.typeId}.${this.data.id}`;
  }

  get createdDate(): Date {
    return this.data && this.data['createdDate'] ? new Date(this.data['createdDate']) : new Date();
  }
}

export enum NotificationStatus {
  DEFAULT,
  ACTION_REQUIRED,
  RESOLVED,
  ACCEPTED,
  REJECTED
}

export enum NotificationType {
  DEFAULT,
  ACCOUNT_REQUEST,
  SUGGESTED_ITEM,
  USER_MESSAGE
}

export class Notifications extends Collection<Notification<any>> {

  delete(id: string): Notifications {
    return build(Notifications, super.delete(id));
  }

  update(data: Notification<any> | Notification<any>[]): Notifications {
    return build(Notifications, super.update(data));
  }
}
