<div id="minutes-container">
  <div id="minutes-header">
    <h1>{{ agendaName$ | async }}</h1>
    <i>Saving is automatic when you leave an agenda item using tab or arrow keys</i>
  </div>
  <div class="minutes-content scrollable">
    <div class="arrows">
      <mat-icon [class.disabled]="previousId === null" (click)="goPrevious()">keyboard_arrow_left</mat-icon>
      <h2>{{ agendaItemName }}</h2>
      <mat-icon [class.disabled]="nextId === null" (click)="goNext()">keyboard_arrow_right</mat-icon>
    </div>
    <form [formGroup]="form" iuForm>
      <iu-editor
        [id]="elementId"
        [height]="300"
        type="FULL"
        formControlName="notes"
        [expanded]="true"
        (focus)="onFocus($event)"
        (focusout)="onFocusout($event)"
        [init]="editorArgs"
        [plugins]="editorPlugins"
        [toolbar]="editorToolbar"
        (onBeforeExecCommand)="changeEditor($event)"
        #editor
      ></iu-editor>
    </form>
  </div>
  <mat-dialog-actions>
    <button [style.width.%]="actionsWidthPct" class="action btn-cancel" mat-raised-button mat-dialog-close>
      Cancel
    </button>
    <button [style.width.%]="actionsWidthPct" *ngIf="minutes.id" class="action btn-delete" mat-raised-button
      (click)="deleteMinutes(minutes.id)">
      Delete
    </button>
    <button [style.width.%]="actionsWidthPct" *ngIf="form.dirty" class="action btn-save" mat-raised-button
      (click)="onSave()">
      Save
    </button>
  </mat-dialog-actions>
</div>
