import { Injectable } from '@angular/core';
import { Action, HttpActions, RouterActions } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountMembersActions } from './members.reducer';
import { accountUrlSelector } from '../accounts/accounts.reducer';
import { UserActions } from '../shared/actions';

@Injectable()
export class MembersEffects {

  /**
   * Reset password after adding new member.
   */
  @Effect() onPost: Observable<Action> = this.actions$.pipe(
    ofType(AccountMembersActions.POST),
    map((action: Action) => {
      this.memberId = action.payload.userId; // TODO: don't do this
      // return HttpActions.post(`resetpassword/${action.payload.user.emailAddress}`, {}, AccountMembersActions.RECOVER_PASSWORD, AccountMembersActions.RECOVER_PASSWORD_ERROR);
      return HttpActions.post(`email/invites/${action.payload.user.id}`, {}, UserActions.INVITE, UserActions.INVITE_ERROR);
    })
  );

  /**
   * Redirect to member edit form after adding new member.
   */
  @Effect() onResetPassword: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.INVITE),
    map((action: Action) => {
      return RouterActions.navigate(`/${this.membersLink}/${this.memberId}/edit`);
    })
  );

  accountUrl = '';
  memberId = 0;

  constructor(private actions$: Actions, private store: Store<any>) {
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
  }

  get membersLink(): string {
    return `/${this.accountUrl}/members`;
  }
}
