<div id="container" fxLayout="column" [style.height.px]="windowHeight" [style.width.px]="containerWidth"
  [class.blue]="isLogin">
  <am-mobile-menu *ngIf="isMobile && !isLogin && accountUrl" [accountUrl]="accountUrl" [routeName]="routeName"
    (logout)="logout()">
  </am-mobile-menu>
  <am-header *ngIf="!isMobile" [headerType]="type"></am-header>
  <div class="scrollable" [class.content]="isDefault" [class.login-content]="isLogin"
    [style.min-height.px]="contentHeight" fxFlexOffset="0px" fxFlexAlign="center" (scroll)="onScroll($event)">
    <ng-content></ng-content>
  </div>
  <am-footer *ngIf="isLogin"></am-footer>
</div>
<am-demo-routes *ngIf="showRoutes"></am-demo-routes>
