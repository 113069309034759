<mat-card class="notes-container">
  <iu-accordion [active]="false">
    <div class="accordion-content">
      <div
        class="editor-wrapper"
        [style.display]="editorDisplay"
      >
        <form
          [formGroup]="form"
          iuForm
        >
          <div id="editorNotes">
            <iu-editor
              id="editor"
              formControlName="notes"
              [height]="300"
              type="FULL"
              [init]="editorArgs"
              [plugins]="editorPlugins"
              [toolbar]="editorToolbar"
              (onBeforeExecCommand)="changeEditor($event)"
            ></iu-editor>
          </div>
        </form>
      </div>
    </div>
    <div
      class="accordion-trigger font-effect-3d-float"
      (click)="toggle()"
    >
      <span class="border-left"></span>
      <div class="toolbar">
        <button
          mat-button class="trigger-label font-effect-3d-float"
          [class.active-link]="opened && type === 'notes'"
          (click)="onClickNotes($event)"
        >
          My Notes
        </button>
        <button
          *ngIf="isMinuteTaker"
          mat-button
          class="trigger-label font-effect-3d-float"
          [class.active-link]="opened && type === 'minutes'"
          (click)="onClickMinutes($event)"
        >
          My Minutes
        </button>
        <span class="wrapper">
          <span class="arrows">
            <span
              class="arrow up"
              (click)="previousItem( $event )"
            >
              <mat-icon>expand_less</mat-icon>
            </span>
            <span
              class="arrow down"
              (click)="nextItem( $event )"
            >
              <mat-icon>expand_more</mat-icon>
            </span>
          </span>
        </span>
        <div class="buttons">
          <button
            mat-raised-button color="accent"
            class="btn font-effect-3d-float"
            *ngIf="opened && form.dirty"
            (click)="onSave($event)"
          >
            <mat-icon>save</mat-icon>
            <span class="icon-text">Save</span>
          </button>
          <button
            mat-raised-button class="btn btn-delete font-effect-3d-float"
            *ngIf="opened && ( (type === 'notes' && notes.id !== 0) || (type === 'minutes' && minutes.id !== 0) )"
            (click)="onDelete($event)"
          >
            <mat-icon>delete</mat-icon>
            <span class="icon-text">Delete</span>
          </button>
          <am-popout
            *ngIf="isMinuteTaker"
            [buttonLabel]="PopoutLabel"
            [height]="400"
            [messageHandler]="messageHandler.bind( this )"
            [clickHandler]="popoutButtonClick.bind( this )"
            [target]="PopoutTarget"
            [url]="PopoutURL"
          ></am-popout>
        </div>
      </div>
      <mat-icon
        [@toggle]="opened ? 'hide' : 'show'"
        (@toggle.start)="onStart($event)"
        (@toggle.done)="onDone($event)"
      >
        expand_less</mat-icon>
    </div>
  </iu-accordion>
</mat-card>

