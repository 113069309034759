import { Action } from '@caiu/library';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Tag, Tags } from './tags.model';
import { accountIdSelector } from '../../accounts/accounts.reducer';
import { currentUserIdSelector } from '../selectors';

export class TagsActions {
  static DELETE = '[Tags] DELETE';
  static DELETE_ERROR = '[Tags] DELETE ERROR';
  static GET = '[Tags] GET';
  static POST = '[Tags] POST';
  static POST_ERROR = '[Tags] POST ERROR';
  static PUT = '[Tags] PUT';
  static PUT_ERROR = '[Tags] PUT ERROR';
}

export function tagsReducer(state: Tags = new Tags(), action: Action): Tags {
  switch (action.type) {

    case TagsActions.GET:
    case TagsActions.POST:
    case TagsActions.PUT:
      return state.update(action.payload);

    case TagsActions.DELETE:
      return state.delete(action.payload);

    default:
      return state;
  }
}

export function tagsSelector(store: Store<any>): Observable<Tags> {
  return store.select('tags');
}

export function userTagsSelector(store: Store<any>): Observable<Tag[]> {
  return combineLatest([tagsSelector(store), accountIdSelector(store), currentUserIdSelector(store)]).pipe(
    map(x => {
      return x[0].asArray;
    })
  );
}
