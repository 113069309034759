import { Collection, build, Metadata, toArray, Dictionary, falsy } from '@caiu/library';

import { Agenda } from '../agendas/agendas.model';
import { Attachment } from '../attachments/attachments.model';
import { EmailItem } from '../email/email.model';
import { AgendaItemMinutes } from '../minutes/minutes.model';
import { AgendaItemNotes } from '../notes/notes.model';
import { Votes } from '../votes/votes.model';
import { BaseEntity } from '../shared/models';
import { Tree, TreeItem } from '../shared/tree';
import { Tag } from '../shared/tags/tags.model';

export type AgendaViewMode = 'NORMAL' | 'BUILD' | 'PROJECTOR' | 'SLIDESHOW'

export class AgendaItem extends BaseEntity {
  // static get TreeItem(): TreeItem<AgendaItem> {
  //   return new TreeItem<AgendaItem>(new AgendaItem());
  // }

  agendaId = 0;
  approved = null;
  _description = '';
  // hasMinutes = false;
  hasNotes = false;
  isAcceptingSuggestions = false;
  isSuggestion = false;
  isVotable = false;
  markedForDelete = false;
  name = '';
  order = 0;
  parentId = 0;
  rejectReason = '';
  securityStatusId = 0;
  subItems: AgendaItem[] = [];
  _hasAttachments = false;
  userNotes = '';

  agenda: Agenda = new Agenda();
  agendaItems: AgendaItem[] = [];
  attachments: Attachment[] = [];
  email: EmailItem[] = [];
  minutes: AgendaItemMinutes = new AgendaItemMinutes();
  notes: AgendaItemNotes = new AgendaItemNotes();
  tags: Tag[] = [];
  votes: Votes = new Votes();

  static BuildSubItems(item: AgendaItem, items: AgendaItem[]): AgendaItem[] {
    return items.filter(x => x.parentId === item.id)
      .map(x => build(AgendaItem, x, {
        subItems: AgendaItem.BuildSubItems(x, items)
      }));
  }

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        ...this.ignore,
        'id',
        '_agendaItem',
        '_hasAttachments',
        'agenda',
        'agendaId',
        'agendaIsPublic',
        'agendaItem',
        'agendaItems',
        'agendaName',
        'approved',
        '_attachments',
        '_description',
        'email',
        'groupId',
        'hasAttachments',
        'hasMinutes',
        'hasNotes',
        'isAcceptingSuggestions',
        'isSuggestion',
        'markedForDelete',
        'meetingId',
        'minutes',
        'notes',
        'order',
        'parentId',
        'securityStatusId',
        'securityStatusName',
        'subItems',
        // 'tags',
        'treeItem',
        'userNotes',
        'votes'
      ]
    });
  }

  get agendaIsPublic(): boolean {
    return this.agenda ? this.agenda.securityStatusId === 4 : false;
  }

  set description(value: string) {
    this._description = value;
  }

  get description(): string {
    return falsy(this._description) || this._description.toLowerCase() === 'null' ? '' : this._description;
  }

  get groupId(): number {
    return this.agenda ? this.agenda.groupId : null;
  }

  set hasAttachments(value: boolean) {
    this._hasAttachments = value;
  }

  get hasAttachments(): boolean {
    return this._hasAttachments || this.attachments.length > 0;
  }

  set isPrivate(value: boolean) {
    if (value) {
      this.securityStatusId = 1;
    }
  }

  get isPrivate(): boolean {
    return this.securityStatusId === 1;
  }

  // get tagIds(): number[] {
  //   return this.tags.map(x => x.id);
  // }

  // get treeItem(): TreeItem<AgendaItem> {
  //   return Object.assign(AgendaItem.TreeItem, TreeItem.Build<AgendaItem>(this));
  // }

  serialize() {
    return {
      id: this.id,
      createdById: this.createdById,
      createdDate: this.createdDate,
      lastModifiedById: this.lastModifiedById,
      lastModifiedDate: this.lastModifiedDate,
      agendaId: this.agendaId,
      approved: this.approved,
      attachments: this.attachments,
      description: this.description,
      isSuggestion: this.isSuggestion,
      isVotable: this.isVotable ? true : false,
      name: this.name,
      order: this.order,
      parentId: this.parentId === 0 ? null : this.parentId,
      securityStatusId: this.securityStatusId,
      subItems: this.subItems,
      tags: this.tags
    };
  }
}

export class AgendaItems extends Collection<AgendaItem> {
  saving = false;
  unsavedItems: Dictionary<AgendaItem> = {};

  static Build(data: AgendaItem): AgendaItem {
    return build(AgendaItem, data, {
      createdById: data.createdById,
      lastModifiedById: data.lastModifiedById
      // attachments: toArray(data.attachments).map(y => build(FileUpload, y))
    });
  }

  static BuildTree(items: AgendaItem[]): Tree<AgendaItem> {
    return Tree.Build(toArray(items).map(item => build(AgendaItem, item)), AgendaItem);
  }

  static Flatten(data: AgendaItem[]): AgendaItem[] {
    return data.reduce((acc, x) => [...acc, x, ...AgendaItems.Flatten(toArray(x.subItems))], []);
  }

  constructor() {
    super(AgendaItem);
  }

  get tree(): Tree<AgendaItem> {
    return AgendaItems.BuildTree(this.asArray);
  }

  get treeItems(): TreeItem<AgendaItem>[] {
    return this.tree.treeItems;
  }

  get asArray(): AgendaItem[] {
    return super.toArray().filter(x => !x.markedForDelete);
  }

  delete(id: number): AgendaItems {
    return build(AgendaItems, super.delete(id));
  }

  update(data: AgendaItem | AgendaItem[]): AgendaItems {
    const items = Array.isArray(data)
      ? AgendaItems.Flatten(data).map(x => AgendaItems.Build(x))
      : AgendaItems.Flatten([data]).map(x => AgendaItems.Build(x));
    return build(AgendaItems, super.update(items));
  }

  updateUnsaved(item: AgendaItem): AgendaItems {
    const updated = build(AgendaItem, this.unsavedItems[item.id], item);
    const unsavedItems = Object.assign(this.unsavedItems, {
      [item.id]: updated
    });
    return build(AgendaItems, this, { unsavedItems });
  }
}

export class Reordering {

  relativeTo: TreeItem<AgendaItem>;

  constructor(
    public tree: Tree<AgendaItem>,
    public reorderItem: TreeItem<AgendaItem>,
    public distance: { x: number; y: number } = { x: 0, y: 0 },
    public offset = 0,
    public dOffset = 0,
    public heights: number[] = [],
    public treeItemHeight = 60,
    public interval = 4
  ) {
    this.relativeTo = tree.findByIndex(this.hoveringOverIndex);
  }

  get currentY(): number {
    return this.startY + this.distance.y;
  }

  get dropTargetHeight(): number {
    return this.heights[this.relativeTo.index];
  }

  get dropTargetY(): number {
    return this.tree.treeItems.filter(x => x.index < this.relativeTo.index).reduce((acc, x) => {
      return acc + this.heights[x.index];
    }, 0);
  }

  get hoveringOverIndex(): number {
    if (this.isAboveTree) {
      return 0;
    }
    if (this.isBelowTree) {
      return this.tree.length - 1;
    }
    return this.tree.treeItems.reduce((acc, x) => {
      const elHeight = this.heights[x.index];
      const height = acc.height + elHeight;
      const index = this.currentY > acc.height && this.currentY < height ? x.index : acc.index;
      return {
        height,
        index
      };
    }, { index: 0, height: 0 }).index;
  }

  get hoveringOverSelf(): boolean {
    return this.reorderItem.id === this.relativeTo.id;
  }

  get isAboveTree(): boolean {
    return this.currentY < 0;
  }

  get isBelowTree(): boolean {
    return this.currentY > this.treeHeight;
  }

  get positionRelativeToDropTarget(): number {
    return this.currentY - this.dropTargetY;
  }

  get positionPctRelativeToDropTarget(): number {
    return this.positionRelativeToDropTarget / this.dropTargetHeight;
  }

  get relation(): 'ON' | 'BEFORE' | 'AFTER' | 'END' {
    if (this.isBelowTree) {
      return 'END';
    }
    if (this.isAboveTree) {
      return 'BEFORE';
    }
    const pct = this.positionPctRelativeToDropTarget;
    if (pct < .25) {
      return 'BEFORE';
    }
    if (pct > .75) {
      return 'AFTER';
    }
    return 'ON';
  }

  get reorderedTree(): Tree<AgendaItem> {
    switch (this.relation) {
      case 'END':
        return this.tree.addToEnd(this.reorderItem);
      case 'BEFORE':
        return this.tree.reorderBefore(this.reorderItem, this.relativeTo);
      case 'AFTER':
        return this.tree.reorderAfter(this.reorderItem, this.relativeTo);
      case 'ON':
        return this.tree.reorderOn(this.reorderItem, this.relativeTo);
    }
  }

  get startY(): number {
    return this.tree.treeItems.filter(x => x.index < this.reorderItem.index).reduce((acc, x) => {
      return acc + this.heights[x.index];
    }, 0);
  }

  get treeHeight(): number {
    return this.heights.reduce((acc, x) => {
      return acc + x;
    }, 0);
  }
}

export class AgendaItemInfo {
  accountUrl = '';
  agendaId = 0;
  agendaItemId = 0;
  meetingId = 0;

  get url(): string {
    return this.accountUrl && this.agendaItemId ?
      `/${this.accountUrl}/meetings/${this.meetingId}/agendas/${this.agendaId}/agendaitems/${this.agendaItemId}`
      : null;
  }
}
