export { StorageActions, Action, Config } from '@caiu/library';
import {
  Action,
  build,
  Collection,
  Dictionary,
  Metadata,
  BaseEntity as CaiuBaseEntity,
  FileUpload as CaiuFileUpload,
  User as BaseUser,
  toInt,
  Token,
  QueryModel,
  Validators,
  toArray
} from '@caiu/library';

import { Group, GroupMember } from '../groups/groups.model';
import { accountUrlValidator } from './validators';

export const PRIMARY_COLOR = '#00568c';

export class BaseEntity extends CaiuBaseEntity {
  id = 0;
}

export class User extends BaseUser {
  id = 0;
  agendaDateRangeId = 3;
  autoSaveEnabled = false;
  createdById = 0;
  createdByName = '';
  createdDate: Date = new Date();
  defaultGroupId = null;
  emailAddress = '';
  failedPasswordAttemptCount = 0;
  firstName = '';
  _fullName = '';
  generalInfo = '';
  groupIds: number[] = [];
  impersonating: CurrentUser = undefined;
  isActive = true;
  isAttendanceTaker = false;
  isLockedOut = false;
  lastLockoutDate: Date = null;
  lastLoginDate: Date = new Date();
  lastModifiedById = 0;
  lastModifiedByName = '';
  lastModifiedDate: Date = new Date();
  lastName = '';
  middleName = '';
  userName = '';
  userTitle = '';
  warnOnDirty = true;

  groups: Group[] = [];
  userAccounts: AccountMember[] = [];
  userGroups: GroupMember[] = [];

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        'id',
        '_userRole',
        'agendaDateRangeId',
        'autoSaveEnabled',
        'createdById',
        'createdByName',
        'createdDate',
        'defaultGroupId',
        'failedPasswordAttemptCount',
        '_fullName',
        'fullName',
        'groupIds',
        'groups',
        'impersonating',
        'isAccountActive',
        'isAccountMember',
        'isActive',
        'isAttendanceTaker',
        'isLockedOut',
        'lastLockoutDate',
        'lastLoginDate',
        'lastModifiedById',
        'lastModifiedByName',
        'lastModifiedDate',
        'middleName',
        'userAccounts',
        'userGroups',
        'userName',
        'userRole',
        'warnOnDirty'
      ],
      emailAddress: {
        validators: [Validators.required, Validators.maxLength(150)]
      },
      firstName: {
        validators: [Validators.required, Validators.maxLength(50)]
      },
      lastName: {
        validators: [Validators.required, Validators.maxLength(50)]
      },
    });
  }

  private _userRole = '';

  set fullName(value: string) {
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get userRole(): string {
    return this._userRole;
  }

  set userRole(value: string) {
    this._userRole = value;
  }

  get isAccountActive(): boolean {
    return true;
  }

  isAccountMember(accountId: number) {
    return this.userAccounts.findIndex(x => x.accountId === accountId) !== -1;
  }
}

export class Account extends BaseEntity {
  id = 0;
  administrator: User = new User();
  allowRequests = true;
  defaultSignature = '';
  description = '';
  emailAgendaTemplate = '';
  emailTemplate = '';
  logo: File = new File();
  memberContentSourceId = '';
  name = '';
  owner: User = new User();
  ownerId = 0;
  publicContentSourceId = '';
  status = '';
  statusId = 1;
  trialPeriodStartDate: Date = null;
  trialPeriodEndDate: Date = null;
  url = '';

  get src(): string {
    return Files.FileToBinary(this.logo);
  }
}

export class Accounts extends Collection<Account> {
  activeUrl = '';
  metadata: Metadata = Object.assign(new Metadata(), {
    items: [{ label: 'Name', name: 'name' }, { label: 'URL', name: 'url' }, { label: 'Status', name: 'status' }]
  });

  static FindAccountId(state: Accounts, url: string): number {
    const activeAccount = state.asArray.find(x => x.url === url);
    return activeAccount ? activeAccount.id || toInt(state.activeId) : 0;
  }

  static FindAccountUrl(state: Accounts, id: number | string): string {
    const activeAccount = state.asArray.find(x => x.id === id);
    return activeAccount ? activeAccount.url || state.activeUrl : '';
  }

  static ReduceAccount(state: Accounts, action: Action, accountId: number): Accounts {
    // const account = accountReducer(state.get(accountId), action);
    // return build(Accounts, state, state.update(account));
    return state;
  }

  static ReduceAccounts(state: Accounts, payload: Account[]): Accounts {
    const items = payload.reduce((acc: Dictionary<Account>, item: Account) => {
      return Object.assign({}, acc, { [item.id]: item });
    }, Object.assign({}, state.items));
    return build(Accounts, state, { items });
  }

  static ReduceActivate(state: Accounts, id: number | string): Accounts {
    const activeAccount = state.asArray.find(x => x.id === id);
    const activeUrl = activeAccount ? activeAccount.url || state.activeUrl : '';
    return build(Accounts, state, { activeId: id, activeUrl });
  }

  constructor() {
    super(Account);
  }

  get active(): Account {
    return this.items[this.activeId] || new Account();
  }

  findAccountId(url: string): number {
    return Accounts.FindAccountId(this, url);
  }

  update(data: Account | Account[]): Accounts {
    return build(Accounts, super.update(data));
  }
}

export class AccountMember {
  id = 0;
  account: Account = new Account();
  _accountName = '';
  accountRoleName = '';
  accountRoleId = 0;
  _accountUrl = '';
  greeting = 'You have received this email because a new AgendaManager member account has been created on your behalf.';
  groupIds: number[] = [];
  hasAccountRequest = false;
  isPrimaryAccount = false;
  isUserActive = false;
  isGroupManager = false;
  isGroupContributor = false;
  isAccountActive = false;
  rejectReason = '';
  role: AccountRole = AccountRole.DEFAULT;
  _user: User = new User();
  userId = 0;
  _accountId = 0;
  _groups: GroupMember[] = [];

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        'id',
        'account',
        '_accountId',
        'accountId',
        '_accountName',
        'accountName',
        'accountRoleName',
        'accountRoleId',
        '_accountUrl',
        'accountUrl',
        'accounts',
        'createdByName',
        'createdDate',
        'displayName',
        'groupIds',
        '_groups',
        'hasAccountRequest',
        'isAdministrator',
        'isApproved',
        'isGroupAdministrator',
        'isGroupContributor',
        'isGroupManager',
        'isLockedOut',
        'isMember',
        'isPrimaryAccount',
        'isSystemAdministrator',
        'isUserActive',
        'lastLoginDate',
        'lastModifiedByName',
        'lastModifiedDate',
        'matches',
        'resetPassword',
        'role',
        '_user',
        'user',
        'userId'
      ],
      emailAddress: {
        validators: [Validators.required, Validators.maxLength(150)]
      },
      firstName: {
        validators: [Validators.required, Validators.maxLength(50)]
      },
      lastName: {
        validators: [Validators.required, Validators.maxLength(50)]
      }
    });
  }

  get accountId(): number {
    return this._accountId || (this.account ? this.account.id : 0);
  }

  set accountId(value: number) {
    this._accountId = value;
  }

  set accountName(value: string) {
    this._accountUrl = value;
  }

  get accountName(): string {
    return this._accountName || this.account.name;
  }

  set accountUrl(value: string) {
    this._accountUrl = value;
  }

  get accountUrl(): string {
    return this._accountUrl || this.account.url;
  }

  get createdByName(): string {
    return this.user.createdByName;
  }

  get createdDate(): Date {
    return this.user.createdDate;
  }

  set deactivated(value: boolean) {
    this.isUserActive = !value;
  }

  get deactivated(): boolean {
    // return !(this.isUserActive && this.user.isActive);
    return !this.isUserActive;
  }

  get displayName(): string {
    return `${this.user.lastName}, ${this.user.firstName}`;
  }

  get emailAddress(): string {
    return this.user.emailAddress;
  }

  set emailAddress(value: string) {
    this.user.emailAddress = value;
  }

  get firstName(): string {
    return this.user.firstName;
  }

  set firstName(value: string) {
    this.user.firstName = value;
  }

  get fullName(): string {
    return this.user.fullName;
  }

  set fullName(value: string) {
    this.user.fullName = value;
  }

  get generalInfo(): string {
    return this.user.generalInfo;
  }

  set generalInfo(value: string) {
    this.user.generalInfo = value;
  }

  get groups(): GroupMember[] {
    return this._groups;
  }

  set groups(value: GroupMember[]) {
    if (value) {
      this._groups = value;
    }
  }

  get lastModifiedByName(): string {
    return this.user.lastModifiedByName;
  }

  get lastModifiedDate(): Date {
    return this.user.lastModifiedDate;
  }

  get lastName(): string {
    return this.user.lastName;
  }

  set lastName(value: string) {
    this.user.lastName = value;
  }

  set unlockAccount(value: boolean) {
    this.user.isLockedOut = false;
  }

  get unlockAccount(): boolean {
    return !this.user.isLockedOut;
  }

  set user(value: User) {
    this._user = value;
  }

  get user(): User {
    return build(User, this._user);
  }

  get userTitle(): string {
    return this.user.userTitle;
  }

  set userTitle(value: string) {
    this.user.userTitle = value;
  }
}

export class AccountMembers extends Collection<AccountMember> {
  total = 0;
  constructor() {
    super(AccountMember);
  }

  login(userId: number, data: any[]): AccountMembers {
    const items = data.map(x => build(AccountMember, {
      userId,
      id: x.id,
      accountId: x.accountId,
      isPrimaryAccount: x.isPrimary,
      isUserActive: x.isUserActive,
      accountRoleId: x.roleId
    }));
    return this.update(items);
  }

  update(data: AccountMember | AccountMember[]): AccountMembers {
    return build(AccountMembers, super.update(data));
  }

  updateMember(data: AccountMember): AccountMembers {
    return this.update(
      data.isPrimaryAccount ?
        [data, ...this.asArray.filter(x => x.userId === data.userId && x.accountId !== data.accountId).map(x => build(AccountMember, x, { isPrimaryAccount: false }))]
        : [data]
    );
  }

  updateSearch(results: AccountMember | AccountMember[], total: number): AccountMembers {
    return build(AccountMembers, super.update(results), { total });
  }
}

export class AccountMemberQuery extends QueryModel<AccountMember> {
  params = {};
}

export enum AccountRole {
  DEFAULT = 0,
  SystemAdministrator = 1,
  Administrator = 2,
  GroupAdministrator = 3,
  Member = 4
}

export class AccountRow {
  constructor(public model: Account) { }

  get accountName() {
    return this.model.name;
  }

  get accountUrl() {
    return this.model.url;
  }

  get accountStatus() {
    return this.model.status;
  }
}

export enum AccountStatus {
  DEFAULT,
  ACTIVE,
  CLOSED,
  TRIAL
}

export class AccountRequest {
  id = 0;
  accountId = 0;
  accountName = '';
  accountUrl = '';
  approved = false;
  createdDate = new Date();
  reason = '';
  userId = 0;
  userName = '';
}

export class AccountsQuery extends QueryModel<Account> {
  params = {};
  take = 20;
}

export class CurrentUser extends User {
  id = 0;
  email = '';
  firstName = '';
  lastName = '';
  accountId = 0;
  accountLevel = 0;
  accounts = [];
  autoSaveEnabled = false;
  confirmPassword = '';
  agendaDateRangeId = 3;
  emailAddress = '';
  defaultGroupId = 0;
  impersonating: CurrentUser;
  lastDateRangeId = 0;
  newPassword = '';
  noramlizedEmailAddress = '';
  normalizedUserName = '';
  password = '';
  passwordResetCode = '';
  phoneNumber = '';
  token: Token = new Token();
  userId = 0;
  warnOnDirty = true;

  static Build(data: CurrentUser): CurrentUser {
    const token = data && data.token ? build(Token, data.token) : new Token();
    return data ? build(CurrentUser, data, {
      token,
      accounts: toArray(data.accounts).map(x => build(AccountMember, x, {
        accountId: x._accountId
      }))
    }) : build(CurrentUser, {
      token
    });
  }

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        'id',
        '_userRole',
        'accessFailedCount',
        'concurrencyStamp',
        'createdById',
        'createdByName',
        'createdDate',
        'dateRangeId',
        'displayMode',
        'emailConfirmed',
        'failedPasswordAttemptCount',
        'fullName',
        'groupId',
        'groupIds',
        'groups',
        'impersonating',
        'isAccountActive',
        'isAccountMember',
        'isActive',
        'isAttendanceTaker',
        'isLockedOut',
        'lastDateRangeId',
        'lastLockoutDate',
        'lastLoginDate',
        'lastModifiedById',
        'lastModifiedByName',
        'lastModifiedDate',
        'lastPasswordChangedDate',
        'lockoutEnabled',
        'lockoutEnd',
        'middleName',
        'normalizedEmail',
        'normalizedEmailAddress',
        'normalizedUserName',
        'password',
        'passwordHash',
        'passwordResetCode',
        'phoneNumber',
        'phoneNumberConfirmed',
        'securityStamp',
        'serverSalt',
        'token',
        'twoFactorEnabled',
        'userAccounts',
        'userGroups',
        'userName',
        'userRole'
      ],
      emailAddress: {
        validators: [Validators.email]
      }
    });
  }

  get accountIds(): number[] {
    return this.accounts.map(x => x.id);
  }

  get authenticated(): boolean {
    return this.token.expires_in > 0;
  }

  get hasAccount(): boolean {
    return this.accountId === 0 ? true : false;
  }

  get primaryAccountId(): number {
    const account = this.accounts.find(x => x.isPrimary === true);
    return account ? account.id : this.accounts.length > 0 ? this.accounts[0].id : 0;
  }

  // set token(value: Token) {
  //   this._token = value;
  // }

  // get token(): Token {
  //   return this.impersonating ? this.impersonating.token : this._token;
  // }

  logout() {
    this.token = new Token();
  }

  update(data: CurrentUser): CurrentUser {
    return build(CurrentUser, data, {
      token: this.token
    });
  }
}

export class Dashboard {
  greeting = '';
}

export class DashboardMessage extends BaseEntity {
  id = 0;
  description = '';
  lock = '';
  subject = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
}

export type DisplayMode = 'default' | 'classic';

export const EDITOR_ARGS = {
  relative_urls: false,
  remove_script_host: false,
  document_base_url: 'https://app.agendamanager.com/'
};

export class File extends BaseEntity {
  id = 0;
  fileName = '';
  fileSize = 0;
  fileBinary: any[] = [];
  fileString = '';
  _fileExtension = '';
  mimeType = '';

  static getSrcPrefix(mimeType: string): string {
    return `data:${mimeType};base64,`;
  }

  set fileExtension(value: string) {
    this._fileExtension = value;
  }

  get fileExtension(): string {
    return this._fileExtension || this.fileName.split('.').pop();
  }

  get src(): string {
    return this.mimeType && this.fileBinary ? `data:${this.mimeType};base64,${this.fileBinary}` : '';
  }

  get srcPrefix(): string {
    return `data:${this.mimeType};base64,`;
  }

  toBinary() {
    return this.mimeType && this.fileBinary ? `data:${this.mimeType};base64,${this.fileBinary}` : '';
  }
}

export class ImageUpload {
  id = 0;
  fileName = '';
  fileSize = 0;
  fileContent: any[] = [];
  fileString = '';
  _fileExtension = '';
  location = '';
  mimeType = '';

  static getSrcPrefix(mimeType: string): string {
    return `data:${mimeType};base64,`;
  }

  set fileExtension(value: string) {
    this._fileExtension = value;
  }

  get fileExtension(): string {
    return this._fileExtension || this.fileName.split('.').pop();
  }

  get src(): string {
    return this.mimeType && this.fileContent ? `data:${this.mimeType};base64,${this.fileContent}` : '';
  }

  get srcPrefix(): string {
    return `data:${this.mimeType};base64,`;
  }

  toBinary() {
    return this.mimeType && this.fileContent ? `data:${this.mimeType};base64,${this.fileContent}` : '';
  }
}

export class FileUpload extends CaiuFileUpload {
  id = 0;
}

export class Files extends Collection<File> {
  static FileToBinary(f: File) {
    return f ? (f.mimeType && f.fileBinary ? `data:${f.mimeType};base64,${f.fileBinary}` : '') : null;
  }

  static MapFileUpload(upload: FileUpload, file?: File) {
    return build(File, {
      fileBinary: upload.src.replace(File.getSrcPrefix(upload.type), ''),
      fileExtension: upload.extension,
      fileName: upload.name,
      fileSize: upload.size,
      mimeType: upload.type,
      id: file ? file.id : 0
    });
  }

  constructor() {
    super(File);
  }
}

export class LoggedInUser {
  access_token = '';
  expires_in = 0;
  user: User = new User();
}

export class Login {
  grant_type = 'password';
  email = '';
  password = '';
}

export class ResetPassword {
  passwordResetCode = '';
  password = '';
  confirmPassword = '';
}

export class Users extends Collection<User> {
  constructor() {
    super(User);
  }

  update(data: User | User[]): Users {
    return build(Users, super.update(data));
  }
}

export class UserSearchResult {
  id = 0;
  firstName = '';
  lastName = '';
  fullName = '';
  user: User = new User();

  static Build(user: User): UserSearchResult {
    return build(UserSearchResult, user, { user });
  }

  get displayName(): string {
    return `${this.lastName}, ${this.firstName}`;
  }
}

export class MembersQuery extends QueryModel<AccountMember> {
  showInactive = false;
  accountId = 0;
  accountUrl = '';
  groupId = 0;
  take = 10;
  term = '';

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: ['params', 'accountId', 'path', 'sortBy', 'sortDirection', 'url']
    });
  }

  set params(value: any) {
  }

  get params(): any {
    return ['groupId', 'showInactive', 'sortBy', 'sortDirection'].reduce((acc, key) => {
      return this[key] ? Object.assign({}, acc, { [key]: this[key] }) : acc;
    }, {});
  }

  get path(): string {
    return QueryModel.BuildQueryString(this);
  }

  get url(): string {
    return this.accountUrl ? `/${this.accountUrl}/members${this.path}` : `/admin/members${this.path}`;
  }
}

export class MemberRow {

  static BuildFromUser(user: User): MemberRow {
    return new MemberRow(build(AccountMember, {
      user
    }))
  }

  constructor(private _member: AccountMember = new AccountMember(), private _accountUrl = '') {
  }

  get member(): AccountMember {
    return build(AccountMember, this._member);
  }

  get user(): User {
    return this.member.user;
  }

  get userId(): number {
    return this.member.userId;
  }

  get firstName(): string {
    return this.user.firstName;
  }

  get lastName(): string {
    return this.user.lastName;
  }

  get emailAddress(): string {
    return this.user.emailAddress;
  }

  get memberLink(): string {
    return `/${this._accountUrl}/members/${this.userId}/edit`;
  }

  get userTitle(): string {
    return this.user.userTitle;
  }

  get userRole(): string {
    return this.member.accountRoleName;
  }

  get locked(): string {
    return this.user.isLockedOut ? 'Yes' : 'No';
  }

  get accountActive(): string {
    return this.member.isUserActive ? 'Yes' : 'No';
  }

  get userActive(): string {
    return this.user.isActive ? 'Yes' : 'No';
  }
}

export interface Distance {
  x: number;
  y: number;
  zIndex?: number;
}

export class HasDetails {
  attachments = false;
  attendance = false;
  descriptions = false;
  minutes = false;
  notes = false;
  voting = false;
  appendAttachments = false;
  showAgendaDescription = false;
  subItems = true;
  tags = false;

  constructor( subItems = true ) {
    this.subItems = subItems
  }
}

export class ExportSettings {
  agendaId = 0;
  html = '';
  addAttachment = false;
  convertAttachment = false;
  name = '';
}
