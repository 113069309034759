import {
    isFunction,
    isPlainObject,
    isString
} from './misc'
import { padNumber } from './number'
import { environment } from '../../../environments/environment'

export type LogLevel = 'debug' | 'error' | 'log' | 'warn'

export type LoggerOptions = {
    logLevels?: LogLevel[]
    timestamp?: boolean
}

const DEFAULT_LOG_LEVELS: LogLevel[] = environment.production
    ? []
    : [
        'debug',
        'error',
        'log',
        'warn'
    ]

export class Logger {
    private _context: string
    private _options: LoggerOptions

    constructor( context = '', options: LoggerOptions = {} ) {
        this._context = context
        this._options = options

        if ( !this._options.logLevels )
            this._options.logLevels = DEFAULT_LOG_LEVELS
    }

    private colorText( baseText: string, logLevel: LogLevel = 'log' ): { text: string, style: string } {
        const text = `%c${ baseText }`
        let style = 'color: inherit'

        switch( logLevel ) {
            case 'debug':
                style = 'color: blue'
                break
            case 'error':
                style = 'color: red'
                break
            case 'warn':
                style = 'color: yellow'
                break
        }
        
        return {
            text,
            style
        }
    }

    private getContextToPrint( params: unknown[] ) {
        if ( params?.length <= 1 )
            return this._context

        const lastItem = params[ params.length - 1 ]
        const isContext = isString( lastItem )

        if ( !isContext )
            return this._context

        return lastItem as string
    }

    private isLogLevelEnabled( logLevel: LogLevel ) {
        return this._options.logLevels?.includes( logLevel ) ?? false
    }

    private formatContext( context?: string ) {
        return context
            ? `[${ context }] `
            : ''
    }

    private getTimestamp() {
        if ( !this._options.timestamp ) return ''

        const now = new Date()

        return `[${
            padNumber( now.getHours() )
        }:${
            padNumber( now.getMinutes() )
        }:${
            padNumber( now.getSeconds() )
        }:${
            padNumber( now.getMilliseconds() )
        }] `
    }

    private stringifyMessage( message: unknown ) {
        if ( isFunction( message ) ) {
            const messageAsString = Function.prototype.toString.call( message )
            const isClass = messageAsString.startsWith( 'class ' )

            if ( isClass )
                return this.stringifyMessage( message.name )

            return this.stringifyMessage( message() )
        }

        return isPlainObject( message ) || Array.isArray( message )
            ? `Object: ${ JSON.stringify( message ) }`
            : message as string
    }

    private formatMessage(
        message: unknown,
        formattedLogLevel: string,
        context?: string,
        timestamp?: string
    ) {
        const loggerString = '[CAIU] '
        const formattedMessage = this.stringifyMessage( message )
    
        return `${ loggerString }${ timestamp }${ formattedLogLevel } %c${ context }${ formattedMessage }`
    }

    private _log( level: LogLevel, trace: boolean, message, context, ...params ) {
        //* console.log( '[CAIU] [Logger] [log]', { level, message, context, params, logLevel: this._options.logLevels } )
        
        if ( !this.isLogLevelEnabled( level ) ) return

        const formattedContext = this.formatContext( context )
        const timeStamp = this.getTimestamp()
        const {
            text: logLevel,
            style: logLevelStyle
        } = this.colorText( `${ level.toUpperCase() }`, level )
        const formattedMessage = this.formatMessage(
            message,
            logLevel,
            formattedContext,
            timeStamp
        )
        let updatedParams = [
            logLevelStyle,
            '',
            ...params
        ]

        if ( updatedParams[ updatedParams.length - 1 ] === context ) updatedParams.splice( updatedParams.length - 1, 1 )

        switch( level ) {
            case 'debug':
                if ( trace ) console.trace( formattedMessage, ...updatedParams )
                else console.log( formattedMessage, ...updatedParams )
                break
            case 'error':
                console.error( formattedMessage, ...updatedParams )
                break
            case 'log':
                console.log( formattedMessage, ...updatedParams )
                break
            case 'warn':
                console.warn( formattedMessage, ...updatedParams )
                break
            default:
                const { text: errorLevel, style } = this.colorText( 'Error', 'error' )
                const errorMessage = this.formatMessage( 'Invalid log type provided.', errorLevel, context, timeStamp )
                console.error( errorMessage, style )
        }
    }

    debug( message: any, context?: string )
    debug( message: any, ...params: [ ...any, string? ] )
    debug( message: any, ...params: any[] ) {
        const context = this.getContextToPrint( params )

        this._log( 'debug', false, message, context, ...params )
    }

    error( message: any, stack?: string, context?: string)
    error( message: any, ...params: [ ...any, string?, string? ] )
    error( message: any, ...params: any[] ) {
        const context = this.getContextToPrint( params )

        this._log( 'error', false, message, context, ...params )
    }

    log( message: any, context?: string )
    log( message: any, ...params: [ ...any, string? ] )
    log( message: any, ...params: any[] ) {
        const context = this.getContextToPrint( params )

        this._log( 'log', false, message, context, ...params )
    }

    trace( message: any, context?: string )
    trace( message: any, ...params: [ ...any, string? ] )
    trace( message: any, ...params: any[] ) {
        const context = this.getContextToPrint( params )

        this._log( 'debug', true, message, context, ...params )
    }

    warn( message: any, context?: string )
    warn( message: any, ...params: [ ...any, string? ] )
    warn( message: any, ...params: any[] ) {
        const context = this.getContextToPrint( params )

        this._log( 'warn', false, message, context, ...params )
    }
}

