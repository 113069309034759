<div id="bin">
  <div class="wrapper scrollable">
    <form class="bincol1" [formGroup]="form">
      <am-panel-mini heading="Select an Account:">
        <mat-form-field>
          <mat-select id="bin-accounts" class="card-content" placeholder="Select Copied From Account..."
            formControlName="accountId">
            <mat-option [value]="0">All Accounts</mat-option>
            <mat-option *ngFor="let member of userAccounts$ | async" [value]="member.accountId">{{
              member?.account?.name
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </am-panel-mini>
      <am-panel-mini heading="Select a Bin Item:">
        <mat-form-field>
          <mat-select placeholder="Select Bin Item" formControlName="binItemId">
            <mat-option class="bin-item-name" *ngFor="let item of binItems" [value]="item.id">{{ item?.agendaItem?.name
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </am-panel-mini>
      <div class="binItemOptions">
        <am-panel-mini heading="Select Bin Item Options:">
          <div class="options">
            <mat-checkbox formControlName="copyDescription">Copy Description</mat-checkbox>
            <mat-checkbox formControlName="copySubItems">Copy Sub Items</mat-checkbox>
            <mat-checkbox formControlName="copyAttachments">Copy Attachments</mat-checkbox>
            <mat-checkbox formControlName="copyNotes">Copy Notes</mat-checkbox>
            <mat-checkbox formControlName="copyMinutes">Copy Minutes</mat-checkbox>
            <mat-checkbox formControlName="copyTags">Copy Tags</mat-checkbox>
            <mat-checkbox formControlName="isPrivate">Always Private</mat-checkbox>
            <mat-checkbox formControlName="isVotable">Is Votable</mat-checkbox>
            <mat-checkbox formControlName="removeAfterCopy">Remove After Copy</mat-checkbox>
          </div>
        </am-panel-mini>
      </div>
      <div class="binItemOptions">
        <am-panel-mini heading="Bin Item Preview:">
          <div class="binpreviewtreeitem">
            <span [class.agendaitem-child]="!(binItem?.agendaItem?.parentId === 0)"
              [class.agendaitem-parent]="binItem?.agendaItem?.parentId">
              <a>{{ binItem?.agendaItem?.name }}</a></span>
            <span class="ui-icon ui-icon-notes" title="Notes"
              *ngIf="binItem?.agendaItem?.minutes || binItem?.agendaItem?.notes"></span>
            <span class="ui-icon ui-icon-paperclip" title="Attachments"
              *ngIf="binItem?.agendaItem?.hasAttachments"></span>
          </div>
        </am-panel-mini>
      </div>
    </form>
    <div class="bincol2">
      <am-bin-empty *ngIf="empty && !loading"></am-bin-empty>
      <am-panel-mini heading="Bin Item Details:" *ngIf="!empty">
        <div id="binitempreview" class="widget-assets widget-padding">
          <div class="item-header name">
            <b>Name: </b><span id="txtBinItemName">{{ binItem?.agendaItem?.name }}</span>
            <div *ngIf="form.value.copyTags" class="tags-wrapper">
              <am-inline-tags [tags]="binItem?.agendaItem?.tags"></am-inline-tags>
            </div>
          </div>
          <div id="divBinItemDescription" *ngIf="form.value.copyDescription" class="item-header">
            <h4>Description:</h4>
            <span [innerHTML]="description | safeHtml"></span>
          </div>
          <div id="divBinItemAttachments" *ngIf="form.value.copyAttachments" class="item-header">
            <am-attachments [attachments]="binItem?.agendaItem?.attachments"></am-attachments>
          </div>
          <div id="divBinItemNotes" *ngIf="form.value.copyNotes" class="item-header">
            <h4>Notes:</h4>
            <am-notes-preview [agendaItemId]="binItem?.agendaItemId">
            </am-notes-preview>
          </div>
          <div id="divBinItemMinutes" *ngIf="form.value.copyMinutes" class="item-header">
            <h4>Minutes:</h4>
            <am-minutes-preview [agendaItemId]="binItem?.agendaItemId">
            </am-minutes-preview>
          </div>
        </div>
      </am-panel-mini>
    </div>
  </div>
  <mat-dialog-actions *ngIf="!empty && !loading">
    <button class="action btn-cancel" mat-raised-button mat-dialog-close>
      Cancel
    </button>
    <button class="action btn-delete" *ngIf="binItem.agendaItemId" mat-raised-button (click)="deleteBinItem(binItemId)">
      Delete
    </button>
    <button class="action btn-save" *ngIf="binItem.agendaItemId" mat-raised-button
      (click)="addAgendaItem(binItem.agendaItemId)">
      Save
    </button>
  </mat-dialog-actions>
  <mat-progress-bar *ngIf="loading" mode="query" color="accent"></mat-progress-bar>
</div>
