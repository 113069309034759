import { build, Action, routeParamSelector, toArray, compareStrings, querySelector, routeParamIntSelector, falsy } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';

import { AccountMembersActions, accountMembersSelector, currentUserAccountsSelector } from '../members/members.reducer';
import { AppActions, UserActions, AccountsActions, AccountActions } from '../shared/actions';
import { Accounts, AccountMember, Account, AccountsQuery } from '../shared/models';
import { currentUserSelector, userSelector, currentUserIdSelector } from '../shared/selectors';
import { startsWith } from '../shared/utils';

export function accountsReducer(state: Accounts = new Accounts(), action: Action): Accounts {
  switch (action.type) {
    case AppActions.INIT_STORE:
      return build(Accounts, action.payload['accounts']);

    case AccountsActions.ACTIVATE:
      return build(Accounts, state, { activeId: action.payload.id });

    case AccountsActions.ACTIVATE_AND_REDIRECT:
      return build(Accounts, state, {
        activeId: action.payload.id,
        activeUrl: Accounts.FindAccountUrl(state, action.payload.id)
      });

    case AccountsActions.ACTIVATE_URL:
      return build(Accounts, state, {
        activeId: Accounts.FindAccountId(state, action.payload),
        activeUrl: action.payload
      });

    case AccountsActions.GET:
      return build(Accounts, state.update(<Account[]>action.payload), {
        activeId: state.activeId ? state.activeId : state.activeUrl ? Accounts.FindAccountId(state, state.activeUrl) : 0,
        accountUrl: state.activeUrl ? state.activeUrl : state.activeId ? Accounts.FindAccountUrl(state, state.activeId) : ''
      });

    case AccountActions.GET:
    case AccountsActions.POST:
    case AccountActions.PUT:
      return state.update(<Account>action.payload);

    case UserActions.LOGIN_SUCCESS:
      const accounts = action.payload.user.userAccounts;
      return accounts.length === 1 ? Accounts.ReduceActivate(state, accounts[0].id) : state;

    case UserActions.LOGOUT:
      return build(Accounts, state, { activeId: 0 });

    case AccountMembersActions.GET:
      const member = (<AccountMember[]>action.payload.results)[0] || new AccountMember();
      const accountId = member.accountId;
      return Accounts.ReduceAccount(state, action, accountId);

    case AccountMembersActions.GET_USER_ACCOUNTS:
      return build(Accounts, state.update(action.payload.map(x => x.account)));

    case UserActions.GET_ACCOUNTS:
      return state.update(toArray(action.payload).map(x => x.account));

    default:
      return state;
  }
}

export function accountsSelector(store: Store<any>): Observable<Accounts> {
  return store.select('accounts');
}

export function allAccountsSelector(store: Store<any>): Observable<Account[]> {
  return accountsSelector(store).pipe(
    map(x => x.asArray.sort((a, b) => compareStrings(a.name, b.name)))
  );
}

export function accountsSearchSelector(store: Store<any>): Observable<Account[]> {
  return combineLatest(allAccountsSelector(store), accountsQuerySelector(store),
    (accounts, query) => {
      const filtered = query.term ? accounts.filter(x => startsWith(x.name, query.term) || startsWith(x.url, query.term)) : accounts;
      const sorted = query.sortBy ? filtered.sort((a, b) => compareStrings(a[query.sortBy] || '', b[query.sortBy] || '')) : filtered;
      return query.sortDirection === 'desc' ? sorted.reverse() : sorted;
    });
}

export function accountsQuerySelector(store: Store<any>): Observable<AccountsQuery> {
  const skip$ = routeParamIntSelector(store, 'skip');
  const take$ = routeParamIntSelector(store, 'take').pipe(
    map(x => x === 0 ? 20 : x)
  );
  const term$ = routeParamSelector(store, 'term');
  const sortBy$ = routeParamSelector(store, 'sortBy');
  const sortDirection$ = routeParamSelector(store, 'sortDirection');
  return combineLatest(skip$, sortBy$, sortDirection$, take$, term$, (skip, sortBy, sortDirection, take, term) =>
    build(AccountsQuery, {
      skip,
      sortBy,
      sortDirection,
      take,
      term
    })
  );
}

export function accountIdSelector(store: Store<any>): Observable<number> {
  return accountSelector(store).pipe(
    map(x => x.id),
    distinctUntilChanged()
  );
}

export function accountNameSelector(store: Store<any>): Observable<string> {
  return accountSelector(store).pipe(
    map(x => x.name),
    distinctUntilChanged()
  );
}

export function accountUrlSelector(store: Store<any>): Observable<string> {
  return combineLatest(
    routeParamSelector(store, 'account'),
    currentUserAccountUrlSelector(store),
    (account, accountUrl) => {
      return account ? account : accountUrl;
    });
}

export function userAccountMembershipsSelector(store: Store<any>): Observable<AccountMember[]> {
  return combineLatest(currentUserSelector(store), currentUserAccountsSelector(store), accountsSelector(store), (user, accountMemberships, accounts) => {
    return accountMemberships.map(x => {
      return build(AccountMember, x, {
        account: accounts.get(x.accountId)
      });
    });
  });
}

export function currentUserAccountUrlSelector(store: Store<any>): Observable<string> {
  return combineLatest(currentUserSelector(store), accountsSelector(store), (user, accounts) => {
    const userAccounts = toArray(user['userAccounts']);
    const account = userAccounts.length > 0 ? userAccounts.find(x => x.isPrimary) || userAccounts[0] : null;
    return account ? build(Account, accounts.asArray.find(x => x.id === account.id)).url : '';
  }).pipe(distinctUntilChanged());
}

export function accountSelector(store: Store<any>): Observable<Account> {
  return combineLatest([accountsSelector(store), accountUrlSelector(store)]).pipe(
    map(x => {
      return build(Account, x[0].asArray.find(y => y.url === x[1]));
    })
  );
}

export function accountUserRoleIdSelector(store: Store<any>): Observable<number> {
  return combineLatest(userSelector(store), accountSelector(store), (user, account) => {
    const member = user.userAccounts.find(x => x.accountId === account.id);
    return member ? member.accountRoleId : 0;
  });
}

export function userAccountSelector(store: Store<any>): Observable<AccountMember> {
  return combineLatest(currentUserAccountsSelector(store), accountIdSelector(store), (userAccounts, accountId) => {
    return build(AccountMember, userAccounts.find(x => x.accountId === accountId));
  });
}

export function userAccountRoleSelector(store: Store<any>): Observable<number> {
  return userAccountSelector(store).pipe(
    map(x => falsy(x.id) ? null : x.accountRoleId),
    distinctUntilChanged()
  );
}

export function userIsSysAdminSelector(store: Store<any>): Observable<boolean> {
  return currentUserAccountsSelector(store).pipe(
    map(x => x.findIndex(y => y.accountRoleId === 1) !== -1)
  );
}

export function userIsAccountAdminSelector(store: Store<any>): Observable<boolean> {
  return combineLatest(userAccountRoleSelector(store), userIsSysAdminSelector(store), (roleId, isSysAdmin) => isSysAdmin || roleId === 1 || roleId === 2);
}

export function userIsGroupAdminSelector(store: Store<any>): Observable<boolean> {
  return userAccountRoleSelector(store).pipe(
    map(x => {
      return x === 1 || x === 2 || x === 3;
    })
  );
}
