<form [formGroup]="form">
  <div class="row">
    <mat-form-field class="name-control" appearance="fill">
      <mat-label>Tag Name</mat-label>
      <input matInput placeholder="Search tags or enter new tag" formControlName="name">
      <mat-hint>This will be the text that appears on the tag.</mat-hint>
    </mat-form-field>
    <mat-form-field class="hex-code-control">
      <mat-label>Tag Color</mat-label>
      <input readonly class="color-input" matInput [(colorPicker)]="colorHexValue" [style.background]="colorHexValue"
        (colorPickerChange)="form.get('colorHex').setValue($event)" />
      <div class="frequently-used" *ngIf="hexCodes.length > 0">
        <h4>My Colors</h4>
        <div class="colors scrollable">
          <button *ngFor="let hex of hexCodes" mat-mini-fab [style.background-color]="hex" class="hex-code"
            [class.selected]="colorHexValue === hex" (click)="form.get('colorHex').setValue(hex)"></button>
        </div>
      </div>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="fill">
      <mat-label>Choose Account</mat-label>
      <mat-select formControlName="accountId">
        <mat-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Tags for agenda items within this account.</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Choose Group</mat-label>
      <mat-select formControlName="groupId">
        <mat-option [value]="0">All Groups</mat-option>
        <mat-option *ngFor="let group of groups" [value]="group.id">{{ group.name }}</mat-option>
      </mat-select>
      <mat-hint>Tags for agenda items within this group.</mat-hint>
    </mat-form-field>
    <mat-checkbox *ngIf="hasApplyToAll" class="apply-to-all" formControlName="applyToAll"
      [style.left.%]="isGroupAdmin ? 52 : 0">{{ applyToAllText }}
    </mat-checkbox>
  </div>
  <iu-editor id="editor" formControlName="description" height="300" type="FULL"></iu-editor>
  <div class="actions">
    <button mat-button class="btn-cancel" mat-dialog-close>Cancel</button>
    <button *ngIf="form.get('name').value" mat-button class="btn-submit" [mat-dialog-close]="valueOut">Save</button>
  </div>
</form>
