<div class="tab-menu-container">
  <ul
    class="tabs"
    (mouseleave)="onExit()"
  >
    <li
      class="tab"
      *ngFor="let tab of tabs; let i = index; let isFirst = first; let isLast = last;"
      [ngClass]="{ 'tab-first': isFirst, 'tab-last': isLast, 'active': isActive(tab), 'before-hover': isBeforeHover(i), 'after-hover': isAfterHover(i) }"
      (mouseenter)="onEnter(i)"
    >
      <a
        class="tab-link"
        [routerLink]="tab.href"
      >{{ tab.label }}</a>
    </li>
    <!--* [17641] Dropdown seems to be causing some issues -->
    <!--* When uncommented, page seems to not be able to accept touch inputs -->
    <!-- <am-dropdown
      [options]="dropdownOptions"
      [dropdownItems]="dropdownItems"
      actionStyle="tab"
      [debug]="true"
    ></am-dropdown> -->
  </ul>
  <div class="sub-menu"
    [class.dashboard]="((routeName$ | async) === 'dashboard') && (userAccessLevel$ | async) !== 'PUBLIC'">
    <div class="sub-menu-items">
      <a *ngFor="let item of submenu; let i = index; let isLast = last;" [ngClass]="{ 'last': isLast }"
        [routerLink]="item.href">{{ item.label }}</a>
    </div>
    <iu-widgets-menu [@toggle]="showWidgetsMenu ? 'show' : 'hide'" [visible]="showWidgetsMenu"
      (@toggle.done)="onToggleDone($event)" #widgetsMenu></iu-widgets-menu>
    <button class="widgets-menu-trigger" mat-button (click)="showWidgetsMenu = !showWidgetsMenu"
      [class.open]="showWidgetsMenu">
      <mat-icon>apps</mat-icon>
    </button>
  </div>
</div>
