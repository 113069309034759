import { Component, Input } from '@angular/core';
import { AgendaItem } from '../agenda-items.model';
import { HasDetails } from 'src/app/shared/models';

@Component({
  selector: 'am-agenda-item-preview',
  templateUrl: './agenda-item-preview.component.html',
  styleUrls: ['./agenda-item-preview.component.scss']
})
export class AgendaItemPreviewComponent {

  @Input() agendaItem: AgendaItem = new AgendaItem();
  @Input() depth = 0;
  @Input() details: HasDetails = new HasDetails();
  @Input() displayOrder: string;
  @Input() displayMinutes = false;
  @Input() displayType: number = 0;

  constructor() { }

  get marginLeftPx(): number {
    return this.depth === 0 ? 0 : Math.min(20 * this.depth, 80);
  }
}
