import { Action, routeParamIdSelector, build } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { Minutes, AgendaItemMinutes } from './minutes.model';

export class MinutesActions {
  static DELETE = '[Minutes] Delete Minutes';
  static DELETE_QUITE = '[Minutes] Delete Minutes Quietly';
  static DELETE_ERROR = '[Minutes] Delete Minutes Error';
  static GET = '[Minutes] Get Minutes';
  static POST = '[Minutes] Post Minutes';
  static POST_ERROR = '[Minutes] Post Minutes Error';
  static PUT = '[Minutes] Put Minutes';
  static PUT_ERROR = '[Minutes] Put Minutes Error';
}

export function minutesReducer(state: Minutes = new Minutes(), action: Action): Minutes {
  switch (action.type) {
    case MinutesActions.GET:
    case MinutesActions.POST:
    case MinutesActions.PUT:
      return action.payload ? state.update(action.payload) : state;

    case MinutesActions.DELETE:
    case MinutesActions.DELETE_QUITE:
      return state.delete(action.payload);

    default:
      return state;
  }
}

export function minutesSelector(store: Store<any>): Observable<Minutes> {
  return store.select('minutes');
}

export function agendaItemMinutesSelector(store: Store<any>): Observable<AgendaItemMinutes> {
  return combineLatest(minutesSelector(store), routeParamIdSelector(store, 'agendaItemId'),
    (minutes, agendaItemId) => build(AgendaItemMinutes, minutes.asArray.find(x => x.agendaItemId === agendaItemId), {
      agendaItemId
    }));
}
