<div class="email">
  <form id="email-form" [formGroup]="form" iuForm>
    <div class="row heading">
      <h3 class="email-label">Email Meeting Details to Group Members</h3>
      <button mat-raised-button class="btn-select-all" (click)="selectAll()">Select All</button>
      <button mat-raised-button class="btn-clear" (click)="clear()">Clear</button>
    </div>
    <am-email-recipients formControlName="to"></am-email-recipients>
    <am-email-recipients heading="CC Recipients" formControlName="cc"></am-email-recipients>
    <mat-form-field appearance="fill">
      <mat-label>Subject</mat-label>
      <input matInput placeholder="Subject" formControlName="subject">
    </mat-form-field>
    <div class="control">
      <h4>Meeting Details</h4>
      <iu-editor id="editor1" formControlName="body" [height]="300" type="FULL" [init]="editorArgs">
      </iu-editor>
    </div>
    <div class="row actions" [class.hint]="agendaSecurityStatusId === 1">
      <mat-hint *ngIf="agendaSecurityStatusId === 1">
        <span class="bold">Note:</span>This is a private meeting that is only visible to
        you and administrators!
      </mat-hint>
      <button *ngIf="form.valid" mat-raised-button class="btn-send" (click)="send()">SEND EMAIL</button>
    </div>
  </form>
</div>
