import { Collection, Metadata, build } from '@caiu/library';

import { BaseEntity } from '../shared/models';

export class AgendaItemMinutes extends BaseEntity {
  agendaItemId = 0;
  minutes = '';
  typeId = 1;
  typeName = 'Minutes';

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: ['id', 'agendaItemId', 'createdBy', 'minutes', 'typeId', 'typeName']
    });
  }

  set notes(value: string) {
    this.minutes = value;
  }

  get notes(): string {
    return this.minutes;
  }
}

export class Minutes extends Collection<AgendaItemMinutes> {

  constructor() {
    super(AgendaItemMinutes);
  }

  update(data: AgendaItemMinutes | AgendaItemMinutes[]): Minutes {
    return build(Minutes, super.update(data));
  }
}
