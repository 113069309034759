<div class="wrapper" [class.details]="hasDetails" [class.readonly]="!canEditAgenda"
  [class.editing]="routeName === 'agenda-item-edit'">
  <am-agenda-item-actions
    *ngIf="!isPublicUser"
    [hasDetails]="hasDetails"
    [agendaId]="agendaId"
    [agendaItem]="agendaItem"
    [editing]="editing"
    [userId]="userId"
    [userCanEdit]="canEditAgenda"
    [canSuggest]="canSuggest"
    [userHasBin]="userHasBin$ | async"
    [showRight]="true"
    [queryParams]="queryParams"
    (delete)="onDelete()"
    (copyToBin)="addBinItem($event)"
    (save)="saveAgendaItem()"
  >
  </am-agenda-item-actions>
  <div class="suggestion" [class.view-mode]="!editing" *ngIf="agendaItem.isSuggestion && canEditAgenda">
    <mat-icon>notification_important</mat-icon>
    As a contributor to this agenda, please
    <button class="btn-accept" mat-raised-button (click)="onAcceptSuggestion()">Accept</button>
    or
    <button class="btn-reject" mat-raised-button (click)="onRejectSuggestion()">Reject</button>
    this suggested item.
  </div>
  <div class="agenda-item-wrapper scrollable-alt"
    [style.height.px]="hasDetails ? contentHeight - 447 : contentHeight - 260">
    <router-outlet></router-outlet>
  </div>
  <!-- <am-agenda-item-actions *ngIf="!isPublicUser && (routeName === 'agenda-item-edit')" [hasDetails]="false"
    [agendaId]="agendaId" [agendaItem]="agendaItem" [editing]="editing" [userId]="userId" [userCanEdit]="canEditAgenda"
    [canSuggest]="canSuggest" [userHasBin]="userHasBin$ | async" [showRight]="false" (delete)="onDelete()"
    (copyToBin)="addBinItem($event)" (save)="saveAgendaItem()"></am-agenda-item-actions> -->

  <div id="bottom-pane" *ngIf="showNotes">
    <am-notes></am-notes>
  </div>
</div>
