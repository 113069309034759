import { Action, build, routeParamIdSelector, toArray } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { Announcements, Announcement } from './announcements.model';
import { Attachment } from '../attachments/attachments.model';
import { accountIdSelector } from '../accounts/accounts.reducer';
import { attachmentsSelector } from '../attachments/attachments.reducer';

export class AnnouncementsActions {
  static ACTIVATE = '[Announcements] Activate';
  static ADD = '[Announcements] Add Announcement';
  static DELETE = '[Announcements] Delete';
  static GET = '[Announcements] Get';
  static GET_ERROR = '[Announcements] Get Error';
  static POST = '[Announcements] Post';
  static POST_ERROR = '[Announcements] Post Error';

  static activate(id: number): Action {
    return {
      type: AnnouncementsActions.ACTIVATE,
      payload: id
    };
  }
}

export class AnnouncementActions {
  static DELETE = '[Announcement] Delete';
  static GET = '[Announcement] Get';
  static PUT = '[Announcement] Put';
  static PUT_ERROR = '[Announcement] Put Error';
  static MOVE_ATTACHMENT = '[Announcement] Move Attachment';
}

export function announcementsReducer(state: Announcements = new Announcements(), action: Action): Announcements {
  switch (action.type) {

    case AnnouncementsActions.GET:
      return state.replace(<Announcement[]>action.payload);

    case AnnouncementsActions.DELETE:
      return build(Announcements, state.delete(<number>action.payload));

    case AnnouncementActions.GET:
      return state.update(<Announcement>action.payload);

    case AnnouncementActions.PUT:
      return state.update(<Announcement>action.payload);

    default:
      return state;
  }
}

export function announcementsSelector(store: Store<any>): Observable<Announcements> {
  return store.select('announcements');
}

export function announcementSelector(store: Store<any>): Observable<Announcement> {
  return combineLatest([announcementsSelector(store), routeParamIdSelector(store, 'announcementId'), attachmentsSelector(store)]).pipe(
    map(x => {
      const data = x[0].get(x[1]);
      return build(Announcement, data, {
        attachments: x[2].asArray.filter(x => x.announcementId === data.id).map(x => build(Attachment, x))
      });
    })
  );
}

export function userAnnouncementsSelector(store: Store<any>): Observable<Announcement[]> {
  return combineLatest([announcementsSelector(store), accountIdSelector(store)]).pipe(
    map(x => x[0].asArray.filter(y => y.announcementTypeId === 1 || y.accountId === x[1]))
  );
}

export function announcementsCountSelector(store: Store<any>): Observable<number> {
  return userAnnouncementsSelector(store).pipe(
    map(x => x.length),
    distinctUntilChanged()
  );
}

export function announcementsEmptySelector(store: Store<any>): Observable<boolean> {
  return announcementsCountSelector(store).pipe(
    map(x => x === 0)
  );
}
