<div class="add-filter">
    <div class="filters">
        <div class="filter" *ngFor="let filter of filters">
            <mat-form-field class="header-select">
                <mat-select placeholder="Column" [value]="filter.column" (selectionChange)="handleSelection($event, filter)">
                    <mat-option *ngFor="let column of getUnfilteredColumns(filter.column)" [value]="column">
                        {{ column.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="filter.column && (filter.column.type === 'string' || filter.column.type === 'string[]')" class="condition-select">
                <mat-select placeholder="Condition" [value]="filter.condition" (selectionChange)="handleCondition($event, filter)">
                    <mat-option *ngFor="let condition of conditions" [value]="condition">
                        {{ condition.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="input" *ngIf="filter.column">
                <mat-form-field class="input-field"
                    *ngIf="filter.column.type === 'string' || filter.column.type === 'string[]' || filter.column.type === 'phone'"
                >
                    <input matInput placeholder="Filter" [value]="filter.value"
                        (keyup)="handleInput($event, filter)" />
                    <button mat-button matSuffix mat-icon-button aria-label="Clear"
                        (click)="filter.value = ''" matTooltip="Close">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="input-field" *ngIf="filter.column.type === 'date'">
                    <input matInput placeholder="Filter Date" [matDatepicker]="picker"
                        (dateInput)="handleDate($event, filter)" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <button mat-button class="remove-filter-btn" (click)="removeFilter(filter)">
                Remove Filter
            </button>
        </div>
    </div>
    <div class="controls" *ngIf="filters.length < columns.length">
        <a mat-raised-button class="btn-add" (click)="addFilter()">
            <span class="btn-label">Add Filter</span>
        </a>
    </div>
</div>