<div class="votes-form">
  <h3>Edit Votes</h3>

  <form [formGroup]="form">
    <mat-form-field class="motion" appearance="outline">
      <mat-label>Motion</mat-label>
      <input type="text" placeholder="Motion" aria-label="Motion" matInput formControlName="motion"
        [matAutocomplete]="auto1" #motionInput>
      <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="selectMotion($event)">
        <mat-option *ngFor="let voter of votersMotion" [value]="voter.voterName">
          {{ voter.voterName }}
        </mat-option>
      </mat-autocomplete>
      <button mat-button *ngIf="motionInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearMotion()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="second" appearance="outline">
      <mat-label>Second</mat-label>
      <input type="text" placeholder="Second" aria-label="Second" matInput formControlName="second"
        [matAutocomplete]="auto2" #secondInput>
      <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="selectSecond($event)">
        <mat-option *ngFor="let voter of votersSecond" [value]="voter.voterName">
          {{ voter.voterName }}
        </mat-option>
      </mat-autocomplete>
      <button mat-button *ngIf="secondInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSecond()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <div class="grid-wrapper">
    <table cellspacing="0" class="grid">
      <thead>
        <tr>
          <th><span class="note">Members must have the "Voter" role to appear here.</span></th>
          <th><a id="all-Yea" title="Select Yea radios for all Members" (click)="applyToAll(1)">Select All</a>
          </th>
          <th><a id="all-Nay" title="Select Nay radios for all Members" (click)="applyToAll(2)">Select All</a>
          </th>
          <th class="all-abstain"><a id="all-Abstain" title="Select Abstain radios for all Members"
              (click)="applyToAll(3)">Select
              All</a></th>
          <th class="all-notcast"><a id="all-NotCast" title="Select Not Cast radios for all Members"
              (click)="applyToAll(4)">Select
              All</a></th>
        </tr>
      </thead>
      <tbody>
        <tr class="vote-form" *ngFor="let vote of formArray.controls" [formGroup]="vote">
          <th>{{ vote?.value?.voterName }}</th>
          <td><input [attr.enabled]="true" type="radio" [value]="1" [checked]="vote?.value?.answerId == 1"
              formControlName="answerId">Yea</td>
          <td><input [attr.enabled]="true" type="radio" [value]="2" [checked]="vote?.value?.answerId == 2"
              formControlName="answerId">Nay</td>
          <td><input [attr.enabled]="true" type="radio" [value]="3" [checked]="vote?.value?.answerId == 3"
              formControlName="answerId">Abstain</td>
          <td><input type="radio" [value]="4" [checked]="vote?.value?.answerId == 4" formControlName="answerId">{{
            vote?.value | notCastText }}</td>
        </tr>
        <tr *ngIf="formArray.controls.length === 0" class="empty">
          <td>No voters have been marked present in attendance.</td>
        </tr>
        <tr class="tally">
          <th scope="row"><em>Total</em></th>
          <td id="yeatotal">{{ yeaCount}}</td>
          <td id="naytotal">{{ nayCount }}</td>
          <td id="abstaintotal">{{ abstainCount }}</td>
          <td id="notcasttotal">{{ notCastCount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="actions">
    <button mat-raised-button class="btn btn-remove font-effect-3d-float" (click)="onCancel()">
      <mat-icon>cancel</mat-icon>
      <span class="icon-text">Cancel</span>
    </button>
    <button mat-raised-button class="btn btn-save font-effect-3d-float" (click)="onSave()">
      <mat-icon>save</mat-icon>
      <span class="icon-text">Save Votes</span>
    </button>
  </div>
</div>
