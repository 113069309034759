import { Collection, build, truthy } from '@caiu/library';

import { BaseEntity } from '../shared/models';

export class Attachment extends BaseEntity {
  accountId = null;
  agendaId = null;
  agendaItemId = null;
  announcementId = null;
  binItemId = 0;
  fileBinary = null;
  fileExtension = '';
  fileId = '';
  fileName = '';
  fileSize = 0;
  isPrivate = false;
  mimeType = '';
  order = 0;
  clearSrc = false;

  get src(): string {
    return truthy(this.fileBinary) && !this.clearSrc ? `data:${this.mimeType};base64,${this.fileBinary}` : '';
  }

  get type(): string {
    return this.mimeType;
  }

  set name(value: string) {
    this.fileName = value;
  }

  get name(): string {
    return this.fileName;
  }
}

export class Attachments extends Collection<Attachment> {
  constructor() {
    super(Attachment);
  }

  findForAgendaItem(agendaItemId: number): Attachment[] {
    return this.asArray.filter(x => x.agendaItemId === agendaItemId);
  }

  updateForAgendaItem(data: Attachment[]): Attachments {
    return build(
      Attachments,
      this.update(
        data.map(x =>
          build(Attachment, x, {
            agendaItemId: x.agendaItemId,
            binItemId: 0
          })
        )
      )
    );
  }

  updateForAnnouncement(data: Attachment[]): Attachments {
    return build(
      Attachments,
      this.update(
        data.map(x =>
          build(Attachment, x, {
            announcementId: x.announcementId,
          })
        )
      )
    );
  }
}
