<li
    [class]="actionClassName"
>
    <span class="dropdown-action_label">{{ options.label }}</span>
    <mat-icon
        *ngIf="!!options.icon"
        class="dropdown-action_icon"
    >
        {{ options.icon }}
    </mat-icon>
    <ul class="dropdown-container">
        <li
            *ngFor="let item of items"
            class="dropdown-item"
            (click)="itemClick( $event, item )"
        >
            <span class="dropdown-item_label">
                {{ item.label }}
            </span>
        </li>
    </ul>
</li>

<ul
    id="debuglist"
    *ngIf="debug"
    style="box-sizing: border-box; position: absolute; bottom: 0; left: 0; z-index: 1000; background-color: white; padding: 0.5em; color: black; opacity: 0.8;"
>
    <li *ngFor="let log of eventLogs">
        <span>
            {{log}}
        </span>
    </li>
</ul>
