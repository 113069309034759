import { Injectable } from '@angular/core';
import { Action, RouterActions, routeParamIdSelector, HttpActions, urlSelector } from '@caiu/library';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { accountUrlSelector } from '../accounts/accounts.reducer';
import { AgendaItem } from '../agenda-items/agenda-items.model';

import { BinActions } from './bin-items.reducer';

@Injectable()
export class BinEffects {
  /**
   * Close dialog after successful API request.
   */
  @Effect() onDelete: Observable<Action> = this.actions$.pipe(
    ofType(BinActions.DELETE, BinActions.ADD_AGENDA_ITEM),
    map((action: Action) => RouterActions.navigate([{ outlets: { popup: null } }]))
  );

  /**
   * Redirect to agenda item after adding new agenda item.
   */
  @Effect() onPost: Observable<Action> = this.actions$.pipe(
    ofType(BinActions.ADD_AGENDA_ITEM, BinActions.ADD_AGENDA_ITEM_AND_REMOVE),
    map((action: Action) => this.redirectToAgendaItem(<AgendaItem>action.payload))
  );

  /**
   * Delete bin item after adding with remove after copy error.
   */
  // @Effect() onPost: Observable<Action> = this.actions$.pipe(
  //   ofType(BinActions.ADD_AGENDA_ITEM_AND_REMOVE),
  //   map((action: Action) => HttpActions.delete(`binitems/${this.binItemId}`, this.binItemId, BinActions.DELETE, BinActions.DELETE_ERROR))
  // );

  accountUrl = '';
  binItemId = 0;

  constructor(private actions$: Actions, private store: Store<any>) {
    routeParamIdSelector(store, 'binItemId').subscribe(id => {
      this.binItemId = id;
    });
    accountUrlSelector(store).subscribe(url => {
      this.accountUrl = url;
    });
  }

  redirectToAgendaItem(agendaItem: AgendaItem): Action {
    return RouterActions.navigate(`/${this.accountUrl}/meetings/${agendaItem.agenda.meetingId}/agendas/${agendaItem.agendaId}/agendaitems/${agendaItem.id}/edit`);
  }
}
