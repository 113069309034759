import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchWidgetComponent } from './search-widget.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [SearchWidgetComponent],
  exports: [SearchWidgetComponent]
})
export class SearchWidgetModule { }
