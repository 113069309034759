import { Collection, inArray, build, BaseEntity, Dictionary, Metadata, Validators, toArray, toInt, atLeastOneAlphaNumericValidator, QueryModel } from '@caiu/library';

import { User } from '../shared/models';

export enum GroupRole {
  DEFAULT,
  MANAGER,
  CONTRIBUTOR,
  MEMBER,
  ATTENDANCE_TAKER,
  MINUTE_TAKER,
  VOTE_TAKER,
  VOTER
}

export enum Outlines {
  DEFAULT,
  NUMBER_LETTER,
  NUMBER_NUMBER,
  NUMBER_LETTER_ONLY,
  ROMAN_NUMERAL
}

export class Group extends BaseEntity {
  id = 0;
  accountId = 0;
  administratorId = 0;
  allowSuggestions = false;
  description = '';
  isActive = true;
  name = '';
  outlineId = 1;
  showTitleOutline = false;
  _memberCount = 0;

  administrators: GroupMember[] = [];
  createdByName = '';
  managers: GroupMember[] = [];
  members: GroupMember[] = [];

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [
        ...this.ignore,
        '_memberCount',
        '_members',
        'id',
        'accountId',
        'active',
        'administrators',
        'createdByName',
        'hasRole',
        'inGroup',
        'isActive',
        'lastModifiedByName',
        'managers',
        'memberCount',
        'members',
        'roleIds',
        'roles',
        'users'
      ],
      groupMembers: {
        isFormArray: true,
        type: GroupMemberEdit
      },
      name: {
        validators: [Validators.required, Validators.maxLength(150), atLeastOneAlphaNumericValidator]
      }
    });
  }

  set groupMembers(value: GroupMemberEdit[]) {
    this.members = GroupMemberEdit.FromRows(value);
  }

  get groupMembers(): GroupMemberEdit[] {
    return GroupMemberEdit.ToRows(this.members);
  }

  get memberCount(): number {
    return this.groupMembers.length;
  }

  set memberCount(value: number) {
    this._memberCount = value;
  }

  get users(): Dictionary<number[]> {
    return this.members.reduce((acc, member) => {
      const roles = acc[member.userId] || [];
      return Object.assign({}, acc, { [member.userId]: [...roles, member.groupRoleId].sort() });
    }, {});
  }

}

export class Groups extends Collection<Group> {

  constructor() {
    super(Group);
  }

  update(data: Group | Group[]): Groups {
    return build(Groups, super.update(data));
  }

  updateFromGroupMembers(data: GroupMember[]): Groups {
    const items = data.reduce((acc, x) => {
      const existing = acc.find(y => y.id === x.groupId) || this.get(x.groupId);
      return [...acc.filter(y => y.id !== x.groupId), build(Group, existing, {
        id: x.groupId,
        name: x.groupName
      })];
    }, []);
    return this.update(items);
  }
}

export class GroupMember {
  id = 0;
  accountId = 0;
  groupId = 0;
  groupIsActive = true;
  groupName = '';
  groupRoleName = '';
  groupRoleId = 0;
  userEmail = '';
  userFirstName = '';
  userLastName = '';
  userId = 0;
  userIsActive = true;
  _userName = '';


  set userName(value: string) {
    this._userName = value;
    const names = value.split(' ');
    this.userFirstName = names[0];
    this.userLastName = names[names.length - 1];
  }

  get userName(): string {
    return this._userName;
  }
}

export class GroupRow {

  constructor(private _group: Group) {
  }

  get group(): Group {
    return build(Group, this._group);
  }

  get groupId(): number {
    return this.group.id;
  }

  get groupName(): string {
    return this.group.name;
  }

  get memberCount(): number {
    return this.group.memberCount;
  }

  get createdBy(): string {
    return this.group.createdByName;
  }

}

export class GroupMemberEdit {
  emailAddress = '';
  groupId = 0;
  userId = 0;
  userName = '';
  _isMember = false;
  _isContributor = false;
  isManager = false;
  isVoter = false;
  isVoteTaker = false;
  isMinuteTaker = false;
  isAttendanceTaker = false;
  user: User = new User();
  metadata: Metadata = {
    ignore: ['members', 'hasRole', 'inGroup', 'roleIds', 'roles', 'user', 'addRole']
  };

  static FromRows(data: GroupMemberEdit[]): GroupMember[] {
    return data.map(x => Object.assign(new GroupMemberEdit([]), x)).reduce((acc, x) => {
      const members = x.members;
      return [...acc, ...members];
    }, []);
  }

  static ToRows(data: GroupMember[]): GroupMemberEdit[] {
    const grouped = data.reduce((acc, x) => {
      return Object.assign({}, acc, {
        [x.userId]: [...toArray(acc[x.userId]), x]
      });
    }, {});
    return Object.keys(grouped).map(key => new GroupMemberEdit(grouped[key]));
  }

  constructor(public _members: GroupMember[] = []) {
    this.groupId = _members[0] ? _members[0].groupId : 0;
    this.userId = _members[0] ? _members[0].userId : 0;
    this.emailAddress = _members[0] ? _members[0].userEmail : '';
    this.userId = _members[0] ? _members[0].userId : 0;
    this.userName = _members[0] ? _members[0].userName : '';
    _members.forEach(x => this.addRole(x.groupRoleId));
  }

  get inGroup(): boolean {
    return this.roleIds && this.roleIds[0] !== 0;
  }

  set isContributor(value: boolean) {
    this._isContributor = value;
  }

  get isContributor(): boolean {
    return this.isManager || this._isContributor;
  }

  set isMember(value: boolean) {
    this._isMember = value;
  }

  get isMember(): boolean {
    return this.isContributor || this._isMember;
  }

  get members(): GroupMember[] {
    return this.roleIds.map(id => build(GroupMember, {
      id: build(GroupMember, this._members.find(x => x.userId === this.userId && x.groupRoleId === toInt(id))).id,
      groupId: this.groupId,
      groupRoleId: toInt(id),
      userId: this.userId
    }));
  }

  get roleIds(): number[] {
    return Object.keys(this.roles).reduce((acc, key) => this.roles[key] ? [...acc, key] : acc, []);
  }

  get roles(): any {
    return {
      1: this.isManager,
      2: this.isContributor,
      3: this.isMember,
      4: this.isAttendanceTaker,
      5: this.isMinuteTaker,
      6: this.isVoteTaker,
      7: this.isVoter
    };
  }

  addRole(roleId: number) {
    switch (roleId) {
      case 1:
        this.isManager = true;
        break;
      case 2:
        this.isContributor = true;
        break;
      case 3:
        this.isMember = true;
        break;
      case 4:
        this.isAttendanceTaker = true;
        break;
      case 5:
        this.isMinuteTaker = true;
        break;
      case 6:
        this.isVoteTaker = true;
        break;
      case 7:
        this.isVoter = true;
        break;
    }
  }

  hasRole(roleId: number) {
    return inArray(this.roleIds, roleId);
  }
}

export class GroupMembers extends Collection<GroupMember> {
  constructor() {
    super(GroupMember);
  }

  update(data: GroupMember | GroupMember[]): GroupMembers {
    return build(GroupMembers, super.update(data));
  }

  updateForGroup(groupId: number, members: GroupMember[]): GroupMembers {
    const items = Collection.BuildDictionaryFromArray([...this.asArray.filter(x => x.groupId !== groupId), ...members]);
    return build(GroupMembers, this, { items });
  }

  updateForGroups(data: Group[]): GroupMembers {
    return data.reduce((acc, x) => acc.updateForGroup(x.id, x.members), this);
  }
}
