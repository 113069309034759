<div class="agenda-item-actions" [class.details]="hasDetails">
  <div class="actions">
    <span *ngIf="editing">
      <button class="btn-save font-effect-3d-float" mat-raised-button color="accent" (click)="save.emit()">
        {{ saveButtonText }}
      </button>
      <span class="or">or</span>
      <a class="btn-cancel" [routerLink]="editLink">Exit Edit Mode</a>
    </span>
    <!-- <span *ngIf="showSuggestions" class="suggestionbuttons">
      <input type="button" value="Reject" class="button fat btnreject" />
    </span> -->
  </div>

  <div *ngIf="showRight" class="normal-view">
    <a mat-raised-button color="primary" class="btn-sm font-effect-3d-float btn-paste" *ngIf="showCopyFromBin"
      [routerLink]="binLink" [queryParams]="queryParams">
      <mat-icon>collections_bookmark</mat-icon>
      <span class="btn-label">Paste From Bin</span>
    </a>
    <a mat-raised-button color="primary" class="btn-sm font-effect-3d-float btn-print" *ngIf="showPrintButton" [routerLink]="printLink" [queryParams]="{agendaItem: true}" queryParamsHandling="merge">
      <mat-icon>print</mat-icon>
      <span class="btn-label">Print</span>
    </a>
    <a mat-raised-button color="primary" class="btn-sm font-effect-3d-float btn-edit" *ngIf="showEdit"
      routerLink="edit">
      <mat-icon>edit</mat-icon>
      <span class="btn-label">Edit Mode</span>
    </a>
    <button mat-raised-button color="primary" class="btn-sm font-effect-3d-float" *ngIf="showCopyToBin"
      (click)="onCopyToBin()">
      <mat-icon>add_to_photos</mat-icon>
      <span class="btn-label">Copy To Bin</span>
    </button>
    <a mat-raised-button color="primary" class="btn-sm btn-email font-effect-3d-float" *ngIf="showEmail"
      [routerLink]="emailLink">
      <mat-icon>mail</mat-icon>
      <span class="btn-label">Email</span>
    </a>
    <button mat-raised-button color="primary" class="btn-sm btn-delete font-effect-3d-float" *ngIf="showDelete"
      (click)="delete.emit(agendaItemId)">
      <mat-icon>delete</mat-icon>
      <span class="btn-label">Delete</span>
    </button>
  </div>

</div>
