import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SmartComponent, DialogAction, build, HttpActions, Control, HttpService, MessageSubscription, truthy, Action, routeParamIdSelector } from '@caiu/library';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';

import { EmailItem } from '../email/email.model';
import { emailItemSelector, EmailActions } from '../email/email.reducer';
import { GroupMembersActions } from '../members/members.reducer';
import { EDITOR_ARGS, HasDetails } from '../shared/models';
import { AgendaItem } from '../agenda-items/agenda-items.model';
import { agendaItemSelector } from '../agenda-items/agenda-items.reducer';
import { previewMinutes, previewNotes, previewVotes } from '../shared/utils';
import { Vote } from '../votes/votes.model';
import { AgendaItemMinutes } from '../minutes/minutes.model';
import { AgendaItemNotes } from '../notes/notes.model';

@Component({
  selector: 'am-email-item',
  templateUrl: './email-item.component.html',
  styleUrls: ['./email-item.component.scss'],
  animations: [
    trigger('toggle', [
      state('show', style({ height: '*' })),
      state('hide', style({ height: '0px' })),
      transition('show <=> hide', [
        animate('500ms ease-out')
      ])
    ])
  ]
})
export class EmailItemComponent extends SmartComponent implements OnInit {

  @Control(EmailItem) form: FormGroup;
  activeId = 0;
  _agendaItem: AgendaItem = new AgendaItem();
  agendaItem$: Observable<AgendaItem>;
  agendaItemId$: Observable<number>;
  agendaItemIdSubject = new BehaviorSubject(0);
  details: HasDetails = new HasDetails();
  editorArgs = EDITOR_ARGS;
  _emailItem: EmailItem = new EmailItem();
  emailItem$: Observable<EmailItem>;
  minutes = '';
  minutes$: Observable<string>;
  notes = '';
  notes$: Observable<string>;
  routeName = 'email-item';
  showEmailSent$: Observable<boolean>;
  showPreview = false;
  votes: Vote[] = [];
  votes$: Observable<Vote[]>;
  messages = [
    build(MessageSubscription, {
      action: EmailActions.POST,
      channel: 'TOASTS',
      mapper: e => `Email sent successfully!`
    }),
    build(MessageSubscription, {
      action: EmailActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `An error occurred while sending the email.`
    })
  ];

  constructor(public store: Store<any>, public dialogRef: MatDialogRef<EmailItemComponent>, public http: HttpService) {
    super(store);
    this.agendaItem$ = agendaItemSelector(store);
    this.agendaItemId$ = routeParamIdSelector(store, 'agendaItemId');
    this.emailItem$ = emailItemSelector(store);
    this.showEmailSent$ = of(false);
    this.minutes$ = this.agendaItemIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendaitems/${id}/minutes`)),
      map(x => build(AgendaItemMinutes, x).minutes),
      filter(y => truthy(y))
    );
    this.notes$ = this.agendaItemIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendaitems/${id}/notes`)),
      map(x => build(AgendaItemNotes, x).notes)
    );
    this.votes$ = this.agendaItemIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendaitems/${id}/votes`)),
      map(x => x.map(y => build(Vote, y)))
    );
  }

  get actions(): DialogAction[] {
    return [
      build(DialogAction, { value: null, label: 'Close' }),
      build(DialogAction, { value: 'send', label: 'Send', color: 'accent' }),
    ];
  }

  set agendaItem(value: AgendaItem) {
    this._agendaItem = value;
  }

  get agendaItem(): AgendaItem {
    return this._agendaItem;
  }

  set agendaItemId(value: number) {
    this.agendaItemIdSubject.next(value);
  }

  get agendaItemId(): number {
    return this.agendaItemIdSubject.value;
  }

  get emailContent(): string {
    return `
      ${this.details.notes ? previewNotes(this.notes) : ''}
      ${this.details.minutes ? previewMinutes(this.minutes) : ''}
      ${this.details.voting ? previewVotes(this.votes) : ''}
    `;
  }

  set emailItem(value: EmailItem) {
    this._emailItem = value;
    this.setValue(value);
  }

  get emailItem(): EmailItem {
    return this._emailItem;
  }

  get title(): string {
    return 'Email';
  }

  get valueOut(): EmailItem {
    return build(EmailItem, this.emailItem, this.form.value, {
      body: `${this.form.value.body}`,
      agendaItem: this.agendaItem,
      addAttachments: this.details.attachments,
      convertAttachments: this.details.appendAttachments,
      content: this.emailContent
    });
  }

  ngOnInit() {
    this.onInit();
    this.sync(['emailItem', 'agendaItem', 'agendaItemId', 'minutes', 'notes', 'votes']);
  }

  onSend() {
    this.addEmail(this.valueOut);
  }

  addEmail(emailItem: EmailItem) {
    this.http.post(`agendaitems/${this.agendaItem.id}/email`, emailItem).pipe(
      map((model: EmailItem) => HttpActions.postSuccess(model, EmailActions.POST)),
      catchError((e: Error) => {
        return of(HttpActions.postError(e, EmailActions.POST_ERROR));
      })
    ).subscribe((action: Action) => {
      this.dispatch(action);
      if (action.type === EmailActions.POST) {
        this.dialogRef.close();
      }
    });
  }

  getGroupMembers(groupId: number) {
    this.dispatch(HttpActions.get(`groups/${groupId}/members`, GroupMembersActions.GET));
  }

}
