import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

import { Logger } from '../shared/utils/logger'
import { PostMessagePayload } from './popout.model'

@Injectable( { providedIn: 'root' } )
export class PopoutService<Data = { [ key: string ]: any } | null, Actions = ''> {
    private readonly logger = new Logger( PopoutService.name )
    
    private messageToPopoutSource = new Subject<PostMessagePayload<Data, Actions>>()
    public messageToPopout$ = this.messageToPopoutSource.asObservable()

    sendMessageToPopout( data: PostMessagePayload<Data, Actions> ) {
        this.logger.log( 'sendMessageToPopout', { data } )
        this.messageToPopoutSource.next( data )
    }
}

