import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  SmartComponent,
  routeParamIdSelector,
  HttpActions,
  build,
  ConfirmDeleteComponent,
  MessageSubscription
} from "@caiu/library";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { Attendee } from "./attendance.model";
import {
  AttendanceActions,
  attendeesSelector,
  attendanceLogSelector,
} from "./attendance.reducer";
import { GroupMember } from "../groups/groups.model";
import { GroupMembersActions } from "../members/members.reducer";
import { agendaGroupIdSelector } from "../agendas/agendas.reducer";
import { AttendanceEventComponent } from "./attendance-event/attendance-event.component";

@Component({
  selector: "am-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.scss"],
})
export class AttendanceComponent extends SmartComponent implements OnInit {
  @ViewChild("writeInsInput") writeInsEl: ElementRef;
  attendance: Attendee[] = [];
  attendance$: Observable<Attendee[]>;
  attendees: Attendee[] = [];
  attendees$: Observable<Attendee[]>;
  _agendaId = 0;
  agendaId$: Observable<number>;
  deleting: number;
  editing: Attendee;
  _groupId = 0;
  groupId$: Observable<number>;
  groupMembers$: Observable<GroupMember[]>;
  messages = [
    build(MessageSubscription, {
      action: AttendanceActions.SAVE,
      channel: 'TOASTS',
      mapper: e => `Attendance saved successfully.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.SAVE_ERROR,
      channel: 'ERRORS',
      mapper: e => `An error occurred while saving attendance. Please try again.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.PUT,
      channel: 'TOASTS',
      mapper: e => `Attendance saved successfully.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.PUT_ERROR,
      channel: 'ERRORS',
      mapper: e => `An error occurred while saving attendance. Please try again.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.POST,
      channel: 'TOASTS',
      mapper: e => `Attendance saved successfully.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `An error occurred while saving attendance. Please try again.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.DELETE,
      channel: 'TOASTS',
      mapper: e => `Attendance log item deleted successfully.`
    }),
    build(MessageSubscription, {
      action: AttendanceActions.DELETE_ERROR,
      channel: 'ERRORS',
      mapper: e => `An error occurred while deleting attendance log item. Please try again.`
    }),

  ];

  constructor(public store: Store<any>, public dialog: MatDialog) {
    super(store);
    this.agendaId$ = routeParamIdSelector(store, "agendaId");
    this.attendance$ = attendanceLogSelector(store);
    this.attendees$ = attendeesSelector(store);
    this.groupId$ = agendaGroupIdSelector(store);
  }

  set agendaId(value: number) {
    this._agendaId = value;
    if (value) {
      this.getAttendees(value);
    }
  }

  get agendaId(): number {
    return this._agendaId;
  }

  set groupId(value: number) {
    this._groupId = value;
    if (value) {
      this.getGroupMembers(value);
    }
  }

  get groupId(): number {
    return this._groupId;
  }

  ngOnInit() {
    this.onInit();
    this.sync(["agendaId", "groupId", "attendance", "attendees"]);
  }

  closeDialog(e: any) {
    if (e && this.deleting) {
      this.deleteAttendee(this.deleting);
    } else if (e && this.editing) {
      this.updateAttendee(build(Attendee, this.editing, e));
    }
    this.deleting = null;
    this.editing = null;
    super.closeDialog(e);
  }

  onDelete(e: Attendee) {
    this.deleting = e.id;
    this.openDialog(ConfirmDeleteComponent, {
      data: {
        question: `Are you sure you want to delete this attendance log item?`,
      },
    });
  }

  onEdit(e: Attendee) {
    this.editing = e;
    this.openDialog(AttendanceEventComponent, {
      data: e,
    });
  }

  getAttendees(agendaId: number) {
    this.dispatch(
      HttpActions.get(`agendas/${agendaId}/attendance`, AttendanceActions.GET)
    );
  }

  getGroupMembers(groupId: number) {
    this.httpGet(`groups/${groupId}/members/all`, GroupMembersActions.GET_GROUP);
  }

  saveAgendaAttendance(agendaId: number, attendance: Attendee[]) {
    const data = attendance.map((x) => {
      const d = new Date(x.timestamp);
      const timestamp = new Date(d.setHours(d.getHours()));
      return build(Attendee, x, { timestamp });
    });
    this.dispatch(
      HttpActions.post(
        `agendas/${agendaId}/attendance`,
        data,
        AttendanceActions.SAVE,
        AttendanceActions.SAVE_ERROR
      )
    );
  }

  addAttendee(e: Attendee) {
    const d = new Date(e.timestamp);
    const timestamp = new Date(d.setHours(d.getHours()));
    const data = build(Attendee, e, { timestamp });
    this.dispatch(HttpActions.post(`attendance`, data, AttendanceActions.POST, AttendanceActions.POST_ERROR));
  }

  deleteAttendee(id: number) {
    this.dispatch(
      HttpActions.delete(`attendance/${id}`, id, AttendanceActions.DELETE, AttendanceActions.DELETE_ERROR)
    );
  }

  updateAttendee(e: Attendee) {
    const d = new Date(e.timestamp);
    const timestamp = new Date(d.setHours(d.getHours()));
    const data = build(Attendee, e, { timestamp });
    this.dispatch(
      HttpActions.put(`attendance/${e.id}`, data, AttendanceActions.PUT, AttendanceActions.PUT_ERROR)
    );
  }

  markAllAbsent() {
    this.saveAgendaAttendance(
      this.agendaId,
      this.attendees.map((x) =>
        build(Attendee, x, { isPresent: false, timestamp: new Date() })
      )
    );
  }

  markAllPresent() {
    this.saveAgendaAttendance(
      this.agendaId,
      this.attendees.map((x) =>
        build(Attendee, x, { isPresent: true, timestamp: new Date() })
      )
    );
  }

  markAbsent(e: Attendee) {
    const attendee = build(Attendee, e, {
      isPresent: false,
      timestamp: new Date(),
    });
    if (e.id === 0) {
      this.addAttendee(attendee);
    } else {
      this.updateAttendee(attendee);
    }
  }

  markPresent(e: Attendee) {
    const attendee = build(Attendee, e, {
      isPresent: true,
      timestamp: new Date(),
    });
    if (e.id === 0) {
      this.addAttendee(attendee);
    } else {
      this.updateAttendee(attendee);
    }
  }

  join(e: Attendee) {
    this.addAttendee(
      build(Attendee, e, { id: 0, isPresent: true, timestamp: new Date() })
    );
  }

  leave(e: Attendee) {
    this.addAttendee(
      build(Attendee, e, { id: 0, isPresent: false, timestamp: new Date() })
    );
  }

  remove(e: Attendee) {
    const attendee = build(Attendee, e, {
      id: 0,
      isPresent: null,
      timestamp: new Date(),
    });
    if (e.id === 0) {
      this.addAttendee(attendee);
    } else {
      this.updateAttendee(attendee);
    }
  }

  writeIn(writeIns: string) {
    if (writeIns) {
      const attendee = build(Attendee, {
        isPresent: true,
        agendaId: this.agendaId,
        timestamp: new Date(),
        userId: null,
        writeIns,
      });
      this.writeInsEl.nativeElement.value = "";
      this.addAttendee(attendee);
    }
  }
}
