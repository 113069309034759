import { NgModule } from '@angular/core'
import {
    Routes,
    RouterModule
} from '@angular/router'

import { PopoutMinutesComponent } from './popouts/popout-minutes/popout-minutes.component'

const routes: Routes = [
    {
        path: 'minutes',
        component: PopoutMinutesComponent,
        data: {
            routeName: 'popout-minutes',
            RouterLabel: 'Minutes Popout'
        }
    }
]

@NgModule( {
    imports: [ RouterModule.forChild( routes ) ],
    exports: [ RouterModule ],
    providers: []
} )
export class PopoutRoutingModule { }

