export const environment = {
  local: false,
  demo: false,
  dev: false,
  test: false,
  staging: true,
  production: false,
  apiBaseUrl: 'https://staging-api.agendamanager.com/api',
  webBaseUrl: 'https://staging.agendamanager.com'
};
