import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { TimeModule } from '@caiu/library';

import { AttendanceRoutingModule } from './attendance-routing.module';
import { AttendanceComponent } from './attendance.component';
import { AttendanceEventComponent } from './attendance-event/attendance-event.component';
import { AttendancePreviewComponent } from './attendance-preview/attendance-preview.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    AttendanceRoutingModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatRadioModule,
    TimeModule
  ],
  declarations: [
    AttendanceComponent,
    AttendanceEventComponent,
    AttendancePreviewComponent
  ],
  exports: [
    AttendanceComponent,
    AttendancePreviewComponent
  ]
})
export class AttendanceModule { }
