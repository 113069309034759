<div class="wrapper">
  <div class="roll-call">
    <div class="row heading">
      <span class="name">Attendance</span>
      <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle (click)="markAllPresent()">Mark All Present</mat-button-toggle>
        <mat-button-toggle (click)="markAllAbsent()">Mark All Absent</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="scrollable">
      <div class="row" *ngFor="let attendee of attendees">
        <span class="name">{{ attendee.userName }}</span>
        <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style"
          [value]="attendee.isPresent">
          <mat-button-toggle [value]="true" (click)="join(attendee)">{{ attendee.labelPresent }}
          </mat-button-toggle>
          <mat-button-toggle [value]="false" (click)="leave(attendee)">{{ attendee.labelAbsent }}
          </mat-button-toggle>
          <mat-button-toggle [value]="null" (click)="remove(attendee)">N/A</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div class="write-ins">
    <mat-form-field appearance="fill">
      <mat-label>Write-In Attendees</mat-label>
      <textarea matInput placeholder="Write-ins..." (keyup.enter)="writeIn(writeInsInput.value)"
        #writeInsInput></textarea>
    </mat-form-field>
    <button mat-raised-button (click)="writeIn(writeInsInput.value)" *ngIf="writeInsInput.value">SUBMIT</button>
  </div>
  <div class="attendance-log" *ngIf="attendance.length > 0">
    <h2>Attendance Log</h2>
    <div class="log scrollable">
      <div class="row" *ngFor="let item of attendance">
        <div class="col">
          <p>{{ item.userId ? item.userName : item.writeIns }}</p>
        </div>
        <div class="col">{{ item.status }}</div>
        <div class="col">{{ item.timestamp | date:"shortTime" }}</div>
        <div class="col">
          <mat-icon (click)="onEdit(item)">edit</mat-icon>
          <mat-icon (click)="onDelete(item)">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
