export { ConfigActions } from '@caiu/library';
import { StorageActions, Action, CurrentUser } from '@caiu/library';

import { Account } from './models';

export class AccountsActions {
  static ACTIVATE = '[Accounts] Activate';
  static ACTIVATE_ID = '[Accounts] Activate ID';
  static ACTIVATE_URL = '[Accounts] Activate URL';
  static ACTIVATE_AND_REDIRECT = '[Accounts] Activate and Redirect';
  static GET = '[Accounts] Get';
  static POST = '[Accounts] POST';
  static POST_ERROR = '[Accounts] POST ERROR';

  static activate(account: Account): Action {
    return {
      type: AccountsActions.ACTIVATE,
      payload: account
    };
  }

  static activateId(id: number): Action {
    return {
      type: AccountsActions.ACTIVATE_ID,
      payload: id
    };
  }

  static activateRedirect(account: Account): Action {
    return {
      type: AccountsActions.ACTIVATE_AND_REDIRECT,
      payload: account
    };
  }

  static activateUrl(url: string): Action {
    return {
      type: AccountsActions.ACTIVATE_URL,
      payload: url
    };
  }
}

export class AccountActions {
  static ACTIVATE = '[Account] Set Active Account';
  static ADD_ACCOUNT_LOGO = '[Account] Add Account Member';
  static GET = '[Account] Get';
  static GET_ERROR = '[Account] Get Error';
  static GET_ACCOUNT_LOGO = '[Account] Get Account Logo';
  static PUT = '[Account] PUT';
  static PUT_ERROR = '[Account] PUT ERROR';
}

export class AppActions {
  static INIT_STORE = StorageActions.INIT_STORE;
  static LOAD_JSON = '[App] Load Json';

  static initStore(store: any): Action {
    return {
      type: AppActions.INIT_STORE,
      payload: store
    };
  }
}

export class DashboardActions {
  static GET = `[Dashboard] Get`;
  static GET_ERROR = `[Dashboard] Get Error`;
  static GET_MESSAGE = `[Dashboard] Get Message`;
  static UPDATE_MESSAGE = `[Dashboard] Update Message`;
}

export class SearchActions {
  static POST = '[Search] Post';
}

export class CurrentUserActions {
  static GET = '[CurrentUser] Get';
  static PUT = '[CurrentUser] Put';
  static PUT_ERROR = '[CurrentUser] Put Error';
  static RECOVER_PASSWORD = '[CurrentUser] Recover Password';
  static RECOVER_PASSWORD_ERROR = '[CurrentUser] Recover Password Error';
}

export class UserActions {
  static ACTIVATE = '[User] Set Active User';
  static GET = '[User] Get'
  static GET_ERROR = '[User] Get Error'
  static GET_ACCOUNTS = '[User] Get Accounts';
  static GET_ACCOUNTS_ERROR = '[User] Get Accounts Error';
  static DELETE = '[User] Delete';
  static POST = '[User] Post';
  static POST_ERROR = '[User] Post Error';
  static PUT = '[User] Put';
  static PUT_ERROR = '[User] Put Error';
  static IMPERSONATE = '[User] Impersonate User';
  static INVITE = '[User] Invite User';
  static INVITE_ERROR = '[User] Invite User Error';
  static LOGOUT = '[User] Logout';
  static LOGIN_SUCCESS = '[User] Login Success';
  static LOGIN_ERROR = '[User] Login Error';
  static RECOVER_PASSWORD = '[User] Recover Password';
  static RECOVER_PASSWORD_ERROR = '[User] Recover Password Error';
  static RESET_LAST_ACTIVE = '[User] Reset Last Active';
  static RESET_PASSWORD = '[User] Reset Password';
  static RESET_PASSWORD_ERROR = '[User] Reset Password Error';
  static REVERT_TO_ADMIN = '[User] Revert to Admin';
  static UPDATE_PROFILE = '[User] Update Profile';
  static UPDATE_USER = '[User] Update User';

  static impersonate(userId: number): Action {
    return {
      type: UserActions.IMPERSONATE,
      payload: userId
    };
  }

  static logout(): Action {
    return {
      type: UserActions.LOGOUT
    };
  }

  static recoverPassword(): Action {
    return {
      type: UserActions.RECOVER_PASSWORD
    };
  }

  static resetPassword(payload: any): Action {
    return {
      type: UserActions.RESET_PASSWORD,
      payload: payload
    };
  }

  static revertToAdmin(): Action {
    return {
      type: UserActions.REVERT_TO_ADMIN
    };
  }

  static update(user: CurrentUser): Action {
    return {
      type: UserActions.UPDATE_USER,
      payload: user
    };
  }
}

export class UsersActions {
  static GET = '[Users] Get';
  static POST = '[Users] Post';
  static PUT = '[Users] Put';
  static PUT_ERROR = '[Users] Put Error'
}

export class DateRangeActions {
  static SET = '[DateRange] SET';

  static set(payload: number): Action {
    return {
      type: DateRangeActions.SET,
      payload
    };
  }
}

export class GroupIdActions {
  static SET = '[GroupId] SET';

  static set(payload: number): Action {
    return {
      type: GroupIdActions.SET,
      payload
    };
  }
}

export class ViewModeActions {
  static SET = '[ViewMode] SET';

  static set(payload: 'NORMAL' | 'BUILD' | 'PROJECTOR' | 'SLIDESHOW'): Action {
    return {
      type: ViewModeActions.SET,
      payload
    };
  }
}

export class LogActions {
  static POST = '[Log] POST';
}
