import { NgModule } from '@angular/core'

import { SharedModule } from '../../shared/shared.module'
import { DialogConfirmComponent } from './dialog-confirm.component'
import { DialogBaseModule } from '../dialog-base/dialog-base.module'

@NgModule( {
	imports: [
		DialogBaseModule,
		SharedModule
	],
	declarations: [ DialogConfirmComponent ],
	exports: [ DialogConfirmComponent ]
} )
export class DialogConfirmModule { }
