import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  SmartComponent,
  HttpActions,
  RouterService,
  routeParamIdSelector,
  routeParamSelector,
  Control,
  EditorComponent,
  build,
  MessageSubscription,
  FULL_PLUGINS,
  FULL_TOOLBAR
} from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AgendaItemMinutes } from './minutes.model';
import { MinutesActions, minutesSelector } from './minutes.reducer';
import { AgendaItem } from '../agenda-items/agenda-items.model';
import { treeSelector } from '../agenda-items/agenda-items.reducer';
import { Agenda } from '../agendas/agendas.model';
import { agendaNameSelector } from '../agendas/agendas.reducer';
import { Tree, TreeItem } from '../shared/tree';

import { ImageUpload } from '../shared/models'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'am-minutes',
  templateUrl: './minutes.component.html',
  styleUrls: ['./minutes.component.scss']
})
export class MinutesComponent extends SmartComponent implements OnInit {

  @Control(AgendaItemMinutes) form: FormGroup;
  @ViewChild('editor') editorCmpt: EditorComponent;
  accountUrl = '';
  accountUrl$: Observable<string>;
  activeIndex$: Observable<number>;
  _activeItem: TreeItem<AgendaItem> = new TreeItem<AgendaItem>(null);
  activeItem$: Observable<TreeItem<AgendaItem>>;
  agenda$: Observable<Agenda>;
  agendaId = 0;
  agendaId$: Observable<number>;
  _agendaItemId = 0;
  agendaItemId$: Observable<number>;
  agendaItemIdSubject = new BehaviorSubject<number>(null);
  formFocused = false;
  meetingId = 0;
  meetingId$: Observable<number>;
  _minutes: AgendaItemMinutes = new AgendaItemMinutes();
  minutes$: Observable<AgendaItemMinutes>;
  nextId = 0;
  nextId$: Observable<number>;
  previousId = 0;
  previousId$: Observable<number>;
  tree: Tree<AgendaItem> = new Tree<AgendaItem>([], AgendaItem);
  tree$: Observable<Tree<AgendaItem>>;
  agendaItemName$: Observable<string>;
  agendaName$: Observable<string>;
  messages = [
    build(MessageSubscription, {
      action: MinutesActions.DELETE,
      channel: 'TOASTS',
      mapper: e => `Minutes deleted successfully!`
    }),
    build(MessageSubscription, {
      action: MinutesActions.DELETE_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error deleting minutes.`
    }),
    build(MessageSubscription, {
      action: MinutesActions.POST,
      channel: 'TOASTS',
      mapper: e => `Minutes saved successfully!`
    }),
    build(MessageSubscription, {
      action: MinutesActions.POST_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving minutes.`
    }),
    build(MessageSubscription, {
      action: MinutesActions.PUT,
      channel: 'TOASTS',
      mapper: e => `Minutes saved successfully!`
    }),
    build(MessageSubscription, {
      action: MinutesActions.PUT_ERROR,
      channel: 'ERRORS',
      mapper: e => `Error saving minutes.`
    })
  ];

  constructor(public store: Store<any>, private routerService: RouterService) {
    super(store);
    this.accountUrl$ = routeParamSelector(store, 'accountUrl');
    this.agendaId$ = routeParamIdSelector(store, 'agendaId');
    this.agendaItemId$ = routeParamIdSelector(store, 'agendaItemId');
    this.meetingId$ = routeParamIdSelector(store, 'meetingId');
    this.tree$ = treeSelector(store);
    this.agendaName$ = agendaNameSelector(store);
    this.activeIndex$ = combineLatest(this.tree$, this.agendaItemIdSubject.asObservable(), (tree, activeId) => {
      return tree.treeItems.findIndex(x => x.id === activeId);
    });
    this.activeItem$ = combineLatest(this.tree$, this.activeIndex$, (tree, activeIndex) => {
      return activeIndex === -1 ? null : tree.treeItems[activeIndex];
    });
    this.nextId$ = combineLatest(this.tree$, this.activeIndex$, (tree, activeIndex) => {
      const treeItem = tree.treeItems[activeIndex + 1];
      return treeItem ? treeItem.id : null;
    });
    this.previousId$ = combineLatest(this.tree$, this.activeIndex$, (tree, activeIndex) => {
      const treeItem = tree.treeItems[activeIndex - 1];
      return treeItem ? treeItem.id : null;
    });
    this.minutes$ = combineLatest([minutesSelector(store), this.agendaItemIdSubject.asObservable()]).pipe(
      map(x => build(AgendaItemMinutes, x[0].asArray.find(y => y.agendaItemId === x[1])))
    );
  }

  editorArgs = {
      // images_upload_url: `${environment.apiBaseUrl}/images`,
      // spellchecker_rpc_url: '../../../assets/spellchecker/spellchecker.php',
      // spellchecker_rpc_url: 'spellchecker.php',
      images_upload_base_path: environment.apiBaseUrl,
      imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
      images_reuse_filename: true,
      images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
      images_upload_handler: function ( blobInfo, success ) {
        //* Create a JSON payload containing the file name and file bytes and serialize it to a string
        const img = build( ImageUpload, {
          fileContent: blobInfo.base64(),
          fileName: blobInfo.filename(),
          fileSize: blobInfo.blob().size,
          mimeType: blobInfo.blob().type
        } )
        const body = JSON.stringify( img )
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        };
        
        ( async () => {
          const response = fetch( `${ environment.apiBaseUrl }/images`, {
            method: 'POST',
            headers,
            body
          } )
          await ( await response ).json()
            .then( ( data: ImageUpload ) => success( `${ environment.apiBaseUrl }/${ data.location }` ) )
            .catch( ( error ) => {
              console.error( error )
            } )
        } )()
      }
    }
    editorPlugins = [ ...FULL_PLUGINS, 'imagetools' ]
    editorToolbar = [ ...FULL_TOOLBAR ]
    editorExpanded = false

  get actionsWidthPct(): number {
    if (this.minutes.id) {
      if (this.form.dirty) {
        return 100 / 3;
      }
      return 50;
    } else {
      if (this.form.dirty) {
        return 50;
      }
      return 100;
    }
  }

  set activeItem(value: TreeItem<AgendaItem>) {
    this._activeItem = value;
  }

  get activeItem(): TreeItem<AgendaItem> {
    return this._activeItem;
  }

  get agendaItem(): AgendaItem {
    return this.activeItem ? this.activeItem.item : new AgendaItem();
  }

  set agendaItemId(value: number) {
    if (this.form.dirty && this.form.value.notes !== this._minutes.notes) {
      this.onSave();
    }
    this._agendaItemId = value;
    this.agendaItemIdSubject.next(value);
    if (value) {
      this.getMinutes(value);
    }
  }

  get agendaItemId(): number {
    return this._agendaItemId;
  }

  get agendaItemName(): string {
    return this.activeItem ? this.activeItem.item.name : null;
  }

  get elementId(): string {
    return `editor${this.agendaItemId}`;
  }

  set minutes(value: AgendaItemMinutes) {
    this._minutes = value;
    this.setValue(build(AgendaItemMinutes, value));
  }

  get minutes(): AgendaItemMinutes {
    return build(AgendaItemMinutes, this._minutes);
  }

  get valueOut(): AgendaItemMinutes {
    return build(AgendaItemMinutes, this.minutes, this.form.value, {
      agendaItemId: this.agendaItemId
    });
  }

  ngOnInit() {
    this.sync(['activeItem', 'agendaItemId', 'minutes', 'nextId', 'previousId', 'tree']);
    this.onInit();
  }

  goPrevious() {
    const id = this.previousId;
    if (id) {
      this.agendaItemId = id;
    }
  }

  goNext() {
    const id = this.nextId;
    if (id) {
      this.agendaItemId = id;
    }
  }

  addMinutes(minutes: AgendaItemMinutes) {
    this.dispatch(HttpActions.post(`minutes`, minutes, MinutesActions.POST, MinutesActions.POST_ERROR));
  }

  deleteMinutes(id: number) {
    this.dispatch(HttpActions.delete(`minutes/${id}`, id, MinutesActions.DELETE, MinutesActions.DELETE_ERROR));
  }

  getMinutes(id: number) {
    this.dispatch(HttpActions.get(`agendaItems/${id}/minutes`, MinutesActions.GET));
  }

  onFocus(e) {
    setTimeout(() => {
      this.formFocused = true;
    }, 0);
  }

  onFocusout(e) {
    this.formFocused = false;
  }

  changeEditor(event) {
    if (
      event
      && event.event
      && event.event.command === 'mceFullScreen'
    ) {
      this.editorExpanded = !this.editorExpanded
    }
  }

  onSave() {
    if (this.minutes.id === 0) {
      this.addMinutes(this.valueOut);
    } else {
      this.updateMinutes(this.valueOut);
    }
  }

  updateMinutes(minutes: AgendaItemMinutes) {
    this.dispatch(HttpActions.put(`minutes/${minutes.id}`, minutes, MinutesActions.PUT, MinutesActions.PUT_ERROR));
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(e: KeyboardEvent) {
    e.stopPropagation();
    switch (e.code) {
      case 'ArrowRight':
        this.goNext();
        break;
      case 'ArrowLeft':
        this.goPrevious();
        break;
      case 'Tab':
        if (this.formFocused) {
          this.goNext();
        } else {
          this.formFocused = true;
        }
        break;
    }
  }

}
