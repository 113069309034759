import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FormComponent,
  FULL_PLUGINS,
  FULL_TOOLBAR,
  Control,
  build
} from '@caiu/library';

import { AgendaItemMinutes } from '../minutes.model';
import { AgendaItem } from '../../agenda-items/agenda-items.model';
import { ImageUpload } from '../../shared/models'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'am-minutes-form',
  templateUrl: './minutes-form.component.html',
  styleUrls: ['./minutes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinutesFormComponent extends FormComponent {

  @Input() agendaItem: AgendaItem = new AgendaItem()
  @Control(AgendaItemMinutes) form: FormGroup
  @Output() changes = new EventEmitter<AgendaItemMinutes>()
  modelKey = 'agendaItem'
  modelChanges = []

  editorArgs = {
    // images_upload_url: `${environment.apiBaseUrl}/images`,
    // spellchecker_rpc_url: '../../../assets/spellchecker/spellchecker.php',
    // spellchecker_rpc_url: 'spellchecker.php',
    images_upload_base_path: environment.apiBaseUrl,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    images_reuse_filename: true,
    images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
    images_upload_handler: function ( blobInfo, success ) {
      //* Create a JSON payload containing the file name and file bytes and serialize it to a string
      const img = build( ImageUpload, {
        fileContent: blobInfo.base64(),
        fileName: blobInfo.filename(),
        fileSize: blobInfo.blob().size,
        mimeType: blobInfo.blob().type
      } )
      const body = JSON.stringify( img )
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
      
      ( async () => {
        const response = fetch( `${ environment.apiBaseUrl }/images`, {
          method: 'POST',
          headers,
          body
        } )
        await ( await response ).json()
          .then( ( data: ImageUpload ) => success( `${ environment.apiBaseUrl }/${ data.location }` ) )
          .catch( ( error ) => {
            console.error( error )
          } )
      } )()
    }
  }
  editorPlugins = [ ...FULL_PLUGINS, 'imagetools' ]
  editorToolbar = [ ...FULL_TOOLBAR ]
  editorExpanded = false

  constructor() {
    super()
  }

  get agendaItemId(): number {
    return this.agendaItem.id;
  }

  get elementId(): string {
    return `editor${ this.agendaItemId }`
  }

  get minutes(): AgendaItemMinutes {
    return new AgendaItemMinutes()
  }

  get valueOut(): AgendaItemMinutes {
    return build(
      AgendaItemMinutes,
      this.minutes,
      this.form.value
    )
  }

  onChanges() {
    this.changes.emit( this.valueOut )
  }

  changeEditor( event ) {
    if (
      event
      && event.event
      && event.event.command === 'mceFullScreen'
    ) {
      this.editorExpanded = !this.editorExpanded
    }
  }
}
