import { Action, routeParamIdSelector, build, DateHelper } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { Email, EmailItem } from './email.model';
import { userNameSelector } from '../shared/selectors';
import { agendaItemSelector } from '../agenda-items/agenda-items.reducer';
import { meetingNameSelector, meetingDateSelector, meetingLocationSelector, meetingStartTimeSelector, meetingEndTimeSelector } from '../meetings/meetings.reducer';
import { map } from 'rxjs/operators';
import { accountUrlSelector } from '../accounts/accounts.reducer';

export class EmailActions {
  static GET = '[Email] Get';
  static POST = '[Email] Post';
  static POST_ERROR = '[Email] Post Error';
}

export class EmailItemActions {
  static GET = '[EmailItem] Get';
}

export function emailReducer(state: Email = new Email(), action: Action): Email {
  switch (action.type) {

    case EmailActions.POST:
      return state;

    case EmailItemActions.GET:
      return state;

    default:
      return state;
  }
}

export function emailSelector(store: Store<any>): Observable<Email> {
  return store.select('email');
}

export function emailItemSelector(store: Store<any>): Observable<EmailItem> {
  return combineLatest([
    userNameSelector(store),
    agendaItemSelector(store),
    meetingNameSelector(store),
    meetingDateSelector(store),
    meetingStartTimeSelector(store),
    meetingEndTimeSelector(store),
    meetingLocationSelector(store),
    accountUrlSelector(store),
    routeParamIdSelector(store, 'meetingId')
  ]).pipe(
    map(x => {
      return build(EmailItem, {
        subject: x[1]['name'],
        body: `
            ${x[0]} has shared with you agenda item details from the meeting
            <a href="https://app.agendamanager.com/${x[7]}/meetings/${x[8]}">${x[2]}</a>
            on ${DateHelper.FormatDateSlashes(<Date>x[3])} [${x[4]}-${x[5]}]
            ${x[6] ? '@ ' + x[6] : ''}.
            <br><br>The item details are included in the attached PDF.
          `
      });
    })
  );
}
