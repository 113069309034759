import { BaseEntity, build, Collection, inArray, Metadata, Validators } from '@caiu/library';

import { PRIMARY_COLOR } from '../models';

export class Tag extends BaseEntity {
  id = 0;
  accountId = null;
  agendaItemId = 0;
  agendaItemTagId = 0;
  _applyToAll = false;
  checked = false;
  colorHex = PRIMARY_COLOR;
  description = '';
  groupId = null;
  markedForDelete = false;
  name = '';
  order = 0;
  userCanEdit = false;
  userId = 0;

  get metadata(): Metadata {
    return build(Metadata, {
      ignore: [...this.ignore, 'id', '_applyToAll', 'markedForDelete', 'order', 'checked', 'userId'],
      name: {
        // validators: [Validators.required]
      }
    });
  }

  set applyToAll(value: boolean) {
    this._applyToAll = value;
    this.userId = null;
  }

  get applyToAll(): boolean {
    return this._applyToAll || this.userId == null;
  }
}

export class Tags extends Collection<Tag> {

  static CanEditTag(tag: Tag, adminAccountIds: number[], managerGroupIds: number[]): boolean {
    return tag.userId ? true
      : tag.groupId !== null ? inArray(managerGroupIds, tag.groupId)
        : tag.accountId !== null ? inArray(adminAccountIds, tag.accountId)
          : true;
  }

  constructor() {
    super(Tag);
  }

  update(data: Tag | Tag[]): Tags {
    return build(Tags, super.update(data));
  }

}
