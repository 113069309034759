import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService, falsy, toInt } from '@caiu/library';
import { Observable } from 'rxjs';

import { AgendaItemsComponent } from './agenda-items.component';

@Injectable()
export class AgendaItemGuard implements CanActivate {
  constructor(private http: HttpService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const agendaItemId = toInt(route.params['agendaItemId']);
    if (falsy(agendaItemId)) {
      return true;
    }
    return this.http.get(`agendaitems/${agendaItemId}/authorize`);
  }
}

const routes: Routes = [
  {
    path: ':agendaItemId',
    component: AgendaItemsComponent,
    // canActivate: [AgendaItemGuard],
    data: { routeName: 'agenda-items', routeLabel: 'Agenda Items' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('../agenda-item/agenda-item.module').then(m => m.AgendaItemModule)
      },
      {
        path: 'edit',
        pathMatch: 'full',
        loadChildren: () => import('../agenda-item-edit/agenda-item-edit.module').then(m => m.AgendaItemEditModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AgendaItemGuard]
})
export class AgendaItemsRoutingModule { }
