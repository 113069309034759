import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Control, DumbComponent } from '@caiu/library';

import { HasDetails } from 'src/app/shared/models';

@Component({
  selector: 'am-print-options',
  templateUrl: './print-options.component.html',
  styleUrls: ['./print-options.component.scss']
})
export class PrintOptionsComponent extends DumbComponent implements OnInit {

  @Control(HasDetails) form: FormGroup;
  @Input() export = true;
  @Input() hasDescription = true;
  @Input() isPublicUser = false;
  @Input() showMinutes = false;
  @Input() isForAgendaItem = false;
  @Input() isMinutesView = false;
  @Output() changes = new EventEmitter<HasDetails>();
  @Output() doPrint = new EventEmitter();
  @Output() pdf = new EventEmitter();
  @Output() word = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
    this.setValue(new HasDetails( !this.isForAgendaItem ));
    this.addSubscription(this.form.valueChanges.subscribe(x => { this.changes.emit(x); }));
  }
}
