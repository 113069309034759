import { Collection } from '@caiu/library';

import { BaseEntity } from '../shared/models';

export class Template extends BaseEntity {
  id = 0;
  userId = 0;
  templateId = 0;
  name = '';
  // agenda: Agenda = new Agenda();

  // get name(): string {
  //   return this._name || this.agenda.name;
  // }

  // set name(value: string) {
  //   this._name = value;
  // }

}

export class Templates extends Collection<Template> {

  constructor() {
    super(Template);
  }
}
