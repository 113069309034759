import { build } from '@caiu/library';

export class Tab {
  label = '';
  href = '';
  isActive = false;
  name = '';
  submenu: Tab[];

  static Build({ label, href, isActive = false, name = '', submenu = [] }) {
    return build(Tab, { label, href, isActive, name, submenu });
  }

  get id(): string {
    return this.name;
  }

}

export class Tabs {

  static BuildTabs(accountUrl: string, accessLevel: 'PUBLIC' | 'MEMBER' | 'GROUP_ADMIN' | 'ACCOUNT_ADMIN' | 'SYS_ADMIN'): Tab[] {
    let submenu = [];
    switch (accessLevel) {
      case 'SYS_ADMIN':
        submenu = [
          Tab.Build({ label: 'Basic Information', href: `/admin/info` })
        ];
        const tabs = [
          Tabs.BuildTab('accounts', accountUrl, submenu),
          Tabs.BuildTab('announcements', accountUrl, submenu),
          Tabs.BuildTab('users', accountUrl, submenu),
          Tabs.BuildTab('restore', accountUrl, submenu)
        ];
        return accountUrl && accountUrl !== 'admin' ? [...tabs, Tabs.BuildTab('meetings', accountUrl, submenu)] : tabs;
      case 'ACCOUNT_ADMIN':
        submenu = [
          Tab.Build({ label: 'Basic Information', href: `/${accountUrl}/info` }),
          Tab.Build({ label: 'Announcements', href: `/${accountUrl}/announcements` }),
          Tab.Build({ label: 'Groups', href: `/${accountUrl}/groups` }),
        ];
        return [
          Tabs.BuildTab('dashboard', accountUrl, submenu),
          Tabs.BuildTab('meetings', accountUrl, submenu),
          Tabs.BuildTab('members', accountUrl, submenu),
          Tabs.BuildTab('search', accountUrl, submenu),
          Tabs.BuildTab('account-info', accountUrl, submenu)
        ];
      case 'GROUP_ADMIN':
        submenu = [
          Tab.Build({ label: 'Announcements', href: `/${accountUrl}/announcements` })
        ];
        return [
          Tabs.BuildTab('dashboard', accountUrl, submenu),
          Tabs.BuildTab('meetings', accountUrl, submenu),
          Tabs.BuildTab('members', accountUrl, submenu),
          Tabs.BuildTab('search', accountUrl, submenu),
          Tabs.BuildTab('groups', accountUrl, submenu)
        ];
      case 'MEMBER':
        return [
          Tabs.BuildTab('dashboard', accountUrl, submenu),
          Tabs.BuildTab('meetings', accountUrl, submenu),
          Tabs.BuildTab('members', accountUrl, submenu),
          Tabs.BuildTab('search', accountUrl, submenu),
          Tabs.BuildTab('groups', accountUrl, submenu)
        ];
      default: // PUBLIC
        return [
          Tabs.BuildTab('public', accountUrl, submenu),
          Tabs.BuildTab('meetings', accountUrl, submenu),
          Tabs.BuildTab('search', accountUrl, submenu)
        ];
    }
  }

  static BuildTab(name: string, accountUrl: string, submenu: Tab[]): Tab {
    switch (name) {
      case 'account-info':
        return build(Tab, {
          name,
          label: 'Account Management',
          href: `/${accountUrl}/info`,
          submenu
        });
      case 'accounts':
        return build(Tab, {
          name,
          label: 'Accounts',
          href: '/admin/accounts',
          submenu
        });
      case 'announcements':
        return build(Tab, {
          name,
          label: 'Announcements',
          href: '/admin/announcements',
          submenu
        });
      case 'dashboard':
        return build(Tab, {
          name,
          label: 'Dashboard',
          href: `/${accountUrl}/dashboard`,
          submenu
        });
      case 'groups':
        return build(Tab, {
          name,
          label: 'Group Management',
          href: `/${accountUrl}/groups`,
          submenu
        });
      case 'home':
        return build(Tab, {
          name,
          label: 'Home',
          href: `/${accountUrl}/dashboard`,
          submenu
        });
      case 'meetings':
        return build(Tab, {
          name,
          label: 'Meetings',
          href: `/${accountUrl}/meetings`,
          submenu
        });
      case 'members':
        return build(Tab, {
          name,
          label: 'Members',
          href: `/${accountUrl}/members`,
          submenu
        });
      case 'public':
        return build(Tab, {
          name,
          label: 'Home',
          href: `/${accountUrl}/account`,
          submenu
        });
      case 'restore':
        return build(Tab, {
          name,
          label: 'Restore',
          href: '/admin/restore',
          submenu
        });
      case 'search':
        return build(Tab, {
          name,
          label: 'Search',
          href: `/${accountUrl}/search`,
          submenu
        });
      case 'users':
        return build(Tab, {
          name,
          label: 'Users',
          href: '/admin/members',
          submenu
        });
      default:
        return build(Tab, { name });
    }
  }

}
