import { NgModule } from '@angular/core'
import { FormsModule } from '@caiu/library'

import { PopoutRoutingModule } from '../../popout-routing.module'
import { PopoutMinutesComponent } from './popout-minutes.component'
import { SharedModule } from '../../../shared/shared.module'
import { DialogConfirmModule } from '../../../dialogs/dialog-confirm/dialog-confirm.module'

@NgModule( {
    imports: [
        DialogConfirmModule,
        FormsModule,
        PopoutRoutingModule,
        SharedModule
    ],
    declarations: [ PopoutMinutesComponent ],
    exports: [ PopoutMinutesComponent ]
} )
export class PopoutMinutesModule { }

