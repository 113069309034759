import { NgModule } from '@angular/core';

import { BinItemsRoutingModule } from './bin-items-routing.module';
import { BinComponent } from './bin.component';
import { BinEmptyComponent } from './bin-empty/bin-empty.component';
import { AttachmentsModule } from '../attachments/attachments.module';
import { MinutesModule } from '../minutes/minutes.module';
import { NotesModule } from '../notes/notes.module';
import { SharedModule } from '../shared/shared.module';
import { TagsModule } from '../shared/tags/tags.module';

@NgModule({
  imports: [
    SharedModule,
    BinItemsRoutingModule,
    AttachmentsModule,
    MinutesModule,
    NotesModule,
    TagsModule
  ],
  declarations: [
    BinComponent,
    BinEmptyComponent
  ],
  exports: [BinComponent]
})
export class BinItemsModule { }
