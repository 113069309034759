import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { build, Control, HttpService, SmartComponent, truthy } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Group } from 'src/app/groups/groups.model';
import { Account, AccountMember } from '../../models';

import { Tag } from '../tags.model';

@Component({
  selector: 'am-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent extends SmartComponent implements OnInit {

  @Control(Tag) form: FormGroup;
  accounts: Account[] = [];
  adminAccounts: Account[] = [];
  adminAccounts$: Observable<Account[]>;
  adminGroups: Group[] = [];
  adminGroups$: Observable<Group[]>;
  groups: Group[] = [];
  groups$: Observable<Group[]>;
  hexCodes: string[] = [];
  hexCodes$: Observable<string[]>;
  _tag: Tag = new Tag();

  constructor(public store: Store<any>, public http: HttpService, @Inject(MAT_DIALOG_DATA) public data: { tag: Tag, accounts: Account[], groups: Group[] }) {
    super(store);
    this.tag = data.tag;
    this.accounts = data.accounts;
    this.groups = data.groups;
    this.groups$ = this.form.get('accountId').valueChanges.pipe(
      switchMap(id => http.get(`accounts/${id}/groups/user`))
    );
    this.adminAccounts$ = http.get(`accounts/admin`);
    this.adminGroups$ = http.get(`groups/manager`);
  }

  get applyToAll(): boolean {
    return this.hasApplyToAll && this.form.get('applyToAll').value;
  }

  get applyToAllText(): string {
    return this.isGroupAdmin ? `Make tag visible to all members of this group` : `Make tag visible to all members of this account`;
  }

  set colorHexValue(value: string) {
    this.form.get('colorHex').setValue(value);
  }

  get colorHexValue(): string {
    return this.form.get('colorHex').value;
  }

  get hasApplyToAll(): boolean {
    return this.isAccountAdmin || this.isGroupAdmin;
  }

  get isAccountAdmin(): boolean {
    return this.adminAccounts.findIndex(x => x.id === this.form.get('accountId').value) !== -1;
  }

  get isGroupAdmin(): boolean {
    return this.adminGroups.findIndex(x => x.id === this.form.get('groupId').value) !== -1 || (this.isAccountAdmin && truthy(this.form.get('groupId').value));
  }

  set tag(value: Tag) {
    this._tag = value;
    this.setValue(value);
  }

  get tag(): Tag {
    return this._tag;
  }

  get valueOut(): Tag {
    const accountId = this.form.get('accountId').value;
    const groupId = this.form.get('groupId').value;
    return build(Tag, this.tag, this.form.value, {
      accountId: accountId === 0 ? null : accountId,
      groupId: groupId === 0 ? null : groupId,
      userId: this.applyToAll ? null : this.tag.userId
    });
  }

  ngOnInit(): void {
    this.sync(['groups', 'adminAccounts', 'adminGroups']);
  }

}
