import { routeParamIdSelector, Action, build, inArray, Dictionary, falsy, compareNumbers, routeNameSelector } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, merge } from 'rxjs';
import { map, distinctUntilChanged, withLatestFrom, filter, switchMap, startWith } from 'rxjs/operators';

import { AgendaItems, AgendaItem } from './agenda-items.model';
import { Actions } from '@ngrx/effects';
import { actionTypeSelector, currentUserIdSelector } from '../shared/selectors';
import { Tree, TreeItem } from '../shared/tree';
import { agendaItemAttachmentsSelector, attachmentsSelector } from '../attachments/attachments.reducer';
import { BinActions } from '../bin-items/bin-items.reducer';
import { agendaSecurityStatusIdSelector } from '../agendas/agendas.reducer';
import { accountUrlSelector, userIsAccountAdminSelector } from '../accounts/accounts.reducer';
import { isAgendaContributorSelector, isGroupMemberSelector } from '../members/members.reducer';

export class AgendaItemsActions {
  static ACTIVATE = '[AgendaItems] Activate';
  static AGENDA_STREAM = '[AgendaItems] Agenda Stream';
  static GET = '[AgendaItems] Get';
  static GET_ERROR = '[AgendaItems] Get Error';
  static POST = '[AgendaItems] Post';
  static POST_ERROR = '[AgendaItems] Post Error';
  static POST_QUICK = '[AgendaItems] Post Quick';
  static SAVE = '[AgendaItems] Save';
  static SAVE_ERROR = '[AgendaItems] Save Error';

  static activate(id: number): Action {
    return {
      type: AgendaItemsActions.ACTIVATE,
      payload: id
    };
  }
}

export class AgendaItemActions {
  static ADD_ATTACHMENTS = '[AgendaItem] Add Attachments';
  static COPY = '[AgendaItem] Copy';
  static COPY_ERROR = '[AgendaItem] Copy Error';
  static DELETE = '[AgendaItem] Delete';
  static DELETE_ERROR = '[AgendaItem] Delete Error';
  static GET = '[AgendaItem] Get';
  static PUT = '[AgendaItem] Put';
  static PUT_ERROR = '[AgendaItem] Put Error';
  static PUT_STREAM = '[AgendaItem] PUT Stream';
  static REMOVE_ATTACHMENTS = '[AgendaItem] Remove Attachment';
  static SAVE = '[AgendaItem] Save';
  static SAVE_CHANGES = '[AgendaItem] Save Changes';
  static UPDATE_UNSAVED = '[AgendaItem] Update Unsaved';

  static save(payload: AgendaItem): Action {
    return {
      type: AgendaItemActions.SAVE,
      payload
    };
  }

  static updateUnsaved(payload: AgendaItem): Action {
    return {
      type: AgendaItemActions.UPDATE_UNSAVED,
      payload
    };
  }
}

export function agendaItemsReducer(state: AgendaItems = new AgendaItems(), action: Action): AgendaItems {
  switch (action.type) {
    case AgendaItemsActions.ACTIVATE:
      return build(AgendaItems, state.activate(<number>action.payload));

    case AgendaItemsActions.GET:
    case AgendaItemsActions.SAVE:
    case AgendaItemActions.COPY:
      return state.update(<AgendaItem[]>action.payload);

    case AgendaItemsActions.POST:
    case AgendaItemsActions.POST_QUICK:
    case AgendaItemActions.GET:
    case AgendaItemActions.PUT:
    case BinActions.ADD_AGENDA_ITEM:
    case BinActions.ADD_AGENDA_ITEM_AND_REMOVE:
    case AgendaItemActions.DELETE:
    case AgendaItemActions.PUT_STREAM:
      return build(AgendaItems, state.update(<AgendaItem>action.payload));

    case AgendaItemsActions.AGENDA_STREAM:
      return state.update(action.payload);

    case AgendaItemActions.UPDATE_UNSAVED:
      return state.updateUnsaved(<AgendaItem>action.payload);

    case AgendaItemActions.SAVE_CHANGES:
      return build(AgendaItems, state, { saving: true });

    default:
      return state;
  }
}

export function agendaItemsSelector(store: Store<any>): Observable<AgendaItems> {
  return store.select('agendaItems');
}

export function agendaItemSelector(store: Store<any>): Observable<AgendaItem> {
  return combineLatest(
    [
      agendaItemsSelector(store),
      routeParamIdSelector(store, 'agendaItemId'),
      agendaItemAttachmentsSelector(store),
      agendaSecurityStatusIdSelector(store),
      isGroupMemberSelector(store).pipe(distinctUntilChanged()),
      userIsAccountAdminSelector(store).pipe(distinctUntilChanged())
    ]
  ).pipe(
      map(x => {
        const item = x[0].get(x[1]);

        return build(AgendaItem, item, {
          attachments: x[2].filter(y => !y.isPrivate || x[4] || x[5]),
          subItems: AgendaItem.BuildSubItems(item, x[0].asArray)
        });
      })
    )
}

export function agendaItemDescriptionSelector(store: Store<any>): Observable<string> {
  return agendaItemSelector(store).pipe(
    map(x => x.description),
    distinctUntilChanged()
  );
}

export function agendaItemNameSelector(store: Store<any>): Observable<string> {
  return agendaItemSelector(store).pipe(
    map(x => x.name),
    distinctUntilChanged()
  );
}

export function agendaIdItemsChangesSelector(store: Store<any>, actions$: Actions): Observable<AgendaItem[]> {
  return actions$.pipe(
    map(actions => inArray([AgendaItemsActions.GET], actions.type)),
    filter(x => x === true),
    switchMap(x => agendaItemsSelector(store)),
    map(x => x.asArray)
  );
}

export function agendaItemsTreeChangesSelector(actions$: Actions, agendaId$: Observable<number>): Observable<number> {
  return combineLatest(agendaId$, agendaTreeActionsSelector(actions$), (agendaId, payload) => {
    return payload.agendaId === agendaId ? agendaId : null;
  }).pipe(filter(x => x !== null));
}

export function agendaItemsTreeSelector(store: Store<any>, actions$: Actions, agendaId$: Observable<number>): Observable<AgendaItem[]> {
  return agendaItemsTreeChangesSelector(actions$, agendaId$).pipe(
    withLatestFrom(agendaItemsSelector(store), (agendaId, agendaItems) => {
      return agendaItems.asArray.filter(x => x.agendaId === agendaId);
    })
  );
}

export function suggestionsSelector(store: Store<any>): Observable<AgendaItem[]> {
  return agendaItemsSelector(store).pipe(map(agendaItems => agendaItems.asArray.filter(item => item.isSuggestion)));
}

export function agendaTreeItemsSelector(store: Store<any>, actions$: Actions, agendaId$: Observable<number>): Observable<Tree<AgendaItem>> {
  return combineLatest([agendaItemsTreeSelector(store, actions$, agendaId$), routeParamIdSelector(store, 'agendaItemId'), attachmentsSelector(store)]).pipe(
    map(x => {
      const items = x[0]
        .filter(y => !y.isSuggestion)
        .map(y => build(AgendaItem, y, {
          attachments: x[2].findForAgendaItem(y.id)
        }));
      const tree = AgendaItems.BuildTree(items);
      tree.activeId = x[1];
      return tree;
    })
  );
}

export function activeAgendaItemsSelector(store: Store<any>, actions$: Actions): Observable<AgendaItem[]> {
  return agendaTreeItemsSelector(store, actions$, routeParamIdSelector(store, 'agendaId')).pipe(
    map(x => {
      const items = x.orderedItems.map(y => build(AgendaItem, y.item));
      return items;
    })
  );
}

export function agendaSuggestedItemsSelector(store: Store<any>, actions$: Actions, agendaId$: Observable<number>): Observable<TreeItem<AgendaItem>[]> {
  return combineLatest([agendaItemsTreeSelector(store, actions$, agendaId$), isAgendaContributorSelector(store, agendaId$), currentUserIdSelector(store)]).pipe(
    map(x => x[1] ? x[0].filter(y => y.isSuggestion).map(y => new TreeItem(build(AgendaItem, y)))
      : x[0].filter(y => y.isSuggestion && y.createdById === x[2]).map(y => new TreeItem(build(AgendaItem, y)))
    )
  );
}

export function agendaTreeItemsStreamSelector(store: Store<any>, actions$: Actions, agendaId$: Observable<number>): Observable<Tree<AgendaItem>> {
  return combineLatest(
    agendaItemsSelector( store ),
    routeParamIdSelector( store, 'agendaId' ),
    ( items, agendaId ) => {
      return AgendaItems.BuildTree( items.asArray.filter( ( item ) => item.agendaId === agendaId ) )
  } )
}

export function agendaTreeItemsStreamSelector2(
  store: Store<any>,
  actions$: Actions,
  agendaItem$: Observable<AgendaItem>,
): Observable<Tree<AgendaItem>> {
  return combineLatest(
    agendaItemsSelector( store ),
    agendaItem$,
    ( items, agendaItem ) => {
      const tree = AgendaItems.BuildTree( items.asArray.filter( ( item ) => item.agendaId === agendaItem.agendaId ) )
      const subtree = tree.getSubtree( agendaItem.id )

      return subtree
  } )
}

export function agendaTreeActionsSelector(actions$: Actions): Observable<{ agendaId: number }> {
  return merge(
    actionTypeSelector(actions$, AgendaItemsActions.GET),
    actionTypeSelector(actions$, AgendaItemsActions.POST),
    actionTypeSelector(actions$, AgendaItemsActions.POST_QUICK),
    actionTypeSelector(actions$, AgendaItemActions.PUT),
    actionTypeSelector(actions$, AgendaItemActions.COPY),
    actionTypeSelector(actions$, AgendaItemActions.DELETE),
    actionTypeSelector(actions$, AgendaItemsActions.SAVE),
    actionTypeSelector(actions$, AgendaItemsActions.AGENDA_STREAM),
    actionTypeSelector(actions$, BinActions.ADD_AGENDA_ITEM)
  ).pipe(
    map(x => {
      return Array.isArray(x) && x.length > 0 ? { agendaId: x[0].agendaId } : { agendaId: x.agendaId };
    })
  );
}

export function agendaItemIdSelector(store: Store<any>): Observable<number> {
  return routeParamIdSelector(store, 'agendaItemId').pipe(
    startWith(0),
    distinctUntilChanged()
  );
}

export function treeSelector(store: Store<any>): Observable<Tree<AgendaItem>> {
  return combineLatest(agendaItemsSelector(store), routeParamIdSelector(store, 'agendaId'), (agendaItems, agendaId) => {
    return AgendaItems.BuildTree(agendaItems.asArray.filter(x => x.agendaId === agendaId && !x.isSuggestion));
  }
  );
}

export function unsavedAgendaItemOrderSelector(store: Store<any>): Observable<number> {
  return combineLatest([treeSelector(store), routeParamIdSelector(store, 'parentId')]).pipe(
    map(x => {
      const siblings = x[1] ? x[0].items.filter(y => y.parentId === x[1]) : x[0].items.filter(y => falsy(y.parentId));
      return siblings.reduce((acc, item) => (item.order > acc ? item.order : acc), 0) + 1;
    }),
    distinctUntilChanged()
  );
}

export function unsavedAgendaItemsSelector(store: Store<any>): Observable<Dictionary<AgendaItem>> {
  return agendaItemsSelector(store).pipe(map(x => x.unsavedItems));
}

export function unsavedAgendaItemSelector(store: Store<any>): Observable<AgendaItem> {
  return combineLatest(
    unsavedAgendaItemsSelector(store),
    routeParamIdSelector(store, 'agendaItemId'),
    routeParamIdSelector(store, 'agendaId'),
    unsavedAgendaItemOrderSelector(store),
    (items, id, agendaId, nextIndex) => {
      if (items[id]) {
        const item = build(AgendaItem, items[id]);
        const order = item.order || nextIndex;
        return Object.assign(item, { agendaId, order });
      }
      return null;
    }
  );
}

export function quickAgendaItemSelector(store: Store<any>): Observable<AgendaItem> {
  return combineLatest(
    unsavedAgendaItemsSelector(store),
    routeParamIdSelector(store, 'agendaItemId'),
    routeParamIdSelector(store, 'agendaId'),
    unsavedAgendaItemOrderSelector(store),
    (items, id, agendaId, nextIndex) => {
      const item = build(AgendaItem, items[id]);
      const order = item.order || nextIndex;
      return Object.assign(item, { agendaId, order });
    }
  );
}

export function treeLevel0Selector(store: Store<any>): Observable<AgendaItem[]> {
  return treeSelector(store).pipe(
    map(tree => {
      return tree.items.filter(x => x.parentId == null);
    })
  );
}

export function nextAgendaItemOrderSelector(store: Store<any>): Observable<number> {
  return treeLevel0Selector(store).pipe(
    map(x => {
      return build(AgendaItem, x.sort((a, b) => compareNumbers(-1 * a.order, -1 * b.order)).find((x, i) => i === 0)).order + 1;
    })
  );
}

export function isVotableSelector(store: Store<any>): Observable<boolean> {
  return agendaItemSelector(store).pipe(
    map(x => x.isVotable),
    distinctUntilChanged()
  );
}

export function nextAgendaItemLinkSelector(store: Store<any>): Observable<string> {
  return combineLatest([
    treeSelector(store),
    accountUrlSelector(store),
    routeParamIdSelector(store, 'meetingId'),
    routeParamIdSelector(store, 'agendaId'),
    routeParamIdSelector(store, 'agendaItemId'),
    routeNameSelector(store)]).pipe(
      map(x => {
        const activeIndex = x[0].getOrderedIndexById(x[4]);
        const agendaItemId = x[0].getNextId(activeIndex);
        if (!agendaItemId) {
          return null;
        }
        const baseUrl = `/${x[1]}/meetings/${x[2]}/agendas/${x[3]}/agendaitems/${agendaItemId}`;
        return x[5] === 'agenda-item-edit' ? `${baseUrl}/edit` : baseUrl;
      }),
      distinctUntilChanged()
    );
}

export function previousAgendaItemLinkSelector(store: Store<any>): Observable<string> {
  return combineLatest([
    treeSelector(store),
    accountUrlSelector(store),
    routeParamIdSelector(store, 'meetingId'),
    routeParamIdSelector(store, 'agendaId'),
    routeParamIdSelector(store, 'agendaItemId'),
    routeNameSelector(store)]).pipe(
      map(x => {
        const activeIndex = x[0].getOrderedIndexById(x[4]);
        const agendaItemId = x[0].getPreviousId(activeIndex);
        if (!agendaItemId) {
          return null;
        }
        const baseUrl = `/${x[1]}/meetings/${x[2]}/agendas/${x[3]}/agendaitems/${agendaItemId}`;
        return x[5] === 'agenda-item-edit' ? `${baseUrl}/edit` : baseUrl;
      }),
      distinctUntilChanged()
    );
}

export function agendaItemsSavingSelector(store: Store<any>): Observable<boolean> {
  return agendaItemsSelector(store).pipe(
    map(x => x.saving),
    distinctUntilChanged()
  );
}
