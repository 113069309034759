import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { DumbComponent } from '@caiu/library';

import { AgendaItem } from '../agenda-items.model';

@Component({
  selector: 'am-agenda-item-actions',
  templateUrl: './agenda-item-actions.component.html',
  styleUrls: ['./agenda-item-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgendaItemActionsComponent extends DumbComponent {
  @Input() agendaId = 0;
  @Input() agendaItem: AgendaItem = new AgendaItem();
  @Input() editing = false;
  @Input() hasDetails = false;
  @Input() queryParams = {};
  @Input() userCanEdit = false;
  @Input() canSuggest = false;
  @Input() userHasBin = false;
  @Input() userId = 0;
  @Input() showRight = true;
  @Output() copyToBin = new EventEmitter<AgendaItem>();
  @Output() delete = new EventEmitter<number>();
  @Output() save = new EventEmitter();

  constructor() {
    super();
  }

  get agendaItemId() {
    return this.agendaItem.id;
  }

  get hasId(): boolean {
    return !(this.agendaItemId === 0);
  }

  get isSuggestion(): boolean {
    return this.agendaItem.isSuggestion;
  }

  get binLink(): any[] {
    return [`/`, {
      outlets: {
        popup: `bin`
      }
    }];
  }

  get editLink(): any[] {
    return this.agendaItemId ? [`../`, this.agendaItemId] : ['../../'];
  }

  get emailLink(): any[] {
    return [`/`, { outlets: { popup: 'email' } }];
  }

  get saveButtonText(): string {
    return this.canSuggest && !this.userCanEdit ? 'Save Suggestion' : 'Save';
  }

  get showCopyFromBin(): boolean {
    return !this.hasId && !this.isSuggestion;
  }

  get showCopyToBin(): boolean {
    return this.hasId;
  }

  get showDelete(): boolean {
    return this.editing && this.hasId && !this.isSuggestion;
  }

  get showEdit(): boolean {
    return !this.editing && (this.userCanEdit || this.agendaItem.createdById === this.userId);
  }

  get showEmail(): boolean {
    return !this.editing || (this.hasId && !this.isSuggestion);
  }

  get showSuggestions(): boolean {
    return this.isSuggestion;
  }

  get showPrintButton(): boolean {
    return !this.editing
  }

  get printLink(): any {
    return [`/`, { outlets: { popup: 'print' } }];
  }

  onCopyToBin() {
    this.copyToBin.emit(this.agendaItem);
  }
}
