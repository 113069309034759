<div id="AccountInfo-Container" [style.display]="accountInfoDisplay" (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave( $event )">
  <div class="AccountInfo-Section">
    <div class="menu-left">
      <div class="menu-user text">{{ user.fullName }}</div>
      <div class="menu-email text">{{ user.email }}</div>
      <am-current-user-accounts *ngIf="accounts.length > 1" [accounts]="accounts" [activeId]="accountId$ | async"
        (changeAccount)="changeAccount($event)"></am-current-user-accounts>
      <span class="account-name" *ngIf="accounts.length === 1">{{ accounts[0].name }}</span>
    </div>
    <div class="menu-right">
      <a mat-raised-button color="primary" class="btn-sm" id="MyProfile"
        [routerLink]="'/' + accountUrl + '/profile'">Edit Profile</a>
      <a mat-raised-button color="primary" class="btn-sm" id="RevertToAdmin" (click)="revertToAdmin()"
        *ngIf="isImpersonating$ | async">Revert To Admin</a>
    </div>
  </div>
  <div class="AccountInfo-Section-bottom">
    <a class="link" id="Support" href="https://caiu.myportallogin.com/" target="_blank">Contact Support</a>
    <a class="link" id="Manual" href="assets/Agenda_Manager_User_Manual_v2_0.pdf" target="_blank">User Manual</a>
    <a mat-raised-button color="accent" class="btn-sm" id="LogOut" (click)="logout()">Log Out</a>
  </div>
</div>
