import { Component, Input, OnInit } from '@angular/core';
import { HttpService, truthy, build, DumbComponent, compareDates, compareStrings, compareNumbers, toArray } from '@caiu/library';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { sortFullNames } from 'src/app/shared/utils';

import { Attendee } from '../attendance.model';

@Component({
  selector: 'am-attendance-preview',
  templateUrl: './attendance-preview.component.html',
  styleUrls: ['./attendance-preview.component.scss']
})
export class AttendancePreviewComponent extends DumbComponent implements OnInit {

  agendaIdSubject = new BehaviorSubject(0);
  attendance$: Observable<Attendee[]>;
  _attendance: Attendee[] = [];
  attendees: Attendee[] = [];
  attendeesAbsent = '';
  attendeesPresent = '';
  logUpdates: string[] = [];
  writeIns = '';

  constructor(public http: HttpService) {
    super();
    this.attendance$ = this.agendaIdSubject.asObservable().pipe(
      filter(id => truthy(id)),
      switchMap(id => http.get(`agendas/${id}/attendance`)),
      map(x => x.map(y => build(Attendee, y)))
    );
  }

  @Input()
  set agendaId(value: number) {
    this.agendaIdSubject.next(value);
  }

  get agendaId(): number {
    return this.agendaIdSubject.value;
  }

  set attendance(value: Attendee[]) {
    this._attendance = value.sort((a, b) => compareDates(a.timestamp, b.timestamp));
    this.attendees = this._attendance
      .reduce((acc, x) => acc.findIndex(y => y.userId === x.userId && y.writeIns === x.writeIns) === -1 ? [...acc, x] : acc, []);
    this.attendeesAbsent = sortFullNames(this.attendees.filter(x => !x.isPresent).map(x => x.userName))
      .reduce((acc, userName) => acc ? `${acc}, ${userName}` : userName, '');
    this.attendeesPresent = sortFullNames(this.attendees.filter(x => x.isPresent && x.userId).map(x => x.userName || x.writeIns.trim()))
      .reduce((acc, userName) => {
        return acc ? `${acc}, ${userName}` : userName;
      }, '');
    this.writeIns = sortFullNames(this.attendees.filter(x => x.isPresent && !x.userId).map(x => x.userName || x.writeIns.trim()))
      .reduce((acc, userName) => {
        return acc ? `${acc}, ${userName}` : userName;
      }, '');
    const grouped = this._attendance.reverse().reduce((acc, x) => {
      const key = x.userId ? x.userId.toString() : x.writeIns;
      return Object.assign({}, acc, { [key]: [...toArray(acc[key]), x] });
    }, {});
    this.logUpdates = Object.keys(grouped)
      .filter(k => grouped[k].length > 1)
      .map(k => grouped[k].filter((x, i) => i > 0))
      .reduce((acc, x) => [...acc, ...x], [])
      .map(x => {
        const userName = x.userName || x.writeIns.trim();
        const dateTime = new Date(x.timestamp);
        dateTime.setSeconds(0);
        return { userName, status: x.status, dateTime }
      })
      .sort((a, b) => compareDates(a.dateTime, b.dateTime)).reverse()
      .map(x => `${x.userName} ${x.status} @ ${x.dateTime.toLocaleTimeString().replace(':00 ', '')}`)
  }

  get attendance(): Attendee[] {
    return this._attendance;
  }

  ngOnInit() {
    this.sync(['attendance']);
  }

}
