import { Action, StorageActions, build, Token } from '@caiu/library';

import { SearchActions, UserActions, CurrentUserActions, UsersActions, ViewModeActions, DateRangeActions, GroupIdActions } from './actions';
import { Users, LoggedInUser, AccountMember, CurrentUser } from './models';
import { fromLocalStore } from './utils';

export function impersonate(state: CurrentUser, payload: LoggedInUser): CurrentUser {
  return build(CurrentUser, state, { impersonating: login(state, payload) });
}

export function init(state: CurrentUser, payload: any): CurrentUser {
  return build(CurrentUser, payload['currentUser']);
}

export function login(state: CurrentUser, payload: LoggedInUser): CurrentUser {
  const token = build(Token, {
    access_token: payload.access_token,
    expires_in: payload.expires_in
  });
  return build(CurrentUser, state, payload.user, {
    token: token,
    userId: payload.user.id,
    accounts: payload.user.userAccounts.map(x => build(AccountMember, { accountId: x.id, isPrimaryAccount: x['isPrimary'], accountRoleId: x['roleId'] })),
    impersonating: null
  });
}

export function logout(state: CurrentUser, payload: LoggedInUser): CurrentUser {
  return build(CurrentUser, state, { token: new Token(), impersonating: null });
}

export function recoverPassword(state: CurrentUser, payload: any): CurrentUser {
  return build(CurrentUser, state, {
    passwordResetCode: payload.passwordResetCode
  });
}

export function resetPassword(state: CurrentUser, payload: any): CurrentUser {
  return build(CurrentUser, payload);
}

export function revertToAdmin(state: CurrentUser): CurrentUser {
  return build(CurrentUser, state, {
    impersonating: null,
    accounts: state.userAccounts.map(x => build(AccountMember, { accountId: x.id, isPrimaryAccount: x['isPrimary'], accountRoleId: x['roleId'] }))
  });
}

export function currentUserReducer(state: CurrentUser = new CurrentUser(), action: Action): CurrentUser {
  switch (action.type) {
    case StorageActions.INIT_STORE:
      return CurrentUser.Build(action.payload.localStore ? action.payload.localStore.currentUser : {});

    case UserActions.GET_ACCOUNTS:
      return build(CurrentUser, state, { accounts: action.payload });

    case UserActions.IMPERSONATE:
      return impersonate(state, action.payload);

    case UserActions.LOGIN_SUCCESS:
      return login(state, action.payload);

    case UserActions.LOGOUT:
      return logout(state, action.payload);

    case CurrentUserActions.GET:
    case CurrentUserActions.PUT:
      return state.update(action.payload);

    // case UserActions.RECOVER_PASSWORD:
    //   return recoverPassword(state, action.payload);

    // case UserActions.RESET_PASSWORD:
    //   return resetPassword(state, action.payload);

    case UserActions.RESET_LAST_ACTIVE:
      return build(CurrentUser, state, { lastActive: new Date() });

    case UserActions.REVERT_TO_ADMIN:
      return revertToAdmin(state);

    default:
      return state;
  }
}

export function searchReducer(state: any = {}, action: Action): any {
  switch (action.type) {
    case SearchActions.POST:
      return action.payload;

    default:
      return state;
  }
}

export function usersReducer(state: Users = new Users(), action: Action): Users {
  switch (action.type) {

    case UsersActions.GET:
    case UsersActions.PUT:
      return state.update(action.payload);
      
    default:
      return state;
  }
}

export function dateRangeReducer(state: number = 0, action: Action): number {
  switch (action.type) {

    case DateRangeActions.SET:
      return action.payload;

    default:
      return state;
  }
}
export function groupIdReducer(state: number = null, action: Action): number {
  switch (action.type) {

    case GroupIdActions.SET:
      return action.payload;

    default:
      return state;
  }
}

export function viewModeReducer(state: 'NORMAL' | 'BUILD' | 'PROJECTOR' | 'SLIDESHOW' = 'NORMAL', action: Action): 'NORMAL' | 'BUILD' | 'PROJECTOR' | 'SLIDESHOW' {
  switch (action.type) {

    case StorageActions.INIT_STORE:
      return fromLocalStore('viewMode', action, 'NORMAL');

    case ViewModeActions.SET:
      return action.payload;

    default:
      return state;
  }
}
