import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, EditorModule, DialogModule } from '@caiu/library';
import { ColorPickerModule } from 'ngx-color-picker';

import { TagsWidgetComponent } from './tags-widget/tags-widget.component';
import { TagsControlComponent } from './tags-control/tags-control.component';
import { TagComponent } from './tag/tag.component';
import { InlineTagsComponent } from './inline-tags/inline-tags.component';
import { VerticalTagsComponent } from './vertical-tags/vertical-tags.component';
import { TagsPreviewComponent } from './tags-preview/tags-preview.component'

@NgModule({
  declarations: [
    TagsWidgetComponent,
    TagsControlComponent,
    TagsPreviewComponent,
    TagComponent,
    InlineTagsComponent,
    VerticalTagsComponent
  ],
  imports: [
    CommonModule,
    ColorPickerModule,
    DialogModule,
    DragDropModule,
    EditorModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule
  ],
  exports: [
    TagsWidgetComponent,
    TagsControlComponent,
    TagsPreviewComponent,
    InlineTagsComponent,
    VerticalTagsComponent
  ]
})
export class TagsModule { }
