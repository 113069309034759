import {
  build,
  Lookup,
  routeParamSelector,
  inArray,
  Action,
  truthy,
  DateHelper,
  routeParamsSelector as allRouteParamsSelector,
  equals,
  DateRange,
  lookupValuesSelector,
  windowHeightSelector,
  windowWidthSelector,
  LookupValue,
  compareNumbers,
  arrayDistinct,
  Token,
  toArray,
  routeParamIdSelector
} from '@caiu/library';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, withLatestFrom, filter } from 'rxjs/operators';

import { CurrentUser, User, Users, Files } from './models';
import { buildDateRange } from './utils';
import { accountIdSelector, currentUserAccountUrlSelector } from '../accounts/accounts.reducer';
import { activeUserAccountRoleIdSelector, activeUserIsSysAdminSelector, currentUserAccountsSelector } from '../members/members.reducer';
import { state } from '@angular/animations';

export function routeParamsSelector(store: Store<any>, keys: string[]): Observable<any> {
  return allRouteParamsSelector(store).pipe(
    map(x => {
      return Object.keys(x).filter(key => inArray(keys, key)).reduce((acc, key) => {
        return Object.assign({}, acc, { [key]: x[key] });
      }, {});
    }),
    distinctUntilChanged((x, y) => equals(x, y))
  );
}

export function usersSelector(store: Store<any>): Observable<Users> {
  return store.select('users');
}

export function actionTypesSelector(actions$: Actions, actionTypes: string[]): Observable<string> {
  return actions$.pipe(
    map((action: Action) => (inArray([actionTypes], action.type) ? action.type : null)),
    filter(x => x !== null)
  );
}

export function actionTypeSelector(actions$: Actions, actionType: string): Observable<any> {
  return actions$.pipe(
    map((action: Action) => (action.type === actionType ? action.payload : null)),
    filter(x => x !== null)
  );
}

export function authenticatedSelector(store: Store<any>): Observable<boolean> {
  return currentUserSelector(store).pipe(
    map(x => x.authenticated),
    distinctUntilChanged()
  );
}

export function currentUserSelector(store: Store<any>): Observable<CurrentUser> {
  const currentUser$: Observable<CurrentUser> = store.select('currentUser').pipe(map(x => build(CurrentUser, x)));
  return currentUser$.pipe(
    withLatestFrom(usersSelector(store), (cu: CurrentUser, users: Users) => {
      const activeUser = build(CurrentUser, cu.impersonating ? cu.impersonating : cu);
      const user = users.items[activeUser.id];
      return build(CurrentUser, user, activeUser, {
        token: build(Token, activeUser.token)
      });
    })
  );
}

export function authTokenSelector(store: Store<any>): Observable<string> {
  return currentUserSelector(store).pipe(
    map(user => {
      // const token: Token =
      //   user ? (user.impersonating ? (user.impersonating.token ? build(Token, user.impersonating.token) : new Token())
      //     : user.token ? build(Token, user.token) : new Token()) : new Token();
      const token: Token = user && user.token ? build(Token, user.token) : new Token();
      return token.toString();
    })
  );
}

export function currentUserIdSelector(store: Store<any>): Observable<number> {
  return currentUserSelector(store).pipe(
    map(user => user.authenticated ? user.id : null),
    distinctUntilChanged()
  );
}

export function isImpersonatingSelector(store: Store<any>): Observable<boolean> {
  return store.select('currentUser').pipe(
    map(x => build(CurrentUser, x).impersonating ? true : false)
  );
}

export function startDateSelector(store: Store<any>): Observable<Date> {
  return combineLatest(routeParamSelector(store, 'startDate'), currentUserSelector(store), (startDate, user) => {
    if (startDate) {
      return DateHelper.ParseDateDashes(startDate);
    }
    const dateRange = buildDateRange(user.agendaDateRangeId);
    return dateRange.startDate;
  }).pipe(distinctUntilChanged());
}

export function endDateSelector(store: Store<any>): Observable<Date> {
  return combineLatest(routeParamSelector(store, 'endDate'), currentUserSelector(store), (endDate, user) => {
    if (endDate) {
      return DateHelper.ParseDateDashes(endDate);
    }
    const dateRange = buildDateRange(user.agendaDateRangeId);
    return dateRange.endDate;
  }).pipe(distinctUntilChanged());
}

export function dateRangeIdSelector(store: Store<any>): Observable<number> {
  return currentUserSelector(store).pipe(
    map(x => x.agendaDateRangeId),
    distinctUntilChanged()
  );
}

export function lastDateRangeIdSelector(store: Store<any>): Observable<number> {
  return store.select('dateRange');
}

export function activeGroupIdSelector(store: Store<any>): Observable<number> {
  return store.select('groupId');
}

export function dateRangeSelector(store: Store<any>): Observable<DateRange> {
  return combineLatest([dateRangeIdSelector(store), lastDateRangeIdSelector(store), routeParamsSelector(store, ['startDate', 'endDate'])]).pipe(
    map(x => {
      if (x[2] && (x[2].startDate || x[2].endDate)) {
        return build(DateRange, {
          startDate: x[2] && x[2].startDate ? DateHelper.ParseDateDashes(x[2].startDate) : new Date(),
          endDate: x[2] && x[2].endDate ? DateHelper.ParseDateDashes(x[2].endDate) : new Date()
        });
      }
      if (x[1] && x[1] > 1) {
        return buildDateRange(x[1]);
      }
      return buildDateRange(x[0]);
    })
  );
}

export function defaultGroupIdSelector(store: Store<any>): Observable<number> {
  return currentUserSelector(store).pipe(
    map(x => x.defaultGroupId),
    distinctUntilChanged()
  );
}

export function filesSelector(store: Store<any>): Observable<Files> {
  return store.select('files');
}

export function lookupAccountStatuses(store: Store<any>): Observable<Lookup> {
  return store.select('lookup').pipe(map(lookup => lookup['AccountStatuses']));
}

export function passwordResetCodeSelector(store: Store<any>): Observable<string> {
  return currentUserSelector(store).pipe(
    map(user => user.passwordResetCode),
    distinctUntilChanged()
  );
}

export function redirectToSelector(store: Store<any>): Observable<string> {
  return combineLatest([currentUserAccountUrlSelector(store), routeParamSelector(store, 'redirectTo', null)]).pipe(
    map(x => {
      return x[1] === '/dashboard' && truthy(x[0]) ? `${x[0]}/dashboard` : x[1];
    }),
    distinctUntilChanged()
  );
}

export function searchSelector(store: Store<any>): Observable<any> {
  return store.select('search');
}

// export function searchResultsSelector(store: Store<any>): Observable<SearchResult[]> {
//   return searchSelector(store).pipe(
//     map(x => x.asArray)
//   );
// }

export function sortDirectionSelector(store: Store<any>): Observable<'asc' | 'desc' | ''> {
  return routeParamSelector(store, 'sortDirection').pipe(
    map((x: string) => x ? (x.toLowerCase() === 'asc' ? 'asc' : (x.toLowerCase() === 'desc' ? 'desc' : '')) : 'asc')
  );
}

export function userSelector(store: Store<any>): Observable<User> {
  return usersSelector(store).pipe(
    map(users => users.active || new User()),
    distinctUntilChanged()
  );
}

export function activeUserSelector(store: Store<any>): Observable<User> {
  return combineLatest([usersSelector(store), routeParamIdSelector(store, 'userId')]).pipe(
    map(x => x[0].get(x[1]))
  );
}

export function userIdSelector(store: Store<any>, id: number): Observable<User> {
  return usersSelector(store).pipe(
    map(users => users.items[id] || new User()),
    distinctUntilChanged()
  );
}

export function userLastActiveSelector(store: Store<any>): Observable<Date> {
  return currentUserSelector(store).pipe(map(x => new Date(x['lastActive'])));
}

export function userEmailSelector(store: Store<any>): Observable<string> {
  return currentUserSelector(store).pipe(
    map(x => x.email),
    distinctUntilChanged()
  );
}

export function userNameSelector(store: Store<any>): Observable<string> {
  return currentUserSelector(store).pipe(
    map(x => x.fullName),
    distinctUntilChanged()
  );
}

export function userAccessLevelSelector(store: Store<any>): Observable<'PUBLIC' | 'MEMBER' | 'GROUP_ADMIN' | 'ACCOUNT_ADMIN' | 'SYS_ADMIN'> {
  return combineLatest([currentUserAccountsSelector(store), accountIdSelector(store), authenticatedSelector(store)]).pipe(
    map(x => {
      if (x[2] && toArray(x[0]).length === 0) {
        return null;
      }
      if (toArray(x[0]).findIndex(y => y.accountRoleId === 1) !== -1) {
        return 'SYS_ADMIN';
      }
      const roles = toArray(x[0]).filter(y => y.accountId === x[1]);
      if (roles.length === 0) {
        return 'PUBLIC';
      }
      if (roles.findIndex(y => y.accountRoleId === 2) !== -1) {
        return 'ACCOUNT_ADMIN';
      }
      if (roles.findIndex(y => y.accountRoleId === 3) !== -1) {
        return 'GROUP_ADMIN';
      }
      if (roles.findIndex(y => y.accountRoleId === 4) !== -1) {
        return 'MEMBER';
      }
    })
  );
}

export function isPublicUserSelector(store: Store<any>): Observable<boolean> {
  return userAccessLevelSelector(store).pipe(
    map(x => x === 'PUBLIC'),
    distinctUntilChanged()
  );
}

export function contentHeightSelector(store: Store<any>): Observable<number> {
  return windowHeightSelector(store).pipe(
    filter(x => x !== 0),
    map(x => x - 173),
    distinctUntilChanged()
  );
}

export function contentWidthSelector(store: Store<any>): Observable<number> {
  return windowWidthSelector(store).pipe(
    map(x => x - .01 * x),
    distinctUntilChanged()
  );
}

export function viewModeSelector(store: Store<any>): Observable<'NORMAL' | 'BUILD' | 'PROJECTOR' | 'SLIDESHOW'> {
  return store.select('viewMode');
}
