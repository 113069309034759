<am-dialog-confirm [closeCallback]="dialogCloseCallback.bind( this )"></am-dialog-confirm>
<header>
    <h1 *ngIf="agendaItemName">{{ agendaItemName }}</h1>
    <div class="wrapper">
        <div>
            <button
                mat-button
                class="trigger-label font-effect-3d-float"
                [class.active-link]="notesActive"
                (click)="onClickNotes( $event )"
            >
                My Notes
            </button>
            <button
                *ngIf="isMinuteTaker"
                mat-button class="trigger-label font-effect-3d-float"
                [class.active-link]="minutesActive"
                (click)="onClickMinutes( $event )"
            >
                My Minutes
            </button>
        </div>
        <div class="arrows">
            <button
                class="arrow up"
                (click)="previousItem( $event )"
            >
                <mat-icon>expand_less</mat-icon>
            </button>
            <button
                class="arrow down"
                (click)="nextItem( $event )"
            >
                <mat-icon>expand_more</mat-icon>
            </button>
        </div>
        <div>
            <button
                *ngIf="saveButtonActive"
                mat-raised-button
                color="accent"
                class="btn font-effect-3d-float"
                (click)="onSave( $event )"
            >
                <mat-icon>save</mat-icon>
                <span class="icon-text">Save</span>
            </button>
            <button
                *ngIf="deleteButtonActive"
                mat-raised-button
                class="btn btn-delete font-effect-3d-float"
                (click)="onDelete( $event )"
            >
                <mat-icon>delete</mat-icon>
                <span class="icon-text">Delete</span>
            </button>
        </div>
        <div class="end-group">
            <button
                mat-raised-button
                class="btn font-effect-3d-float"
                (click)="onClickCLose( $event )"
            >
                Close
            </button>
        </div>
    </div>
</header>
<main>
    <form
        iuForm
        [formGroup]="form"
    >
        <div id="editorNotes">
            <iu-editor
                id="editor"
                formControlName="notes"
                [height]="300"
                type="FULL"
                [init]="editorArgs"
                [plugins]="editorPlugins"
                [toolbar]="editorToolbar"
                (onBeforeExecCommand)="changeEditor($event)"
            ></iu-editor>
        </div>
    </form>
</main>

