type stringOrObject = string | {}

type simpleObject = { [ key: string ]: any }

export type PopoutMessageDefaultAction = 'close'

export const isDefaultAction = ( key: stringOrObject ): key is PopoutMessageDefaultAction => ( [
    'close'
] as stringOrObject[] ).includes( key )

export type PostMessagePayload<Data = simpleObject, Actions = null> = {
    action: PopoutMessageDefaultAction | Actions | {}
    data: Data
}

export type PopoutMessageEvent<Data = simpleObject, Actions = null> = MessageEvent<PostMessagePayload<Data, Actions>>

