<div class="tags">
  <form [formGroup]="form">
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Choose Account</mat-label>
        <mat-select formControlName="accountId" (selectionChange)="accountId = $event.value">
          <mat-option *ngFor="let member of accounts" [value]="member.accountId">{{ member.accountName }}
          </mat-option>
        </mat-select>
        <mat-hint>Tags for agenda items within this account.</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Choose Group</mat-label>
        <mat-select formControlName="groupId">
          <mat-option [value]="0">All Groups</mat-option>
          <mat-option *ngFor="let group of groups" [value]="group.id">{{ group.name }}</mat-option>
        </mat-select>
        <mat-hint>Tags for agenda items within this group.</mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field class="name-control" appearance="fill">
      <mat-label>Tag Name</mat-label>
      <input matInput placeholder="Search tags or enter new tag" formControlName="name" (keyup.enter)="onAdd($event)">
      <mat-hint>This will be the text that appears on the tag.</mat-hint>
      <button class="btn-add" (click)="onAdd($event)" mat-button>
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <div class="tags-list scrollable" [class.empty]="filteredTags.length === 0">
    <mat-chip-list cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="onDrop($event)">
      <mat-chip class="inline-tag" cdkDrag *ngFor="let tag of filteredTags" (click)="openTag(tag)"
        [style.background]="tag.colorHex" [class.readonly]="!tag.userCanEdit">
        <span class="font-effect-outline">{{ tag.name }}</span>
        <mat-icon class="font-effect-outline" (click)="onRemove($event, tag)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <p class="no-results" *ngIf="filteredTags.length === 0">No tags match search criteria.</p>
  </div>
</div>
