import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'am-arrows',
  templateUrl: './arrows.component.html',
  styleUrls: ['./arrows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowsComponent {

  @Output() clickUp = new EventEmitter();
  @Output() clickDown = new EventEmitter();

  constructor() { }

}
