import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { routeNameSelector, SmartComponent } from '@caiu/library';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { Tab, Tabs } from './tabs.model';
import { isGroupAdminSelector } from 'src/app/members/members.reducer';
import { WidgetsMenuComponent } from '../widgets/widgets-menu/widgets-menu.component';
import { Widget } from '../widgets/widgets.model';
import { accountUrlSelector } from 'src/app/accounts/accounts.reducer';
import { userAccessLevelSelector } from '../selectors';
import { map } from 'rxjs/operators';
import {
  DropdownItem,
  DropdownOptions
} from '../dropdown/dropdown.model'
import { Logger } from '../utils';

type UserAccessLevels = 'PUBLIC' | 'MEMBER' | 'GROUP_ADMIN' | 'ACCOUNT_ADMIN' | 'SYS_ADMIN'

@Component({
  selector: 'am-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [
    trigger('toggle', [state('show', style({ width: '55%' })), state('hide', style({ width: '0' })), transition('show <=> hide', [animate('300ms ease-out')])])
  ]
})
export class TabsComponent extends SmartComponent implements OnInit {
  @Input() url = '';
  @ViewChild('widgetsMenu') widgetsMenu: WidgetsMenuComponent;
  isGroupAdmin$: Observable<boolean>;
  lkpWidgets$: Observable<Widget[]>;
  routeName$: Observable<string>;
  showWidgetsMenu = false;
  tabs$: Observable<Tab[]>;
  tabs: Tab[] = [];
  userAccessLevel$: Observable<UserAccessLevels>
  accountUrl$: Observable<string>
  accountUrl = ''
  _hoverIndex = -1;
  private readonly logger = new Logger( TabsComponent.name )

  constructor(public store: Store<any>, private router: Router) {
    super(store);
    this.isGroupAdmin$ = isGroupAdminSelector(store);
    this.routeName$ = routeNameSelector(store);
    this.userAccessLevel$ = userAccessLevelSelector(store);
    this.accountUrl$ = accountUrlSelector( store )
    this.tabs$ = combineLatest([this.accountUrl$, this.userAccessLevel$]).pipe(
      map(x => Tabs.BuildTabs(x[0], x[1]))
    );
  }

  get activeIndex(): number {
    return this.tabs.findIndex(tab => this.isActive(tab));
  }

  get activeTab(): Tab {
    return this.tabs.find(tab => tab.isActive) || this.tabs[0] || new Tab();
  }

  get currentUrl(): string {
    return this.router.url;
  }

  get hoverIndex(): number {
    return this._hoverIndex;
  }

  set hoverIndex(value: number) {
    this._hoverIndex = value;
  }

  get submenu(): Tab[] {
    return this.activeTab.submenu;
  }

  get dropdownOptions(): DropdownOptions {
    return {
      label: 'Agenda Management',
      actionElement: 'li',
      icon: 'arrow_drop_down'
    }
  }

  get dropdownItems(): DropdownItem[] {
    return [
      {
        label: 'Templates',
        route: `/${ this.accountUrl }/templates`
      },
      {
        label: 'Tags',
        route: `/${ this.accountUrl }/tags`
      }
    ]
  }

  ngOnInit() {
    this.sync( [
      'accountUrl',
      'tabs'
    ] )
  }

  onEnter(index: number) {
    if (index !== this.hoverIndex) {
      this.hoverIndex = index;
    }
  }

  onExit() {
    this.hoverIndex = -1;
  }

  onToggleDone(e) {
    if (this.widgetsMenu) {
      this.widgetsMenu.toggle();
    }
  }

  isActive(tab: Tab): boolean {
    return this.currentUrl.includes(tab.href);
  }

  isBeforeActive(index: number): boolean {
    return index === this.activeIndex - 1;
  }

  isAfterActive(index: number): boolean {
    return index === this.activeIndex + 1;
  }

  isBeforeHover(index: number): boolean {
    return this.hoverIndex !== -1 && index === this.hoverIndex - 1;
  }

  isAfterHover(index: number): boolean {
    return this.hoverIndex !== -1 && index === this.hoverIndex + 1;
  }
}
