import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { CurrentUserComponent } from './current-user.component';
import { CurrentUserDialogComponent } from './current-user-dialog.component';
import { CurrentUserAccountsComponent } from './current-user-accounts.component';

@NgModule({
  declarations: [
    CurrentUserComponent,
    CurrentUserDialogComponent,
    CurrentUserAccountsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    CurrentUserComponent,
  ]
})
export class CurrentUserModule { }
