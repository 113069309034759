import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { build, toArray } from '@caiu/library';

import { Tag } from '../tags.model';

@Component({
  selector: 'am-inline-tags',
  templateUrl: './inline-tags.component.html',
  styleUrls: ['./inline-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineTagsComponent implements OnInit {

  @Input() tags: Tag[] = [];
  @Input() showAll = false;

  constructor() { }

  get allTags(): Tag[] {
    return toArray(this.tags);
  }

  get first(): Tag {
    return this.allTags.length > 0 ? build(Tag, this.allTags[0]) : null;
  }

  get hasMore(): boolean {
    return this.more > 0;
  }

  get more(): number {
    return this.allTags.length - 1;
  }

  get moreText(): string {
    return this.hasMore ? this.tags.reduce((acc, x, i) => i > 0 ? acc === '' ? x.name : `${acc}, ${x.name}` : acc, '') : null;
  }

  ngOnInit(): void {
  }

}
