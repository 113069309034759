import { NgModule, Injectable } from '@angular/core';
import {
  Routes,
  RouterModule,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { DialogAction, build, DialogModel } from '@caiu/library';
import { Observable, of } from 'rxjs';

import { BinComponent } from './bin.component';

@Injectable()
export class BinResolver implements Resolve<DialogModel> {
  constructor() { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<DialogModel> {
    const actions = [
      build(DialogAction, { value: 'close', label: 'Close', primary: true })
    ];
    return of(build(DialogModel, { title: 'Bin', width: '1000px', actions }));
  }
}

const routes: Routes = [
  {
    path: 'bin',
    outlet: 'popup',
    component: BinComponent,
    data: { routeName: 'bin', routeLabel: 'Bin' },
    resolve: {
      dialog: BinResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [BinResolver]
})
export class BinItemsRoutingModule { }
