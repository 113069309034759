import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FilterColumn, GridFilter, FilterCondition } from './filter.model';

@Component({
  selector: 'am-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() records: MatTableDataSource<any> | any[];
  @Input() columns: FilterColumn[];
  @Output() onFilter = new EventEmitter<any[]>();
  conditions: FilterCondition[] = [
    new FilterCondition('contains', 'Contains'),
    new FilterCondition('not contain', 'Does not contain'),
    new FilterCondition('starts', 'Starts with'),
    new FilterCondition('ends', 'Ends with'),
    new FilterCondition('equal', 'Is equal to'),
    new FilterCondition('not equal', 'Is not equal to')
  ];
  id = 0;
  filters: GridFilter[] = [];

  constructor() { }

  get unfilteredRecords(): any[] {
    return this.records instanceof MatTableDataSource
      ? this.records.data
      : this.records;
  }

  ngOnInit(): void {

  }

  addFilter() {
    this.filters.push({
      id: ++this.id,
      column: null,
      condition: null,
      value: ''
    });
  }

  handleDate(e: any, filter: GridFilter) {
    filter.value = e.target.value;

    this.filterRecords();
  }

  handleInput(e: any, filter: GridFilter) {
    filter.value = e.target.value;

    this.filterRecords();
  }

  handleSelection(e: any, filter: GridFilter) {
    const column = <FilterColumn>e.value;

    filter.column = column;

    if (filter.column.type === 'string') {
      filter.condition = this.conditions[0];
    }
  }

  handleCondition(e: any, filter: GridFilter) {
    const condition = <FilterCondition>e.value;

    filter.condition = condition;
  }

  filterByDate(toFilter: any[], filter: GridFilter): any[] {
    let filtered = toFilter.filter(record => {
      const propName = filter.column.name;
      const value = new Date(record[propName]);
      const filterDate = new Date(filter.value);

      return value.getDate() === filterDate.getDate()
        && value.getMonth() === filterDate.getMonth()
        && value.getFullYear() === filterDate.getFullYear();
    });

    return filtered;
  }

  filterByPhoneNumber(toFilter: any[], filter: GridFilter): any[] {
    let filtered = toFilter.filter(record => {
      const propName = filter.column.name;
      const value = <string>record[propName] ? <string>record[propName] : '';
      const plainValue = value.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
      const specialValue = `(${plainValue.substring(0, 3)}) ${plainValue.substring(3, 6)}-${plainValue.substring(6)}`;

      return plainValue.toLowerCase().startsWith(filter.value.toLowerCase())
        || specialValue.toLowerCase().startsWith(filter.value.toLowerCase());
    });

    return filtered;
  }

  filterByString(toFilter: any[], filter: GridFilter): any[] {
    let filtered = toFilter.filter(record => {
      const propName = filter.column.name;
      const value = <string>record[propName] ? <string>record[propName] : '';

      switch (filter.condition.name) {
        case 'contains':
          return value.toString().toLowerCase().indexOf(filter.value.toLowerCase()) > -1
        case 'not contain':
          return value.toString().toLowerCase().indexOf(filter.value.toLowerCase()) === -1
        case 'starts':
          return value.toString().toLowerCase().startsWith(filter.value.toLowerCase())
        case 'ends':
          return value.toString().toLowerCase().endsWith(filter.value.toLowerCase())
        case 'equal':
          return value.toString().toLowerCase() === filter.value.toLowerCase()
        case 'not equal':
          return value.toString().toLowerCase() !== filter.value.toLowerCase()
        default:
          return value.toString().toLowerCase().startsWith(filter.value.toLowerCase());
      }
    });

    return filtered;
  }

  filterByStringArray(toFilter: any[], filter: GridFilter): any[] {
    let filtered = toFilter.filter(record => {
      const propName = filter.column.name;
      const values = <string[]>record[propName] ? <string[]>record[propName] : [''];

      return values.findIndex(x => x.toLowerCase().startsWith(filter.value.toLowerCase())) > -1;
    });

    return filtered;
  }

  filterRecords(unfiltered: any[] = []) {
    let filtered = [];
    let useFiltered = false;

    if (this.filters && this.filters.length > 0) {
      this.filters.forEach((filter, index) => {
        if (filter.column) {
          const toFilter = useFiltered
            ? filtered
            : unfiltered.length > 0
              ? unfiltered
              : this.unfilteredRecords;

          switch (filter.column.type) {
            case 'string':
              filtered = this.filterByString(toFilter, filter);
              break;
            case 'date':
              filtered = this.filterByDate(toFilter, filter);
              break;
            case 'string[]':
              filtered = this.filterByStringArray(toFilter, filter);
              break;
            case 'phone':
              filtered = this.filterByPhoneNumber(toFilter, filter);
              break;
            default:
              break;
          }

          if (!useFiltered)
            useFiltered = true;
        }
      });
    } else {
      filtered = unfiltered.length > 0
        ? unfiltered
        : this.unfilteredRecords;
    }
    this.onFilter.emit(filtered);
  }

  getUnfilteredColumns(column: FilterColumn): FilterColumn[] {
    let unfilteredColumns: FilterColumn[] = [];

    if (column)
      unfilteredColumns.push(column);

    unfilteredColumns.push(...this.columns.filter(column => {
      return this.filters.findIndex(x => x.column && x.column.name && x.column.name.toLowerCase() === column.name.toLowerCase()) === -1;
    }));

    return unfilteredColumns;
  }

  removeFilter(filter: GridFilter) {
    const index = this.filters.findIndex(x => x.id === filter.id);

    this.filters.splice(index, 1);

    this.filterRecords();
  }
}
