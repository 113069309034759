import { build, Action, DateHelper, inArray, routeParamSelector, toInt, lazyRouteParamsSelector, routeParamIdSelector } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of, merge } from 'rxjs';
import { map, filter, distinctUntilChanged, skip, startWith } from 'rxjs/operators';

import { Meeting, Meetings, MeetingsQuery } from './meetings.model';
import { accountIdSelector } from '../accounts/accounts.reducer';
import { Agenda } from '../agendas/agendas.model';
import { agendasSelector } from '../agendas/agendas.reducer';
import { activeGroupIdSelector, dateRangeSelector, defaultGroupIdSelector } from '../shared/selectors';
import { buildQueryStringFromObject } from '../shared/utils';

export class MeetingsActions {
  static ACTIVATE = '[Meetings] Activate';
  static GET = '[Meetings] Get';
  static POST = '[Meetings] POST';
  static POST_ERROR = '[Meetings] POST ERROR';

  static activate(id: number): Action {
    return {
      type: MeetingsActions.ACTIVATE,
      payload: id
    };
  }
}

export class MeetingActions {
  static DELETE = '[Meeting] DELETE';
  static DELETE_ERROR = '[Meeting] DELETE ERROR';
  static GET = '[Meeting] Get';
  static GET_ERROR = '[Meeting] Get Error';
  static PUT = '[Meeting] PUT';
  static PUT_STREAM = '[Meeting] PUT STREAM';
  static PUT_ERROR = '[Meeting] PUT ERROR';
}

export function meetingsReducer(state: Meetings = new Meetings(), action: Action): Meetings {

  switch (action.type) {
    case MeetingsActions.ACTIVATE:
      return build(Meetings, state.activate(<number>action.payload));

    case MeetingsActions.GET:
      return state.update(<Meeting[]>action.payload);

    case MeetingsActions.POST:
      return build(Meetings, state.update(<Meeting[]>action.payload));

    case MeetingActions.GET:
    case MeetingActions.PUT:
    case MeetingActions.PUT_STREAM:
    case MeetingActions.DELETE:
      return state.update(action.payload);

    default:
      return state;
  }
}

export function meetingsSelector(store: Store<any>): Observable<Meetings> {
  return store.select('meetings');
}

export function deletedMeetingsSelector(store: Store<any>): Observable<Meeting[]> {
  return combineLatest(accountIdSelector(store), meetingsSelector(store), (accountId, meetings) =>
    meetings.asArray.filter(meeting => meeting.markedForDelete && (accountId === 6 || meeting.accountId === accountId))
  );
}

export function meetingSelector(store: Store<any>): Observable<Meeting> {
  return combineLatest([meetingsSelector(store), routeParamIdSelector(store, 'meetingId'), agendasSelector(store), routeParamIdSelector(store, 'agendaId')]).pipe(
    map(x => {
      return build(Meeting, x[0].get(x[1]), { agendas: x[2].asArray.filter(y => (y.meetingId === x[1] || y.id === x[3]) && !y.markedForDelete && typeof (y.id) === 'number') });
    })
  );
}

export function meetingAgendasSelector(store: Store<any>): Observable<Agenda[]> {
  return meetingSelector(store).pipe(map(x => x.agendas));
}

export function meetingAgendaSelector(store: Store<any>): Observable<Agenda> {
  return meetingAgendasSelector(store).pipe(
    map(agendas => {
      if (agendas.length > 0) {
        const active = agendas.find(agenda => agenda.id === this.agendaId);
        return active || agendas[0];
      }
      return new Agenda();
    })
  );
}

export function meetingsQueryGroupIdSelector(store: Store<any>): Observable<number> {
  return combineLatest([
    routeParamIdSelector(store, 'groupId'),
    activeGroupIdSelector(store),
    defaultGroupIdSelector(store)
  ]).pipe(
    map(x => {
      if (x[0]) {
        return x[0];
      }
      if (x[1] !== null) {
        return x[1];
      }
      return x[2];
    }),
    distinctUntilChanged()
  );
}

export function meetingsQuerySelector(store: Store<any>): Observable<MeetingsQuery> {
  return combineLatest(
    [routeParamIdSelector(store, 'skip'),
    routeParamIdSelector(store, 'take'),
    routeParamSelector(store, 'term'),
    routeParamSelector(store, 'sortBy'),
    routeParamSelector(store, 'sortDirection'),
    accountIdSelector(store),
    meetingsQueryGroupIdSelector(store),
    dateRangeSelector(store)]).pipe(
      map(x => {
        return build(MeetingsQuery, {
          skip: x[0],
          take: x[1] || 10,
          term: x[2],
          sortBy: x[3],
          sortDirection: x[4],
          accountId: x[5],
          groupId: x[6],
          startDate: x[7].startDate,
          endDate: x[7].endDate
        });
      }),
      filter(q => q.accountId !== 0),
      distinctUntilChanged((x, y) =>
        x.accountId === y.accountId
        && x.skip === y.skip
        && x.take === y.take
        && x.term === y.term
        && x.sortBy === y.sortBy
        && x.sortDirection === y.sortDirection
        && x.groupId === y.groupId
        && DateHelper.IsSameDay(x.startDate, y.startDate)
        && DateHelper.IsSameDay(x.endDate, y.endDate))
    );
}

export function meetingsQueryStringSelector(store: Store<any>): Observable<string> {
  return meetingsQuerySelector(store).pipe(
    map(q => buildQueryStringFromObject({
      skip: q.skip,
      take: q.take,
      term: q.term,
      sortBy: q.sortBy,
      sortDirection: q.sortDirection,
      accountId: q.accountId,
      groupId: q.groupId,
      startDate: DateHelper.FormatDateDashes(q.startDate),
      endDate: DateHelper.FormatDateDashes(q.endDate)
    })),
    distinctUntilChanged()
  );
}

export function meetingsSearchSelector(store: Store<any>): Observable<Meeting[]> {
  return combineLatest(meetingsSelector(store), meetingsQueryStringSelector(store), agendasSelector(store), (meetings, queryString, agendas) => {
    const url = `meetings${queryString}`;
    return meetings.asArray.filter(x => !x.markedForDelete && inArray(x.matches, url))
      .map(x => build(Meeting, x, { agendas: agendas.asArray.filter(y => y.meetingId === x.id && !y.markedForDelete) }));
  });
}

export function meetingDateSelector(store: Store<any>): Observable<Date> {
  return meetingSelector(store).pipe(
    map(x => x.startDateTime),
    distinctUntilChanged()
  );
}

export function meetingStartTimeSelector(store: Store<any>): Observable<string> {
  return meetingSelector(store).pipe(
    map(x => x.startTime),
    distinctUntilChanged()
  );
}

export function meetingEndTimeSelector(store: Store<any>): Observable<string> {
  return meetingSelector(store).pipe(
    map(x => x.endTime),
    distinctUntilChanged()
  );
}

export function meetingLocationSelector(store: Store<any>): Observable<string> {
  return meetingSelector(store).pipe(
    map(x => x.location),
    distinctUntilChanged()
  );
}

export function meetingNameSelector(store: Store<any>): Observable<string> {
  return meetingSelector(store).pipe(
    map(x => x.name),
    distinctUntilChanged()
  );
}

export function meetingSecurityStatusIdSelector(store: Store<any>): Observable<number> {
  return meetingSelector(store).pipe(
    map(x => x.securityStatusId),
    distinctUntilChanged()
  );
}

export function meetingUrlSelector(store: Store<any>): Observable<string> {
  return meetingSelector(store).pipe(
    map(x => x.videoConferenceUrl),
    distinctUntilChanged()
  );
}
