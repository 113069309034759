import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Audit } from '@caiu/library';

@Component({
  selector: 'am-audit-info',
  templateUrl: './audit-info.component.html',
  styleUrls: ['./audit-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditInfoComponent {

  @Input() audit: Audit;

  constructor() { }

}
