import { Component, ContentChild, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { build, DialogModel, SmartComponent, RouterActions } from '@caiu/library';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'am-dialog-route',
  templateUrl: './dialog-route.component.html',
  styleUrls: ['./dialog-route.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogRouteComponent extends SmartComponent implements OnInit {
  @ContentChild('outlet') outlet;
  dialog$: BehaviorSubject<DialogModel> = new BehaviorSubject<DialogModel>(new DialogModel());
  routeName = 'dialog';

  constructor(public store: Store<any>, public dialog: MatDialog) {
    super(store);
  }

  ngOnInit() { }

  onActivate(component: any) {
    this.dispatch(RouterActions.activate(component.routeName, this.routeName));
    this.dialog$.next(
      build(DialogModel, {
        title: component.title,
        actions: component.actions
      })
    );
  }

  onDeactivate(e: any) {
    this.dispatch(RouterActions.activate(this.routeName));
  }
}
