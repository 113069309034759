import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core'
import { Subscription } from 'rxjs'

import {
    PopoutMessageEvent,
    PostMessagePayload,
    isDefaultAction
} from './popout.model'
import { Logger } from '../shared/utils'
import { PopoutService } from './popout.service'

@Component( {
    selector: 'am-popout',
    templateUrl: './popout.component.html',
    styleUrls: [ './popout.component.scss' ]
} )
export class PopoutComponent<Data = { [ key: string ]: any } | null, Actions = ''> implements OnInit, OnDestroy {
    @Input() buttonLabel: string = ''
    @Input() buttonIcon: string = 'launch'
    @Input() height?: number = null
    @Input() menuBar = false
    @Input() messageHandler: ( event: PostMessagePayload<Data, Actions> ) => void
    @Input() resizable = true
    @Input() scrollbars = true
    @Input() target: string
    @Input() url: string
    @Input() width?: number = null
    @Input() clickHandler: ( event: MouseEvent, isOpen: boolean ) => void

    readonly logger = new Logger( PopoutComponent.name )

    protected window: Window | null = null
    protected popoutServiceSubscription: Subscription
    public isOpen = false


    constructor(
        private popoutService: PopoutService
    ) {
        this.popoutServiceSubscription = popoutService.messageToPopout$.subscribe( ( data: PostMessagePayload<Data, Actions> ) => {
            this.logger.log( 'popoutSubscription', { data } )
            this.sendMessage( data )
        } )
    }
    
    get windowOrigin() {
        return `${ window.location.origin }${ this.url }`
    }

    private buildFeatures() {
        return `popup,${
            'status'
        },${
            'location=false'
        },${
            'toolbar=false'
        },${
            this.menuBar ? 'menubar' : ''
        },${
            this.resizable ? 'resizable' : ''
        },${
            this.scrollbars ? 'scrollbars' : ''
        },${
            this.height && this.height >= 100 ? `height=${ this.height }` : ''
        },${
            this.width && this.width >= 100 ? `width=${ this.width }` : ''
        }`
    }

    ngOnInit() {}

    //* Cleanup child window if user leaves page for any reason
    ngOnDestroy() {
        this.popoutServiceSubscription.unsubscribe()
        
        if ( this.window && !this.window.closed ) {
            this.logger.log( 'ngOnDestroy cleaning up window' )
            this.closeWindow()
        }
    }

    @HostListener( 'window:beforeunload', [ '$event ' ] )
    protected handleUnload( event: BeforeUnloadEvent ) {
        this.logger.log( 'handleUnload', event )
        this.closeWindow()
    }

    protected handleMessages( data: PostMessagePayload<Data, Actions> ) {     
        if ( !isDefaultAction( data.action ) ) {
            this.logger.log( 'handleMessages non-default message found' )
            this.messageHandler && this.messageHandler( data )

            return
        }

        switch( data.action ) {
            case 'close':
                this.logger.log( 'handleMessage close event', { data: data } )
                this.isOpen = false
                this.window = null
                this.messageHandler && this.messageHandler( data )
                
                break
            default:
                this.logger.log( `handleMessages no handler for action "${ data.action }" provided.` )
        }
    }

    @HostListener( 'window:message', [ '$event' ] )
    handleMessagesListener( event: PopoutMessageEvent<Data, Actions> ) {
        this.logger.log( 'handleMessages' )
        this.handleMessages( event.data )
    }

    protected sendChildMessage( payload: PostMessagePayload<Data, Actions> ) {
        if ( !this.window ) {
            this.logger.warn( 'sendChildMessage no child window present to send message to.' )

            return
        }

        this.window.postMessage( payload, this.windowOrigin )
    }

    //* Method for parent components to call to send messages to the child window
    sendMessage( payload: PostMessagePayload<Data, Actions> ) {
        this.sendChildMessage( payload )
    }

    closeWindow() {
        this.logger.log( 'closeWindow' )

        const payload: PostMessagePayload<Data, Actions> = {
            action: 'close',
            data: null
        }

        this.sendChildMessage( payload )
        this.window = null
        this.isOpen = false
    }

    togglePopout( event: MouseEvent ) {
        this.logger.log( 'togglePopout' )

        event.stopPropagation()

        this.isOpen = !this.isOpen

        if ( !this.isOpen ) {
            this.closeWindow()

            this.clickHandler && this.clickHandler( event, this.isOpen )

            return
        }

        this.window = window.open(
            this.url,
            this.target,
            this.buildFeatures()
        )

        this.clickHandler && this.clickHandler( event, this.isOpen )
    }
}
