import { Action, routeParamIdSelector, build, compareDates, compareStrings } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { Attendance, Attendee } from './attendance.model';
import { agendaGroupMembersSelector } from '../members/members.reducer';
import { GroupMemberEdit } from '../groups/groups.model';
import { map } from 'rxjs/operators';

export class AttendanceActions {
  static DELETE = '[Attendance] DELETE';
  static DELETE_ERROR = '[Attendance] DELETE ERROR';
  static GET = '[Attendance] GET';
  static POST = '[Attendance] POST';
  static POST_ERROR = '[Attendance] POST ERROR';
  static PUT = '[Attendance] PUT';
  static PUT_ERROR = '[Attendance] PUT ERROR';
  static SAVE = '[Attendance] SAVE';
  static SAVE_ERROR = '[Attendance] SAVE ERROR';
}

export function attendanceReducer(state: Attendance = new Attendance(), action: Action): Attendance {
  switch (action.type) {

    case AttendanceActions.GET:
      return state.update(action.payload);

    case AttendanceActions.POST:
    case AttendanceActions.PUT:
      return state.updateAttendee(action.payload);

    case AttendanceActions.SAVE:
      return state.replace(action.payload);

    case AttendanceActions.DELETE:
      return state.delete(action.payload);

    default:
      return state;
  }
}

export function attendanceSelector(store: Store<any>): Observable<Attendance> {
  return store.select('attendance');
}

export function agendaAttendanceSelector(store: Store<any>): Observable<Attendee[]> {
  return combineLatest([attendanceSelector(store), routeParamIdSelector(store, 'agendaId')]).pipe(
    map(x => {
      return x[0].asArray.filter(y => y.agendaId === x[1]).sort((a, b) => compareDates(a.timestamp, b.timestamp));
    })
  );
}

export function attendanceLogSelector(store: Store<any>): Observable<Attendee[]> {
  return combineLatest([agendaAttendanceSelector(store), agendaGroupMembersSelector(store), routeParamIdSelector(store, 'agendaId')]).pipe(
    map(x => {
      return x[0].map(z => {
        const member = build(GroupMemberEdit, x[1].find(y => y.userId === z.userId));
        return build(Attendee, z, {
          agendaId: x[2],
          userId: member.userId,
          userName: member.userName
        });
      })
        .filter(y => !(y.isPresent === null))
        .sort((a, b) => compareDates(a.timestamp, b.timestamp));
    })
  );
}

export function attendeesSelector(store: Store<any>): Observable<Attendee[]> {
  return combineLatest([agendaAttendanceSelector(store), agendaGroupMembersSelector(store), routeParamIdSelector(store, 'agendaId')]).pipe(
    map(x => {
      return x[1].map(y => {
        const attendee = x[0].find(z => z.userId === y.userId);
        return attendee ? build(Attendee, attendee, {
          agendaId: x[2],
          userId: y.userId,
          userName: y.userName
        }) : build(Attendee, attendee, {
          agendaId: x[2],
          userId: y.userId,
          userName: y.userName,
          isPresent: null
        });
      }
      ).sort((a, b) => compareStrings(`${a.userLastName} ${a.userFirstName}`, `${b.userLastName} ${b.userFirstName}`))
    })
  );
}
