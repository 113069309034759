import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {
  windowHeightSelector,
  windowWidthSelector,
  SmartComponent,
  routeParamSelector,
  routeNameSelector,
  isMobileSelector
} from '@caiu/library';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UserActions } from '../actions';

@Component({
  selector: 'am-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent extends SmartComponent implements OnInit {
  @Input() type: 'DEFAULT' | 'LOGIN' = 'DEFAULT';
  @Output() scrolling = new EventEmitter<number>();
  accountUrl = '';
  accountUrl$: Observable<string>;
  isMobile = false;
  isMobile$: Observable<boolean>;
  routeName = '';
  routeName$: Observable<string>;
  section = '';
  windowHeight = 0;
  windowHeight$: Observable<number>;
  windowWidth = 0;
  windowWidth$: Observable<number>;

  constructor(public store: Store<any>, private elementRef: ElementRef) {
    super(store);
    this.accountUrl$ = routeParamSelector(store, 'account');
    this.isMobile$ = isMobileSelector(store);
    this.routeName$ = routeNameSelector(store);
    this.windowHeight$ = windowHeightSelector(store);
    this.windowWidth$ = windowWidthSelector(store);
  }

  get canAnalyze(): boolean {
    return true;
  }

  get canExport(): boolean {
    return false;
  }

  get contentHeight(): number {
    return this.windowHeight - 173;
  }

  get containerWidth(): number {
    return this.windowWidth - 160;
  }

  get envIsLocal(): boolean {
    return environment.local;
  }

  get envIsDev(): boolean {
    return environment.dev;
  }

  get envIsTest(): boolean {
    return environment.test;
  }

  get envIsStaging(): boolean {
    return environment.staging;
  }

  get envIsProduction(): boolean {
    return environment.production;
  }

  get isDefault(): boolean {
    return this.type === 'DEFAULT';
  }

  get isLogin(): boolean {
    return this.type === 'LOGIN';
  }

  get showRoutes(): boolean {
    // return (
    //   (this.routeName === 'dashboard') &&
    //   (this.envIsLocal || this.envIsDev || this.envIsTest)
    // );
    return false;
  }

  get windowHeightChanges(): Subscription {
    return this.windowHeight$.subscribe(x => {
      this.windowHeight = x;
      if (x !== 0) {
        this.resetWindowHeight();
      }
    });
  }

  get windowWidthChanges(): Subscription {
    return this.windowWidth$.subscribe(x => {
      this.windowWidth = x;
      if (x !== 0) {
        this.resetWindowWidth();
      }
    });
  }

  ngOnInit() {
    this.sync(['accountUrl', 'isMobile', 'routeName']);
    this.subscribe([
      this.windowHeightChanges,
      this.windowWidthChanges
    ]);
  }

  onScroll(e) {
    const scrollTop = e.srcElement.scrollTop;
    this.scrolling.emit(scrollTop);
  }

  logout() {
    this.dispatch(UserActions.logout());
  }

  resetWindowHeight() {
    if (this.elementRef && this.elementRef.nativeElement) {
      this.elementRef.nativeElement.style.height = `${this.windowHeight}px`;
    }
  }

  resetWindowWidth() {
    if (this.elementRef && this.elementRef.nativeElement) {
      this.elementRef.nativeElement.style.width = `${this.windowWidth}px`;
    }
  }
}
