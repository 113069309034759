import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { TabsComponent } from './tabs.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { DropdownModule } from '../dropdown/dropdown.module';

@NgModule({
  declarations: [
    TabsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    WidgetsModule,
    MatButtonModule,
    MatIconModule,
    DropdownModule
  ],
  exports: [
    TabsComponent,
  ]
})
export class TabsModule { }
