<div class="email-recipients">
  <mat-form-field class="recipients-list" appearance="fill">
    <mat-label>{{ heading }}</mat-label>
    <mat-chip-list #chipList aria-label="Recipient selection">
      <mat-chip *ngFor="let recipient of recipients" (removed)="remove(recipient)" color="primary">
        {{recipient}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="New Recipient..."
        #recipientInput
        [formControl]="recipientCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        (blur)="onInputBlur()"
      >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let recipient of filteredRecipients | async" [value]="recipient">
        {{recipient}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
