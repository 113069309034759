import { Action, routeParamIdSelector, build, toArray } from '@caiu/library';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { Notes, AgendaItemNotes } from './notes.model';
import { currentUserIdSelector } from '../shared/selectors';
import { map } from 'rxjs/operators';


export class NotesActions {
  static DELETE = '[Notes] Delete Notes';
  static DELETE_QUIET = '[Notes] Delete Notes Quietly';
  static DELETE_ERROR = '[Notes] Delete Notes Error';
  static GET = '[Notes] Get Notes';
  static POST = '[Notes] Post Notes';
  static POST_ERROR = '[Notes] Post Notes Error';
  static PUT = '[Notes] Put Notes';
  static PUT_ERROR = '[Notes] Put Notes Error';
}

export function notesReducer(state: Notes = new Notes(), action: Action): Notes {
  switch (action.type) {
    case NotesActions.GET:
    case NotesActions.POST:
    case NotesActions.PUT:
      return action.payload ? state.update(action.payload) : state;

    case NotesActions.DELETE:
    case NotesActions.DELETE_QUIET:
      return state.delete(action.payload);

    default:
      return state;
  }
}

export function notesSelector(store: Store<any>): Observable<Notes> {
  return store.select('notes');
}

export function agendaItemNotesSelector(store: Store<any>): Observable<AgendaItemNotes> {
  return combineLatest([notesSelector(store), routeParamIdSelector(store, 'agendaItemId'), currentUserIdSelector(store)]).pipe(
    map(x => build(AgendaItemNotes, x[0].asArray.find(y => y.agendaItemId === x[1] && y.createdById === x[2]), {
      agendaItemId: x[1]
    }))
  );
}

export function agendaNotesSelector(store: Store<any>, agendaId$: Observable<number>): Observable<AgendaItemNotes[]> {
  return combineLatest([notesSelector(store), agendaId$, currentUserIdSelector(store)]).pipe(
    map(x => x[0].asArray.filter(y => y.agendaId === x[1] && y.createdById === x[2]))
  );
}
